import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import {
	fetchAwardsListMonths,
	type Month,
} from "@/api/admin/awards/list-months";
import {
	type Awards,
	fetchAwardsMonthlyRanking,
} from "@/api/admin/awards/monthly-ranking";
import {
	type Integration,
	listIntegrations,
} from "@/api/app/account/list-integrations";
import EyeIcon from "@/assets/eye.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import TrophyIcon from "@/assets/trophy.svg?react";
import { Modal } from "@/components/modal";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { useNavigationStore } from "@/store/navigation-store";
import { authorFallback } from "@/utils/mask-author-fallback";

export function Awards() {
	const [integrations, setIntegrations] = useState<Integration[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [award, setAward] = useState<Awards | null>(null);
	const [months, setMonths] = useState<Month[]>([]);
	const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
	const [showStrategiesModal, setShowStrategiesModal] = useState(false);

	async function fetchIntegrations() {
		setIsLoading(true);
		const response = await listIntegrations();

		if (response.status === "success") {
			setIntegrations(response.data);
		}

		setIsLoading(false);
	}

	const hasIntegrationActive = integrations.some(
		(integration) => integration.active,
	);

	const navigate = useNavigate();

	useEffect(() => {
		const paths = [
			{
				name: "Premiações",
				path: "/awards",
			},
		];

		setPaths(paths);
	}, []);

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	async function fetchRanking(month: string) {
		setSelectedMonth(month);
		setIsLoading(true);

		const response = await fetchAwardsMonthlyRanking({ month });

		if (response.status === "success") {
			setAward(response.data);
		}

		setIsLoading(false);
	}

	async function fetchMonths() {
		setIsLoading(true);
		const response = await fetchAwardsListMonths();

		if (response.status === "success") {
			setMonths(response.data);
		}

		setIsLoading(false);
	}

	useEffect(() => {
		const currentMonth = `${new Date().toLocaleString("default", { year: "numeric" })}-${new Date()
			.toLocaleString("default", { month: "numeric" })
			.padStart(2, "0")}`;

		setSelectedMonth(currentMonth);
		fetchRanking(currentMonth);
		fetchMonths();
		fetchIntegrations();
	}, []);

	const reorderedRanking: {
		id: string;
		user_id: string;
		user_name: string;
		image: string;
		strategy: null | string;
		total_sales: number;
	}[] = award ? [award.ranking[1], award.ranking[0], award.ranking[2]] : [];

	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (containerRef.current) {
			const middleIndex = Math.floor(reorderedRanking.length / 2);
			const middleElement = containerRef.current.children[
				middleIndex
			] as HTMLElement;
			if (middleElement) {
				middleElement.scrollIntoView({
					behavior: "auto",
					block: "nearest",
					inline: "center",
				});
			}
		}
	}, [reorderedRanking]);

	const shouldShowFooter = () => {
		if (!selectedMonth) return false;

		const today = new Date();
		const [year, month] = selectedMonth.split("-").map(Number);
		const lastDayOfMonth = new Date(year, month, 0).getDate();
		const selectedDate = new Date(year, month - 1, lastDayOfMonth);

		return today >= selectedDate;
	};

	return (
		<>
			<Helmet title="Premiações" />

			{!isLoading && !hasIntegrationActive && award?.ranking.length === 0 && (
				<div
					className="flex h-[calc(100vh-200px)] items-center justify-center bg-cover bg-center bg-no-repeat px-4 py-8 md:px-28 lg:px-56"
					style={{
						backgroundImage:
							"linear-gradient(180deg, rgba(11, 18, 18, 0.90) 83.72%, #0B1212 100%), url('/awards.png')",
					}}
				>
					<div className="flex w-full max-w-4xl flex-col items-center gap-10">
						<div className="flex flex-col items-center gap-4 text-center">
							<div className="flex h-16 w-16 items-center justify-center rounded-lg border border-[#013c20] bg-[#058c4b]/5 p-4">
								<div className="h-12 w-12 bg-transparent">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="48"
										height="48"
										viewBox="0 0 48 48"
										fill="none"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M22 31.8C17.4355 30.8734 14 26.8379 14 22V8H34V22C34 26.8379 30.5645 30.8734 26 31.8V36H30V40H18V36H22V31.8ZM30 12H18V22C18 25.3137 20.6863 28 24 28C27.3137 28 30 25.3137 30 22V12Z"
											fill="#058D4C"
										/>
										<path d="M12 12H8V22H12V12Z" fill="#058D4C" />
										<path d="M36 12H40V22H36V12Z" fill="#058D4C" />
									</svg>
								</div>
							</div>
							<h2 className="text-xl font-medium leading-tight text-white md:text-2xl">
								Realize integração com uma plataforma de afiliação e visualize o
								ranking de premiações!
							</h2>
						</div>
						<button
							className="rounded-lg bg-[#058c4b] px-6 py-3 text-base font-semibold text-white"
							onClick={() => navigate("/account/integrations")}
						>
							Realizar integração
						</button>
					</div>
				</div>
			)}

			{isLoading && !award && (
				<div className="flex flex-col items-center gap-8">
					<section className="flex w-full flex-col items-center gap-20">
						<div className="flex w-full flex-col justify-between gap-10 md:flex-row md:items-center">
							<div className="flex items-center gap-4">
								{Array.from({ length: 3 }).map((_, index) => (
									<Skeleton
										key={index}
										className={`flex h-10 items-center justify-center rounded-[4px] px-2 py-1.5 font-inter text-P6 font-medium `}
									>
										<Skeleton className="h-4 w-20" />
									</Skeleton>
								))}
							</div>

							<div className="flex h-12 w-full items-center justify-center gap-4 rounded-[8px] bg-neutral-100 px-4 md:w-auto">
								<div className="flex items-center justify-center gap-2">
									<Skeleton className="h-4 w-24" />
									<Skeleton className="h-6 w-8" />
								</div>

								<span className="block h-6 w-[1px] bg-neutral-200" />

								<div className="flex items-center justify-center gap-2">
									<Skeleton className="h-4 w-24" />
									<Skeleton className="h-6 w-12" />
								</div>
							</div>
						</div>

						<div className="block w-full overflow-x-hidden lg:hidden">
							<div
								ref={containerRef}
								className="scrollbar-hide flex snap-x snap-mandatory overflow-x-auto"
								style={{ scrollSnapType: "x mandatory" }}
							>
								{Array.from({ length: 3 }).map((_, index) => (
									<div
										className="mt-16 flex-shrink-0 snap-center px-4"
										key={index}
									>
										<div className="relative flex h-[340px] w-[256px] flex-col items-center justify-around gap-6 rounded-[16px] bg-neutral-200 px-8 pb-12 pt-24">
											<div className="absolute -top-16 flex flex-col items-center gap-4">
												<Skeleton className="h-32 w-32 rounded-full" />
												<div className="flex items-center gap-2">
													<Skeleton className="h-6 w-6" />
													<Skeleton className="h-5 w-12" />
												</div>
											</div>

											<div className="flex w-full flex-col items-center gap-4">
												<Skeleton className="h-6 w-36" />
												<div className="h-px w-full bg-neutral-100" />

												<div className="flex flex-col items-center">
													<Skeleton className="h-8 w-24" />
													<Skeleton className="h-4 w-32" />
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>

						<div className="hidden w-full items-end justify-evenly lg:flex">
							{Array.from({ length: 3 }).map((_, index) => (
								<div
									key={index}
									className="mt-16 flex-shrink-0 snap-center px-4"
								>
									<div className="relative flex h-[340px] w-[256px] flex-col items-center justify-around gap-6 rounded-[16px] bg-neutral-200 px-8 pb-12 pt-24">
										<div className="absolute -top-16 flex flex-col items-center gap-4">
											<Skeleton className="h-32 w-32 rounded-full" />
											<div className="flex items-center gap-2">
												<Skeleton className="h-6 w-6" />
												<Skeleton className="h-5 w-12" />
											</div>
										</div>

										<div className="flex w-full flex-col items-center gap-4">
											<Skeleton className="h-6 w-36" />
											<div className="h-px w-full bg-neutral-100" />

											<div className="flex flex-col items-center">
												<Skeleton className="h-8 w-24" />
												<Skeleton className="h-4 w-32" />
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</section>

					<section className="flex w-full flex-col gap-4 md:px-48">
						{Array.from({ length: 7 }).map((_, index) => (
							<SkeletonRankingCard key={index} index={index} />
						))}
					</section>
				</div>
			)}

			{!isLoading &&
				hasIntegrationActive &&
				award &&
				award.ranking?.length > 0 && (
					<div className="flex flex-col items-center gap-8">
						<section className="flex w-full flex-col items-center gap-20">
							<div className="flex w-full flex-col justify-between gap-10 md:flex-row md:items-center">
								<div className="flex items-center gap-4">
									{months.slice(-3).map((month, index) => (
										<button
											key={index}
											type="button"
											className={`flex h-10 items-center justify-center rounded-[4px] px-2 py-1.5 font-inter text-P6 font-medium ${
												selectedMonth === month.date
													? "bg-white text-neutral-0"
													: "bg-neutral-200 text-neutral-1100"
											}`}
											onClick={() => fetchRanking(month.date)}
										>
											<p>
												{month.month.charAt(0).toUpperCase() +
													month.month.slice(1)}
											</p>
										</button>
									))}
								</div>

								<div className="flex h-12 w-full items-center justify-center gap-4 rounded-[8px] bg-neutral-100 px-4 md:w-auto">
									<div className="flex items-center justify-center gap-2">
										<span className="font-inter text-P6 font-normal leading-160 text-neutral-600">
											Sua posição
										</span>

										<p className="font-inter text-P3 font-bold leading-160 text-neutral-1100">
											{award?.currentUser.position} º
										</p>
									</div>

									<span className="block h-6 w-[1px] bg-neutral-200" />

									<div className="flex items-center justify-center gap-2">
										<span className="font-inter text-P6 font-normal leading-160 text-neutral-600">
											Nº de vendas
										</span>

										<p className="font-inter text-P3 font-bold leading-160 text-neutral-1100">
											{award?.currentUser?.total_sales || 0}
										</p>
									</div>
								</div>
							</div>

							<div className="block w-full overflow-x-hidden lg:hidden">
								<div
									ref={containerRef}
									className="scrollbar-hide scrollbar-hide flex snap-x snap-mandatory overflow-x-auto"
									style={{ scrollSnapType: "x mandatory" }}
								>
									{reorderedRanking.map((position, index) => (
										<div
											key={position?.id ?? index}
											className="mt-16 flex-shrink-0 snap-center px-4"
										>
											<div
												className={`relative flex h-[340px] w-[256px] flex-col items-center justify-around gap-6 rounded-[16px] px-8 pb-12 pt-24 ${index === 1 ? "md:mb-[80px]" : ""}`}
												style={{
													background: `linear-gradient(180deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.00) 100%)`,
												}}
											>
												<div className="absolute -top-16 flex flex-col items-center gap-4">
													<Avatar className="h-32 w-32 border-[6px] border-neutral-0">
														<AvatarImage src={position?.image} />
														<AvatarFallback>
															{authorFallback(position?.user_name)}
														</AvatarFallback>
													</Avatar>
													<div
														className={`flex items-center gap-2 ${
															index === 1
																? "text-[#F1A22C]"
																: index === 0
																	? "text-[#ACACAC]"
																	: "text-[#755B29]"
														}`}
													>
														<TrophyIcon width={24} height={24} />
														<p className="font-inter text-P2 font-bold leading-160 text-neutral-1100">
															{index === 0 ? 2 : index === 1 ? 1 : 3}
														</p>
													</div>
												</div>

												<div className="flex w-full flex-col items-center gap-4">
													<p
														className="w-full max-w-[200px] truncate text-center font-inter text-P4 font-medium leading-160 text-neutral-1100"
														title={position?.user_name}
													>
														{position?.user_name}
													</p>

													<div className="h-px w-full bg-neutral-100" />

													<div className="flex flex-col items-center">
														<p
															className="font-poppins text-H6 font-semibold leading-140 text-neutral-1100"
															title={`${position?.total_sales} vendas`}
														>
															{position?.total_sales}
														</p>

														<span className="font-inter text-P6 font-normal leading-160 text-neutral-400">
															Total de vendas
														</span>
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>

							<div className="hidden w-full items-end justify-evenly lg:flex">
								{reorderedRanking.map((position, index) => (
									<div key={position?.id ?? index}>
										<div
											className={`relative flex h-[340px] w-full min-w-[256px] flex-col items-center justify-around gap-6 rounded-[16px] px-8 pb-12 pt-24 ${index === 1 ? "md:mb-[80px]" : ""}`}
											style={{
												background: `linear-gradient(180deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.00) 100%)`,
											}}
										>
											<div className="absolute -top-16 flex flex-col items-center gap-4">
												<Avatar className="size-32 border-[6px] border-neutral-0">
													<AvatarImage src={position?.image} />
													<AvatarFallback>
														{authorFallback(position?.user_name)}
													</AvatarFallback>
												</Avatar>
												<div
													className={`flex items-center gap-2 ${
														index === 1
															? "text-[#F1A22C]"
															: index === 0
																? "text-[#ACACAC]"
																: "text-[#755B29]"
													}`}
												>
													<TrophyIcon width={24} height={24} />
													<p className="font-inter text-P2 font-bold leading-160 text-neutral-1100">
														{index === 0 ? 2 : index === 1 ? 1 : 3}
													</p>
												</div>
											</div>

											<div className="flex w-full flex-col items-center gap-4">
												<p
													className="w-full max-w-[200px] truncate text-center font-inter text-P4 font-medium leading-160 text-neutral-1100"
													title={position?.user_name}
												>
													{position?.user_name}
												</p>

												<div className="h-px w-full bg-neutral-100" />

												<div className="flex flex-col items-center">
													<p
														className="font-poppins text-H6 font-semibold leading-140 text-neutral-1100"
														title={`${position?.total_sales} vendas`}
													>
														{position?.total_sales}
													</p>

													<span className="font-inter text-P6 font-normal leading-160 text-neutral-400">
														Total de vendas
													</span>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</section>

						<section className="mb-28 flex w-full flex-col gap-4 md:px-48">
							{award?.ranking.slice(3, 10).map((position, index) => (
								<div
									key={index}
									className={`
										flex w-full items-center justify-between rounded-[12px] border border-solid border-neutral-100 px-6 py-4
										${index < 2 ? "bg-neutral-100" : "bg-neutral-0"}
									`}
								>
									<div className="flex items-center gap-2 md:gap-10">
										<div
											className={`
											flex items-center gap-2 ${index < 2 ? "text-[#755B29]" : "!text-neutral-200"}
										`}
										>
											<TrophyIcon width={24} height={24} />

											<span className="w-7 font-inter text-P2 font-semibold leading-140 text-neutral-1100">
												{index + 4}
											</span>
										</div>

										<div className="flex items-center gap-4">
											<Avatar className="size-8 border-none border-neutral-0">
												<AvatarImage src={position?.image} />
												<AvatarFallback className="!text-P7 text-neutral-1100">
													{authorFallback(position?.user_name)}
												</AvatarFallback>
											</Avatar>

											<p
												className="max-w-[90px] truncate font-inter text-P5 font-normal leading-160 text-neutral-1100 md:max-w-60"
												title={position?.user_name}
											>
												{position?.user_name}
											</p>
										</div>
									</div>

									<div className="flex items-center gap-2">
										<span className="font-inter text-P6 font-normal leading-160 text-neutral-400">
											Vendas
										</span>

										<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
											{position?.total_sales}
										</p>
									</div>
								</div>
							))}
						</section>

						<footer
							className={`fixed bottom-0 left-0 right-0 z-[6] flex items-center justify-center bg-neutral-0 px-8 pb-8 pt-4 xl:left-[257px] ${
								shouldShowFooter() ? "" : "hidden"
							}`}
						>
							<div className="flex w-full items-center justify-center gap-4 md:w-auto">
								<Button
									className="flex !h-12 w-full items-center justify-center gap-2 rounded-[8px] font-inter !text-P5 font-semibold leading-160"
									onClick={() => setShowStrategiesModal(true)}
								>
									<EyeIcon width={20} height={20} />
									<p>Ver estratégias utilizadas</p>
								</Button>
							</div>
						</footer>

						{showStrategiesModal && (
							<Modal
								title="Melhores estratégias"
								closeModal={() => setShowStrategiesModal(false)}
								confirmAction={() => setShowStrategiesModal(false)}
								hasCancel={false}
								hasConfirm={false}
							>
								<div className="flex flex-col gap-8">
									{Array.from({ length: 5 }).map((_, index) => (
										<div key={index} className="flex flex-col gap-8">
											<div className="flex items-start justify-start gap-4">
												<div
													className={`flex items-center gap-2 ${
														index === 0
															? "text-[#F1A22C]"
															: index === 1
																? "text-[#ACACAC]"
																: "text-[#755B29]"
													}`}
												>
													<TrophyIcon width={24} height={24} />
													<p className="font-inter text-P2 font-bold leading-160 text-neutral-1100">
														{index + 1}
													</p>
												</div>

												<div className="flex w-full flex-col gap-4">
													<div className="flex items-center justify-between">
														<div className="flex items-center gap-4">
															<Avatar className="size-8 border-none border-neutral-0">
																<AvatarImage
																	src={award?.ranking[index]?.image}
																/>
																<AvatarFallback className="!text-P7 text-neutral-1100">
																	{authorFallback(
																		award?.ranking[index]?.user_name,
																	)}
																</AvatarFallback>
															</Avatar>

															<p
																className="font-inter text-P5 font-normal leading-160 text-neutral-1100"
																title={award?.ranking[index]?.user_name}
															>
																{award?.ranking[index]?.user_name}
															</p>
														</div>

														<div className="flex items-center gap-2">
															<span className="font-inter text-P6 font-normal leading-160 text-neutral-600">
																Total de vendas
															</span>

															<p
																className="font-inter text-P5 font-bold leading-160 text-neutral-1100"
																title="Total de vendas"
															>
																{award?.ranking[index]?.total_sales}
															</p>
														</div>
													</div>

													<p className="font-inter text-P6 font-normal leading-160 text-neutral-600">
														{award?.ranking[index]?.strategy || "Não informado"}
													</p>
												</div>
											</div>

											{index !== 4 && (
												<div className="h-px w-full bg-neutral-200" />
											)}
										</div>
									))}
								</div>
							</Modal>
						)}
					</div>
				)}

			{award?.ranking.length === 0 && !isLoading && (
				<div className="flex w-full flex-col gap-10">
					<div className="flex w-full flex-col justify-between gap-10 md:flex-row md:items-center">
						<div className="flex items-center gap-4">
							{months.slice(-3).map((month, index) => (
								<button
									key={index}
									type="button"
									className={`flex h-10 items-center justify-center rounded-[4px] px-2 py-1.5 font-inter text-P6 font-medium ${
										selectedMonth === month.date
											? "bg-white text-neutral-0"
											: "bg-neutral-200 text-neutral-1100"
									}`}
									onClick={() => fetchRanking(month.date)}
								>
									<p>
										{month.month.charAt(0).toUpperCase() + month.month.slice(1)}
									</p>
								</button>
							))}
						</div>

						<div className="flex h-12 w-full items-center justify-center gap-4 rounded-[8px] bg-neutral-100 px-4 md:w-auto">
							<div className="flex items-center justify-center gap-2">
								<span className="font-inter text-P6 font-normal leading-160 text-neutral-600">
									Sua posição
								</span>

								<p className="font-inter text-P3 font-bold leading-160 text-neutral-1100">
									{award?.currentUser.position} º
								</p>
							</div>

							<span className="block h-6 w-[1px] bg-neutral-200" />

							<div className="flex items-center justify-center gap-2">
								<span className="font-inter text-P6 font-normal leading-160 text-neutral-600">
									Nº de vendas
								</span>

								<p className="font-inter text-P3 font-bold leading-160 text-neutral-1100">
									{award?.currentUser?.total_sales || 0}
								</p>
							</div>
						</div>
					</div>

					<div className="flex w-full flex-col items-center justify-center gap-12 md:py-36">
						<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
							<SearchIcon />
						</span>

						<div className="flex flex-col gap-2">
							<h3 className="text-center font-inter text-P2 font-medium leading-160 text-neutral-1100 md:w-[600px]">
								Esse período ainda não tem um ranking definido!
							</h3>

							<p className="text-center font-inter text-P3 font-normal leading-160 text-neutral-500">
								Após a conclusão do mês selecionado os ganhadores serão listados
								aqui.
							</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

function SkeletonRankingCard({ index }: { index: number }) {
	return (
		<div
			key={index}
			className={`
        flex w-full items-center justify-between rounded-[12px] border border-solid border-neutral-100 px-6 py-4
        ${index < 2 ? "bg-neutral-100" : "bg-neutral-0"}
      `}
		>
			<div className="flex items-center gap-2 md:gap-10">
				<div
					className={`
            flex items-center gap-2 ${index < 2 ? "text-[#755B29]" : "!text-neutral-200"}
          `}
				>
					<Skeleton className="h-6 w-6" />

					<Skeleton className="h-5 w-8" />
				</div>

				<div className="flex items-center gap-4">
					<div className="h-8 w-8 rounded-full bg-neutral-300" />
					<Skeleton className="h-5 w-32" />
				</div>
			</div>

			<div className="flex items-center gap-2">
				<Skeleton className="h-5 w-16" />
				<Skeleton className="h-5 w-24" />
			</div>
		</div>
	);
}
