import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import FileIcon from "@/assets/file-2.svg?react";
import FormatUppercaseIcon from "@/assets/format-uppercase.svg?react";
import ImageIcon from "@/assets/image-2.svg?react";
import PlayButtonOIcon from "@/assets/play-button-o.svg?react";
import { FileCard } from "@/components/file-card";
import { FileUploader } from "@/components/file-uploader";
import { Modal } from "@/components/modal";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

interface AdminAcademyShowModalLessonMaterialProps {
	closeModal: () => void;
	isEditing?: boolean;
	currentMaterial: {
		id?: string;
		title: string;
		format: string;
		file: string;
		material_id: string;
		link: string;
	} | null;
	onSuccess: ({
		id,
		title,
		format,
		file,
		material_id,
	}: {
		id?: string;
		title: string;
		format: string;
		file: File | string;
		material_id?: string;
	}) => void;
}

export function AdminAcademyShowModalLessonMaterial({
	currentMaterial,
	closeModal,
	isEditing = false,
	onSuccess,
}: AdminAcademyShowModalLessonMaterialProps) {
	const [isLoading, setIsLoading] = useState(false);

	// Ajuste a lógica de tipo aqui para refletir corretamente o material atual
	const materialType = currentMaterial?.format as
		| "image"
		| "text"
		| "link"
		| "document";

	const type =
		materialType === "image"
			? "Imagem"
			: materialType === "text"
				? "Texto"
				: materialType === "link"
					? "Link"
					: "Documento";

	// Defina o esquema de validação do formulário
	const formSchema = z.object({
		id: z.string().optional(),
		name: z
			.string()
			.min(3, "O nome do material deve conter no mínimo 3 caracteres"),
		format: z.string(),
		file: z.union([z.instanceof(File), z.string()]),
		material_id: z.string().optional(),
		link: z.string().optional(),
	});

	type Filter = z.infer<typeof formSchema>;

	// Use useForm para criar o formulário com defaultValues ajustados
	const form = useForm<Filter>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			id: currentMaterial?.id || "",
			material_id: currentMaterial?.material_id || "",
			name: currentMaterial?.title || "",
			format: isEditing ? type : "",
			file: currentMaterial?.link || "",
		},
	});

	const confirmAction = form.handleSubmit((data) => {
		// Aqui você trata os dados coletados no modal
		// Se for edição, o campo 'file' conterá o link em vez de um arquivo

		const formData = {
			id: data.id,
			title: data.name,
			format: data.format.toLowerCase(),
			file: data.file instanceof File ? data.file : data.file,
			material_id: data.material_id,
		};

		// Simula sucesso e retorna os dados coletados ao invés de fazer a chamada API
		onSuccess(formData);

		form.reset(); // Reseta o formulário
		setIsLoading(false); // Finaliza o loading
	});

	return (
		<Modal
			title={isEditing ? "Editar material" : "Adicionar material"}
			closeModal={closeModal}
			confirmAction={confirmAction}
			cancelAction={closeModal}
			isLoading={isLoading}
		>
			<Form {...form}>
				<div className="flex flex-col gap-8">
					<div className="flex w-full flex-col items-center gap-6">
						<div className="flex w-full flex-col gap-2">
							<FloatingLabelInput
								id="name"
								type="text"
								label="Nome do material"
								hasError={!!form.formState.errors.name}
								{...form.register("name")}
							/>

							{form.formState.errors.name && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{form.formState.errors.name.message}
								</p>
							)}
						</div>

						<FormField
							control={form.control}
							name="format"
							render={({ field }) => (
								<FormItem className="w-full">
									<Select
										onValueChange={(e) => {
											field.onChange(e);
										}}
										defaultValue={field.value}
										value={field.value}
									>
										<FormControl>
											<SelectTrigger
												className="w-full"
												hasError={!!form.formState.errors.format}
											>
												<SelectValue placeholder="Tipo de material" />
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											<SelectItem value="Imagem">
												<div className="flex items-center gap-3">
													<ImageIcon width={20} height={20} />
													<p>Imagem</p>
												</div>
											</SelectItem>
											<SelectItem value="Documento">
												<div className="flex items-center gap-3">
													<FileIcon width={20} height={20} />
													<p>Documento</p>
												</div>
											</SelectItem>
											<SelectItem value="Link">
												<div className="flex items-center gap-3">
													<PlayButtonOIcon width={20} height={20} />
													<p>Video</p>
												</div>
											</SelectItem>
											<SelectItem value="Texto">
												<div className="flex items-center gap-3">
													<FormatUppercaseIcon width={20} height={20} />
													<p>Texto</p>
												</div>
											</SelectItem>
										</SelectContent>
									</Select>

									{form.formState.errors.format && (
										<p className="font-inter text-P6 font-normal leading-160 text-red-600">
											{form.formState.errors.format.message}
										</p>
									)}
								</FormItem>
							)}
						/>
					</div>

					<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
						<div className="flex flex-col gap-2">
							<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
								{form.watch("format") === "Link" ? "Link" : "Arquivo"}
							</p>

							<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
								{form.watch("format") === "Link"
									? "Adicione o link do seu vídeo"
									: "Adicione o arquivo do seu material"}
							</span>
						</div>

						{form.watch("format") === "Link" && (
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="link"
									type="text"
									label="Link"
									hasError={!!form.formState.errors.link}
									{...form.register("file")}
								/>

								{form.formState.errors.link && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.link.message}
									</p>
								)}
							</div>
						)}

						{form.watch("format") !== "Link" && (
							<div className="flex w-full flex-col gap-2">
								{typeof form.watch("file") === "string" &&
								form.watch("file") !== "" ? (
									<FileCard
										url={form.watch("file") as string}
										onRemove={() => form.setValue("file", "")}
									/>
								) : form.watch("file") instanceof File ? (
									<FileCard
										file={form.watch("file") as File}
										onRemove={() => form.setValue("file", "")}
									/>
								) : (
									<FileUploader
										maxFiles={1}
										maxSize={20 * 1024 * 1024}
										accept={
											form.watch("format") === "Imagem"
												? {
														"image/*": [".jpg", ".jpeg", ".png", ".gif"],
													}
												: form.watch("format") === "Link"
													? {
															"video/mp4": [".mp4"],
															"video/quicktime": [".mov"],
														}
													: form.watch("format") === "Documento"
														? {
																"application/pdf": [".pdf"],
																"application/msword": [".doc"],
																"application/vnd.openxmlformats-officedocument.wordprocessingml.document":
																	[".docx"],
																"application/vnd.ms-powerpoint": [".ppt"],
																"application/vnd.openxmlformats-officedocument.presentationml.presentation":
																	[".pptx"],
																"application/vnd.ms-excel": [".xls"],
																"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
																	[".xlsx"],
																"text/csv": [".csv"],
																"application/zip": [".zip"],
															}
														: form.watch("format") === "Texto"
															? {
																	"text/plain": [".txt"],
																}
															: undefined
										}
										onUpload={async (e) => {
											form.setValue("file", e[0]);
										}}
										disabled={false}
									/>
								)}

								{form.formState.errors.file && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.file.message}
									</p>
								)}
							</div>
						)}
					</div>
				</div>
			</Form>
		</Modal>
	);
}
