import { create } from "zustand";

import { Product } from "@/api/app/products/show";

type State = {
	product: Product | null;
};

type Actions = {
	setProduct: (product: Product | null) => void;
};

export const useProductStore = create<State & Actions>((set) => ({
	product: null,
	setProduct: (product) => set({ product }),
}));
