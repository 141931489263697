import { markLessonAsWatched } from "@/api/app/academy/trainings/mark-lesson-watched";
import CheckIcon from "@/assets/check.svg?react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { useLessonStore } from "@/store/lesson";
import { formatDate } from "@/utils/date-format";
import { authorFallback } from "@/utils/mask-author-fallback";

export function AcademyTrainingDetails() {
	const { lesson, setLesson } = useLessonStore();

	if (!lesson) return null;

	async function handleCompleteLesson() {
		if (!lesson) return;

		const response = await markLessonAsWatched(!lesson.watched, lesson.id);

		if (response.status === "success") {
			setLesson({ ...lesson, watched: !lesson.watched });
		}
	}

	return (
		<>
			<div className="flex flex-col gap-2">
				<div className="flex items-center gap-3 font-inter text-P5 font-medium leading-160 text-neutral-500">
					<p>Módulo {lesson.module}</p>
					<span className="size-1.5 rounded-[1px] bg-primary-600" />
					<p>Aula {lesson.position + 1}</p>
				</div>

				<div className="flex flex-col justify-between gap-8 md:flex-row md:gap-12">
					<h2 className="line-clamp-1 text-ellipsis font-inter text-P1 font-medium leading-140 text-neutral-1100 ">
						{lesson.name}
					</h2>

					<Button
						className={`flex !h-10 gap-2 rounded-[8px] border border-neutral-200 !bg-transparent px-4 font-inter !text-P6 font-semibold leading-160 text-neutral-1100 transition-all duration-300 hover:bg-neutral-200 ${
							lesson.watched && "border-none !bg-primary-800"
						}`}
						onClick={() => handleCompleteLesson()}
					>
						{lesson.watched ? <CheckIcon /> : null}
						<p>{lesson.watched ? "Aula concluída" : "Concluir aula"}</p>
					</Button>
				</div>
			</div>

			<hr className="h-px border-t-neutral-100" />

			<div className="flex flex-col gap-3">
				<h3 className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
					Sobre a aula
				</h3>

				<div className="flex flex-col gap-8">
					<p className="font-inter text-P5 font-normal leading-160 text-neutral-500">
						{lesson?.description ?? ""}
					</p>

					<div className="flex flex-col gap-6 md:flex-row md:items-center md:gap-14">
						<div className="flex gap-4">
							<p className="font-inter text-P5 font-normal leading-160 text-neutral-500">
								Data de lançamento:
							</p>

							<p className="font-inter text-P5 font-normal leading-160 text-neutral-1100">
								{formatDate(lesson.created_at)}
							</p>
						</div>

						<div className="flex items-center gap-4">
							<p className="font-inter text-P5 font-normal leading-160 text-neutral-500">
								Mentor:
							</p>

							<div className="flex items-center gap-3">
								<Avatar className="size-6">
									<AvatarImage src={lesson?.mentor?.image} />
									<AvatarFallback>
										{authorFallback(lesson?.mentor?.name ?? "Desconhecido")}
									</AvatarFallback>
								</Avatar>

								<p className="font-inter text-P5 font-normal leading-160 text-neutral-1100">
									{lesson?.mentor?.name ?? "Desconhecido"}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
