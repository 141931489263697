import { useEffect, useReducer, useState } from "react";

import { getDashboardSalesList, Sale } from "@/api/admin/panel/sales/sales";
import CloseIcon from "@/assets/close.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import { PaginationList } from "@/components/pagination-list";
import { Tag } from "@/components/tag";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Input } from "@/components/ui/input";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import { authorFallback } from "@/utils/mask-author-fallback";

interface AdminPanelSalesAffiliatesProps {
	productId?: string;
}

export function AdminPanelSalesAffiliates({
	productId,
}: AdminPanelSalesAffiliatesProps) {
	const [searchTerm, setSearchTerm] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [salesList, setSalesList] = useState<Sale[] | null>(null);
	const [paginationData, setPaginationData] = useState({
		lastPage: 1,
		page: 1,
		perPage: 10,
		total: 0,
	});

	type Platforms = "Braip" | "Monetizze" | "The Mart";

	function reducer(
		state: {
			currentPage: number;
			lastPage: number;
			total: number;
			to: number;
		},
		action: {
			type: string;
			payload?: {
				lastPage?: number;
				total?: number;
				to?: number;
				page?: number;
			};
		},
	) {
		switch (action.type) {
			case "SET_INITIAL_STATE":
				return {
					...state,
					lastPage: action.payload?.lastPage ?? state.lastPage,
					total: action.payload?.total ?? state.total,
					to: action.payload?.to ?? state.to,
					currentPage: action.payload?.page ?? state.currentPage,
				};
			case "NEXT_PAGE":
				return {
					...state,
					currentPage: state.currentPage + 1,
					to: state.to + state.to,
				};
			case "PREVIOUS_PAGE":
				return {
					...state,
					currentPage: state.currentPage - 1,
					to: state.to - state.to,
				};
			case "PAGE_CHANGE":
				return {
					...state,
					currentPage: action.payload?.page ?? state.currentPage,
					to: (action.payload?.page ?? state.currentPage) * state.to,
				};
			default:
				return state;
		}
	}

	const [state, dispatch] = useReducer(reducer, {
		currentPage: paginationData.page,
		lastPage: paginationData.lastPage,
		total: paginationData.total,
		to: paginationData.perPage,
	});

	useEffect(() => {
		dispatch({
			type: "SET_INITIAL_STATE",
			payload: {
				lastPage: paginationData.lastPage,
				total: paginationData.total,
				page: paginationData.page,
				to: paginationData.perPage,
			},
		});
	}, [paginationData]);

	const platformColors: {
		[key in Platforms]:
			| "minimalist-pink"
			| "minimalist-blue"
			| "minimalist-orange";
	} = {
		Braip: "minimalist-pink",
		Monetizze: "minimalist-blue",
		"The Mart": "minimalist-orange",
	};

	async function handlePanelSales() {
		setIsLoading(true);

		const response = await getDashboardSalesList({
			page: state.currentPage,
			externalKey: productId,
		});

		if (response.status === "success") {
			const { data, lastPage, page, perPage, total } = response;
			setSalesList(data);
			setPaginationData({ lastPage, page, perPage, total });
		}

		setIsLoading(false);
	}

	useEffect(() => {
		handlePanelSales();
	}, [state.currentPage, productId, searchTerm]);

	return (
		<div className="flex flex-col gap-10 rounded-[8px] border border-solid border-neutral-100 p-6">
			<h2 className="font-inter text-P3 font-medium leading-140 text-neutral-1100">
				Vendas por afiliados
			</h2>

			<div className="flex flex-col gap-8">
				<div className="relative w-full !text-neutral-500 focus-within:text-neutral-1100">
					<Input
						placeholder="Pesquise pelo nome do afiliado"
						className="h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:text-P5 placeholder:!text-neutral-500"
						onChange={(e) => setSearchTerm(e.target.value)}
						value={searchTerm}
					/>
					<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

					{searchTerm !== "" && (
						<button
							className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-200 text-neutral-1100"
							onClick={() => setSearchTerm("")}
						>
							<CloseIcon />
						</button>
					)}
				</div>

				{salesList?.length === 0 && !isLoading && (
					<div className="flex h-full w-full flex-1 flex-col items-center justify-center gap-6 py-32">
						<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
							<Search2Icon />
						</span>
						<div className="flex flex-col items-center gap-2">
							<h3 className="text-center font-inter text-P2 font-medium leading-140 text-neutral-1100">
								No momento você não possui nenhuma venda realizada
							</h3>
							<p className="text-center font-inter text-P3 font-normal leading-160 text-neutral-500">
								Verifique se os filtros selecionados estão corretos
							</p>
						</div>
					</div>
				)}

				{salesList && salesList.length > 0 && !isLoading && (
					<>
						<Table>
							<TableHeader>
								<TableRow>
									<TableHead className="w-[20%]">Afiliado</TableHead>
									<TableHead className="w-[15%]">Vendas</TableHead>
									<TableHead className="w-[15%]">Comissão</TableHead>
									<TableHead className="w-[20%]">Plataforma</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{salesList.map((sale, index) => (
									<TableRow key={index}>
										<TableCell>
											<div className="flex items-center gap-4">
												<div>
													<Avatar className="size-9 border-[6px] border-neutral-0">
														<AvatarImage src={sale.affiliateImage} />
														<AvatarFallback className="!text-P7 text-neutral-1100">
															{authorFallback(sale.affiliateName)}
														</AvatarFallback>
													</Avatar>
												</div>
												<p className="line-clamp-1 text-ellipsis font-inter text-P5 font-normal leading-160 text-neutral-1100">
													{sale.affiliateName}
												</p>
											</div>
										</TableCell>
										<TableCell>
											<p className="line-clamp-1 text-ellipsis font-inter text-P5 font-normal leading-160 text-neutral-1100">
												{sale.value}
											</p>
										</TableCell>
										<TableCell>
											<p className="line-clamp-1 text-ellipsis font-inter text-P5 font-normal leading-160 text-neutral-1100">
												{sale.commission_value}
											</p>
										</TableCell>
										<TableCell>
											<div>
												<Tag
													size="small"
													colors={platformColors[sale.platform as Platforms]}
													className="w-fit rounded px-[8px] py-1 text-P7"
												>
													{sale.platform}
												</Tag>
											</div>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</>
				)}

				<PaginationList
					currentPage={state.currentPage}
					lastPage={state.lastPage}
					to={state.to > state.total ? state.total : state.to}
					total={state.total}
					onNextPage={() => dispatch({ type: "NEXT_PAGE" })}
					onPageChange={(page) =>
						dispatch({ type: "PAGE_CHANGE", payload: { page } })
					}
					onPreviousPage={() => dispatch({ type: "PREVIOUS_PAGE" })}
				/>
			</div>
		</div>
	);
}
