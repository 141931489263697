import { create } from "zustand";

import type { Lesson } from "@/api/app/academy/trainings/lesson";

type State = {
	lesson: Lesson | null;
};

type Actions = {
	setLesson: (lesson: Lesson | null) => void;
};

export const useLessonStore = create<State & Actions>((set) => ({
	lesson: null,
	setLesson: (lesson) => set({ lesson }),
}));
