import FileIcon from "@/assets/file.svg?react";
import ImageIcon from "@/assets/image.svg?react";
import MailIcon from "@/assets/mail.svg?react";
import PenIcon from "@/assets/pen.svg?react";
import PlayIcon from "@/assets/play.svg?react";
import TrashIcon from "@/assets/trash.svg?react";

interface FileCardProps {
	url?: string;
	file?: File;
	onRemove: () => void;
	hasOnEdit?: boolean;
	onEdit?: () => void;
}

export function FileCard({
	url,
	file,
	hasOnEdit = false,
	onEdit,
	onRemove,
}: FileCardProps) {
	const types = {
		image: "Arquivo de imagem",
		document: "Arquivo PDF",
		text: "Arquivo de texto",
		link: "Arquivo de vídeo",
	};

	function getFileType(url?: string, file?: File) {
		const fileName = file?.name || url?.split("/").pop();

		if (
			fileName?.includes(".jpg") ||
			fileName?.includes(".jpeg") ||
			fileName?.includes(".png")
		) {
			return "image";
		}

		if (fileName?.includes(".pdf")) {
			return "document";
		}

		if (fileName?.includes(".txt")) {
			return "text";
		}

		return "link";
	}

	function getNormalizedFileName(url?: string, file?: File) {
		if (file) return file.name;
		if (url) return url.split("/").pop();
		return "";
	}

	const fileType = getFileType(url, file);
	const fileName = getNormalizedFileName(url, file);

	return (
		<div className="flex items-center justify-between rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-4">
			<div className="flex flex-1 space-x-4">
				<div className="flex size-12 items-center justify-center rounded-[10px] border border-primary-1000 bg-primary-600 bg-opacity-[0.04] p-3 text-primary-600">
					{types[fileType] === "Arquivo de imagem" && (
						<ImageIcon width={24} height={24} />
					)}

					{types[fileType] === "Arquivo PDF" && (
						<FileIcon width={24} height={24} />
					)}

					{types[fileType] === "Arquivo de texto" && (
						<MailIcon width={24} height={24} />
					)}

					{types[fileType] === "Arquivo de vídeo" && (
						<PlayIcon width={24} height={24} />
					)}
				</div>

				<div className="flex w-full flex-col gap-2">
					<div className="flex flex-col gap-[2px]">
						<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
							{types[fileType]}
						</span>
						<p
							className="w-full max-w-[300px] truncate font-inter text-P5 font-medium leading-160 text-neutral-1100"
							title={fileName}
						>
							{fileName}
						</p>
					</div>
				</div>
			</div>

			<div className="flex items-center gap-2">
				{hasOnEdit && (
					<button
						className="flex items-center justify-center p-3 text-neutral-600"
						onClick={() => {
							onEdit && onEdit();
						}}
					>
						<PenIcon />
					</button>
				)}

				<button
					className="flex items-center justify-center p-3 text-neutral-600"
					onClick={() => {
						onRemove();
					}}
				>
					<TrashIcon />
				</button>
			</div>
		</div>
	);
}
