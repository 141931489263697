import { useState } from "react";
import { Link } from "react-router-dom";

import PlayButtonIcon from "@/assets/play-button.svg?react";
import { authorFallback } from "@/utils/mask-author-fallback";

import { Tag } from "./tag";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";

interface TrainingCardProps {
	id: string;
	image: string;
	modules: number;
	lessons: number;
	title: string;
	author: string;
	avatar: string;
}

export function TrainingCard({
	id,
	image,
	modules,
	lessons,
	title,
	author,
	avatar,
}: TrainingCardProps) {
	const [isHovered, setIsHovered] = useState(false);

	function handleHover() {
		setIsHovered(!isHovered);
	}

	return (
		<Link
			to={`/academy/${id}`}
			className="relative flex w-full max-w-[256px] flex-col items-center rounded-xl bg-neutral-100 bg-opacity-40 transition-colors duration-300 ease-in-out hover:bg-opacity-60 lg:max-w-full"
			onMouseEnter={handleHover}
			onMouseLeave={handleHover}
		>
			<div className="relative flex w-full items-center px-4 pt-4">
				<img
					src={image}
					alt={title}
					className="h-[154px] w-full rounded-[10px] object-cover"
				/>

				<div
					className={`absolute bottom-4 right-8 flex h-10 w-10 items-center justify-center rounded-[8px] bg-primary-600 text-neutral-1100 opacity-100 transition-opacity duration-300 lg:opacity-0 ${isHovered && "!opacity-100"}`}
				>
					<PlayButtonIcon />
				</div>
			</div>

			<div className="flex w-full flex-col gap-4 p-6">
				<div className="flex gap-4">
					<Tag
						size="small"
						colors="minimalist-grey"
						className="rounded px-[6px] py-1 text-P7"
					>
						{modules > 1 ? `${modules} Módulos` : `${modules} Módulo`}
					</Tag>
					<Tag
						size="small"
						colors="minimalist-grey"
						className="rounded px-[6px] py-1 text-P7"
					>
						{lessons > 1 ? `${lessons} Aulas` : `${lessons} Aula`}
					</Tag>
				</div>

				<div className="flex flex-col gap-3">
					<p
						className="line-clamp-1 text-ellipsis font-inter text-P4 font-semibold leading-160 text-neutral-1100"
						title={title}
					>
						{title}
					</p>

					<div className="flex items-center gap-4">
						<Avatar className="size-6">
							<AvatarImage src={avatar ?? ""} />
							<AvatarFallback>
								{authorFallback(author ?? "Desconhecido")}
							</AvatarFallback>
						</Avatar>

						<p className="font-inter text-P5 font-normal leading-160 text-neutral-600">
							{author ?? "Desconhecido"}
						</p>
					</div>
				</div>
			</div>
		</Link>
	);
}
