import { AxiosError } from "axios";

import { api } from "@/lib/axios";

interface FetchAdminProductsParams {
	name: string;
	type: string;
	niche: string;
	platform: string;
	sortBy: "created_at" | "commission_value" | "sales";
	perPage: number;
}

type Platform = {
	external_id: string;
	id: string;
	link: string;
	platform: string;
	platform_id: string;
	url_postback: string;
};

type Type = {
	id: string;
	name: string;
};

export type Product = {
	commission_value: number;
	created_at: string;
	id: string;
	image: string;
	name: string;
	platforms: Platform[];
	type: Type;
};

export async function fetchAdminProducts(
	params: Partial<FetchAdminProductsParams>,
): Promise<
	| {
			status: "success";
			data: Product[];
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get("/product", {
			params,
		});

		return {
			status: "success",
			data: data.data.data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
