import { useState } from "react";

import FileIcon from "@/assets/file.svg?react";
import ImageIcon from "@/assets/image.svg?react";
import MailIcon from "@/assets/mail.svg?react";
import SoftwareDownloadIcon from "@/assets/software-download.svg?react";

interface MaterialCardProps {
	title: string;
	type: "image" | "document" | "text" | "link";
	link: string;
}

export function MaterialCard({ title, type, link }: MaterialCardProps) {
	const [isHovered, setIsHovered] = useState(false);

	function handleHover() {
		setIsHovered(!isHovered);
	}

	const types = {
		image: "Arquivo de imagem",
		document: "Arquivo PDF",
		text: "Arquivo de texto",
		link: "Outro tipo de arquivo",
	};

	function handleDownloadMaterial() {
		window.open(link, "_blank");
	}

	return (
		<div
			className="relative flex h-[164px] w-full cursor-pointer flex-col gap-6 rounded-xl bg-neutral-100 bg-opacity-40 p-6 transition-colors duration-300 ease-in-out hover:bg-opacity-60"
			onMouseEnter={handleHover}
			onMouseLeave={handleHover}
			onClick={handleDownloadMaterial}
		>
			<div className="flex size-12 items-center justify-center rounded-[10px] border border-primary-1000 bg-primary-600 bg-opacity-[0.04] p-3 text-primary-600">
				{type === "image" && <ImageIcon width={24} height={24} />}

				{type === "document" && <FileIcon width={24} height={24} />}

				{type === "text" && <MailIcon width={24} height={24} />}
			</div>
			<div className="flex flex-col gap-1">
				<span className="font-inter text-P7 font-normal leading-160 text-neutral-500">
					{types[type]}
				</span>
				<p className="line-clamp-1 text-ellipsis font-inter text-P5 font-semibold leading-160 text-neutral-1100">
					{title}
				</p>
			</div>

			<div
				className={`absolute bottom-6 right-6 flex size-8 items-center justify-center rounded-[4px] bg-primary-600 p-2 text-neutral-1100 opacity-100 transition-opacity duration-300 md:opacity-0 ${isHovered && "!opacity-100"}`}
			>
				<SoftwareDownloadIcon width={16} height={16} />
			</div>
		</div>
	);
}
