import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { deleteAcademyMaterial } from "@/api/admin/academy/materials/delete-material";
import {
	fetchAdminMaterials,
	type Material,
} from "@/api/admin/academy/materials/list";
import CloseIcon from "@/assets/close.svg?react";
import FileIcon from "@/assets/file-2.svg?react";
import FormatUppercaseIcon from "@/assets/format-uppercase.svg?react";
import ImageIcon from "@/assets/image-2.svg?react";
import MathPlus from "@/assets/math-plus.svg?react";
import OptionsIcon from "@/assets/options.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import { AdminMaterialCard } from "@/components/admin-material-card";
import { AlertModal } from "@/components/alert-modal";
import { DeleteModal } from "@/components/delete-modal";
import { SkeletonAdminMaterialCard } from "@/components/skeletons/admin-material-card";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { useNavigationStore } from "@/store/navigation-store";

import { AdminAcademyMaterialsCreate } from "./create";

export function AdminAcademyMaterials() {
	const [filterIsOpen, setFilterIsOpen] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [materials, setMaterials] = useState<Material[]>([]);
	const [currentMaterial, setCurrentMaterial] = useState<Material | null>(null);
	const [successModal, setSuccessModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [successDeleteModal, setSuccessDeleteModal] = useState(false);
	const [selectedMaterial, setSelectedMaterial] = useState<string | null>(null);

	const filter = z.object({
		title: z.string().optional(),
		format: z.enum(["image", "document", "text", "link"]).optional(),
	});

	type Filter = z.infer<typeof filter>;

	const form = useForm<Filter>();

	async function handleFilterSubmit(data: Filter) {
		setIsLoading(true);
		const response = await fetchAdminMaterials({
			perPage: 10000,
			title: data.title || undefined,
			format: data.format,
		});

		if (response.status === "success") {
			setMaterials(response.data);
		}

		setIsLoading(false);
		setFilterIsOpen(false);
	}

	async function fetchMaterials() {
		setIsLoading(true);
		const response = await fetchAdminMaterials({
			perPage: 10000,
		});

		if (response.status === "success") {
			setMaterials(response.data);
		}

		setIsLoading(false);
	}

	async function handleDeleteMaterial() {
		if (!selectedMaterial) return;

		setIsLoading(true);

		const response = await deleteAcademyMaterial(selectedMaterial);

		if (response.status === "success") {
			setDeleteModal(false);
			setSuccessDeleteModal(true);
			setIsLoading(false);

			const updatedMaterials = await fetchAdminMaterials({
				perPage: 10000,
			});
			if (updatedMaterials.status === "success") {
				setMaterials(updatedMaterials.data);
			}
		}
	}

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Configurações",
				path: "/admin",
			},
			{
				name: "Academy",
				path: "/admin/academy",
			},
			{
				name: "Materiais",
				path: "/admin/academy/materials",
			},
		];

		setPaths(paths);
	}, []);

	useEffect(() => {
		fetchMaterials();
	}, []);

	return (
		<div className="flex h-full flex-col justify-between gap-12">
			<header className="flex items-center justify-between">
				<h2 className="font-inter text-P2 font-medium leading-140 text-neutral-1100">
					Todos os materiais
				</h2>

				<div className="flex items-center justify-center gap-4">
					<button
						className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 px-6 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400"
						disabled={isLoading}
						onClick={() => setFilterIsOpen(!filterIsOpen)}
					>
						<OptionsIcon />

						<p className="font-inter text-P6 font-semibold leading-160">
							Filtro
						</p>
					</button>

					<button
						className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-primary-600 px-6 text-neutral-1100 transition-all duration-300 hover:bg-primary-700"
						onClick={() => setModalIsOpen(true)}
					>
						<MathPlus />

						<p className="font-inter text-P6 font-semibold leading-160">
							Adicionar material
						</p>
					</button>
				</div>
			</header>

			<div
				className={`flex max-h-0 flex-col gap-8 rounded-[10px] bg-neutral-100 bg-opacity-60 opacity-0 transition-all duration-500 ${
					filterIsOpen && "max-h-fit p-6 opacity-100"
				}`}
			>
				{filterIsOpen && (
					<Form {...form}>
						<div className="flex w-full flex-col items-center gap-6 md:flex-row">
							<div
								className={`relative w-full ${
									materials.length > 0 ? "text-neutral-600" : "text-neutral-200"
								} focus-within:text-neutral-1100`}
							>
								<Input
									placeholder="Pesquise por um produto"
									className={`h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:!text-P5  ${
										materials.length > 0
											? "placeholder:text-neutral-600"
											: "placeholder:text-neutral-200"
									}`}
									{...form.register("title")}
								/>
								<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

								{form.watch("title") !== undefined &&
									form.watch("title") !== "" && (
										<button
											className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-200 text-neutral-1100"
											onClick={() => form.setValue("title", undefined)}
										>
											<CloseIcon />
										</button>
									)}
							</div>

							<FormField
								control={form.control}
								name="format"
								render={({ field }) => (
									<FormItem className="w-full">
										<Select
											onValueChange={(e) => {
												field.onChange(e);
											}}
											defaultValue={field.value}
											value={field.value}
										>
											<FormControl>
												<SelectTrigger
													className="w-full"
													hasError={!!form.formState.errors.format}
												>
													<SelectValue placeholder="Tipo de material" />
												</SelectTrigger>
											</FormControl>
											<SelectContent>
												<SelectItem value="image">
													<div className="flex items-center gap-3">
														<ImageIcon width={20} height={20} />
														<p>Imagem</p>
													</div>
												</SelectItem>
												<SelectItem value="document">
													<div className="flex items-center gap-3">
														<FileIcon width={20} height={20} />
														<p>Documento</p>
													</div>
												</SelectItem>
												<SelectItem value="text">
													<div className="flex items-center gap-3">
														<FormatUppercaseIcon width={20} height={20} />
														<p>Texto</p>
													</div>
												</SelectItem>
											</SelectContent>
										</Select>

										{form.formState.errors.format && (
											<p className="font-inter text-P6 font-normal leading-160 text-red-600">
												{form.formState.errors.format.message}
											</p>
										)}
									</FormItem>
								)}
							/>
						</div>

						<div className="flex flex-col justify-between gap-6 md:flex-row md:items-center">
							<div>
								<span className="font-inter text-P5 font-normal leading-160 text-neutral-400">
									Nenhum filtro aplicado
								</span>
							</div>

							<div className="flex gap-4">
								<Button
									className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] bg-transparent font-inter !text-P6 font-semibold leading-160 text-neutral-600 transition-all duration-500 hover:bg-neutral-200 disabled:bg-transparent disabled:text-neutral-400"
									variant="secondary"
									onClick={() => {
										form.setValue("title", undefined);
										form.setValue("format", undefined);
										setFilterIsOpen(false);

										handleFilterSubmit({});
									}}
								>
									Limpar filtro
								</Button>
								<Button
									className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] font-inter !text-P6 font-semibold leading-160"
									onClick={form.handleSubmit(async (data) => {
										handleFilterSubmit(data);
									})}
								>
									Aplicar
								</Button>
							</div>
						</div>
					</Form>
				)}
			</div>

			<div className={!filterIsOpen ? "-mt-12" : ""}>
				<div className="mt-6 grid grid-cols-1 gap-6">
					{isLoading &&
						Array.from({ length: 5 }).map((_, index) => (
							<SkeletonAdminMaterialCard key={index} />
						))}

					{!isLoading &&
						materials.map((material) => (
							<AdminMaterialCard
								key={material.id}
								name={material.title}
								type={material.format as "image" | "text" | "link" | "document"}
								onEdit={() => {
									setCurrentMaterial(material);
									setIsEditing(true);
									setModalIsOpen(true);
								}}
								onRemove={() => {
									setSelectedMaterial(material.id);
									setDeleteModal(true);
								}}
							/>
						))}
				</div>

				{!isLoading && materials.length === 0 && (
					<div className="flex flex-col items-center justify-center gap-6 px-10 py-36">
						<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
							<SearchIcon />
						</span>

						<div className="flex flex-col gap-2">
							<h3 className="text-center font-inter font-medium leading-160 text-neutral-1100">
								{form.watch("title")
									? "Não foi possível encontrar nenhum material"
									: "No momento você não possui nenhum material adicionado"}
							</h3>
							<p className="text-center font-inter font-normal leading-160 text-neutral-500">
								{form.watch("title")
									? "Tente novamente com outra palavra chave"
									: "Adicione um material e ele poderá ser visualizado e gerenciado por aqui."}
							</p>
						</div>
					</div>
				)}
			</div>

			{modalIsOpen && (
				<AdminAcademyMaterialsCreate
					closeModal={() => {
						setModalIsOpen(false);
						setIsEditing(false);
						setCurrentMaterial(null);
					}}
					isEditing={isEditing}
					currentMaterial={currentMaterial}
					onSuccess={() => {
						fetchMaterials();
						setModalIsOpen(false);
						setIsEditing(false);
						setCurrentMaterial(null);
						setSuccessModal(true);
					}}
				/>
			)}

			{successModal && (
				<AlertModal
					title={
						isEditing
							? "Alterações salvas com sucesso!"
							: "Material adicionado com sucesso!"
					}
					icon="done.svg"
					confirmText="Concluir"
					confirmAction={() => {
						setSuccessModal(false);
					}}
					hasCancel={isEditing ? false : true}
					cancelText="Adicionar mais materiais"
					cancelAction={() => {
						setSuccessModal(false);
						setModalIsOpen(true);
					}}
					closeModal={() => setSuccessModal(false)}
				/>
			)}

			{deleteModal && (
				<DeleteModal
					title="Deseja realmente excluir esse material?"
					description="Essa ação não poderá ser desfeita."
					icon="warning.svg"
					confirmText="Excluir material"
					isLoading={isLoading}
					cancelAction={() => setDeleteModal(false)}
					confirmAction={handleDeleteMaterial}
					closeModal={() => setDeleteModal(false)}
				/>
			)}

			{successDeleteModal && (
				<AlertModal
					title="Material excluído com sucesso!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessDeleteModal(false);
					}}
					closeModal={() => {
						setSuccessDeleteModal(false);
					}}
				/>
			)}
		</div>
	);
}
