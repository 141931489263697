import {
	Pagination,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
} from "@/components/ui/pagination";

interface PaginationListProps {
	total: number;
	to: number;
	currentPage: number;
	lastPage: number;
	onPageChange: (page: number) => void;
	onNextPage: () => void;
	onPreviousPage: () => void;
}

export function PaginationList({
	total,
	to,
	currentPage,
	lastPage,
	onPageChange,
	onNextPage,
	onPreviousPage,
}: PaginationListProps) {
	const getPageNumbers = () => {
		const pageNumbers = [];
		const maxPagesToShow = 3;

		if (lastPage <= maxPagesToShow) {
			for (let i = 1; i <= lastPage; i++) {
				pageNumbers.push(i);
			}
		} else {
			if (currentPage <= 2) {
				pageNumbers.push(1, 2, 3);
			} else if (currentPage >= lastPage - 1) {
				pageNumbers.push(lastPage - 2, lastPage - 1, lastPage);
			} else {
				pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
			}
		}

		return pageNumbers;
	};

	return (
		<div className="flex flex-col-reverse items-center justify-between gap-4 md:flex-row md:items-center">
			<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
				{to} de {total} resultados encontrados
			</span>

			<Pagination>
				<PaginationContent>
					<PaginationItem>
						<PaginationPrevious
							onClick={onPreviousPage}
							disabled={currentPage === 1}
						/>
					</PaginationItem>

					{currentPage > 2 && lastPage > 3 && (
						<>
							<PaginationItem>
								<PaginationLink onClick={() => onPageChange(1)}>
									1
								</PaginationLink>
							</PaginationItem>
							<PaginationItem>
								<PaginationEllipsis />
							</PaginationItem>
						</>
					)}

					{getPageNumbers().map((pageNumber) => (
						<PaginationItem key={pageNumber}>
							<PaginationLink
								isActive={pageNumber === currentPage}
								onClick={() => onPageChange(pageNumber)}
							>
								{pageNumber}
							</PaginationLink>
						</PaginationItem>
					))}

					{currentPage < lastPage - 1 && lastPage > 3 && (
						<>
							<PaginationItem>
								<PaginationEllipsis />
							</PaginationItem>
							<PaginationItem>
								<PaginationLink onClick={() => onPageChange(lastPage)}>
									{lastPage}
								</PaginationLink>
							</PaginationItem>
						</>
					)}

					<PaginationItem>
						<PaginationNext
							onClick={onNextPage}
							disabled={currentPage === lastPage}
						/>
					</PaginationItem>
				</PaginationContent>
			</Pagination>
		</div>
	);
}
