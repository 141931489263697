import ChevronRightIcon from "@/assets/chevron-right.svg?react";
import FileDocumentIcon from "@/assets/file-document.svg?react";

interface ContentCardProps {
	title: string;
	onNavigate?: () => void;
}

export function ContentCardNavigation({ title, onNavigate }: ContentCardProps) {
	return (
		<div
			className={`flex h-full max-h-[62px] cursor-pointer items-center justify-between rounded-xl border border-neutral-100 p-4 transition-all duration-300 hover:bg-neutral-100`}
			onClick={onNavigate}
		>
			<div className="flex items-center gap-6 p-1 text-neutral-1100">
				<FileDocumentIcon />

				<p className="font-inter text-P4 font-medium leading-160">{title}</p>
			</div>

			<div className={`flex items-center gap-2 text-neutral-200`}>
				<ChevronRightIcon />
			</div>
		</div>
	);
}
