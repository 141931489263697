import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";

import { getUser } from "@/api/app/account/me";
import { getOnboarding, Question } from "@/api/auth/onboarding";
import { saveOnboarding, SelectedAnswers } from "@/api/auth/onboarding/save";
import { OptionItem } from "@/components/option-item";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { useAuthStore } from "@/store/auth";

export function Onboarding() {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [questions, setQuestions] = useState<Question[]>([]);
	const [selectedAnswers, setSelectedAnswers] = useState<SelectedAnswers[]>([]);

	const navigate = useNavigate();

	function handleOptionSelect(questionId: string, answerId: string) {
		const updatedQuestions = [...questions];
		const questionIndex = updatedQuestions.findIndex(
			(question) => question.id === questionId,
		);

		updatedQuestions[questionIndex].answers.forEach((answer) => {
			answer.isSelected = false;
		});

		const answerIndex = updatedQuestions[questionIndex].answers.findIndex(
			(answer) => answer.id === answerId,
		);
		updatedQuestions[questionIndex].answers[answerIndex].isSelected = true;

		setQuestions(updatedQuestions);

		const answer = {
			question_id: questionId,
			answer_id: answerId,
		};

		const answerIndexInSelectedAnswers = selectedAnswers.findIndex(
			(selectedAnswer) => selectedAnswer.question_id === questionId,
		);

		if (answerIndexInSelectedAnswers === -1) {
			setSelectedAnswers([...selectedAnswers, answer]);
		} else {
			const updatedSelectedAnswers = [...selectedAnswers];
			updatedSelectedAnswers[answerIndexInSelectedAnswers] = answer;
			setSelectedAnswers(updatedSelectedAnswers);
		}
	}

	function areAllQuestionsAnswered() {
		return questions.slice(currentIndex, currentIndex + 2).every((question) => {
			return question.answers.some((answer) => answer.isSelected);
		});
	}

	function goToPrevious() {
		if (currentIndex === 0) return;
		setCurrentIndex(currentIndex - 2);
	}

	async function goToNext() {
		if (areAllQuestionsAnswered() && currentIndex !== questions.length - 2) {
			setCurrentIndex(currentIndex + 2);
		} else if (currentIndex === questions.length - 2) {
			await saveOnboarding({
				onboarding_questions: selectedAnswers,
			}).then(() => {
				navigate("/");
			});
		}
	}

	async function fetchQuestions() {
		const response = await getOnboarding();

		if (response.status === "success") {
			setQuestions(response.data);
		}

		if (response.status === "error") {
			console.error(response.errors);
		}
	}

	const { setProfile, isAuth, profile } = useAuthStore();

	useEffect(() => {
		if (isAuth) {
			fetchQuestions();
			getUser().then((response) => {
				if (response.status === "success") {
					const { data } = response;
					setProfile(data);
				}
			});
		}
	}, [isAuth]);

	useEffect(() => {
		if (profile?.questionsUser?.length ?? 0 > 0) {
			navigate("/");
		}
	}, [profile]);

	return (
		<div className="flex h-screen animate-fade flex-col items-center gap-8 md:gap-20">
			<Helmet title="Onboarding" />
			<header className="sticky top-0 flex w-full justify-center border-b border-solid border-neutral-100 bg-neutral-0">
				<div className="flex max-w-[1440px] flex-1 items-center justify-between p-8 md:px-28">
					<Link to="/onboarding">
						<img src="/logo.svg" alt="Logo Suportefy" width={110} height={32} />
					</Link>

					<p className="font-inter text-P3 font-medium leading-160 text-white">
						🏁 Onboarding
					</p>
				</div>
			</header>

			{questions.length > 0 && (
				<>
					<main className="flex w-full flex-col justify-between gap-12 px-8 pb-28 md:max-w-[1440px] md:flex-row md:gap-[6.5rem] md:px-28 md:pb-0">
						<section className="hidden w-full min-w-96 flex-col gap-4 md:flex">
							<h2 className="font-inter text-P2 font-medium leading-140 text-white">
								Olá, {profile?.name}!
							</h2>

							<p className="font-inter text-P4 font-normal leading-160 text-neutral-500">
								Ficamos feliz de ter você aqui. Queremos conhecer sua jornada
								para oferecer uma melhor experiência em nosso portal.
							</p>
						</section>

						<div className="flex flex-1 flex-col items-center justify-between gap-3 md:hidden">
							<div className="flex w-full items-center justify-between">
								<p className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Progresso
								</p>
								<p className="font-inter text-P5 font-medium leading-160 text-white">
									{(currentIndex + 2) / 2} de {questions.length / 2}
								</p>
							</div>
							<Progress
								value={Math.round(
									((currentIndex + 2) / questions.length) * 100,
								)}
							/>
						</div>

						<section className="flex w-full flex-col gap-12 md:min-w-[728px] md:gap-16">
							{questions
								.slice(currentIndex, currentIndex + 2)
								.map((question) => (
									<div key={question.id}>
										<article className="flex flex-col gap-8">
											<h3 className="font-poppins text-P3 font-bold leading-140 text-white md:text-H7 md:font-semibold md:leading-140">
												{question.description}
											</h3>

											<div className="flex flex-col flex-wrap gap-4 md:flex-row">
												{question.answers.map((answer) => (
													<OptionItem
														key={answer.id}
														text={answer.description}
														isSelected={answer.isSelected}
														onClick={() =>
															handleOptionSelect(question.id, answer.id)
														}
													/>
												))}
											</div>
										</article>

										<span className="flex h-px w-full bg-neutral-100 md:hidden"></span>
									</div>
								))}
						</section>
					</main>

					<footer className="fixed inset-x-auto bottom-0 flex w-full items-center justify-center bg-neutral-0 px-5 py-8 md:sticky md:max-w-[1440px] md:gap-[120px] md:bg-none md:px-28 md:py-12">
						<div className="hidden h-12 flex-1 flex-col items-center justify-between md:flex">
							<div className="flex w-full items-center justify-between">
								<p className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Progresso
								</p>
								<p className="font-inter text-P5 font-medium leading-160 text-white">
									{(currentIndex + 2) / 2} de {questions.length / 2}
								</p>
							</div>
							<Progress
								value={Math.round(
									((currentIndex + 2) / questions.length) * 100,
								)}
							/>
						</div>

						<div className="flex w-full gap-4 md:w-auto">
							<Button
								className="flex h-12 w-full flex-1 items-center justify-center gap-2 rounded-[8px] md:w-[140px]"
								variant="secondary"
								onClick={goToPrevious}
								disabled={currentIndex === 0}
							>
								Anterior
							</Button>
							<Button
								className="flex h-12 w-full flex-1 items-center justify-center gap-2 rounded-[8px] md:w-[140px]"
								onClick={goToNext}
								disabled={!areAllQuestionsAnswered()}
							>
								{currentIndex === questions.length - 2 ? "Concluir" : "Próximo"}
							</Button>
						</div>
					</footer>
				</>
			)}
		</div>
	);
}
