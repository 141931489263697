import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import ChevronDownIcon from "@/assets/chevron-down.svg?react";
import MathPlus from "@/assets/math-plus.svg?react";
import MoreAltIcon from "@/assets/more-alt.svg?react";
import PenIcon from "@/assets/pen.svg?react";
import TrashIcon from "@/assets/trash.svg?react";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = forwardRef<
	ElementRef<typeof AccordionPrimitive.Item>,
	ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
	<AccordionPrimitive.Item
		ref={ref}
		className={cn(
			"mb-6 flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-100 p-6 transition-all duration-300",
			className,
		)}
		{...props}
	/>
));
AccordionItem.displayName = "AccordionItem";

interface AccordionTriggerProps
	extends ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> {
	children?: React.ReactNode;
	module: number;
	title: string;
	addedLessons: number;
	addNewLesson: () => void;
	editModule: () => void;
	deleteModule: () => void;
}

const optionsIcons = {
	trash: <TrashIcon />,
	pen: <PenIcon />,
};

const options:
	| {
			title: string;
			icon: keyof typeof optionsIcons;
	  }[]
	| undefined = [
	{ title: "Editar", icon: "pen" },
	{ title: "Excluir", icon: "trash" },
];

const AccordionTrigger = forwardRef<
	ElementRef<typeof AccordionPrimitive.Trigger>,
	AccordionTriggerProps
>(
	(
		{
			className,
			children,
			module,
			title,
			addedLessons,
			addNewLesson,
			editModule,
			deleteModule,
			...props
		},
		ref,
	) => (
		<AccordionPrimitive.Header className="flex flex-col gap-4">
			<div className="flex items-center justify-between">
				<div className="flex flex-col gap-0.5">
					<span className="font-inter text-P7 font-medium leading-160 text-neutral-600">
						Módulo {module}
					</span>

					<p className="font-inter text-P5 font-medium leading-160 text-neutral-1100">
						{title}
					</p>
				</div>

				<span className="relative flex cursor-pointer items-center justify-center rounded-[8px] bg-transparent p-2  text-neutral-1100 transition-all duration-300 hover:rounded-[8px] hover:bg-neutral-200">
					<DropdownMenu>
						<DropdownMenuTrigger className="focus-visible:outline-none">
							<MoreAltIcon />
						</DropdownMenuTrigger>
						<DropdownMenuContent>
							{options.map((option, index) => (
								<DropdownMenuItem
									key={index + "option"}
									onClick={
										option.title === "Editar" ? editModule : deleteModule
									}
								>
									{optionsIcons[option.icon]}
									<p>{option.title}</p>
								</DropdownMenuItem>
							))}
						</DropdownMenuContent>
					</DropdownMenu>
				</span>
			</div>

			<hr className="h-px border-t-neutral-100" />

			<div className="flex flex-1 items-center justify-between transition-all duration-300 [&[data-state=open]>div>span]:bg-neutral-200 [&[data-state=open]>span>svg]:rotate-180">
				<div className="flex h-8 items-center justify-center rounded-[6px] bg-neutral-1100 bg-opacity-10 px-2.5">
					<p className="font-inter text-P6 font-medium leading-160 text-neutral-1100">
						{addedLessons > 1
							? `${addedLessons} aulas adicionadas`
							: addedLessons === 1
								? "1 aula adicionada"
								: "Nenhuma aula adicionada"}
					</p>
				</div>

				<div className="flex items-center gap-4">
					<button
						className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 px-6 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40"
						onClick={addNewLesson}
					>
						<MathPlus />

						<span className="font-inter text-P5 font-medium leading-160">
							Nova aula
						</span>
					</button>
					<AccordionPrimitive.Trigger
						ref={ref}
						className={cn(
							"flex size-10 items-center justify-center rounded-[6px] border border-solid border-neutral-200 bg-transparent p-[5px] transition-all duration-300 hover:bg-neutral-200 [&[data-state=open]>svg]:rotate-180",
							className,
						)}
						{...props}
					>
						<ChevronDownIcon width={22} height={22} color="white" />
					</AccordionPrimitive.Trigger>
				</div>
			</div>
		</AccordionPrimitive.Header>
	),
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = forwardRef<
	ElementRef<typeof AccordionPrimitive.Content>,
	ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
	<AccordionPrimitive.Content
		ref={ref}
		className="overflow-hidden text-P5 transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
		{...props}
	>
		<div
			className={cn(
				"flex flex-col gap-6 font-inter text-P5 font-normal leading-160 text-neutral-500",
				className,
			)}
		>
			{children}
		</div>
	</AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
