import BraipLogo from "@/assets/braip.svg?react";
import MonetizzeLogo from "@/assets/monetizze.svg?react";
import TheMartLogo from "@/assets/themart.svg?react";

interface DashboardSalesProps {
	sales_total_value: string;
	sales_total_commission_value: string;
	data_platform_sales_total: {
		platform: string;
		total_value: string;
		total_commission_value: string;
	}[];
}

export function DashboardSales({
	sales_total_value,
	sales_total_commission_value,
	data_platform_sales_total,
}: DashboardSalesProps) {
	const mockData = [
		{
			platform: "Monetizze",
			total_value: "R$ 0,00",
			total_commission_value: "R$ 0,00",
		},
		{
			platform: "The Mart",
			total_value: "R$ 0,00",
			total_commission_value: "R$ 0,00",
		},
		{
			platform: "Braip",
			total_value: "R$ 0,00",
			total_commission_value: "R$ 0,00",
		},
	];

	const dataToDisplay =
		data_platform_sales_total.length > 0 ? data_platform_sales_total : mockData;

	return (
		<section className="flex flex-col gap-8 md:flex-row">
			<div className="flex w-full flex-col justify-center gap-8 md:max-w-[352px]">
				<div className="flex flex-col gap-4 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-6">
					<p className="truncate font-inter text-P4 font-normal leading-160 text-neutral-800">
						Total em vendas
					</p>
					<div className="flex items-center justify-between">
						<p className="truncate font-inter text-H7 font-medium leading-140 text-neutral-1100">
							{sales_total_value}
						</p>
					</div>
				</div>
				<div className="flex flex-col gap-4 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-6">
					<p className="truncate font-inter text-P4 font-normal leading-160 text-neutral-800">
						Total em comissões
					</p>
					<div className="flex items-center justify-between">
						<p className="truncate font-inter text-H7 font-medium leading-140 text-neutral-1100">
							{sales_total_commission_value}
						</p>
					</div>
				</div>
			</div>
			<div className="flex h-full w-full flex-col gap-10 rounded-[8px] border border-solid border-neutral-100 p-6 md:flex-row">
				{dataToDisplay.map((platform) => (
					<div className="flex w-full flex-col gap-8" key={platform.platform}>
						<span
							className={`
									flex h-12 w-12 items-center justify-center rounded-[8px] p-1
									${platform.platform === "Monetizze" && "bg-[#0500EC]"}
									${platform.platform === "The Mart" && "bg-[#FF6700]"}
									${platform.platform === "Braip" && "bg-[#6D36FB]"}
									`}
						>
							{platform.platform === "Monetizze" && <MonetizzeLogo />}
							{platform.platform === "The Mart" && <TheMartLogo />}
							{platform.platform === "Braip" && <BraipLogo />}
						</span>
						<div className="flex flex-col gap-4">
							<div className="flex flex-col gap-2 rounded-[8px]">
								<p className="truncate font-inter text-P5 font-normal leading-160 text-neutral-800">
									Vendas
								</p>
								<div className="flex items-center justify-between">
									<p className="truncate font-inter text-P2 font-medium leading-140 text-neutral-1100">
										{platform.total_value}
									</p>
								</div>
							</div>
							<hr className="h-px border-t-neutral-100" />
							<div className="flex flex-col gap-2 rounded-[8px]">
								<p className="truncate font-inter text-P5 font-normal leading-160 text-neutral-800">
									Comissões
								</p>
								<div className="flex items-center justify-between">
									<p className="truncate font-inter text-P2 font-medium leading-140 text-neutral-1100">
										{platform.total_commission_value}
									</p>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</section>
	);
}
