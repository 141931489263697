import { useState } from "react";

import CloseIcon from "@/assets/close.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import { MaterialCard } from "@/components/material-card";
import { Input } from "@/components/ui/input";
import { useProductStore } from "@/store/product";

export function ProductMaterials() {
	const { product } = useProductStore();

	const materials = product?.materials ?? [];

	const [searchTerm, setSearchTerm] = useState("");
	const [filteredMaterials, setFilteredMaterials] = useState(materials);

	function handleSearch(searchTerm: string) {
		setSearchTerm(searchTerm);

		const filtered = materials.filter((material) =>
			material.title.toLowerCase().includes(searchTerm.toLowerCase()),
		);

		setFilteredMaterials(filtered);
	}

	return (
		<div className="flex flex-col gap-6">
			{materials.length > 0 && (
				<div className="relative text-neutral-200 focus-within:text-neutral-1100">
					<Input
						placeholder="Pesquisar"
						className="h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:text-P5 placeholder:text-neutral-200"
						onChange={(e) => handleSearch(e.target.value)}
						value={searchTerm}
					/>
					<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

					{searchTerm && (
						<button
							className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-200 text-neutral-1100"
							onClick={() => handleSearch("")}
						>
							<CloseIcon />
						</button>
					)}
				</div>
			)}

			<div className={`grid grid-cols-1 gap-8 md:grid-cols-2`}>
				{filteredMaterials.map((material) => (
					<MaterialCard
						key={material.id}
						title={material.title}
						type={material.format as "image" | "text" | "link" | "document"}
						link={material.link}
					/>
				))}
			</div>

			{!filteredMaterials.length && (
				<div className="flex flex-col items-center justify-center gap-6 px-56 py-36">
					<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
						<SearchIcon />
					</span>
					<h3 className="w-[240px] text-center font-inter font-medium leading-160 text-neutral-1100">
						{searchTerm
							? "Não foi possível encontrar nenhum material"
							: "Esse produto não possui nenhum material cadastrado"}
					</h3>
				</div>
			)}
		</div>
	);
}
