import { AxiosError } from "axios";

import { api } from "@/lib/axios";

type Platform = {
	external_id: string;
	id: string;
	link: string;
	platform: string;
	platform_id: string;
	url_postback: string;
};

type Material = {
	id: string;
	material_id: string;
	title: string;
	format: string;
	link: string;
	created_at: string;
};

type Doubt = {
	id: string;
	question: string;
	answer: string;
};

export type Product = {
	id: string;
	name: string;
	type: {
		id: string;
		name: string;
	};
	niche: {
		id: string;
		name: string;
	};
	image: string;
	description: string | null;
	rules: string | null;
	awards: string | null;
	earnings_projection: string | null;
	platforms: Platform[];
	materials: Material[];
	doubts: Doubt[];
	telegram: string | null;
	created: string;
};

export async function showAdminProduct(id: string): Promise<
	| {
			status: "success";
			data: Product;
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get(`/product/show/${id}`);

		return {
			status: "success",
			data: data.data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
