import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import BellIcon from "@/assets/bell.svg?react";
import ChevronDownIcon from "@/assets/chevron-down.svg?react";
import MenuLeftAltIcon from "@/assets/menu-left-alt.svg?react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useAuthStore } from "@/store/auth";
import { useNavigationStore } from "@/store/navigation-store";
import { authorFallback } from "@/utils/mask-author-fallback";

import { Breadcrumb } from "./breadcrumb";
import { NotificationDropdown } from "./notification-dropdown";
import { ProfileDropdown } from "./profile-dropdown";

export function Header() {
	const [isNotificationOpen, setIsNotificationOpen] = useState(false);
	const [isProfileOpen, setIsProfileOpen] = useState(false);
	const profileRef = useRef<HTMLDivElement>(null);
	const notificationRef = useRef<HTMLDivElement>(null);

	const [paths] = useNavigationStore((state) => [state.paths]);

	const { setMenuIsOpen, menuIsOpen } = useNavigationStore();

	useEffect(() => {
		function handleClickOutsideProfile(event: MouseEvent) {
			if (
				profileRef.current &&
				!profileRef.current.contains(event.target as Node)
			) {
				setIsProfileOpen(false);
			}
		}

		function handleClickOutsideNotification(event: MouseEvent) {
			if (
				notificationRef.current &&
				!notificationRef.current.contains(event.target as Node)
			) {
				setIsNotificationOpen(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutsideProfile);
		document.addEventListener("mousedown", handleClickOutsideNotification);
	}, []);

	const { profile } = useAuthStore();

	const [unreadNotifications, setUnreadNotifications] = useState(0);

	function checkUnreadNotifications() {
		if (!profile) {
			return;
		}

		const total = profile.notifications.meta.total;
		const countRead = profile.notifications.meta.count_read;

		const unread = total - countRead;

		setUnreadNotifications(unread);
	}

	useEffect(() => {
		checkUnreadNotifications();
	}, [profile]);

	return (
		<header className="fixed left-0 right-0 top-0 z-10 flex items-center justify-between border-b border-b-neutral-100 bg-neutral-0 px-5 pb-6 pt-4 md:px-8 md:py-6 xl:left-[258px]">
			<div className="hidden xl:flex">
				<Breadcrumb paths={paths} />
			</div>

			<button
				className="flex items-center justify-center text-neutral-1100 xl:hidden"
				onClick={() => setMenuIsOpen(!menuIsOpen)}
			>
				<MenuLeftAltIcon width={32} height={32} />
			</button>

			<Link to="/" className="flex xl:hidden">
				<img src="/logo.svg" alt="Logo Suportefy" width={110} height={32} />
			</Link>

			<div className="flex items-center gap-6 md:gap-8">
				<div className="relative">
					<button
						className={`relative flex h-8 w-8 items-center justify-center rounded-[8px] p-1 transition-all duration-300 hover:bg-neutral-100 md:h-auto md:w-auto md:p-[13px] ${
							isNotificationOpen && "bg-neutral-200"
						}`}
						onClick={() => setIsNotificationOpen((prev) => !prev)}
					>
						<BellIcon color="white" />

						{profile && unreadNotifications > 0 && (
							<span className="absolute -right-[5px] -top-[5px] flex size-5 items-center justify-center gap-2.5 rounded-full border border-neutral-100 bg-red-600 p-2 md:right-1 md:top-1">
								<span className="font-inter text-xs font-medium text-white">
									{unreadNotifications}
								</span>
							</span>
						)}
					</button>

					{(isNotificationOpen || isProfileOpen) && (
						<div className="fixed bottom-0 left-0 right-0 top-0 z-[5] bg-neutral-0 bg-opacity-40" />
					)}

					{isNotificationOpen && (
						<div className="fixed inset-0 z-10 md:absolute md:-right-2 md:bottom-auto md:left-auto md:top-[75px]">
							<header className="flex h-[50px] bg-neutral-100 md:hidden" />
							<div ref={notificationRef}>
								<NotificationDropdown
									closeDropdown={() => setIsNotificationOpen(false)}
								/>
							</div>
						</div>
					)}
				</div>

				<div className="relative">
					<button
						className="flex items-center gap-4"
						onClick={() => setIsProfileOpen((prev) => !prev)}
					>
						<Avatar>
							<AvatarImage src={profile?.image ?? ""} />
							<AvatarFallback>
								{authorFallback(profile?.nickname)}
							</AvatarFallback>
						</Avatar>

						<div className="hidden items-center gap-4 md:flex">
							<p className="w-full max-w-[200px] truncate font-inter text-P5 font-medium leading-160 text-white">
								Olá, {profile?.nickname}!
							</p>

							<ChevronDownIcon color="white" />
						</div>
					</button>

					{isProfileOpen && (
						<div className="fixed inset-0 z-10 md:absolute md:-right-2 md:bottom-auto md:left-auto md:top-[75px]">
							<header className="flex h-[50px] bg-neutral-100 md:hidden" />

							<div ref={profileRef}>
								<ProfileDropdown
									closeDropdown={() => setIsProfileOpen(false)}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</header>
	);
}
