import { AxiosError } from "axios";

import { api } from "@/lib/axios";

interface FetchAdminUsersParams {
	search: string;
	email: string;
	status: number | string;
	permission: "admin" | "user";
	period: string;
	sortBy: "created_at-DESC" | "created_at-ASC" | "name-DESC" | "name-ASC";
	perPage: number;
}

export type User = {
	id: string;
	name: string;
	email: string;
	phone: string;
	image: string;
	status: "Desativado" | "Ativo";
	isAdmin: boolean;
	created_at: string;
};

export async function fetchAdminUsers(
	params: Partial<FetchAdminUsersParams>,
): Promise<
	| {
			status: "success";
			data: User[];
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get("/user", {
			params,
		});

		return {
			status: "success",
			data: data.data.data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
