import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { addAcademyMaterial } from "@/api/admin/academy/materials/add-material";
import type { Material } from "@/api/admin/academy/materials/list";
import { updateAcademyMaterial } from "@/api/admin/academy/materials/update-material";
import FileIcon from "@/assets/file-2.svg?react";
import FormatUppercaseIcon from "@/assets/format-uppercase.svg?react";
import ImageIcon from "@/assets/image-2.svg?react";
import PlayButtonOIcon from "@/assets/play-button-o.svg?react";
import { FileCard } from "@/components/file-card";
import { FileUploader } from "@/components/file-uploader";
import { Modal } from "@/components/modal";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

interface AdminAcademyMaterialsCreateProps {
	closeModal: () => void;
	isEditing?: boolean;
	currentMaterial?: Material | null;
	onSuccess: () => void;
}

export function AdminAcademyMaterialsCreate({
	currentMaterial,
	closeModal,
	isEditing = false,
	onSuccess,
}: AdminAcademyMaterialsCreateProps) {
	const [isLoading, setIsLoading] = useState(false);

	const materialType = currentMaterial?.format as
		| "image"
		| "text"
		| "link"
		| "document";

	const type =
		materialType === "image"
			? "Imagem"
			: materialType === "text"
				? "Texto"
				: materialType === "link"
					? "Link"
					: "Documento";

	const formSchema = z.object({
		name: z
			.string()
			.min(3, "O nome do material deve conter no mínimo 3 caracteres"),
		type: z.string(),
		file: z.union([z.instanceof(File), z.string()]),
		material_id: z.string().optional(),
		link: z.string().optional(),
	});

	type Filter = z.infer<typeof formSchema>;

	const form = useForm<Filter>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			name: currentMaterial?.title || "",
			type: isEditing ? type : "",
			file: currentMaterial?.link || "",
		},
	});

	const confirmAction = form.handleSubmit(async (data) => {
		const formData = new FormData();
		formData.append("title", data.name);

		if (data.file instanceof File) {
			formData.append("material", data.file instanceof File ? data.file : "");
		}

		if (data.type === "Link") {
			formData.append("link", data.file);
		}

		setIsLoading(true);

		if (isEditing && currentMaterial) {
			if (data.file instanceof File) {
				formData.append("link", "");
			}

			const response = await updateAcademyMaterial(
				currentMaterial.id,
				formData,
			);

			if (response.status === "success") {
				onSuccess();

				form.reset();

				setIsLoading(false);
				return;
			}

			if (response.status === "error") {
				const { errors } = response;
				for (const key in errors) {
					form.setError(key as keyof Filter, {
						type: "manual",
						message: errors[key],
					});
				}
			}
		} else {
			const response = await addAcademyMaterial(formData);

			if (response.status === "success") {
				onSuccess();

				form.reset();

				setIsLoading(false);
				return;
			}

			if (response.status === "error") {
				const { errors } = response;
				for (const key in errors) {
					form.setError(key as keyof Filter, {
						type: "manual",
						message: errors[key],
					});
				}
			}
		}

		setIsLoading(false);
	});

	return (
		<Modal
			title={isEditing ? "Editar material" : "Adicionar material"}
			closeModal={closeModal}
			confirmAction={confirmAction}
			isLoading={isLoading}
		>
			<Form {...form}>
				<div className="flex flex-col gap-8">
					<div className="flex w-full flex-col items-center gap-6">
						<div className="flex w-full flex-col gap-2">
							<FloatingLabelInput
								id="name"
								type="text"
								label="Nome do material"
								hasError={!!form.formState.errors.name}
								{...form.register("name")}
							/>

							{form.formState.errors.name && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{form.formState.errors.name.message}
								</p>
							)}
						</div>

						<FormField
							control={form.control}
							name="type"
							render={({ field }) => (
								<FormItem className="w-full">
									<Select
										onValueChange={(e) => {
											field.onChange(e);
										}}
										defaultValue={field.value}
										value={field.value}
									>
										<FormControl>
											<SelectTrigger
												className="w-full"
												hasError={!!form.formState.errors.type}
											>
												<SelectValue placeholder="Tipo de material" />
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											<SelectItem value="Imagem">
												<div className="flex items-center gap-3">
													<ImageIcon width={20} height={20} />
													<p>Imagem</p>
												</div>
											</SelectItem>
											<SelectItem value="Documento">
												<div className="flex items-center gap-3">
													<FileIcon width={20} height={20} />
													<p>Documento</p>
												</div>
											</SelectItem>
											<SelectItem value="Link">
												<div className="flex items-center gap-3">
													<PlayButtonOIcon width={20} height={20} />
													<p>Video</p>
												</div>
											</SelectItem>
											<SelectItem value="Texto">
												<div className="flex items-center gap-3">
													<FormatUppercaseIcon width={20} height={20} />
													<p>Texto</p>
												</div>
											</SelectItem>
										</SelectContent>
									</Select>

									{form.formState.errors.type && (
										<p className="font-inter text-P6 font-normal leading-160 text-red-600">
											{form.formState.errors.type.message}
										</p>
									)}
								</FormItem>
							)}
						/>
					</div>

					<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
						<div className="flex flex-col gap-2">
							<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
								{form.watch("type") === "Link" ? "Link" : "Arquivo"}
							</p>

							<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
								{form.watch("type") === "Link"
									? "Adicione o link do seu vídeo"
									: "Adicione o arquivo do seu material"}
							</span>
						</div>

						{form.watch("type") === "Link" && (
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="link"
									type="text"
									label="Link"
									hasError={!!form.formState.errors.link}
									{...form.register("file")}
								/>

								{form.formState.errors.link && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.link.message}
									</p>
								)}
							</div>
						)}

						{form.watch("type") !== "Link" && (
							<div className="flex w-full flex-col gap-2">
								{typeof form.watch("file") === "string" &&
								form.watch("file") !== "" ? (
									<FileCard
										url={form.watch("file") as string}
										onRemove={() => form.setValue("file", "")}
									/>
								) : form.watch("file") instanceof File ? (
									<FileCard
										file={form.watch("file") as File}
										onRemove={() => form.setValue("file", "")}
									/>
								) : (
									<FileUploader
										maxFiles={1}
										maxSize={20 * 1024 * 1024}
										accept={
											form.watch("type") === "Imagem"
												? {
														"image/*": [".jpg", ".jpeg", ".png", ".gif"],
													}
												: form.watch("type") === "Link"
													? {
															"video/mp4": [".mp4"],
															"video/quicktime": [".mov"],
														}
													: form.watch("type") === "Documento"
														? {
																"application/pdf": [".pdf"],
																"application/msword": [".doc"],
																"application/vnd.openxmlformats-officedocument.wordprocessingml.document":
																	[".docx"],
																"application/vnd.ms-powerpoint": [".ppt"],
																"application/vnd.openxmlformats-officedocument.presentationml.presentation":
																	[".pptx"],
																"application/vnd.ms-excel": [".xls"],
																"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
																	[".xlsx"],
																"text/csv": [".csv"],
																"application/zip": [".zip"],
															}
														: form.watch("type") === "Texto"
															? {
																	"text/plain": [".txt"],
																}
															: undefined
										}
										onUpload={async (e) => {
											form.setValue("file", e[0]);
										}}
										disabled={false}
									/>
								)}

								{form.formState.errors.file && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.file.message}
									</p>
								)}
							</div>
						)}
					</div>
				</div>
			</Form>
		</Modal>
	);
}
