import type { ReactNode } from "react";

import ArrowLeftIcon from "@/assets/arrow-left.svg?react";
import CloseIcon from "@/assets/close-2.svg?react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

import { Switch } from "./ui/switch";

interface AdminModalProps {
	title: string;
	children: ReactNode;
	hasCancel?: boolean;
	hasConfirm?: boolean;
	hasToggle?: boolean;
	toggleIsChecked?: boolean;
	onToggleChange?: () => void;
	confirmText?: string;
	cancelText?: string;
	isLoading?: boolean;
	showBackButton?: boolean;
	showHeader?: boolean;
	confirmAction: () => void;
	cancelAction?: () => void;
	closeModal: () => void;
	backAction?: () => void;
	className?: string;
}

export function Modal({
	title,
	children,
	hasCancel = true,
	hasConfirm = true,
	hasToggle = false,
	toggleIsChecked = false,
	onToggleChange,
	confirmText = "Salvar",
	cancelText = "Cancelar",
	isLoading = false,
	showBackButton = false,
	showHeader = true,
	confirmAction,
	cancelAction,
	closeModal,
	backAction,
	className,
}: AdminModalProps) {
	return (
		<div className="fixed bottom-0 left-0 right-0 top-0 z-20 flex items-end justify-center bg-[#000] bg-opacity-60 md:items-center md:p-4">
			<div
				className={cn(
					"relative flex h-5/6 w-full max-w-lg flex-col items-center justify-between rounded-xl bg-neutral-100 shadow-lg md:h-[600px] md:max-w-[620px]",
					className,
				)}
			>
				{showHeader && (
					<header className="flex w-full items-center justify-between gap-4 border-b border-neutral-200 p-4 md:p-6">
						<div className="flex items-center gap-4">
							{showBackButton && (
								<button onClick={backAction} className="text-neutral-400">
									<ArrowLeftIcon width={24} height={24} />
								</button>
							)}
							<h3 className="text-center font-inter text-P3 font-medium leading-160 text-neutral-1100 md:text-left">
								{title}
							</h3>
						</div>

						<button onClick={closeModal} className="text-neutral-400">
							<CloseIcon width={24} height={24} />
						</button>
					</header>
				)}

				<main className="h-full w-full overflow-y-auto p-4 md:p-8">
					{children}
				</main>

				{(hasCancel || hasConfirm) && (
					<footer
						className="flex w-full flex-col items-center gap-4 rounded-xl bg-neutral-100 p-4 md:flex-row md:justify-between md:gap-8 md:px-8 md:pb-8 md:pt-4"
						style={{
							boxShadow: "0px -10px 40px -10px rgba(18, 23, 23, 0.10)",
						}}
					>
						{hasToggle && (
							<div className="flex items-center gap-2 md:gap-4">
								<p className="font-inter text-P5 font-semibold text-neutral-1100">
									{toggleIsChecked ? "Ativado" : "Desativado"}
								</p>
								<Switch
									checked={toggleIsChecked}
									onCheckedChange={onToggleChange}
								/>
							</div>
						)}

						<div className="flex w-full flex-row gap-2 md:gap-4">
							{hasCancel && (
								<Button
									className="flex !h-12 w-full max-w-[30%] items-center justify-center gap-2 rounded-[8px] !bg-neutral-200 font-inter !text-P5 font-semibold leading-160 text-neutral-1100 transition-all duration-500 hover:!bg-opacity-40 md:!w-[140px] md:max-w-full
						"
									variant="secondary"
									onClick={cancelAction}
								>
									{cancelText}
								</Button>
							)}

							{hasConfirm && (
								<Button
									className="flex !h-12 !w-full items-center justify-center gap-2 rounded-[8px] font-inter !text-P5 font-semibold leading-160"
									onClick={confirmAction}
									loading={isLoading}
								>
									{confirmText}
								</Button>
							)}
						</div>
					</footer>
				)}
			</div>
		</div>
	);
}
