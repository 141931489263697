import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";

import { Card, CardContent, CardHeader } from "@/components/ui/card";
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart";

interface AdminPanelUsersPeriodProps {
	data_new_users_and_integrations: {
		[date: string]: {
			type: "new_users" | "new_integration_users";
			date: string;
			count: number;
		}[];
	};
}

const chartConfig = {
	newUsersWithIntegration: {
		label: "Integração",
		color: "hsl(var(--the-mart))",
	},
	newUsers: {
		label: "Novos usuários",
		color: "hsl(var(--monetizze))",
	},
} satisfies ChartConfig;

export function AdminPanelUsersPeriod({
	data_new_users_and_integrations,
}: AdminPanelUsersPeriodProps) {
	const chartData = Object.keys(data_new_users_and_integrations).map((date) => {
		const dataForDate = data_new_users_and_integrations[date];
		const formattedData: Record<string, number | string> = { month: date };

		dataForDate.forEach((entry) => {
			if (entry.type === "new_users") {
				formattedData.newUsers = entry.count;
			} else if (entry.type === "new_integration_users") {
				formattedData.newUsersWithIntegration = entry.count;
			}
		});

		return formattedData;
	});

	const mockData = [
		{ month: "January", newUsers: 0, newUsersWithIntegration: 0 },
		{ month: "February", newUsers: 0, newUsersWithIntegration: 0 },
		{ month: "March", newUsers: 0, newUsersWithIntegration: 0 },
		{ month: "April", newUsers: 0, newUsersWithIntegration: 0 },
		{ month: "May", newUsers: 0, newUsersWithIntegration: 0 },
		{ month: "June", newUsers: 0, newUsersWithIntegration: 0 },
	];

	const dataToDisplay = chartData.length > 0 ? chartData : mockData;

	return (
		<div className="flex flex-col gap-10 rounded-[8px] border border-solid border-neutral-100 p-6">
			<Card className="flex flex-col gap-10 border-none bg-transparent">
				<CardHeader className="p-0">
					<div className="flex items-center justify-between">
						<h2 className="font-inter text-P3 font-medium leading-140 text-neutral-1100">
							Por período
						</h2>

						<ul className="flex gap-6">
							<li className="flex items-center justify-center gap-2">
								<span className="h-2.5 w-2.5 rounded-full bg-blue-800" />

								<p className="font-inter text-P6 font-medium leading-160 text-neutral-500">
									Novos usuários
								</p>
							</li>

							<li className="flex items-center justify-center gap-2">
								<span className="h-2.5 w-2.5 rounded-full bg-orange-800" />

								<p className="font-inter text-P6 font-medium leading-160 text-neutral-500">
									Novos usuários com integração
								</p>
							</li>
						</ul>
					</div>
				</CardHeader>
				<CardContent className="p-0">
					<ChartContainer config={chartConfig} className="h-72 w-full">
						<AreaChart
							accessibilityLayer
							data={dataToDisplay}
							margin={{
								left: -20,
								right: 12,
							}}
						>
							<CartesianGrid vertical={false} />
							<XAxis
								dataKey="month"
								tickLine={false}
								axisLine={false}
								tickMargin={8}
							/>
							<YAxis
								tickLine={false}
								axisLine={false}
								tickMargin={8}
								tickCount={5}
							/>

							<ChartTooltip cursor={false} content={<ChartTooltipContent />} />

							<defs>
								<linearGradient id="fillMonetizze" x1="0" y1="0" x2="0" y2="1">
									<stop
										offset="0%"
										stopColor="var(--color-newUsers)"
										stopOpacity={0.5}
									/>
									<stop
										offset="100%"
										stopColor="var(--color-newUsers)"
										stopOpacity={0}
									/>
								</linearGradient>
								<linearGradient id="fillTheMart" x1="0" y1="0" x2="0" y2="1">
									<stop
										offset="0%"
										stopColor="var(--color-newUsersWithIntegration)"
										stopOpacity={0.5}
									/>
									<stop
										offset="100%"
										stopColor="var(--color-newUsersWithIntegration)"
										stopOpacity={0}
									/>
								</linearGradient>
							</defs>
							<Area
								dataKey="newUsersWithIntegration"
								type="natural"
								fill="url(#fillTheMart)"
								stroke="var(--color-newUsersWithIntegration)"
							/>
							<Area
								dataKey="newUsers"
								type="natural"
								fill="url(#fillMonetizze)"
								stroke="var(--color-newUsers)"
							/>
						</AreaChart>
					</ChartContainer>
				</CardContent>
			</Card>
		</div>
	);
}
