import { AxiosError } from "axios";

import { api } from "@/lib/axios";

export interface InitialSignUpBody {
	name: string;
	email: string;
	phone: string;
}

export async function initialSignUp({
	name,
	email,
	phone,
}: InitialSignUpBody): Promise<
	| { status: "success"; data: { [key: string]: string } }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.post("/auth/initial-register", {
			name,
			email,
			phone,
		});

		return {
			status: "success",
			data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
