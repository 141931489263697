import { AxiosError } from "axios";

import { api } from "@/lib/axios";

type Answer = {
	description: string;
	id: string;
	isSelected: boolean;
};

export type Question = {
	id: string;
	description: string;
	answers: Answer[];
};

export async function getOnboarding(): Promise<
	| {
			status: "success";
			data: Question[];
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get("/onboarding");

		const questions: Question[] = data.data.map((question: Question) => {
			return {
				id: question.id,
				description: question.description,
				answers: question.answers.map((answer: Answer) => {
					return {
						id: answer.id,
						description: answer.description,
						isSelected: false,
					};
				}),
			};
		});

		return {
			status: "success",
			data: questions,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
