import { type VariantProps, cva } from "class-variance-authority";
import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

const tagVariants = cva(
	"flex items-center justify-center gap-1.5 rounded-[8px] p-[6px] font-inter font-medium leading-160 !text-neutral-1100",
	{
		variants: {
			colors: {
				green: "bg-primary-600",
				red: "bg-red-600",
				orange: "bg-orange-600",
				blue: "bg-blue-600",
				pink: "bg-pink-600",
				grey: "bg-neutral-600",
				"minimalist-green": "bg-primary-1000",
				"minimalist-red": "bg-red-1000",
				"minimalist-orange": "bg-orange-1000",
				"minimalist-blue": "bg-blue-1000",
				"minimalist-pink": "bg-pink-1000",
				"minimalist-grey": "bg-neutral-200",
			},
			size: {
				small: "text-P5",
				medium: "text-P5",
				large: "text-P4",
			},
		},
	},
);

export interface TagProps
	extends HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof tagVariants> {
	disabled?: boolean;
}

function Tag({
	className,
	colors,
	size,
	disabled = false,
	...props
}: TagProps) {
	return (
		<div
			className={cn(
				tagVariants({ colors, size }),
				`${disabled && "opacity-40"}`,
				className,
			)}
			{...props}
		/>
	);
}

export { Tag, tagVariants };
