import { Skeleton } from "@/components/ui/skeleton";

export function SkeletonAdminProductsShowQuestions() {
	return (
		<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-100 p-6">
			<div className="flex items-center justify-between">
				<Skeleton className="h-6 w-1/3 rounded" />
				<Skeleton className="h-10 w-32 rounded" />
			</div>

			<div className="relative text-neutral-200 focus-within:text-neutral-1100">
				<Skeleton className="h-14 w-full rounded indent-8" />
				<Skeleton className="absolute right-4 top-1/2 h-6 w-6 -translate-y-1/2 rounded-full" />
			</div>

			<div>
				<div className="mt-6 grid grid-cols-1 gap-2">
					{Array.from({ length: 3 }).map((_, index) => (
						<div key={index} className="mb-4 flex flex-col gap-4">
							<Skeleton className="h-20 w-full rounded" />
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
