import { AxiosError } from "axios";

import { api } from "@/lib/axios";

interface createIntegrationPayload {
	email: string;
	platform_id: string;
}

export async function createIntegration({
	email,
	platform_id,
}: createIntegrationPayload): Promise<
	| {
			status: "success";
			data: string;
	  }
	| { status: "error"; errors: { [key: string]: string } }
	| { status: "warning"; errors: { [key: string]: string } }
> {
	try {
		const response = await api.post(`/integrations-user/store`, {
			email,
			platform_id,
		});

		return {
			status: "success",
			data: response.data.data.id,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "warning",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
