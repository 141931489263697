import { useNavigate } from "react-router-dom";

import ArrowLeftIcon from "@/assets/arrow-left.svg?react";
import ArrowRightIcon from "@/assets/arrow-right.svg?react";
import { Button } from "@/components/ui/button";
import { useLessonStore } from "@/store/lesson";
import { useTrainingStore } from "@/store/training";

export function AcademyTrainingLesson() {
	const { lesson } = useLessonStore();
	const { training } = useTrainingStore();

	function getUuid(link: string) {
		const url = new URL(link);
		const uuid = url.pathname.split("/")[1];

		return uuid;
	}

	const navigate = useNavigate();

	function handleNextLesson() {
		if (lesson && training) {
			// Encontra o módulo atual baseado no módulo_id da aula
			const currentModule = training.modules.find(
				(module) => module.id === lesson.module_id,
			);

			if (currentModule) {
				// Encontra o índice da aula atual dentro do módulo
				const currentLessonIndex = currentModule.lessons.findIndex(
					(l) => l.id === lesson.id,
				);

				// Verifica se há uma próxima aula
				if (currentLessonIndex < currentModule.lessons.length - 1) {
					const nextLesson = currentModule.lessons[currentLessonIndex + 1];

					navigate(`/academy/${training.id}/${nextLesson.id}`);
				}
			}
		}
	}

	function handlePreviousLesson() {
		if (lesson && training) {
			// Encontra o módulo atual baseado no módulo_id da aula
			const currentModule = training.modules.find(
				(module) => module.id === lesson.module_id,
			);

			if (currentModule) {
				// Encontra o índice da aula atual dentro do módulo
				const currentLessonIndex = currentModule.lessons.findIndex(
					(l) => l.id === lesson.id,
				);

				// Verifica se há uma aula anterior
				if (currentLessonIndex > 0) {
					const previousLesson = currentModule.lessons[currentLessonIndex - 1];

					navigate(`/academy/${training.id}/${previousLesson.id}`);
				}
			}
		}
	}

	function canNavigateToPreviousLesson() {
		if (lesson && training) {
			const currentModule = training.modules.find(
				(module) => module.id === lesson.module_id,
			);

			if (currentModule) {
				const currentLessonIndex = currentModule.lessons.findIndex(
					(l) => l.id === lesson.id,
				);

				if (currentLessonIndex > 0) {
					return true;
				}
			}
		}

		return false;
	}

	function canNavigateToNextLesson() {
		if (lesson && training) {
			const currentModule = training.modules.find(
				(module) => module.id === lesson.module_id,
			);

			if (currentModule) {
				const currentLessonIndex = currentModule.lessons.findIndex(
					(l) => l.id === lesson.id,
				);

				if (currentLessonIndex < currentModule.lessons.length - 1) {
					return true;
				}
			}
		}

		return false;
	}

	return (
		<>
			{lesson?.link && (
				<div className="flex h-48 w-full items-center justify-center rounded-xl bg-neutral-100 bg-opacity-60 md:h-full">
					{lesson?.link && (
						<div className="relative w-full md:px-40">
							<div
								className="relative w-full overflow-hidden"
								style={{ paddingBottom: "56.25%" }}
							>
								<iframe
									id={`panda-${getUuid(lesson?.link)}`}
									src={lesson?.link}
									className="absolute left-0 top-0 h-full w-full"
									allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
									allowFullScreen={true}
								/>
							</div>
						</div>
					)}
				</div>
			)}

			<div className="mt-8 flex items-center gap-4 md:hidden">
				<Button
					className="flex !h-10 flex-1 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 font-inter !text-P6 font-semibold leading-160 text-neutral-1100 transition-all duration-500 hover:bg-neutral-200 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:bg-transparent disabled:text-neutral-600"
					variant="secondary"
					onClick={handlePreviousLesson}
					disabled={!canNavigateToPreviousLesson()}
				>
					<ArrowLeftIcon width={18} height={18} />
					Aula anterior
				</Button>

				<Button
					className="flex !h-10 flex-1 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 font-inter !text-P6 font-semibold leading-160 text-neutral-1100 transition-all duration-500 hover:bg-neutral-200 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:bg-transparent disabled:text-neutral-600"
					onClick={handleNextLesson}
					disabled={!canNavigateToNextLesson()}
				>
					Próxima aula
					<ArrowRightIcon width={18} height={18} />
				</Button>
			</div>
		</>
	);
}
