import { AxiosError } from "axios";

import { api } from "@/lib/axios";

export interface SignInBody {
	email: string;
	password: string;
}

export async function signIn({
	email,
	password,
}: SignInBody): Promise<
	| { status: "success"; data: { [key: string]: string } }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.post("/auth/login", { email, password });

		return {
			status: "success",
			data: data.data.token,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		if (axiosError.response?.status === 401) {
			return {
				status: "error",
				errors: {
					email: "E-mail ou senha estão incorretos.",
					password: "E-mail ou senha estão incorretos.",
				},
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
