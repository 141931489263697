import { zodResolver } from "@hookform/resolvers/zod";
import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { confirmSignUp } from "@/api/auth/sign-up/confirm";
import { resendCodeSignUp } from "@/api/auth/sign-up/resend-code";
import { Button } from "@/components/ui/button";
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot,
} from "@/components/ui/input-otp";
import { useAuthStore } from "@/store/auth";
import { maskHideEmail } from "@/utils/mask-hide-email";

const signUpConfirmForm = z.object({
	code: z
		.string({
			required_error: "Código é obrigatório",
		})
		.length(6),
});

type SignUpConfirmForm = z.infer<typeof signUpConfirmForm>;

export function SignUpConfirm() {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);

	const { setToken, onRegister, resetOnRegister } = useAuthStore();

	const {
		handleSubmit,
		setValue,
		getValues,
		setError,
		formState: { errors },
	} = useForm<SignUpConfirmForm>({
		resolver: zodResolver(signUpConfirmForm),
	});

	async function handleSignUpConfirm({ code }: SignUpConfirmForm) {
		setIsLoading(true);
		const response = await confirmSignUp({
			code,
			email: onRegister ? onRegister.email : "",
		});

		if (response.status === "success") {
			const { data: token } = response;

			setToken(`${token}`);

			resetOnRegister();

			navigate("/sign-up-password");

			setIsLoading(false);

			return;
		}

		if (response.status === "error") {
			const { errors } = response;

			for (const key in errors) {
				setError(key as keyof SignUpConfirmForm, {
					type: "manual",
					message: errors[key],
				});
			}

			setIsLoading(false);

			return;
		}
	}

	useEffect(() => {
		if (!onRegister?.email) {
			navigate("/sign-up");
		}
	}, []);

	return (
		<>
			<Helmet title="Cadastre-se" />

			{onRegister && (
				<div className="flex animate-fade flex-col gap-12">
					<div className="flex flex-col gap-2">
						<h1 className="font-poppins text-H7 font-semibold leading-140 text-white">
							Enviamos um código de verificação em seu e-mail!
						</h1>

						<p className="font-inter text-P4 leading-160 text-neutral-500">
							O código foi enviado
							<span className="cursor-pointer text-white">
								{" "}
								{maskHideEmail(onRegister.email)}
							</span>
						</p>
					</div>

					<form
						onSubmit={handleSubmit(handleSignUpConfirm)}
						className="flex flex-col gap-10"
					>
						<div className="flex flex-col justify-center gap-2 md:justify-start">
							<InputOTP
								maxLength={6}
								pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
								inputMode="text"
								value={getValues("code")}
								onChange={(value) => setValue("code", value)}
								render={({ slots }) => (
									<>
										<InputOTPGroup>
											{slots.slice(0, 3).map((slot, index) => (
												<InputOTPSlot
													key={index}
													hasError={!!errors.code}
													{...slot}
												/>
											))}{" "}
										</InputOTPGroup>
										<InputOTPSeparator />
										<InputOTPGroup>
											{slots.slice(3).map((slot, index) => (
												<InputOTPSlot
													key={index}
													hasError={!!errors.code}
													{...slot}
												/>
											))}
										</InputOTPGroup>
									</>
								)}
							/>

							{errors.code && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{errors.code.message}
								</p>
							)}
						</div>

						<div className="flex flex-col gap-8">
							<Button type="submit" loading={isLoading}>
								Verificar código
							</Button>

							<footer className="flex items-center justify-center">
								<p className="font-inter text-P5 leading-160 text-neutral-500">
									Não recebeu código?
									<span
										className="cursor-pointer font-inter text-P5 font-medium leading-160 text-white transition-colors hover:text-neutral-800 hover:underline hover:underline-offset-8"
										onClick={async () =>
											await resendCodeSignUp({
												email: onRegister.email,
											})
										}
									>
										{" "}
										Reenviar código
									</span>
								</p>
							</footer>
						</div>
					</form>
				</div>
			)}
		</>
	);
}
