import { Skeleton } from "@/components/ui/skeleton";

export function DashboardSalesSkeleton() {
	return (
		<section className="flex flex-col gap-8 md:flex-row">
			{/* Skeleton for Total Sales and Total Commission */}
			<div className="flex w-full flex-col justify-center gap-8 md:max-w-[352px]">
				{/* Skeleton for Total Sales */}
				<div className="flex flex-col gap-4 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-6">
					<Skeleton className="h-5 w-[150px]" />
					<div className="flex items-center justify-between">
						<Skeleton className="h-6 w-[120px]" />
					</div>
				</div>
				{/* Skeleton for Total Commission */}
				<div className="flex flex-col gap-4 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-6">
					<Skeleton className="h-5 w-[150px]" />
					<div className="flex items-center justify-between">
						<Skeleton className="h-6 w-[120px]" />
					</div>
				</div>
			</div>

			{/* Skeleton for Sales Data by Platform */}
			<div className="flex h-full w-full flex-col gap-10 rounded-[8px] border border-solid border-neutral-100 p-6 md:flex-row">
				{[...Array(3)].map((_, index) => (
					<div
						className="flex w-full flex-col gap-8"
						key={`platform-skeleton-${index}`}
					>
						{/* Skeleton for Platform Logo */}
						<span className="flex h-12 w-12 items-center justify-center rounded-[8px] bg-neutral-200">
							<Skeleton className="h-8 w-8" />
						</span>
						<div className="flex flex-col gap-4">
							{/* Skeleton for Sales */}
							<div className="flex flex-col gap-2 rounded-[8px]">
								<Skeleton className="h-5 w-[80px]" />
								<div className="flex items-center justify-between">
									<Skeleton className="h-6 w-[100px]" />
								</div>
							</div>
							<hr className="h-px border-t-neutral-100" />
							{/* Skeleton for Commissions */}
							<div className="flex flex-col gap-2 rounded-[8px]">
								<Skeleton className="h-5 w-[100px]" />
								<div className="flex items-center justify-between">
									<Skeleton className="h-6 w-[100px]" />
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</section>
	);
}
