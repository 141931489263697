import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import DoneIcon from "@/assets/done.svg?react";
import { Button } from "@/components/ui/button";
import { useAuthStore } from "@/store/auth";

export function SignUpWelcome() {
	const navigate = useNavigate();

	const { isAuth } = useAuthStore();

	useEffect(() => {
		if (!isAuth) {
			navigate("/sign-in");
		}
	}, [isAuth]);

	return (
		<>
			<Helmet title="Cadastre-se" />

			<div className="flex animate-fade flex-col gap-12">
				<span className="flex size-20 items-center justify-center rounded-s border border-solid border-neutral-200 bg-[#ffffff0a] p-4 text-primary-600">
					<DoneIcon />
				</span>
				<div className="flex flex-col gap-2">
					<h1 className="font-poppins text-H7 font-semibold leading-140 text-white">
						Seja bem-vindo(a)!
					</h1>
					<p className="font-inter text-P4 leading-160 text-neutral-500 md:text-P4">
						Sua conta foi criada com sucesso! Bora começar a sua jornada
						digital?
					</p>
				</div>
				<section className="flex flex-col gap-10">
					<div className="flex flex-col gap-8">
						<Button onClick={() => navigate("/onboarding")}>Vamos lá!</Button>
					</div>
				</section>
			</div>
		</>
	);
}
