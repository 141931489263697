import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { fetchAdminUsers, type User } from "@/api/admin/users/list";
import CloseIcon from "@/assets/close.svg?react";
import OptionsIcon from "@/assets/options.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { useNavigationStore } from "@/store/navigation-store";

import { AdminUsersList } from "./list";

export function AdminUsers() {
	const filter = z.object({
		name: z.string().optional(),
		status: z.string().optional(),
		permission: z.enum(["admin", "user"]).optional(),
		period: z.string().optional(),
	});

	type Filter = z.infer<typeof filter>;
	const [isLoading, setIsLoading] = useState(false);
	const [filterIsOpen, setFilterIsOpen] = useState(false);
	const [users, setUsers] = useState<User[]>([]);

	const form = useForm<Filter>();

	async function fetchUsers() {
		setIsLoading(true);
		const response = await fetchAdminUsers({
			perPage: 10000,
			sortBy: "name-ASC",
		});

		if (response.status === "success") {
			setUsers(response.data);
		}

		setIsLoading(false);
	}

	async function handleFilterSubmit(data: Filter) {
		setIsLoading(true);

		const response = await fetchAdminUsers({
			perPage: 10000,
			search: data.name || undefined,
			sortBy: "name-ASC",
			status: data.status || undefined,
			permission: data.permission || undefined,
			...data,
		});

		if (response.status === "success") {
			setUsers(response.data);
		}

		setIsLoading(false);
		setFilterIsOpen(false);
	}

	useEffect(() => {
		fetchUsers();
	}, []);

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Configurações",
				path: "/admin",
			},
			{
				name: "Usuários",
				path: "/admin/users",
			},
		];

		setPaths(paths);
	}, []);

	return (
		<>
			<Helmet title="Configurações" />

			<div className="flex h-full flex-col gap-12">
				<header className="flex items-center justify-between">
					<h2 className="font-inter text-P2 font-medium leading-140 text-neutral-1100">
						Todos os usuários
					</h2>

					<button
						onClick={() => setFilterIsOpen(!filterIsOpen)}
						className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 px-6 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40"
					>
						<OptionsIcon />

						<p className="font-inter text-P6 font-semibold leading-160">
							Filtro
						</p>
					</button>
				</header>

				<div
					className={`flex max-h-0 flex-col gap-8 rounded-[10px] bg-neutral-100 bg-opacity-60 opacity-0 transition-all duration-500 ${
						filterIsOpen && "max-h-fit p-6 opacity-100"
					}`}
				>
					{filterIsOpen && (
						<Form {...form}>
							<div className="flex flex-col gap-6">
								<div className="relative w-full !text-neutral-500 focus-within:text-neutral-1100">
									<Input
										placeholder="Pesquisar"
										className="h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:text-P5 placeholder:!text-neutral-500"
										{...form.register("name")}
									/>
									<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

									{form.watch("name") !== "" && (
										<button
											className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-200 text-neutral-1100"
											onClick={() => form.setValue("name", undefined)}
										>
											<CloseIcon />
										</button>
									)}
								</div>

								<div className="flex gap-6">
									<FormField
										control={form.control}
										name="status"
										render={({ field }) => (
											<FormItem className="w-full">
												<Select
													onValueChange={(e) => {
														field.onChange(e);
													}}
													defaultValue={field.value}
													value={field.value}
												>
													<FormControl>
														<SelectTrigger className="w-full">
															<SelectValue placeholder="Status" />
														</SelectTrigger>
													</FormControl>
													<SelectContent>
														<SelectItem value="1">Ativo</SelectItem>
														<SelectItem value="2">Desativado</SelectItem>
													</SelectContent>
												</Select>
											</FormItem>
										)}
									/>

									<FormField
										control={form.control}
										name="permission"
										render={({ field }) => (
											<FormItem className="w-full">
												<Select
													onValueChange={(e) => {
														field.onChange(e);
													}}
													defaultValue={field.value}
													value={field.value}
												>
													<FormControl>
														<SelectTrigger className="w-full">
															<SelectValue placeholder="Permissão" />
														</SelectTrigger>
													</FormControl>
													<SelectContent>
														<SelectItem value="admin">Admin</SelectItem>
														<SelectItem value="user">Aluno</SelectItem>
													</SelectContent>
												</Select>
											</FormItem>
										)}
									/>

									{/* <FormField
										control={form.control}
										name="period"
										render={({ field }) => (
											<FormItem className="w-full">
												<Select
													onValueChange={(e) => {
														field.onChange(e);
													}}
													defaultValue={field.value}
													value={field.value}
												>
													<FormControl>
														<SelectTrigger className="w-full">
															<SelectValue placeholder="Período" />
														</SelectTrigger>
													</FormControl>
													<SelectContent>
														<SelectItem value="Teste">Teste</SelectItem>
													</SelectContent>
												</Select>
											</FormItem>
										)}
									/> */}
								</div>
							</div>

							<div className="flex flex-col justify-between gap-6 md:flex-row md:items-center">
								<div>
									<span className="font-inter text-P5 font-normal leading-160 text-neutral-400">
										Nenhum filtro aplicado
									</span>
								</div>

								<div className="flex gap-4">
									<Button
										className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] bg-transparent font-inter !text-P6 font-semibold leading-160 text-neutral-600 transition-all duration-500 hover:bg-neutral-200 disabled:bg-transparent disabled:text-neutral-400"
										variant="secondary"
										onClick={() => {
											form.setValue("period", undefined);
											form.setValue("permission", undefined);
											form.setValue("status", undefined);
											form.setValue("name", undefined);
											setFilterIsOpen(false);

											handleFilterSubmit({});
										}}
									>
										Limpar filtro
									</Button>
									<Button
										className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] font-inter !text-P6 font-semibold leading-160"
										onClick={form.handleSubmit(async (data) => {
											handleFilterSubmit(data);
										})}
									>
										Aplicar
									</Button>
								</div>
							</div>
						</Form>
					)}
				</div>

				{isLoading &&
					Array.from({ length: 5 }).map((_, index) => (
						<div key={index} className="mb-4 flex items-center gap-4">
							<Skeleton className="size-9 rounded-full" />

							<div className="flex flex-1 justify-between">
								<Skeleton className="h-5 w-32" />
								<Skeleton className="h-5 w-56" />
								<Skeleton className="h-5 w-24" />
								<Skeleton className="h-5 w-8" />
							</div>
						</div>
					))}

				{!isLoading && (
					<AdminUsersList
						users={users}
						filterIsOpen={filterIsOpen}
						setUsers={setUsers}
					/>
				)}
			</div>
		</>
	);
}
