import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { initialForgotPassword } from "@/api/auth/forgot-password/initial";
import { Button } from "@/components/ui/button";
import { FloatingLabelInput } from "@/components/ui/float-input";

const signInForm = z.object({
	email: z.string().email({
		message: "E-mail inválido",
	}),
});

type ForgotPasswordForm = z.infer<typeof signInForm>;

export function ForgotPassword() {
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<ForgotPasswordForm>({
		resolver: zodResolver(signInForm),
	});

	async function handleForgotPassword({ email }: ForgotPasswordForm) {
		setIsLoading(true);
		const response = await initialForgotPassword({
			email,
		});

		if (response.status === "success") {
			navigate("/forgot-password-email");
			setIsLoading(false);
			return;
		}

		if (response.status === "error") {
			const { errors } = response;

			for (const key in errors) {
				setError(key as keyof ForgotPasswordForm, {
					message: errors[key as keyof ForgotPasswordForm],
				});
			}

			setIsLoading(false);
			return;
		}
	}

	return (
		<>
			<Helmet title="Esqueci a senha" />

			<div className="flex animate-fade flex-col gap-12">
				<div className="flex flex-col gap-2">
					<h1 className="font-poppins text-H7 font-semibold leading-140 text-white">
						Esqueceu a senha?
					</h1>
					<p className="font-inter text-P5 leading-160 text-neutral-500 md:text-P4">
						Digite seu e-mail abaixo e enviaremos um e-mail para recuperação da
						sua senha.
					</p>
				</div>

				<form
					onSubmit={handleSubmit(handleForgotPassword)}
					className="flex flex-col gap-10"
				>
					<div className="flex flex-col gap-2">
						<FloatingLabelInput
							id="email"
							type="email"
							label="E-mail"
							hasError={!!errors.email}
							{...register("email")}
						/>

						{errors.email && (
							<p className="font-inter text-P6 font-normal leading-160 text-red-600">
								{errors.email.message}
							</p>
						)}
					</div>

					<div className="flex flex-col gap-4">
						<Button type="submit" loading={isLoading}>
							Continuar
						</Button>

						<Button
							variant="link"
							className="text-neutral-600"
							onClick={() => navigate("/sign-in")}
						>
							Retornar para o login
						</Button>
					</div>
				</form>
			</div>
		</>
	);
}
