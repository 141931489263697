import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { addAcademyTraining } from "@/api/admin/academy/trainings/add-training";
import { addAcademyMentor } from "@/api/admin/academy/trainings/mentor/add-mentor";
import type { Mentor } from "@/api/admin/academy/trainings/mentor/list-mentors";
import MathPlus from "@/assets/math-plus.svg?react";
import { FileCard } from "@/components/file-card";
import { FileUploader } from "@/components/file-uploader";
import { Modal } from "@/components/modal";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

interface AdminAcademyTrainingsCreateProps {
	mentors: Mentor[];
	closeModal: () => void;
	refreshMentors: () => void;
	onSuccess: () => void;
}

export function AdminAcademyTrainingsCreate({
	closeModal,
	mentors,
	refreshMentors,
	onSuccess,
}: AdminAcademyTrainingsCreateProps) {
	const [showMentorModal, setShowMentorModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const createMentor = z.object({
		name: z.string().min(1, {
			message: "O nome do mentor é obrigatório.",
		}),
		image: z.union([z.instanceof(File), z.string()]).refine(
			(image) => {
				if (image instanceof File) {
					return image.size <= 4 * 1024 * 1024;
				}

				return true;
			},
			{
				message: "A imagem deve ter no máximo 4MB.",
			},
		),
	});

	type CreateMentor = z.infer<typeof createMentor>;

	const createMentorForm = useForm<CreateMentor>({
		resolver: zodResolver(createMentor),
	});

	const createTraining = z.object({
		name: z.string().min(1, {
			message: "O nome do treinamento é obrigatório.",
		}),
		mentor: z.string({
			required_error: "O mentor responsável é obrigatório.",
		}),
		image: z
			.union([
				z.instanceof(File, {
					message: "A imagem deve ser um arquivo.",
				}),
				z.string(),
			])
			.refine(
				(image) => {
					if (image instanceof File) {
						return image.size <= 4 * 1024 * 1024;
					}

					return true;
				},
				{
					message: "A imagem deve ter no máximo 4MB.",
				},
			),
	});

	type CreateTraining = z.infer<typeof createTraining>;

	const createTrainingForm = useForm<CreateTraining>({
		resolver: zodResolver(createTraining),
	});

	return (
		<Modal
			title={showMentorModal ? "Adicionar mentor" : "Adicionar treinamento"}
			closeModal={closeModal}
			isLoading={isLoading}
			confirmAction={() => {
				if (showMentorModal) {
					createMentorForm.handleSubmit(async (data) => {
						setIsLoading(true);
						const formData = new FormData();
						formData.append("name", data.name);

						if (data.image instanceof File) {
							formData.append("image", data.image);
						}

						const { status } = await addAcademyMentor(formData);

						if (status === "success") {
							refreshMentors();
							setIsLoading(false);
							setShowMentorModal(false);

							return;
						}
					})();
				}

				if (!showMentorModal) {
					createTrainingForm.handleSubmit(async (data) => {
						setIsLoading(true);
						const formData = new FormData();

						formData.append("name", data.name);
						formData.append("mentor_id", data.mentor);
						formData.append("description", data.name);
						formData.append("image", data.image);

						const { status } = await addAcademyTraining(formData);

						if (status === "success") {
							setIsLoading(false);
							onSuccess();
						}
					})();
				}
			}}
			backAction={() => {
				setShowMentorModal(false);
			}}
			cancelAction={closeModal}
			showBackButton={showMentorModal}
		>
			{!showMentorModal && (
				<Form {...createTrainingForm}>
					<div className="flex flex-col gap-8">
						<div className="flex w-full flex-col items-center gap-6">
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="name"
									type="text"
									label="Nome do treinamento"
									hasError={!!createTrainingForm.formState.errors.name}
									{...createTrainingForm.register("name")}
								/>

								{createTrainingForm.formState.errors.name && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{createTrainingForm.formState.errors.name.message}
									</p>
								)}
							</div>

							<div className="flex w-full flex-col gap-6 rounded-[8px] border border-solid border-neutral-200 p-6">
								<div className="flex justify-between">
									<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
										Mentor responsável
									</p>

									<button
										className="flex items-center justify-center gap-1 text-neutral-600 transition-all duration-300 hover:text-neutral-1100"
										onClick={() => setShowMentorModal(true)}
									>
										<MathPlus />

										<span className="font-inter text-P5 font-medium leading-160">
											Adicionar mentor
										</span>
									</button>
								</div>

								<FormField
									control={createTrainingForm.control}
									name="mentor"
									render={({ field }) => (
										<FormItem className="w-full">
											<Select
												onValueChange={(e) => {
													field.onChange(e);
												}}
												defaultValue={field.value}
												value={field.value}
											>
												<FormControl>
													<SelectTrigger
														className="w-full"
														hasError={
															!!createTrainingForm.formState.errors.mentor
														}
													>
														<SelectValue placeholder="Mentor responsável" />
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{mentors.map((mentor) => (
														<SelectItem key={mentor.id} value={mentor.id}>
															{mentor.name}
														</SelectItem>
													))}
												</SelectContent>
											</Select>

											{createTrainingForm.formState.errors.mentor && (
												<p className="font-inter text-P6 font-normal leading-160 text-red-600">
													{createTrainingForm.formState.errors.mentor.message}
												</p>
											)}
										</FormItem>
									)}
								/>
							</div>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex flex-col gap-2">
								<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Imagem do treinamento
								</p>

								<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Essa será a capa referente ao card do seu treinamento
								</span>
							</div>
							<div className="flex w-full flex-col gap-2">
								{createTrainingForm.watch("image") instanceof File ? (
									<FileCard
										file={createTrainingForm.watch("image") as File}
										onRemove={() => createTrainingForm.setValue("image", "")}
									/>
								) : (
									<FileUploader
										maxFiles={1}
										maxSize={10 * 1024 * 1024}
										accept={{ "image/*": [".png", ".jpeg", ".jpg"] }}
										onUpload={async (e) => {
											createTrainingForm.setValue("image", e[0]);
										}}
										disabled={false}
									/>
								)}

								{createTrainingForm.formState.errors.image && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{createTrainingForm.formState.errors.image.message}
									</p>
								)}
							</div>
						</div>
					</div>
				</Form>
			)}

			{showMentorModal && (
				<Form {...createMentorForm}>
					<div className="flex flex-col gap-8">
						<div className="flex w-full flex-col items-center gap-6">
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="name"
									type="text"
									label="Nome do produto"
									hasError={!!createMentorForm.formState.errors.name}
									{...createMentorForm.register("name")}
								/>

								{createMentorForm.formState.errors.name && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{createMentorForm.formState.errors.name.message}
									</p>
								)}
							</div>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex flex-col gap-2">
								<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Imagem do mentor
								</p>

								<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Será a imagem referente ao perfil do mentor
								</span>
							</div>

							<div className="flex w-full flex-col gap-2">
								{typeof createMentorForm.watch("image") === "string" &&
								createMentorForm.watch("image") !== "" ? (
									<FileCard
										url={createMentorForm.watch("image") as string}
										onRemove={() => createMentorForm.setValue("image", "")}
									/>
								) : createMentorForm.watch("image") instanceof File ? (
									<FileCard
										file={createMentorForm.watch("image") as File}
										onRemove={() => createMentorForm.setValue("image", "")}
									/>
								) : (
									<FileUploader
										maxFiles={1}
										maxSize={10 * 1024 * 1024}
										accept={{ "image/*": [".png", ".jpeg", ".jpg"] }}
										onUpload={async (e) => {
											createMentorForm.setValue("image", e[0]);
										}}
										disabled={false}
									/>
								)}

								{createMentorForm.formState.errors.image && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{createMentorForm.formState.errors.image.message}
									</p>
								)}
							</div>
						</div>
					</div>
				</Form>
			)}
		</Modal>
	);
}
