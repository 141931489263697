import dayjs from "dayjs";
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";

import type { Integration } from "@/api/app/account/list-integrations";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart";

interface DashboardPeriodProps {
	data_platform_sales_total_by_day: {
		[date: string]: {
			date: string;
			platform: string;
			total_value: string;
			total_commission_value: string;
		}[];
	};
	integrations: Integration[];
}

export function DashboardPeriod({
	data_platform_sales_total_by_day,
	integrations,
}: DashboardPeriodProps) {
	const chartConfig = {
		theMart: {
			label: "The Mart",
			color: "hsl(var(--the-mart))",
		},
		braip: {
			label: "Braip",
			color: "hsl(var(--braip))",
		},
		monetizze: {
			label: "Monetizze",
			color: "hsl(var(--monetizze))",
		},
	} satisfies ChartConfig;

	// Filtrar apenas integrações ativas para exibir na legenda
	const activeIntegrations = integrations.filter(
		(integration) => integration.active,
	);

	// Verificar se há dados ou criar mock com valores zerados
	const chartData = Object.keys(data_platform_sales_total_by_day).length
		? Object.keys(data_platform_sales_total_by_day).map((date) => {
				const dataForDate = data_platform_sales_total_by_day[date];
				const formattedData: Record<string, number | string> = { date };

				dataForDate.forEach((entry) => {
					formattedData[entry.platform] = Number(entry.total_value);
				});

				return formattedData;
			})
		: Array.from({ length: 7 }, (_, i) => {
				const date = dayjs()
					.subtract(6 - i, "day")
					.format("YYYY-MM-DD");
				return {
					date,
					braip: 0,
					monetizze: 0,
					theMart: 0,
				};
			});

	return (
		<Card className="flex flex-col gap-10 border-none bg-transparent">
			<CardHeader>
				<div className="flex items-center justify-between">
					<h2 className="font-inter text-P3 font-medium leading-140 text-neutral-1100">
						Por período
					</h2>

					<ul className="flex gap-6">
						{activeIntegrations.map((integration) => (
							<li
								key={integration.platform.name}
								className="flex items-center justify-center gap-2"
							>
								<span
									className={`h-2.5 w-2.5 rounded-full ${
										integration.platform.name === "Braip"
											? "bg-pink-800"
											: integration.platform.name === "Monetizze"
												? "bg-blue-800"
												: "bg-orange-800"
									}`}
								/>
								<p className="font-inter text-P6 font-medium leading-160 text-neutral-500">
									{integration.platform.name}
								</p>
							</li>
						))}
					</ul>
				</div>
			</CardHeader>

			<CardContent>
				<ChartContainer config={chartConfig} className="h-72 w-full">
					<AreaChart data={chartData} margin={{ left: -20, right: 12 }}>
						<CartesianGrid vertical={false} stroke="#151C1C" />
						<XAxis
							dataKey="date"
							tickLine={false}
							axisLine={false}
							tickMargin={8}
							tickFormatter={(value) => dayjs(value).format("DD/MM")}
						/>
						<YAxis
							tickLine={false}
							axisLine={false}
							tickMargin={8}
							tickCount={5}
						/>
						<ChartTooltip cursor={false} content={<ChartTooltipContent />} />
						<defs>
							<linearGradient id="fillBraip" x1="0" y1="0" x2="0" y2="1">
								<stop
									offset="0%"
									stopColor="var(--color-braip)"
									stopOpacity={0.5}
								/>
								<stop
									offset="100%"
									stopColor="var(--color-braip)"
									stopOpacity={0}
								/>
							</linearGradient>
							<linearGradient id="fillMonetizze" x1="0" y1="0" x2="0" y2="1">
								<stop
									offset="0%"
									stopColor="var(--color-monetizze)"
									stopOpacity={0.5}
								/>
								<stop
									offset="100%"
									stopColor="var(--color-monetizze)"
									stopOpacity={0}
								/>
							</linearGradient>
							<linearGradient id="fillTheMart" x1="0" y1="0" x2="0" y2="1">
								<stop
									offset="0%"
									stopColor="var(--color-theMart)"
									stopOpacity={0.5}
								/>
								<stop
									offset="100%"
									stopColor="var(--color-theMart)"
									stopOpacity={0}
								/>
							</linearGradient>
						</defs>
						<Area
							dataKey="braip"
							type="natural"
							fill="url(#fillBraip)"
							stroke="var(--color-braip)"
						/>
						<Area
							dataKey="monetizze"
							type="natural"
							fill="url(#fillMonetizze)"
							stroke="var(--color-monetizze)"
						/>
						<Area
							dataKey="theMart"
							type="natural"
							fill="url(#fillTheMart)"
							stroke="var(--color-theMart)"
						/>
					</AreaChart>
				</ChartContainer>
			</CardContent>
		</Card>
	);
}
