import { useState } from "react";

import PenIcon from "@/assets/pen.svg?react";
import TrashIcon from "@/assets/trash.svg?react";
import { authorFallback } from "@/utils/mask-author-fallback";

import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";

interface AdminMentorCardProps {
	name: string;
	avatar: string;
	onEdit: () => void;
	onRemove: () => void;
}

export function AdminMentorCard({
	name,
	avatar,
	onEdit,
	onRemove,
}: AdminMentorCardProps) {
	const [isPenHovered, setIsPenHovered] = useState(false);
	const [isTrashHovered, setIsTrashHovered] = useState(false);

	return (
		<div className="flex items-center justify-between rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-4">
			<div className="flex flex-1 items-center gap-4">
				<Avatar className="size-10">
					<AvatarImage src={avatar} />
					<AvatarFallback>{authorFallback(name)}</AvatarFallback>
				</Avatar>

				<p className="line-clamp-1 font-inter text-P5 font-medium leading-160 text-neutral-1100">
					{name}
				</p>
			</div>

			<div className="flex items-center gap-1">
				<button
					className={`flex items-center justify-center rounded-[6px] p-3
						${isPenHovered ? "bg-neutral-200 text-neutral-1100" : "text-neutral-600"}
						`}
					onClick={onEdit}
					onMouseEnter={() => setIsPenHovered(true)}
					onMouseLeave={() => setIsPenHovered(false)}
				>
					<PenIcon />
				</button>

				<button
					className={`flex items-center justify-center rounded-[6px] p-3
						${isTrashHovered ? "bg-neutral-200 text-neutral-1100" : "text-neutral-600"}
						`}
					onClick={onRemove}
					onMouseEnter={() => setIsTrashHovered(true)}
					onMouseLeave={() => setIsTrashHovered(false)}
				>
					<TrashIcon />
				</button>
			</div>
		</div>
	);
}
