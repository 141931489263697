import { OTPInput, SlotProps } from "input-otp";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import { cn } from "@/lib/utils";

const InputOTP = forwardRef<
	ElementRef<typeof OTPInput>,
	ComponentPropsWithoutRef<typeof OTPInput>
>(({ className, ...props }, ref) => (
	<OTPInput
		ref={ref}
		containerClassName={cn(
			"flex md:flex-auto items-center gap-2 md:gap-4",
			className,
		)}
		{...props}
	/>
));
InputOTP.displayName = "InputOTP";

const InputOTPGroup = forwardRef<
	ElementRef<"div">,
	ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn("flex items-center gap-2 md:gap-4", className)}
		{...props}
	/>
));
InputOTPGroup.displayName = "InputOTPGroup";

const InputOTPSlot = forwardRef<
	ElementRef<"div">,
	SlotProps &
		ComponentPropsWithoutRef<"div"> & {
			hasError?: boolean;
		}
>(({ char, hasFakeCaret, isActive, className, hasError, ...props }, ref) => {
	return (
		<div
			ref={ref}
			className={cn(
				`relative flex h-10 w-8 items-center justify-center rounded-[8px] border border-neutral-200 font-inter text-P3 font-normal text-white transition-all md:h-14 md:w-12 ${
					isActive &&
					`z-10 border-none ring-2 ring-primary-600 ring-offset-background`
				} ${char && `border-primary-600`} ${hasError && `border-red-600 ring-red-600`}`,
				className,
			)}
			{...props}
		>
			{char}
			{hasFakeCaret && (
				<div className="pointer-events-none absolute inset-0 flex items-center justify-center">
					<div className="h-4 w-px animate-caret-blink bg-foreground duration-1000" />
				</div>
			)}
		</div>
	);
});
InputOTPSlot.displayName = "InputOTPSlot";

const InputOTPSeparator = forwardRef<
	ElementRef<"div">,
	ComponentPropsWithoutRef<"div">
>(({ ...props }, ref) => (
	<div ref={ref} role="separator" {...props}>
		<span className="block h-1 w-2 bg-neutral-400"></span>
	</div>
));
InputOTPSeparator.displayName = "InputOTPSeparator";

export { InputOTP, InputOTPGroup, InputOTPSlot, InputOTPSeparator };
