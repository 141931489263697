import { AxiosError } from "axios";

import { api } from "@/lib/axios";

interface FetchMaterialParams {
	title: string;
	sortBy: "created_at" | "title";
	format: "image" | "document" | "text" | "link";
	perPage: number;
}

export type Material = {
	id: string;
	title: string;
	link: string;
	format: "image" | "document" | "text" | "link";
	created_at: string;
};

export async function fetchAdminMaterials(
	params: Partial<FetchMaterialParams>,
): Promise<
	| {
			status: "success";
			data: Material[];
			lastPage: number;
			page: number;
			perPage: number;
			total: number;
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get("/material", {
			params,
		});

		return {
			status: "success",
			data: data.data.data,
			lastPage: data.data.meta.lastPage,
			page: data.data.meta.page,
			perPage: data.data.meta.perPage,
			total: data.data.meta.total,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
