import { AxiosError } from "axios";

import { api } from "@/lib/axios";

type Notification = {
	id: string;
	read_at: string | null;
	created_at: string;
	user_id: string;
	data: {
		message: string;
		data: {
			id: string;
			name: string;
		};
	};
};

type LastLessons = {
	available: boolean;
	duration: number;
	id: string;
	module_id: string;
	name: string;
	position: number;
	thumbnail: string;
	time_watched: number;
	training_id: string;
	watched: boolean;
};

type Question = {
	id: string;
	question: string;
	answer: string;
};

type Address = {
	id: string;
	zipcode: string;
	street: string;
	number: string;
	district: string;
	complement: string | null;
	city: string;
	state: string;
};

type User = {
	id: string;
	name: string;
	nickname: string;
	email: string;
	phone: string;
	status: string;
	isAdmin: boolean;
	isSudo: boolean;
	created_at: string;
	image: string | null;
	address: Address;
	questionsUser: Question[];
	notifications: {
		data: Notification[];
		meta: {
			count_read: number;
			total: number;
		};
	};
	lastLessons: LastLessons[];
};

export async function getUser(): Promise<
	| {
			status: "success";
			data: User;
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get("/auth");

		return {
			status: "success",
			data: data.data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
