import { AxiosError } from "axios";

import { api } from "@/lib/axios";

interface checkCodeIntegrationPayload {
	id: string;
	code: string;
}

export async function checkCodeIntegration({
	id,
	code,
}: checkCodeIntegrationPayload): Promise<
	| {
			status: "success";
	  }
	| { status: "error"; errors: { [key: string]: string } }
	| { status: "warning"; errors: { [key: string]: string } }
> {
	try {
		await api.put(`/integrations-user/check-code/${id}`, {
			code,
		});

		return {
			status: "success",
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "warning",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
