import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { fetchAdminProducts, type Product } from "@/api/admin/products/list";
import {
	type Integration,
	listIntegrations,
} from "@/api/app/account/list-integrations";
import { type Dashboard, getDashboard } from "@/api/app/dashboard/list";
import OptionsIcon from "@/assets/options.svg?react";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { useNavigationStore } from "@/store/navigation-store";

import { DashboardPayments } from "./payments";
import { DashboardPeriod } from "./period";
import { DashboardSales } from "./sales";
import { DashboardSalesMovements } from "./sales-movements";
import { DashboardSalesSkeleton } from "./skeleton/sales";
import { DashboardState } from "./state";

export function Dashboard() {
	useEffect(() => {
		const paths = [
			{
				name: "Dashboard",
				path: "/dashboard",
			},
		];

		setPaths(paths);
	}, []);

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);
	const [isLoading, setIsLoading] = useState(false);
	const [dashboard, setDashboard] = useState<Dashboard | null>(null);
	const [integrations, setIntegrations] = useState<Integration[]>([]);
	const [filterIsOpen, setFilterIsOpen] = useState(false);
	const [products, setProducts] = useState<Product[]>([]);

	const filter = z.object({
		period: z.string().optional(),
		product: z.string().optional(),
	});

	type Filter = z.infer<typeof filter>;

	const form = useForm<Filter>();

	async function fetchDashboard() {
		setIsLoading(true);
		const response = await getDashboard({});

		if (response.status === "success") {
			setDashboard(response.data);
		}

		setIsLoading(false);
	}

	async function fetchIntegrations() {
		setIsLoading(true);
		const response = await listIntegrations();

		if (response.status === "success") {
			setIntegrations(response.data);
		}

		setIsLoading(false);
	}

	async function fetchProducts() {
		setIsLoading(true);
		const response = await fetchAdminProducts({
			perPage: 200,
		});

		if (response.status === "success") {
			setProducts(response.data);
		}

		setIsLoading(false);
	}

	async function handleFilterSubmit(data: Filter) {
		setIsLoading(true);

		const period = {
			today: [dayjs().format("DD/MM/YYYY"), dayjs().format("DD/MM/YYYY")],
			last_7_days: [
				dayjs().subtract(7, "day").format("DD/MM/YYYY"),
				dayjs().format("DD/MM/YYYY"),
			],
			last_30_days: [
				dayjs().subtract(30, "day").format("DD/MM/YYYY"),
				dayjs().format("DD/MM/YYYY"),
			],
			this_month: [
				dayjs().startOf("month").format("DD/MM/YYYY"),
				dayjs().format("DD/MM/YYYY"),
			],
			last_month: [
				dayjs().subtract(1, "month").startOf("month").format("DD/MM/YYYY"),
				dayjs().subtract(1, "month").endOf("month").format("DD/MM/YYYY"),
			],
		};

		const response = await getDashboard({
			period: period[data.period as keyof typeof period],
			product_id: data.product || undefined,
		});

		if (response.status === "success") {
			setDashboard(response.data);
		}

		setIsLoading(false);
		setFilterIsOpen(false);
	}

	useEffect(() => {
		fetchDashboard();
		fetchIntegrations();
		fetchProducts();
	}, []);

	const hasIntegrationActive = integrations.some(
		(integration) => integration.active,
	);

	const navigate = useNavigate();

	return (
		<>
			<Helmet title="Dashboard" />

			{isLoading && (
				<div className="flex flex-col gap-8">
					<div className="mb-4 flex items-center justify-between">
						<Skeleton className="h-6 w-1/4" />
						<div className="flex items-center gap-6">
							<Skeleton className="h-10 w-24" />
						</div>
					</div>

					<DashboardSalesSkeleton />

					<div className="rounded-[8px] border border-solid border-neutral-100">
						<Skeleton className="h-96 w-full" />
					</div>

					<section className="flex flex-col gap-8 md:flex-row">
						<Skeleton className="h-96 w-full rounded-[8px]" />
						<Skeleton className="h-96 w-full rounded-[8px]" />
					</section>

					<div className="rounded-[8px] border border-solid border-neutral-100">
						<Skeleton className="h-96 w-full" />
					</div>
				</div>
			)}

			{!isLoading && !hasIntegrationActive && (
				<div
					className="flex h-[calc(100vh-200px)] items-center justify-center bg-cover bg-center bg-no-repeat px-4 py-8 md:px-28 lg:px-56"
					style={{
						backgroundImage:
							"linear-gradient(180deg, rgba(11, 18, 18, 0.90) 83.72%, #0B1212 100%), url('/dashboard.png')",
					}}
				>
					<div className="flex w-full max-w-4xl flex-col items-center gap-10">
						<div className="flex flex-col items-center gap-4 text-center">
							<div className="flex h-16 w-16 items-center justify-center rounded-lg border border-[#013c20] bg-[#058c4b]/5 p-4">
								<div className="h-12 w-12 bg-transparent">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="48"
										height="48"
										viewBox="0 0 48 48"
										fill="none"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M22 31.8C17.4355 30.8734 14 26.8379 14 22V8H34V22C34 26.8379 30.5645 30.8734 26 31.8V36H30V40H18V36H22V31.8ZM30 12H18V22C18 25.3137 20.6863 28 24 28C27.3137 28 30 25.3137 30 22V12Z"
											fill="#058D4C"
										/>
										<path d="M12 12H8V22H12V12Z" fill="#058D4C" />
										<path d="M36 12H40V22H36V12Z" fill="#058D4C" />
									</svg>
								</div>
							</div>
							<h2 className="text-xl font-medium leading-tight text-white md:text-2xl">
								Realize integração com uma plataforma de afiliação e visualize o
								ranking de premiações!
							</h2>
						</div>
						<button
							className="rounded-lg bg-[#058c4b] px-6 py-3 text-base font-semibold text-white"
							onClick={() => navigate("/account/integrations")}
						>
							Realizar integração
						</button>
					</div>
				</div>
			)}

			{!isLoading && hasIntegrationActive && dashboard && (
				<div className="flex flex-col gap-8">
					<header className="mb-4 flex items-center justify-between">
						<h2 className="font-inter text-P3 font-medium leading-140 text-neutral-1100">
							Visão geral
						</h2>

						<div className="flex items-center gap-6">
							<button
								className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 px-6 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400"
								disabled={false}
								onClick={() => {
									setFilterIsOpen(!filterIsOpen);
								}}
							>
								<OptionsIcon />

								<p className="font-inter text-P6 font-semibold leading-160">
									Filtro
								</p>
							</button>
						</div>
					</header>

					<div>
						<div
							className={`flex max-h-0 flex-col gap-8 rounded-[10px] bg-neutral-100 bg-opacity-60 opacity-0 transition-all duration-500 ${filterIsOpen && "max-h-fit p-6 opacity-100"}`}
						>
							{filterIsOpen && (
								<Form {...form}>
									<div className="flex w-full flex-col items-center gap-6 md:flex-row">
										<FormField
											control={form.control}
											name="period"
											render={({ field }) => (
												<FormItem className="w-1/2">
													<Select
														onValueChange={(e) => field.onChange(e)}
														defaultValue={field.value}
														value={field.value}
													>
														<FormControl>
															<SelectTrigger className="w-full">
																<SelectValue placeholder="Selecione um período" />
															</SelectTrigger>
														</FormControl>
														<SelectContent>
															<SelectItem value="today">Hoje</SelectItem>
															<SelectItem value="last_7_days">
																Últimos 7 dias
															</SelectItem>
															<SelectItem value="last_30_days">
																Últimos 30 dias
															</SelectItem>
															<SelectItem value="this_month">
																Este mês
															</SelectItem>
															<SelectItem value="last_month">
																Mês passado
															</SelectItem>
														</SelectContent>
													</Select>
												</FormItem>
											)}
										/>

										<FormField
											control={form.control}
											name="product"
											render={({ field }) => (
												<FormItem className="w-1/2">
													<Select
														onValueChange={(e) => field.onChange(e)}
														defaultValue={field.value}
														value={field.value}
													>
														<FormControl>
															<SelectTrigger className="w-full">
																<SelectValue placeholder="Produto" />
															</SelectTrigger>
														</FormControl>
														<SelectContent>
															{products.map((product) => (
																<SelectItem value={product.id} key={product.id}>
																	{product.name}
																</SelectItem>
															))}
														</SelectContent>
													</Select>
												</FormItem>
											)}
										/>
									</div>

									<div className="flex flex-col justify-between gap-6 md:flex-row md:items-center">
										<div>
											<span className="font-inter text-P5 font-normal leading-160 text-neutral-400">
												Nenhum filtro aplicado
											</span>
										</div>

										<div className="flex gap-4">
											<Button
												className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] bg-transparent font-inter !text-P6 font-semibold leading-160 text-neutral-600 transition-all duration-500 hover:bg-neutral-200 disabled:bg-transparent disabled:text-neutral-400"
												variant="secondary"
												onClick={() => {
													form.setValue("period", undefined);
													form.setValue("product", undefined);
													setFilterIsOpen(false);
													handleFilterSubmit({});
												}}
											>
												Limpar filtro
											</Button>
											<Button
												className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] font-inter !text-P6 font-semibold leading-160"
												onClick={form.handleSubmit(async (data) => {
													handleFilterSubmit(data);
												})}
											>
												Aplicar
											</Button>
										</div>
									</div>
								</Form>
							)}
						</div>
					</div>

					<DashboardSales
						sales_total_value={dashboard.sales_total_value}
						sales_total_commission_value={
							dashboard.sales_total_commission_value
						}
						data_platform_sales_total={dashboard.data_platform_sales_total}
					/>

					<section className="rounded-[8px] border border-solid border-neutral-100">
						<DashboardPeriod
							data_platform_sales_total_by_day={
								dashboard.data_platform_sales_total_by_day
							}
							integrations={integrations}
						/>
					</section>

					<section className="flex flex-col gap-8 md:flex-row">
						<div className="h-full max-h-[364px] w-full rounded-[8px] border border-solid border-neutral-100">
							<DashboardPayments
								count_sales_by_payment_method={
									dashboard.count_sales_by_payment_method
								}
							/>
						</div>

						<div className="w-full rounded-[8px] border border-solid border-neutral-100">
							<DashboardState
								count_sales_by_state={dashboard.count_sales_by_state}
							/>
						</div>
					</section>

					<section className="rounded-[8px] border border-solid border-neutral-100">
						<DashboardSalesMovements />
					</section>
				</div>
			)}
		</>
	);
}
