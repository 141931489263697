import axios from "axios";

import { env } from "@/env";
import { useAuthStore } from "@/store/auth";

const api = axios.create({
	baseURL: env.VITE_API_URL,
	withCredentials: true,
});

api.interceptors.request.use((config) => {
	const token = useAuthStore.getState().token;

	if (token) {
		config.headers["Authorization"] = `Bearer ${token}`;
	} else {
		delete config.headers["Authorization"];
	}

	return config;
});

api.interceptors.response.use(
	(response) => {
		return response;
	},

	(error) => {
		if (error.response.status === 401) {
			useAuthStore.getState().logout();

			window.location.href = "/sign-in";
		}

		return Promise.reject(error);
	},
);

export { api };
