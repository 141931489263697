import { ChevronLeft, ChevronRight, MoreHorizontal } from "lucide-react";
import { ComponentProps, forwardRef } from "react";

import { ButtonProps } from "@/components/ui/button";
import { cn } from "@/lib/utils";

const Pagination = ({ className, ...props }: ComponentProps<"nav">) => (
	<nav
		role="navigation"
		aria-label="pagination"
		className={cn("flex w-full md:w-auto", className)}
		{...props}
	/>
);
Pagination.displayName = "Pagination";

const PaginationContent = forwardRef<HTMLUListElement, ComponentProps<"ul">>(
	({ className, ...props }, ref) => (
		<ul
			ref={ref}
			className={cn(
				"flex w-full flex-1 flex-row items-center justify-between gap-2",
				className,
			)}
			{...props}
		/>
	),
);
PaginationContent.displayName = "PaginationContent";

const PaginationItem = forwardRef<HTMLLIElement, ComponentProps<"li">>(
	({ className, ...props }, ref) => (
		<li ref={ref} className={cn("", className)} {...props} />
	),
);
PaginationItem.displayName = "PaginationItem";

type PaginationLinkProps = {
	isActive?: boolean;
} & Pick<ButtonProps, "size"> &
	ComponentProps<"button">;

const PaginationLink = ({
	className,
	isActive,
	size = "icon",
	...props
}: PaginationLinkProps) => (
	<button
		aria-current={isActive ? "page" : undefined}
		className={cn(
			"!flex size-10 !items-center justify-center rounded-[10px] bg-transparent p-[11px] font-inter text-P4 font-medium leading-160 text-neutral-1100 transition-all duration-500",
			isActive && "bg-primary-600",
			className,
		)}
		{...props}
	/>
);
PaginationLink.displayName = "PaginationLink";

const PaginationPrevious = ({
	className,
	...props
}: ComponentProps<typeof PaginationLink>) => (
	<PaginationLink
		aria-label="Go to previous page"
		size="default"
		className={cn(
			"mr-2 flex items-center justify-center rounded-[10px] bg-neutral-200 p-[11px] text-neutral-1100 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400",
			className,
		)}
		{...props}
	>
		<ChevronLeft className="size-[18px]" />
	</PaginationLink>
);
PaginationPrevious.displayName = "PaginationPrevious";

const PaginationNext = ({
	className,
	...props
}: ComponentProps<typeof PaginationLink>) => (
	<PaginationLink
		aria-label="Go to next page"
		size="default"
		className={cn(
			"ml-2 flex items-center justify-center rounded-[10px] bg-neutral-200 p-[11px] text-neutral-1100 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400",
			className,
		)}
		{...props}
	>
		<ChevronRight className="size-[18px]" />
	</PaginationLink>
);
PaginationNext.displayName = "PaginationNext";

const PaginationEllipsis = ({
	className,
	...props
}: ComponentProps<"span">) => (
	<span
		aria-hidden
		className={cn(
			"flex h-9 w-9 items-center justify-center text-neutral-1100",
			className,
		)}
		{...props}
	>
		<MoreHorizontal className="h-4 w-4" />
		<span className="sr-only">...</span>
	</span>
);
PaginationEllipsis.displayName = "PaginationEllipsis";

export {
	Pagination,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
};
