import { zodResolver } from "@hookform/resolvers/zod";
import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { checkCodeIntegration } from "@/api/app/account/check-code-integration";
import { createIntegration } from "@/api/app/account/create-integration";
import {
	type Integration,
	listIntegrations,
} from "@/api/app/account/list-integrations";
import { resendCodeIntegration } from "@/api/app/account/resend-code-integration";
import { updateIntegration } from "@/api/app/account/update-integration";
import { verifyPasswordIntegration } from "@/api/app/account/verify-password-integration";
import BraipLogo from "@/assets/braip.svg?react";
import CheckIcon from "@/assets/check-3.svg?react";
import DoneIcon from "@/assets/done.svg?react";
import IntegrationIcon from "@/assets/integration.svg?react";
import MonetizzeLogo from "@/assets/monetizze.svg?react";
import PenIcon from "@/assets/pen.svg?react";
import SuportefyLogo from "@/assets/suportefy.svg?react";
import TheMartLogo from "@/assets/themart.svg?react";
import { DeleteModal } from "@/components/delete-modal";
import { Modal } from "@/components/modal";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form } from "@/components/ui/form";
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot,
} from "@/components/ui/input-otp";
import { Switch } from "@/components/ui/switch";
import { useNavigationStore } from "@/store/navigation-store";
import { maskHideEmail } from "@/utils/mask-hide-email";

export function AccountIntegrations() {
	const [showModal, setShowModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [currentEmail, setCurrentEmail] = useState<string | null>(null);
	const [currentIntegrationId, setCurrentIntegrationId] = useState<
		string | null
	>(null);
	const [showModalRemoveIntegration, setShowModalRemoveIntegration] =
		useState(false);
	const [isEditingEmail, setIsEditingEmail] = useState(false);
	const [modalCurrentStep, setModalCurrentStep] = useState<1 | 2 | 3 | 4 | 5>(
		1,
	);
	const [currentPlatformId, setCurrentPlatformId] = useState<string | null>(
		null,
	);
	const [platform, setPlatform] = useState<
		"Braip" | "Monetizze" | "The Mart" | null
	>(null);

	function resetStates() {
		setShowModal(false);
		setCurrentEmail(null);
		setCurrentIntegrationId(null);
		setShowModalRemoveIntegration(false);
		setIsEditingEmail(false);
		setModalCurrentStep(1);
		setCurrentPlatformId(null);
		setPlatform(null);

		formEmail.reset();
		formOTP.reset();
		formVerifyPassword.reset();
	}

	const modalSteps = {
		1: {
			title: `Conectar Suportefy com a ${platform}`,
			subtitle:
				"É simples e rápido. Antes de começar, siga as instruções abaixo:",
			confirmText: "Iniciar integração",
			closeModal: () => {
				resetStates();
			},
			cancelAction: () => {
				resetStates();
			},
			confirmAction: () => {
				setModalCurrentStep(2);
			},
			showHeader: false,
			className: "md:h-[700px]",
		},
		2: {
			title: `Conectar Suportefy com a ${platform}`,
			subtitle:
				"Digite seu e-mail abaixo e enviaremos um código para validação.",
			confirmText: "Enviar",
			closeModal: () => {
				resetStates();
			},
			cancelAction: () => {
				resetStates();
			},
			confirmAction: async () => {
				formEmail.handleSubmit(async (data) => {
					setIsLoading(true);

					setCurrentEmail(data.email);

					if (!isEditingEmail) {
						const response = await createIntegration({
							email: data.email,
							platform_id: currentPlatformId as string,
						});

						if (response.status === "success") {
							setCurrentIntegrationId(response.data);
						}

						if (response.status === "error") {
							const { errors } = response;

							for (const key in errors) {
								formEmail.setError(key as keyof IntegrationForm, {
									type: "manual",
									message: errors[key],
								});
							}

							setIsLoading(false);
							return;
						}

						setModalCurrentStep(3);
						setIsLoading(false);

						return;
					}

					if (isEditingEmail) {
						setIsLoading(true);
						await handleReactiveIntegration(
							currentIntegrationId as string,
							data.email,
							false,
						).then(async () => {
							await resendCode(currentIntegrationId as string)
								.then(() => {
									setModalCurrentStep(3);
								})
								.catch(() => {
									console.log("Erro ao reenviar código");

									setIsLoading(false);
									setModalCurrentStep(5);
								});

							setIsLoading(false);
						});
						return;
					}
				})();
			},
			showHeader: false,
			className: "md:h-auto",
		},
		3: {
			title: `Conectar Suportefy com a ${platform}`,
			subtitle: `O código foi enviado ${maskHideEmail(currentEmail as string)}. Insira o mesmo abaixo:`,
			confirmText: "Validar",
			closeModal: () => {
				resetStates();
			},
			cancelAction: () => {
				resetStates();
			},
			confirmAction: () => {
				formOTP.handleSubmit(async (data) => {
					setIsLoading(true);

					const response = await checkCodeIntegration({
						code: data.code,
						id: currentIntegrationId as string,
					});

					if (response.status === "success") {
						setModalCurrentStep(4);
					}

					if (response.status === "error") {
						const { errors } = response;

						for (const key in errors) {
							formOTP.setError(key as keyof IntegrationFormOTP, {
								type: "manual",
								message: errors[key],
							});
						}

						setIsLoading(false);
						return;
					}

					setIsLoading(false);
				})();
			},
			showHeader: false,
			className: "md:h-auto",
		},
		4: {
			title: "Integração realizada com sucesso!",
			subtitle: "Agora aproveite todos os benefícios de sua integração.",
			confirmText: "Concluir",
			closeModal: () => {
				resetStates();
			},
			cancelAction: () => {
				resetStates();
			},
			confirmAction: () => {
				resetStates();

				fetchIntegrations();
			},
			showHeader: false,
			hasCancel: false,
			className: "md:h-auto",
		},
		5: {
			title: "Alterar e-mail de integração",
			subtitle: "Para alterar seu e-mail atual, digite sua senha abaixo:",
			confirmText: "Continuar",
			closeModal: () => {
				resetStates();
			},
			cancelAction: () => {
				resetStates();
			},
			confirmAction: () => {
				formVerifyPassword.handleSubmit(async (data) => {
					setIsLoading(true);

					const response = await verifyPasswordIntegration(
						currentIntegrationId as string,
						{
							password: data.password,
						},
					);

					if (response.status === "success") {
						setIsLoading(false);
						setIsEditingEmail(true);
						setModalCurrentStep(2);
					}

					if (response.status === "error") {
						setIsLoading(false);
						setCurrentIntegrationId(null);
						setCurrentEmail(null);
					}
				})();
			},
			showHeader: false,
			className: "md:h-auto",
		},
	};

	const integrationFormEmail = z.object({
		email: z
			.string({
				required_error: "E-mail é obrigatório",
			})
			.email({
				message: "E-mail inválido",
			}),
	});

	type IntegrationForm = z.infer<typeof integrationFormEmail>;

	const formEmail = useForm<IntegrationForm>({
		resolver: zodResolver(integrationFormEmail),
	});

	const integrationFormOTP = z.object({
		code: z
			.string({
				required_error: "Código é obrigatório",
			})
			.length(6),
	});

	type IntegrationFormOTP = z.infer<typeof integrationFormOTP>;

	const formOTP = useForm<IntegrationFormOTP>({
		resolver: zodResolver(integrationFormOTP),
	});

	const integrationFormVerifyPassword = z.object({
		password: z
			.string({
				required_error: "Senha é obrigatória",
			})
			.min(6, {
				message: "Senha deve ter no mínimo 6 caracteres",
			}),
	});

	type IntegrationFormVerifyPassword = z.infer<
		typeof integrationFormVerifyPassword
	>;

	const formVerifyPassword = useForm<IntegrationFormVerifyPassword>({
		resolver: zodResolver(integrationFormVerifyPassword),
	});

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Home",
				path: "/",
			},
			{
				name: `Minha conta`,
				path: `/account`,
			},
			{
				name: `Integrações`,
				path: `/account/integrations`,
			},
		];

		setPaths(paths);
	}, []);

	const [integrations, setIntegrations] = useState<Integration[]>([]);

	async function fetchIntegrations() {
		const response = await listIntegrations();

		if (response.status === "success") {
			setIntegrations(response.data);
		}
	}

	async function resendCode(id: string) {
		setIsLoading(true);

		await resendCodeIntegration({
			id,
		});

		setIsLoading(false);
	}

	async function handleRemoveIntegration() {
		setIsLoading(true);

		const response = await updateIntegration(currentIntegrationId as string, {
			email: currentEmail as string,
			active: false,
		});

		if (response.status === "success") {
			setIsLoading(false);
			setShowModalRemoveIntegration(false);
			setCurrentIntegrationId(null);
			setCurrentEmail(null);

			fetchIntegrations();
		}

		if (response.status === "error") {
			setIsLoading(false);
			setShowModalRemoveIntegration(false);
			setCurrentIntegrationId(null);
			setCurrentEmail(null);
		}
	}

	async function handleReactiveIntegration(
		id: string,
		email: string,
		active: boolean = true,
	) {
		setIsLoading(true);

		const response = await updateIntegration(id, {
			email: email,
			active: active,
		});

		if (response.status === "success") {
			setIsLoading(false);

			fetchIntegrations();
		}

		if (response.status === "error") {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		fetchIntegrations();
	}, []);

	return (
		<>
			<Helmet title="Integrações" />

			<section className="flex flex-col gap-10">
				<div className="flex flex-col gap-2">
					<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
						Integrações
					</p>

					<span className="font-inter text-P4 font-normal leading-160 text-neutral-600 md:text-P5">
						Realize a integração com suas plataformas de forma rápida e simples
						e acompanhe tudo em um só lugar.
					</span>
				</div>

				<div className="flex flex-col gap-4">
					{integrations.map((integration) => (
						<div
							key={integration.platform.id}
							className="flex h-20 items-center justify-between rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-4"
						>
							<div className="flex items-center gap-4">
								<span className="flex size-12 items-center justify-center rounded-[8px] border border-solid border-primary-1000 bg-primary-600 bg-opacity-[0.04] p-2">
									{integration.platform.name === "Braip" && <BraipLogo />}
									{integration.platform.name === "Monetizze" && (
										<MonetizzeLogo />
									)}
									{integration.platform.name === "The Mart" && <TheMartLogo />}
								</span>

								<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
									{integration.platform.name}
								</p>
							</div>

							<div className="flex items-center gap-4">
								<div className="flex items-center gap-4">
									<p className="hidden font-inter text-P6 font-medium leading-160 text-neutral-1100 md:block">
										{integration.active ? "Ativado" : "Desativado"}
									</p>

									<Switch
										checked={
											integration.active === false &&
											integration.email_verified_at === null &&
											integration.email !== null
												? false
												: integration.active
										}
										onCheckedChange={async () => {
											if (integration.active) {
												setShowModalRemoveIntegration(true);
												setCurrentIntegrationId(integration.id as string);
												setCurrentEmail(integration.email);

												return;
											}

											if (
												Boolean(integration.active) === false &&
												integration.email_verified_at !== null &&
												integration.email !== null
											) {
												await handleReactiveIntegration(
													integration.id as string,
													integration.email,
												);
												return;
											}

											if (
												integration.active === false &&
												integration.email_verified_at === null &&
												integration.email !== null
											) {
												setModalCurrentStep(3);
												setShowModal(true);
												setCurrentPlatformId(integration.platform.id);
												setPlatform(
													integration.platform.name as
														| "Braip"
														| "Monetizze"
														| "The Mart",
												);
												setCurrentEmail(integration.email);

												setCurrentIntegrationId(integration.id as string);
												await resendCode(integration.id as string);

												return;
											}

											setPlatform(
												integration.platform.name as
													| "Braip"
													| "Monetizze"
													| "The Mart",
											);
											setModalCurrentStep(1);
											setShowModal(true);
											setCurrentPlatformId(integration.platform.id);
										}}
									/>
								</div>

								<span className="h-6 w-px bg-neutral-200" />

								<button
									className={`flex h-10 w-10 items-center justify-center gap-2 rounded-[6px] text-neutral-400 transition-all duration-300 hover:bg-neutral-400 hover:bg-opacity-40 hover:text-neutral-1100 disabled:cursor-not-allowed disabled:text-neutral-400
									${isLoading || integration.active === false ? "opacity-50 hover:bg-opacity-0" : ""}`}
									disabled={isLoading || integration.active === false}
									onClick={() => {
										setModalCurrentStep(5);
										setShowModal(true);
										setCurrentPlatformId(integration.platform.id);
										setPlatform(
											integration.platform.name as
												| "Braip"
												| "Monetizze"
												| "The Mart",
										);
										setCurrentEmail(integration.email);
										setCurrentIntegrationId(integration.id as string);
									}}
								>
									<PenIcon />
								</button>
							</div>
						</div>
					))}
				</div>
			</section>

			{showModal && (
				<Modal {...modalSteps[modalCurrentStep]} isLoading={isLoading}>
					{modalCurrentStep !== 4 && (
						<header
							className="mb-10 flex items-center justify-center rounded-[8px] px-[162px] py-10"
							style={{
								background:
									"linear-gradient(0deg, rgba(255, 255, 255, 0.03) 4.9%, rgba(255, 255, 255, 0.10) 94.06%)",
							}}
						>
							<div className="flex items-center justify-center gap-8">
								<div className="flex h-20 w-20 items-center justify-center rounded-xl border border-solid border-[#ffffff19] bg-neutral-1100 bg-opacity-[0.05] p-6">
									<SuportefyLogo />
								</div>

								<IntegrationIcon />

								<div className="flex h-20 w-20 items-center justify-center rounded-xl border border-solid border-[#ffffff19] bg-neutral-1100 bg-opacity-[0.05] p-3">
									{platform === "Braip" && <BraipLogo />}
									{platform === "Monetizze" && <MonetizzeLogo />}
									{platform === "The Mart" && <TheMartLogo />}
								</div>
							</div>
						</header>
					)}

					{modalCurrentStep === 4 && (
						<header
							className="mb-10 flex items-center justify-center rounded-[8px] px-[162px] py-10"
							style={{
								background:
									"linear-gradient(0deg, rgba(5, 141, 76, 0.20) 4.9%, #058D4C 94.06%)",
							}}
						>
							<div className="flex items-center justify-center gap-8">
								<div className="flex h-20 w-20 items-center justify-center rounded-xl border border-solid border-primary-900 bg-primary-1000 p-6 text-primary-600">
									<DoneIcon width={40} height={40} />
								</div>
							</div>
						</header>
					)}

					<main className="flex flex-col gap-8">
						<div className="flex flex-col gap-2">
							<p className="font-inter text-P1 font-medium leading-140 text-neutral-1100">
								{modalSteps[modalCurrentStep].title}
							</p>

							<span className="font-inter text-P4 font-normal leading-160 text-neutral-600">
								{modalSteps[modalCurrentStep].subtitle}
							</span>
						</div>

						{modalCurrentStep === 1 && (
							<div className="flex flex-col gap-8">
								<hr className="h-px border-t-neutral-200" />

								<div className="flex flex-col gap-4">
									<div className="flex items-center gap-3 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-2">
										<CheckIcon width={20} height={20} />

										<p className="font-inter text-P5 font-normal leading-160 text-neutral-800">
											Utilize o mesmo e-mail com o qual realizou o cadastro na
											plataforma.
										</p>
									</div>

									<div className="flex items-center gap-3 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-2">
										<CheckIcon width={20} height={20} />

										<p className="font-inter text-P5 font-normal leading-160 text-neutral-800">
											Será possível integrar apenas uma conta por plataforma.
										</p>
									</div>

									<div className="flex items-center gap-3 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-2">
										<CheckIcon width={20} height={20} />

										<p className="font-inter text-P5 font-normal leading-160 text-neutral-800">
											Tenha acesso ao e-mail com o qual você realizará a
											integração.
										</p>
									</div>

									<div className="flex items-center gap-3 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-2">
										<CheckIcon width={20} height={20} />

										<p className="font-inter text-P5 font-normal leading-160 text-neutral-800">
											Não é permitido utilizar o mesmo e-mail de outras contas.
										</p>
									</div>
								</div>
							</div>
						)}

						{modalCurrentStep === 2 && (
							<Form {...formEmail}>
								<div className="flex flex-col gap-8">
									<div className="flex w-full flex-col items-center gap-6">
										<div className="flex w-full flex-col gap-2">
											<FloatingLabelInput
												id="email"
												type="text"
												label="E-mail"
												hasError={!!formEmail.formState.errors.email}
												{...formEmail.register("email")}
											/>

											{formEmail.formState.errors.email && (
												<p className="font-inter text-P6 font-normal leading-160 text-red-600">
													{formEmail.formState.errors.email.message}
												</p>
											)}
										</div>
									</div>
								</div>
							</Form>
						)}

						{modalCurrentStep === 3 && (
							<Form {...formOTP}>
								<InputOTP
									maxLength={6}
									pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
									inputMode="text"
									value={formOTP.getValues("code")}
									onChange={(value) => formOTP.setValue("code", value)}
									className="flex w-full items-center justify-center overflow-hidden md:max-w-[536px]"
									render={({ slots }) => (
										<div className="flex w-full items-center justify-between gap-4 overflow-hidden md:w-auto">
											<InputOTPGroup className="flex w-full gap-3">
												{slots.slice(0, 3).map((slot, index) => (
													<InputOTPSlot
														className="!md:w-[72px] !h-14 !w-[40px]"
														key={index}
														hasError={!!formOTP.formState.errors.code}
														{...slot}
													/>
												))}
											</InputOTPGroup>
											<InputOTPSeparator />
											<InputOTPGroup className="flex w-full gap-3">
												{slots.slice(3).map((slot, index) => (
													<InputOTPSlot
														className="!md:w-[72px] !h-14 !w-[40px]"
														key={index}
														hasError={!!formOTP.formState.errors.code}
														{...slot}
													/>
												))}
											</InputOTPGroup>
										</div>
									)}
								/>
							</Form>
						)}

						{modalCurrentStep === 4 && (
							<div className="flex flex-col gap-8">
								<hr className="h-px border-t-neutral-200" />

								<div className="flex flex-col gap-4">
									<div className="flex items-center gap-3 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-2">
										<CheckIcon width={20} height={20} />

										<p className="font-inter text-P5 font-normal leading-160 text-neutral-800">
											Visualize todas vendas e comissões da plataforma.
										</p>
									</div>

									<div className="flex items-center gap-3 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-2">
										<CheckIcon width={20} height={20} />

										<p className="font-inter text-P5 font-normal leading-160 text-neutral-800">
											Análise seus resultados das plataformas em um só lugar.
										</p>
									</div>

									<div className="flex items-center gap-3 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-2">
										<CheckIcon width={20} height={20} />

										<p className="font-inter text-P5 font-normal leading-160 text-neutral-800">
											Acesse todas movimentações das suas vendas.
										</p>
									</div>
								</div>
							</div>
						)}

						{modalCurrentStep === 5 && (
							<Form {...formVerifyPassword}>
								<div className="flex flex-col gap-6">
									<div className="flex flex-col gap-2">
										<FloatingLabelInput
											id="password"
											type="password"
											label="Senha"
											hasError={!!formVerifyPassword.formState.errors.password}
											{...formVerifyPassword.register("password")}
										/>

										{formVerifyPassword.formState.errors.password && (
											<p className="font-inter text-P6 font-normal leading-160 text-red-600">
												{formVerifyPassword.formState.errors.password.message}
											</p>
										)}
									</div>
								</div>
							</Form>
						)}
					</main>
				</Modal>
			)}

			{showModalRemoveIntegration && (
				<DeleteModal
					title="Deseja realmente desfazer a integração?"
					description="Essa ação não poderá ser desfeita."
					icon="warning.svg"
					confirmText="Desfazer integração"
					cancelText="Manter integração"
					isLoading={isLoading}
					cancelAction={() => {
						setShowModalRemoveIntegration(false);
						setCurrentIntegrationId(null);
						setCurrentEmail(null);
					}}
					confirmAction={handleRemoveIntegration}
					closeModal={() => {
						setShowModalRemoveIntegration(false);
						setCurrentIntegrationId(null);
						setCurrentEmail(null);
					}}
				/>
			)}
		</>
	);
}
