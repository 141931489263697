import { Skeleton } from "@/components/ui/skeleton";

export function SkeletonFreeMaterialsLink() {
	return (
		<div className="relative flex h-full items-end rounded-xl p-10 transition-all duration-300 hover:opacity-90 md:min-w-[352px]">
			<Skeleton className="absolute inset-0 rounded-xl" />

			<div className="absolute right-0 top-0 flex h-full items-center justify-center md:h-auto">
				<Skeleton className="h-8 w-8 rounded-full" />
			</div>

			<div className="absolute bottom-0 left-0 right-0 flex flex-col gap-2 p-10">
				<Skeleton className="h-8 w-28 rounded" />
				<Skeleton className="h-8 w-28 rounded" />
			</div>
		</div>
	);
}
