import {
	ComponentPropsWithoutRef,
	ElementRef,
	InputHTMLAttributes,
	PropsWithoutRef,
	forwardRef,
} from "react";

import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	hasError?: boolean;
	bgColor?: string;
}

const FloatingInput = forwardRef<HTMLInputElement, InputProps>(
	({ className, hasError, bgColor, ...props }, ref) => {
		return (
			<Input
				placeholder=" "
				className={cn("peer", className, bgColor)}
				showPasswordIcon={props.type === "password"}
				hasError={hasError}
				ref={ref}
				{...props}
			/>
		);
	},
);
FloatingInput.displayName = "FloatingInput";

interface FloatingLabelProps extends ComponentPropsWithoutRef<typeof Label> {
	hasError?: boolean;
	bgColor?: string;
}

const FloatingLabel = forwardRef<ElementRef<typeof Label>, FloatingLabelProps>(
	({ className, hasError, bgColor, ...props }, ref) => {
		return (
			<Label
				className={cn(
					`peer-place absolute start-2 top-2 z-[5] origin-[0] -translate-y-4 transform bg-neutral-100 px-2 !text-P5 font-normal text-neutral-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:rounded-lg  peer-focus:px-2 peer-focus:text-P6 peer-focus:font-semibold peer-focus:text-white rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4 ${
						hasError && "!text-neutral-1100"
					}`,
					className,
					bgColor,
				)}
				ref={ref}
				{...props}
			/>
		);
	},
);
FloatingLabel.displayName = "FloatingLabel";

type FloatingLabelInputProps = InputProps & { label?: string };

const FloatingLabelInput = forwardRef<
	ElementRef<typeof FloatingInput>,
	PropsWithoutRef<FloatingLabelInputProps>
>(({ id, label, hasError, bgColor, ...props }, ref) => {
	return (
		<div className="relative text-neutral-200 focus-within:text-white">
			<FloatingInput
				ref={ref}
				id={id}
				hasError={hasError}
				bgColor={bgColor}
				{...props}
			/>
			<FloatingLabel htmlFor={id} hasError={hasError} bgColor={bgColor}>
				{label}
			</FloatingLabel>
		</div>
	);
});
FloatingLabelInput.displayName = "FloatingLabelInput";

export { FloatingInput, FloatingLabel, FloatingLabelInput };
