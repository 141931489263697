import { Link } from "react-router-dom";

import ChevronRightIcon from "@/assets/chevron-right.svg?react";

import { Tag } from "./tag";

export type Platforms = "Braip" | "Monetizze" | "The Mart";

export interface AdminProductCardProps {
	id: string;
	product: string;
	product_image: string;
	type: string;
	producer: string;
	producer_avatar: string;
	platform: Platforms[];
}

export function AdminProductCard({
	id,
	product,
	product_image,
	type,
	platform,
}: AdminProductCardProps) {
	const platformColors: {
		[key in Platforms]:
			| "minimalist-pink"
			| "minimalist-blue"
			| "minimalist-orange";
	} = {
		Braip: "minimalist-pink",
		Monetizze: "minimalist-blue",
		"The Mart": "minimalist-orange",
	};

	return (
		<Link
			to={`/admin/products/${id}`}
			className="flex cursor-pointer items-center gap-6 rounded-[8px] border border-solid border-neutral-100 p-4 text-neutral-600 transition-all hover:bg-neutral-100"
		>
			<img
				src={product_image || "https://via.placeholder.com/150"}
				className="h-[64px] w-[86px] rounded-[6px] object-cover"
			/>

			<div className="flex flex-1 items-center gap-6">
				<div className="flex flex-1 flex-col justify-center gap-2">
					<span className="line-clamp-1 text-ellipsis font-inter text-P6 font-normal leading-160 text-neutral-600">
						Produto
					</span>

					<p className="line-clamp-1 text-ellipsis text-left font-inter text-P5 font-medium leading-160 text-neutral-1100">
						{product}
					</p>
				</div>

				<div className="flex flex-1 flex-col justify-center gap-2">
					<span className="line-clamp-1 text-ellipsis font-inter text-P6 font-normal leading-160 text-neutral-600">
						Tipo
					</span>

					<p className="line-clamp-1 text-ellipsis text-left font-inter text-P5 font-medium leading-160 text-neutral-1100">
						{type}
					</p>
				</div>

				<div className="flex flex-1 flex-col justify-center gap-2">
					<span className="line-clamp-1 text-ellipsis font-inter text-P6 font-normal leading-160 text-neutral-600">
						Plataforma
					</span>

					<div className="flex items-center gap-2">
						{platform.map((platform) => (
							<Tag
								key={platform}
								size="small"
								colors={platformColors[platform]}
								className="rounded px-[8px] py-1 text-P7"
							>
								{platform}
							</Tag>
						))}
					</div>
				</div>
			</div>

			<ChevronRightIcon width={24} height={24} />
		</Link>
	);
}
