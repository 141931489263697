import { AxiosError } from "axios";

import { api } from "@/lib/axios";

type Mentor = {
	id: string;
	name: string;
	image: string;
};

export type Material = {
	id: number;
	material_id: string;
	title: string;
	format: string;
	link: string;
	created_at: string;
};

export type Lesson = {
	id: string;
	name: string;
	description: string;
	thumbnail: string;
	position: number;
	available: boolean;
	mentor: Mentor;
	link: string;
	module: string;
	module_id: string;
	module_position: number;
	duration: number;
	timeWatched: number;
	watched: boolean;
	materials: Material[];
	created_at: string;
};

export async function showAcademyTrainingLesson(id: string): Promise<
	| {
			status: "success";
			data: Lesson;
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get(`/lesson/show/${id}`);

		return {
			status: "success",
			data: data.data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
