interface DashboardStateProps {
	count_sales_by_state: {
		state: string;
		count: number;
	}[];
}

export function DashboardState({ count_sales_by_state }: DashboardStateProps) {
	// Mock com estados zerados caso a lista esteja vazia
	const data =
		count_sales_by_state.length > 0
			? count_sales_by_state
			: [
					{ state: "SP", count: 0 },
					{ state: "MG", count: 0 },
					{ state: "ES", count: 0 },
					{ state: "RJ", count: 0 },
					{ state: "PR", count: 0 },
				];

	return (
		<div className="flex flex-col gap-8 p-6">
			<h2 className="font-inter text-P3 font-medium leading-140 text-neutral-1100">
				Estados com mais vendas
			</h2>

			<div className="flex flex-col gap-6">
				<div className="flex items-center justify-between border-b border-solid border-neutral-200 pb-4">
					<p className="font-inter text-P6 font-normal leading-160 text-neutral-600">
						Estado
					</p>
					<p className="font-inter text-P6 font-normal leading-160 text-neutral-600">
						Vendas
					</p>
				</div>

				<ul className="flex flex-col gap-4">
					{data.map(({ state, count }) => (
						<li key={state} className="flex flex-col gap-3">
							<div className="flex items-center justify-between gap-6">
								<p className="font-inter text-P5 font-normal leading-160 text-neutral-800">
									{state}
								</p>
								<p className="font-inter text-P5 font-medium leading-160 text-neutral-1100">
									{count}
								</p>
							</div>

							<span className="relative h-[3px] w-full rounded-[100px] bg-neutral-200">
								<span
									className="absolute h-full rounded-[100px] bg-primary-600"
									style={{ width: `${count}%` }}
								/>
							</span>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}
