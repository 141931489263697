import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
	fetchAdminTrainings,
	type Training,
} from "@/api/admin/academy/trainings/list";
import { deleteAcademyMentor } from "@/api/admin/academy/trainings/mentor/delete-mentor";
import {
	fetchAdminMentors,
	type Mentor,
} from "@/api/admin/academy/trainings/mentor/list-mentors";
import CloseIcon from "@/assets/close.svg?react";
import MathPlus from "@/assets/math-plus.svg?react";
import OptionsIcon from "@/assets/options.svg?react";
import PenIcon from "@/assets/pen.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import { AdminTrainingCard } from "@/components/admin-training-card";
import { AlertModal } from "@/components/alert-modal";
import { DeleteModal } from "@/components/delete-modal";
import { SkeletonAdminTrainingCard } from "@/components/skeletons/admin-training-card";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { useNavigationStore } from "@/store/navigation-store";

import { AdminAcademyTrainingsCreate } from "./create";
import { AdminAcademyTrainingsEditMentors } from "./edit-mentors";

export function AdminAcademyTrainings() {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [mentorModalIsOpen, setMentorModalIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [filterIsOpen, setFilterIsOpen] = useState(false);
	const [trainings, setTrainings] = useState<Training[]>([]);
	const [mentors, setMentors] = useState<Mentor[]>([]);
	const [deleteModal, setDeleteModal] = useState(false);
	const [successDeleteModal, setSuccessDeleteModal] = useState(false);
	const [selectedMentor, setSelectedMentor] = useState<Mentor | null>(null);
	const [successModal, setSuccessModal] = useState(false);
	const [successTrainingModal, setSuccessTrainingModal] = useState(false);
	const [deleteMentorErrorModal, setDeleteMentorErrorModal] = useState(false);

	const filter = z.object({
		name: z.string().optional(),
		mentor: z.string().optional(),
	});

	type Filter = z.infer<typeof filter>;

	const form = useForm<Filter>();

	async function fetchMentors() {
		setIsLoading(true);
		const response = await fetchAdminMentors();

		if (response.status === "success") {
			setMentors(response.data);
		}

		setIsLoading(false);
	}

	async function fetchTrainings() {
		setIsLoading(true);
		const response = await fetchAdminTrainings({
			perPage: 100000,
		});

		if (response.status === "success") {
			setTrainings(response.data);
		}

		setIsLoading(false);
	}

	async function handleFilterSubmit(data: Filter) {
		setIsLoading(true);

		const response = await fetchAdminTrainings({
			perPage: 10000,
			name: data.name || undefined,
			mentor: data.mentor || undefined,
		});

		if (response.status === "success") {
			setTrainings(response.data);
		}

		setIsLoading(false);
		setFilterIsOpen(false);
	}

	async function handleDeleteMentor() {
		if (!selectedMentor) return;

		setIsLoading(true);

		const response = await deleteAcademyMentor(selectedMentor.id);

		if (response.status === "success") {
			setDeleteModal(false);
			setSuccessDeleteModal(true);
			setSelectedMentor(null);
			setIsLoading(false);

			const updatedMentors = await fetchAdminMentors();
			if (updatedMentors.status === "success") {
				setMentors(updatedMentors.data);
			}
		}

		if (response.status === "error") {
			setDeleteModal(false);
			setDeleteMentorErrorModal(true);
			setIsLoading(false);
		}
	}

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Configurações",
				path: "/admin",
			},
			{
				name: "Academy",
				path: "/admin/academy",
			},
			{
				name: "Treinamentos",
				path: "/admin/academy/",
			},
		];

		setPaths(paths);
	}, []);

	useEffect(() => {
		fetchTrainings();
		fetchMentors();
	}, []);

	return (
		<div className="flex h-full flex-col justify-between gap-12">
			<header className="flex items-center justify-between border-b border-solid border-neutral-100 pb-12">
				<h2 className="font-inter text-P2 font-medium leading-140 text-neutral-1100">
					Todos os treinamentos
				</h2>

				<div className="flex items-center justify-center gap-4">
					<button
						className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 px-6 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400"
						disabled={isLoading}
						onClick={() => setFilterIsOpen(!filterIsOpen)}
					>
						<OptionsIcon />

						<p className="font-inter text-P6 font-semibold leading-160">
							Filtro
						</p>
					</button>

					<button
						className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 px-6 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400"
						disabled={isLoading}
						onClick={() => setMentorModalIsOpen(!mentorModalIsOpen)}
					>
						<PenIcon />

						<p className="font-inter text-P6 font-semibold leading-160">
							Editar mentores
						</p>
					</button>

					<button
						className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-primary-600 px-6 text-neutral-1100 transition-all duration-300 hover:bg-primary-700"
						onClick={() => setModalIsOpen(true)}
					>
						<MathPlus />

						<p className="font-inter text-P6 font-semibold leading-160">
							Adicionar
						</p>
					</button>
				</div>
			</header>

			<div
				className={`flex max-h-0 flex-col gap-8 rounded-[10px] bg-neutral-100 bg-opacity-60 opacity-0 transition-all duration-500 ${filterIsOpen && "max-h-fit p-6 opacity-100"}`}
			>
				{filterIsOpen && (
					<Form {...form}>
						<div className="flex w-full flex-col items-center gap-6 md:flex-row">
							<div
								className={`relative w-full ${
									trainings.length > 0 ? "text-neutral-600" : "text-neutral-200"
								} focus-within:text-neutral-1100`}
							>
								<Input
									placeholder="Pesquise por um treinamento"
									className={`h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:!text-P5  ${
										trainings.length > 0
											? "placeholder:text-neutral-600"
											: "placeholder:text-neutral-200"
									}`}
									{...form.register("name")}
								/>
								<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

								{form.watch("name") !== undefined &&
									form.watch("name") !== "" && (
										<button
											className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-200 text-neutral-1100"
											onClick={() => form.setValue("name", undefined)}
										>
											<CloseIcon />
										</button>
									)}
							</div>

							<FormField
								control={form.control}
								name="mentor"
								render={({ field }) => (
									<FormItem className="w-full">
										<Select
											onValueChange={(e) => {
												field.onChange(e);
											}}
											defaultValue={field.value}
											value={field.value}
										>
											<FormControl>
												<SelectTrigger className="w-full">
													<SelectValue placeholder="Selecione um mentor" />
												</SelectTrigger>
											</FormControl>
											<SelectContent>
												{mentors.map((mentor) => (
													<SelectItem key={mentor.id} value={mentor.id}>
														{mentor.name}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</FormItem>
								)}
							/>
						</div>

						<div className="flex flex-col justify-between gap-6 md:flex-row md:items-center">
							<div>
								<span className="font-inter text-P5 font-normal leading-160 text-neutral-400">
									Nenhum filtro aplicado
								</span>
							</div>

							<div className="flex gap-4">
								<Button
									className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] bg-transparent font-inter !text-P6 font-semibold leading-160 text-neutral-600 transition-all duration-500 hover:bg-neutral-200 disabled:bg-transparent disabled:text-neutral-400"
									variant="secondary"
									onClick={() => {
										form.setValue("name", undefined);
										form.setValue("mentor", undefined);
										setFilterIsOpen(false);
									}}
								>
									Limpar filtro
								</Button>
								<Button
									className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] font-inter !text-P6 font-semibold leading-160"
									onClick={form.handleSubmit(async (data) => {
										handleFilterSubmit(data);
									})}
								>
									Aplicar
								</Button>
							</div>
						</div>
					</Form>
				)}
			</div>

			<div className={!filterIsOpen ? "-mt-12" : ""}>
				<div className="flex flex-col gap-4 pb-8">
					{isLoading &&
						Array.from({ length: 6 }).map((_, index) => (
							<SkeletonAdminTrainingCard key={index} />
						))}

					{!isLoading &&
						trainings.map((training) => (
							<AdminTrainingCard
								key={training.id}
								lessons={training.lessonsCount}
								mentor_avatar={training?.mentor?.image}
								modules={training.modulesCount}
								training_image={training.image}
								mentor={training?.mentor?.name}
								name={training.name}
								id={training.id}
							/>
						))}
				</div>

				{!isLoading && trainings.length === 0 && (
					<div className="flex h-full w-full flex-1 flex-col items-center justify-center gap-6 py-32">
						<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
							<SearchIcon />
						</span>

						<div className="flex flex-col items-center gap-2">
							<h3 className="text-center font-inter text-P2 font-medium leading-140 text-neutral-1100">
								No momento você não possui nenhum treinamento cadastrado
							</h3>

							<p className="text-center font-inter text-P3 font-normal leading-160 text-neutral-500">
								Adicione um material para visualizar ele aqui.
							</p>
						</div>
					</div>
				)}
			</div>

			{modalIsOpen && (
				<AdminAcademyTrainingsCreate
					mentors={mentors}
					closeModal={() => setModalIsOpen(false)}
					refreshMentors={fetchMentors}
					onSuccess={async () => {
						await fetchTrainings();
						setModalIsOpen(false);
						setSuccessTrainingModal(true);
					}}
				/>
			)}

			{successTrainingModal && (
				<AlertModal
					title="Treinamento adicionado com sucesso!"
					description="Agora você pode adicionar módulos e aulas ao seu treinamento."
					icon="done.svg"
					confirmText="Adicionar módulos e aulas"
					cancelText="Adicionar mais tarde"
					confirmAction={() => {
						setSuccessTrainingModal(false);

						const lastTraining = trainings[trainings.length - 1];

						if (lastTraining) {
							window.location.href = `/admin/academy/${lastTraining.id}`;
						}
					}}
					cancelAction={() => {
						setSuccessTrainingModal(false);
					}}
					closeModal={() => {
						setSuccessTrainingModal(false);
					}}
				/>
			)}

			{mentorModalIsOpen && (
				<AdminAcademyTrainingsEditMentors
					title="Editar mentores"
					mentors={mentors}
					setSelectedMentor={setSelectedMentor}
					closeModal={() => setMentorModalIsOpen(false)}
					onSuccess={async () => {
						await fetchMentors();

						setSuccessModal(true);
						setMentorModalIsOpen(false);
					}}
					selectedMentor={selectedMentor}
					onDelete={() => {
						setDeleteModal(true);
						setMentorModalIsOpen(false);
					}}
				/>
			)}

			{successModal && (
				<AlertModal
					title="Alterações salvas com sucesso!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessModal(false);
						setMentorModalIsOpen(true);
					}}
					closeModal={() => {
						setSuccessModal(false);
						setMentorModalIsOpen(true);
					}}
				/>
			)}

			{deleteModal && (
				<DeleteModal
					title="Deseja realmente excluir este mentor?"
					description="O mesmo será desvinculado de todos os treinamentos que está vinculado."
					icon="warning.svg"
					confirmText="Excluir mentor"
					isLoading={isLoading}
					cancelAction={() => {
						setDeleteModal(false);
						setMentorModalIsOpen(true);
					}}
					confirmAction={handleDeleteMentor}
					closeModal={() => {
						setDeleteModal(false);
						setMentorModalIsOpen(true);
					}}
				/>
			)}

			{successDeleteModal && (
				<AlertModal
					title="Mentor excluído!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessDeleteModal(false);
						setMentorModalIsOpen(true);
					}}
					closeModal={() => {
						setSuccessDeleteModal(false);
						setMentorModalIsOpen(true);
					}}
				/>
			)}

			{deleteMentorErrorModal && (
				<DeleteModal
					title="Erro ao excluir mentor!"
					description="O mentor não pode ser excluído pois está vinculado a alguma aula ou treinamento."
					icon="error.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setDeleteMentorErrorModal(false);
						setMentorModalIsOpen(true);
					}}
					closeModal={() => {
						setDeleteMentorErrorModal(false);
						setMentorModalIsOpen(true);
					}}
				/>
			)}
		</div>
	);
}
