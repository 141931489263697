export function authorFallback(author: string | undefined) {
	if (!author) return "";

	const initials = author
		.split(" ")
		.slice(0, 2)
		.map((name) => name[0])
		.join("");

	return initials;
}
