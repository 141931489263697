import SearchIcon from "@/assets/search.svg?react";
import SoftwareDownloadIcon from "@/assets/software-download.svg?react";
import { useLessonStore } from "@/store/lesson";

export function LessonMaterials() {
	const { lesson } = useLessonStore();

	if (!lesson) return null;

	const types = {
		image: "Arquivo de imagem",
		document: "Arquivo PDF",
		text: "Arquivo de texto",
		link: "Outro tipo de arquivo",
	};

	function handleDownloadMaterial(link: string) {
		window.open(link, "_blank");
	}

	return (
		<>
			<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
				Materias complementares
			</p>

			<div className="flex flex-col gap-4">
				{lesson?.materials?.map((material, index) => (
					<button
						className="flex flex-col gap-1 rounded-xl bg-neutral-100 bg-opacity-40 p-3 text-left hover:bg-opacity-100"
						key={index}
						onClick={() => handleDownloadMaterial(material.link)}
					>
						<p className="font-inter text-P7 font-normal leading-160 text-neutral-500">
							{types[material.format]}
						</p>

						<div className="flex w-full items-center justify-between gap-6">
							<p className="line-clamp-2 text-ellipsis font-inter text-P6 font-medium leading-160 text-neutral-1100">
								{material.title}
							</p>

							<div className="flex items-center justify-center rounded-[4px] bg-primary-600 p-2 text-neutral-1100">
								<SoftwareDownloadIcon width={16} height={16} />
							</div>
						</div>
					</button>
				))}

				{lesson?.materials?.length === 0 && (
					<div className="flex flex-col items-center gap-8 pb-14 pt-16">
						<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-4 text-primary-600">
							<SearchIcon width={32} height={32} />
						</span>

						<p className="text-center font-inter text-P4 font-medium leading-160 text-neutral-1100">
							Essa aula não possui nenhum material no momento.
						</p>
					</div>
				)}
			</div>
		</>
	);
}
