import { AxiosError } from "axios";

import { api } from "@/lib/axios";

interface getDashboardSalesListParams {
	externalKey?: string;
	page?: number;
}

export type Sale = {
	id: string;
	affiliateEmail: string;
	affiliateImage: string;
	affiliateName: string;
	commission_value: string;
	platform: "Braip" | "Monetizze" | "The Mart";
	value: string;
};

export async function getDashboardSalesList(
	params: Partial<getDashboardSalesListParams>,
): Promise<
	| {
			status: "success";
			data: Sale[];
			lastPage: number;
			page: number;
			perPage: number;
			total: number;
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get("/panel/sales-panel", {
			params,
		});

		return {
			status: "success",
			data: data.data.data,
			lastPage: data.data.meta.lastPage,
			page: data.data.meta.page,
			perPage: data.data.meta.perPage,
			total: data.data.meta.total,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
