import { Skeleton } from "@/components/ui/skeleton";

export function SkeletonAdminMaterialCard() {
	return (
		<div className="flex items-center justify-between rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-4">
			<div className="flex flex-1 space-x-4">
				<Skeleton className="h-12 w-12 rounded-[10px]" />

				<div className="flex w-full flex-col justify-center gap-2">
					<div className="flex flex-col gap-[2px]">
						<Skeleton className="h-4 w-24" />
						<Skeleton className="h-4 w-36" />
					</div>
				</div>
			</div>
		</div>
	);
}
