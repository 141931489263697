import { Skeleton } from "@/components/ui/skeleton";

export function SkeletonAdminProductCard() {
	return (
		<div className="flex w-full items-center gap-6 rounded-[8px] border border-solid border-neutral-100 p-4 text-neutral-600 transition-all">
			<Skeleton className="h-[64px] w-[86px] rounded-[6px]" />

			<div className="flex flex-1 items-center gap-6">
				<div className="flex flex-1 flex-col justify-center gap-2">
					<Skeleton className="h-5 w-16" />
					<Skeleton className="h-6 w-full" />
				</div>

				<div className="flex flex-1 flex-col justify-center gap-2">
					<Skeleton className="h-5 w-16" />
					<Skeleton className="h-6 w-full" />
				</div>

				<div className="flex flex-1 flex-col justify-center gap-2">
					<Skeleton className="h-5 w-16" />
					<div className="flex items-center gap-2">
						<Skeleton className="h-6 w-16" />
						<Skeleton className="h-6 w-16" />
					</div>
				</div>
			</div>
		</div>
	);
}
