import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { fetchAdminProducts, type Product } from "@/api/admin/products/list";
import {
	fetchAdminProductsPlatforms,
	Platform,
} from "@/api/admin/products/platforms";
import { fetchAdminProductsTypes, Type } from "@/api/admin/products/type";
import CloseIcon from "@/assets/close.svg?react";
import MathPlus from "@/assets/math-plus.svg?react";
import OptionsIcon from "@/assets/options.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import {
	AdminProductCard,
	type Platforms,
} from "@/components/admin-product-card";
import { AlertModal } from "@/components/alert-modal";
import { SkeletonAdminProductCard } from "@/components/skeletons/admin-product-card";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { useNavigationStore } from "@/store/navigation-store";

import { AdminProductsCreate } from "./create";

export function AdminProducts() {
	const [filterIsOpen, setFilterIsOpen] = useState(false);

	const filter = z.object({
		name: z.string().optional(),
		type: z.string().optional(),
		platform: z.enum(["Braip", "Monetizze", "The Mart"]).optional(),
	});

	type Filter = z.infer<typeof filter>;

	const form = useForm<Filter>();

	const [products, setProducts] = useState<Product[]>([]);
	const [types, setTypes] = useState<Type[]>([]);
	const [platforms, setPlatforms] = useState<Platform[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [successModal, setSuccessModal] = useState(false);
	const [productId, setProductId] = useState<string | null>(null);

	async function fetchProducts() {
		setIsLoading(true);
		const response = await fetchAdminProducts({
			perPage: 200,
		});

		if (response.status === "success") {
			setProducts(response.data);
		}

		setIsLoading(false);
	}

	async function fetchTypes() {
		const response = await fetchAdminProductsTypes();

		if (response.status === "success") {
			setTypes(response.data);
		}
	}

	async function fetchPlatforms() {
		const response = await fetchAdminProductsPlatforms();

		if (response.status === "success") {
			setPlatforms(response.data);
		}
	}

	async function handleFilterSubmit(data: Filter) {
		setIsLoading(true);
		const response = await fetchAdminProducts({
			perPage: 20,
			...data,
			name: data.name || undefined,
		});

		if (response.status === "success") {
			setProducts(response.data);
		}

		setIsLoading(false);
		setFilterIsOpen(false);
	}

	useEffect(() => {
		fetchProducts();
		fetchTypes();
		fetchPlatforms();
	}, []);

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Configurações",
				path: "/admin",
			},
			{
				name: "Produtos",
				path: "/admin/products",
			},
		];

		setPaths(paths);
	}, []);

	return (
		<>
			<Helmet title="Configurações" />

			<div className="flex h-full flex-col justify-between gap-12">
				<header className="flex items-center justify-between">
					<h2 className="font-inter text-P2 font-medium leading-140 text-neutral-1100">
						Todos os produtos
					</h2>

					<div className="flex items-center justify-center gap-4">
						<button
							className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 px-6 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400"
							disabled={isLoading}
							onClick={() => setFilterIsOpen(!filterIsOpen)}
						>
							<OptionsIcon />

							<p className="font-inter text-P6 font-semibold leading-160">
								Filtro
							</p>
						</button>

						<button
							className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-primary-600 px-6 text-neutral-1100 transition-all duration-300 hover:bg-primary-700"
							onClick={() => setModalIsOpen(true)}
						>
							<MathPlus />

							<p className="font-inter text-P6 font-semibold leading-160">
								Adicionar produto
							</p>
						</button>
					</div>
				</header>

				<div
					className={`flex max-h-0 flex-col gap-8 rounded-[10px] bg-neutral-100 bg-opacity-60 opacity-0 transition-all duration-500 ${
						filterIsOpen && "max-h-fit p-6 opacity-100"
					}`}
				>
					{filterIsOpen && (
						<Form {...form}>
							<div className="flex w-full flex-col items-center gap-6 md:flex-row">
								<div
									className={`relative w-full ${
										products.length > 0
											? "text-neutral-600"
											: "text-neutral-200"
									} focus-within:text-neutral-1100`}
								>
									<Input
										placeholder="Pesquise por um produto"
										className={`h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:!text-P5  ${
											products.length > 0
												? "placeholder:text-neutral-600"
												: "placeholder:text-neutral-200"
										}`}
										{...form.register("name")}
									/>
									<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

									{form.watch("name") !== "" && (
										<button
											className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-200 text-neutral-1100"
											onClick={() => form.setValue("name", undefined)}
										>
											<CloseIcon />
										</button>
									)}
								</div>

								<FormField
									control={form.control}
									name="type"
									render={({ field }) => (
										<FormItem className="w-1/2">
											<Select
												onValueChange={(e) => {
													field.onChange(e);
												}}
												defaultValue={field.value}
												value={field.value}
											>
												<FormControl>
													<SelectTrigger className="w-full">
														<SelectValue placeholder="Tipo" />
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{types.map((type) => (
														<SelectItem value={type.id} key={type.id}>
															{type.name}
														</SelectItem>
													))}
												</SelectContent>
											</Select>
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="platform"
									render={({ field }) => (
										<FormItem className="w-1/2">
											<Select
												onValueChange={(e) => {
													field.onChange(e);
												}}
												defaultValue={field.value}
												value={field.value}
											>
												<FormControl>
													<SelectTrigger className="w-full">
														<SelectValue placeholder="Plataforma" />
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{platforms.map((platform) => (
														<SelectItem value={platform.id} key={platform.id}>
															{platform.name}
														</SelectItem>
													))}
												</SelectContent>
											</Select>
										</FormItem>
									)}
								/>
							</div>

							<div className="flex flex-col justify-between gap-6 md:flex-row md:items-center">
								<div>
									<span className="font-inter text-P5 font-normal leading-160 text-neutral-400">
										Nenhum filtro aplicado
									</span>
								</div>

								<div className="flex gap-4">
									<Button
										className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] bg-transparent font-inter !text-P6 font-semibold leading-160 text-neutral-600 transition-all duration-500 hover:bg-neutral-200 disabled:bg-transparent disabled:text-neutral-400"
										variant="secondary"
										onClick={() => {
											form.setValue("name", undefined);
											form.setValue("platform", undefined);
											form.setValue("type", undefined);
											setFilterIsOpen(false);

											handleFilterSubmit({});
										}}
									>
										Limpar filtro
									</Button>
									<Button
										className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] font-inter !text-P6 font-semibold leading-160"
										onClick={form.handleSubmit(async (data) => {
											handleFilterSubmit(data);
										})}
									>
										Aplicar
									</Button>
								</div>
							</div>
						</Form>
					)}
				</div>

				<div className={!filterIsOpen ? "-mt-12 h-full" : ""}>
					<div className="flex flex-col gap-4 pb-8">
						{products.length > 0 &&
							!isLoading &&
							products.map((product) => (
								<AdminProductCard
									key={product.id}
									product={product.name}
									product_image={product.image}
									type={product.type.name}
									producer={"Produtor" /* TODO: Replace with producer name */}
									producer_avatar={"" /* TODO: Replace with producer avatar */}
									platform={
										product.platforms.map(
											(platform) => platform.platform,
										) as Platforms[]
									}
									id={product.id}
								/>
							))}

						{isLoading &&
							Array.from({ length: 4 }).map((_, index) => (
								<SkeletonAdminProductCard key={index} />
							))}
					</div>

					{!products.length && !isLoading && (
						<div className="flex h-full w-full flex-1 flex-col items-center justify-center gap-6">
							<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
								<SearchIcon />
							</span>

							<div className="flex flex-col items-center gap-2">
								<h3 className="text-center font-inter text-P2 font-medium leading-140 text-neutral-1100">
									No momento você não possui nenhum produto cadastrado
								</h3>

								<p className="text-center font-inter text-P3 font-normal leading-160 text-neutral-500">
									Adicione um produto para visualizar ele aqui.
								</p>
							</div>
						</div>
					)}
				</div>

				{modalIsOpen && (
					<AdminProductsCreate
						closeModal={() => {
							setModalIsOpen(false);
						}}
						closeAfterCreate={async (id: string) => {
							await fetchProducts();
							setProductId(id);

							if (id) {
								setModalIsOpen(false);
								setSuccessModal(true);
							}

							return;
						}}
					/>
				)}

				{successModal && (
					<AlertModal
						title="Novo produto adicionado com sucesso!"
						description="Agora você pode está adicionando descrição, materiais e dúvidas ao seu produto."
						icon="done.svg"
						confirmText="Continuar editando"
						cancelText="Editar mais tarde"
						confirmAction={() => {
							setSuccessModal(false);

							if (productId !== null) {
								window.location.href = `/admin/products/${productId}`;
							}
						}}
						cancelAction={() => {
							setSuccessModal(false);
							setProductId(null);
						}}
						closeModal={() => {
							setSuccessModal(false);
							setProductId(null);
						}}
					/>
				)}
			</div>
		</>
	);
}
