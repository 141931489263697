import { useEffect, useRef, useState } from "react";

import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";

interface TextareaInputProps {
	value: string;
	setValue: (value: string) => void;
	maxLength?: number;
	showCounter?: boolean;
	showButtons?: boolean;
	height?: string;
	heightIsFixed?: boolean;
	placeholder?: string;
	onComplete?: () => void;
}

export function TextareaInput({
	value,
	setValue,
	maxLength = 250,
	showCounter = true,
	showButtons = true,
	height = "286px",
	heightIsFixed = false,
	placeholder = "Insira seu texto aqui",
	onComplete,
}: TextareaInputProps) {
	const [isFocused, setIsFocused] = useState(false);
	const textAreaRef = useRef<HTMLDivElement>(null);

	function handleFocus() {
		setIsFocused(true);
	}

	useEffect(() => {
		function handleClickOutsideProfile(event: MouseEvent) {
			if (
				textAreaRef.current &&
				!textAreaRef.current.contains(event.target as Node)
			) {
				setIsFocused(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutsideProfile);

		return () => {
			document.removeEventListener("mousedown", handleClickOutsideProfile);
		};
	}, []);

	return (
		<div className="relative flex w-full flex-col gap-2" ref={textAreaRef}>
			<Textarea
				className="w-full resize-none rounded-[8px] border border-neutral-200 bg-transparent p-4 font-inter !text-P5 font-normal leading-160 text-neutral-1100"
				style={{
					height: heightIsFixed
						? `${height}`
						: isFocused || value
							? `${height}`
							: "auto",
				}}
				placeholder={placeholder}
				maxLength={maxLength}
				value={value}
				onChange={(e) => setValue(e.target.value)}
				onFocus={handleFocus}
			/>

			{showCounter && (isFocused || value) && (
				<p className="font-inter text-P6 font-normal leading-160 text-neutral-600">
					{value.length} de {maxLength} caracteres.
				</p>
			)}

			{showButtons && isFocused && (
				<div className="absolute bottom-4 right-4 flex gap-4">
					<Button
						className="flex !h-10 flex-1 items-center justify-center gap-2 rounded-[4px] bg-transparent font-inter !text-P6 font-semibold leading-160 text-neutral-600 transition-all duration-500 hover:bg-neutral-200 disabled:bg-transparent disabled:text-neutral-400"
						variant="secondary"
						type="button"
						onClick={() => {
							setValue("");
							setIsFocused(false);
						}}
					>
						Cancelar
					</Button>
					<Button
						className={`flex !h-10 flex-1 items-center justify-center gap-2 rounded-[4px] bg-neutral-100 font-inter !text-P6 font-semibold leading-160 text-neutral-400 hover:bg-neutral-200 hover:bg-opacity-40
								${value ? "!bg-primary-600 text-neutral-1100" : ""}
							`}
						type="button"
						onClick={() => {
							if (value && onComplete) {
								onComplete();
							}

							setTimeout(() => {
								setIsFocused(false);
							}, 2000);
						}}
					>
						Concluir
					</Button>
				</div>
			)}
		</div>
	);
}
