import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion";
import { useProductStore } from "@/store/product";

export function ProductAbout() {
	const { product } = useProductStore();

	return (
		<Accordion type="single" defaultValue="item-1" collapsible>
			<AccordionItem value="item-1">
				<AccordionTrigger>Descrição</AccordionTrigger>
				<AccordionContent>
					<div>
						{product?.description
							? // quebra de linha com /n
								product?.description
									.split("\n")
									.map((item, index) => <p key={index}>{item}</p>)
							: "Nenhuma descrição disponível."}
					</div>
				</AccordionContent>
			</AccordionItem>

			<AccordionItem value="item-2">
				<AccordionTrigger>Regras</AccordionTrigger>
				<AccordionContent>
					<div>
						{product?.rules
							? product?.rules
									.split("\n")
									.map((item, index) => <p key={index}>{item}</p>)
							: "Nenhuma regra disponível."}
					</div>
				</AccordionContent>
			</AccordionItem>

			<AccordionItem value="item-3">
				<AccordionTrigger>Premiações</AccordionTrigger>
				<AccordionContent>
					<div>
						{product?.awards
							? product?.awards
									.split("\n")
									.map((item, index) => <p key={index}>{item}</p>)
							: "Nenhuma premiação disponível."}
					</div>
				</AccordionContent>
			</AccordionItem>

			<AccordionItem value="item-4">
				<AccordionTrigger>Projeção de ganho</AccordionTrigger>
				<AccordionContent>
					<div>
						{product?.earnings_projection
							? product?.earnings_projection
									.split("\n")
									.map((item, index) => <p key={index}>{item}</p>)
							: "Nenhuma projeção de ganho disponível."}
					</div>
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	);
}
