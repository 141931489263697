import { create } from "zustand";

type Path = {
	name: string;
	path: string;
};

interface NavigationStore {
	paths: Path[];
	setPaths: (paths: Path[]) => void;
	menuIsOpen: boolean;
	setMenuIsOpen: (menuIsOpen: boolean) => void;
}

export const useNavigationStore = create<NavigationStore>((set) => ({
	paths: [],
	setPaths: (paths) => set({ paths }),
	menuIsOpen: false,
	setMenuIsOpen: (menuIsOpen) => set({ menuIsOpen }),
}));
