import { Link } from "react-router-dom";

import CheckIcon from "@/assets/check.svg?react";
import TimeIcon from "@/assets/time.svg?react";

import { Tag } from "./tag";

interface LessonCardProps {
	size: "small" | "large";
	trainingId: string;
	lessonId: string;
	duration: number;
	title: string;
	lesson: number;
	image: string;
	timeWatched: number;
	isCompleted?: boolean;
}

export function LessonCard({
	size = "large",
	trainingId,
	lessonId,
	duration,
	title,
	lesson,
	image,
	timeWatched,
	isCompleted = false,
}: LessonCardProps) {
	function calculateProgress() {
		if (isCompleted) {
			return 100;
		}

		return (timeWatched / duration) * 100;
	}

	function formatDuration() {
		const hours = Math.floor(duration / 60);
		const minutes = duration % 60;

		return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
	}

	return (
		<Link
			to={`/academy/${trainingId}/${lessonId}`}
			className={`
			flex rounded-xl bg-neutral-100 bg-opacity-40
			transition-all duration-300 hover:bg-opacity-100
			${size === "large" ? "h-[132px] gap-6 p-4" : ""}
			${size === "small" ? "h-[72px] gap-4 p-2" : ""}
		`}
		>
			<div className="relative flex items-center justify-between">
				<img
					src={image}
					alt={title}
					className={`object-cover
					${size === "large" ? "h-[100px] rounded-[8px]" : ""}
					${size === "small" ? "h-[56px] rounded-[4px]" : ""}
				`}
					style={{
						background: `linear-gradient(180deg, rgba(11, 18, 18, 0.00) 0%, rgba(11, 18, 18, 0.80) 100%), url(${image}) lightgray 50% / cover no-repeat`,
					}}
				/>

				<div
					className={`absolute bg-neutral-1100 bg-opacity-20
					${size === "large" ? "bottom-3 left-2 right-2 h-1 rounded-[2px]" : ""}
					${size === "small" ? "bottom-[6px] left-1 right-1 h-[2px] rounded-[1px]" : ""}
				`}
				>
					<div
						className={`h-full bg-neutral-1100
						${size === "large" ? "rounded-[2px]" : ""}
						${size === "small" ? "rounded-[1px]" : ""}
					`}
						style={{ width: `${calculateProgress()}%` }}
					/>
				</div>
			</div>

			<div
				className={`flex flex-1 flex-col
				${size === "large" ? "gap-4" : ""}
				${size === "small" ? "gap-2.5" : ""}
			`}
			>
				<div className="flex items-center justify-between">
					<div className="flex items-center gap-3">
						<Tag
							size="small"
							colors="minimalist-grey"
							className="rounded px-[6px] py-1 text-P7"
						>
							Aula {lesson}
						</Tag>

						<div className="flex gap-1.5 text-neutral-700">
							<TimeIcon />

							<p className="font-inter text-P7 font-normal leading-160">
								{formatDuration()}
							</p>
						</div>
					</div>

					{size === "small" && isCompleted ? (
						<span className="size-5 rounded-[4px] bg-primary-800 text-neutral-1100">
							<CheckIcon className="size-5" />
						</span>
					) : null}
				</div>

				<p
					className="line-clamp-2 text-ellipsis text-left font-inter text-P5 font-semibold leading-160 text-neutral-1100"
					title={title}
				>
					{title}
				</p>
			</div>
		</Link>
	);
}
