import { Skeleton } from "@/components/ui/skeleton";

export function SkeletonTrainingCard() {
	return (
		<div className="relative flex w-full min-w-[256px] flex-col items-center rounded-xl bg-neutral-100 bg-opacity-40 transition-colors duration-300 ease-in-out hover:bg-opacity-60">
			<div className="relative flex w-full items-center px-4 pt-4">
				<Skeleton className="h-[154px] w-full rounded-[10px]" />
			</div>

			<div className="flex w-full flex-col gap-4 p-6">
				<div className="flex gap-4">
					<Skeleton className="h-6 w-[60px] rounded px-[6px] py-1" />
					<Skeleton className="h-6 w-[60px] rounded px-[6px] py-1" />
				</div>

				<div className="flex flex-col gap-3">
					<Skeleton className="h-6 w-full rounded" />

					<div className="flex items-center gap-4">
						<Skeleton className="h-6 w-6 rounded-full" />
						<Skeleton className="h-5 w-20 rounded" />
					</div>
				</div>
			</div>
		</div>
	);
}
