import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { showAdminProduct } from "@/api/admin/products/show";
import { TabNavigation } from "@/components/tab-navigation";
import { useAdminProductStore } from "@/store/admin-product";
import { useNavigationStore } from "@/store/navigation-store";

export function AdminProductsShow() {
	const { id } = useParams<{ id: string }>();

	const tabs = [
		{
			title: "Detalhes",
			to: `/admin/products/${id}`,
		},
		{
			title: "Sobre",
			to: `/admin/products/${id}/about`,
		},
		{
			title: "Materiais",
			to: `/admin/products/${id}/materials`,
		},
		{
			title: "Dúvidas",
			to: `/admin/products/${id}/questions`,
		},
	];

	const { product, setProduct } = useAdminProductStore();

	const navigate = useNavigate();

	async function getProductById() {
		if (!id) return navigate("/products");

		const response = await showAdminProduct(id);

		if (response.status === "success") {
			setProduct(response.data);
		}

		if (response.status === "error") {
			navigate("/products");
		}
	}

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		setProduct(null);
		getProductById();
	}, [id]);

	useEffect(() => {
		const paths = [
			{
				name: "Configurações",
				path: "/admin",
			},
			{
				name: "Produtos",
				path: "/admin/products",
			},
			{
				name: product?.name || "Carregando...",
				path: `/admin/products/${id}`,
			},
		];

		setPaths(paths);
	}, [product]);

	return (
		<section className="flex w-full flex-col gap-12">
			<div className="flex flex-col gap-10">
				<h3 className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
					{!product ? "Carregando..." : product.name}
				</h3>

				<TabNavigation tabs={tabs} />
			</div>

			<Outlet />
		</section>
	);
}
