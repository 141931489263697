import { Link, Outlet } from "react-router-dom";

import { AuthProvider } from "@/hooks/auth";

export function AuthLayout() {
	return (
		<AuthProvider>
			<div className="h-screen md:flex">
				<div className="hidden md:flex md:w-[424px] md:bg-neutral-0"></div>
				<div className="hidden md:flex md:flex-1 md:bg-[url('/bg2.png')] md:bg-cover md:bg-center">
					<div className="absolute bottom-[100px] right-28 flex w-[215px] flex-col gap-6">
						<span className="h-1 w-10 rounded-[100px] bg-primary-600" />

						<div className="flex flex-col gap-3">
							<h6 className="font-poppins text-H6 font-semibold leading-140 text-neutral-1100">
								Um só lugar para você vender mais!
							</h6>

							<p className="font-inter text-P4 font-medium leading-160 text-neutral-500">
								Por Felipe Moreno
							</p>
						</div>
					</div>
				</div>
				<div className="flex h-full flex-col gap-16 bg-neutral-100 p-8 md:absolute md:top-1/2 md:ml-28 md:h-auto md:w-[488px] md:-translate-y-1/2 md:rounded-[10px] md:p-10">
					<Link to="/sign-in">
						<img src="/logo.svg" alt="Logo Suportefy" width={110} height={32} />
					</Link>
					<Outlet />
				</div>
			</div>
		</AuthProvider>
	);
}
