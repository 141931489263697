import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { TabNavigation } from "@/components/tab-navigation";
import { useNavigationStore } from "@/store/navigation-store";

export function AdminPanel() {
	const tabs = [
		{
			title: "Vendas",
			to: `/admin`,
		},
		{
			title: "Usuários",
			to: `/admin/panel-users`,
		},
	];

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Configurações",
				path: "/admin",
			},
			{
				name: "Painel",
				path: "/admin",
			},
		];

		setPaths(paths);
	}, []);

	return (
		<section className="flex w-full flex-col gap-12">
			<TabNavigation tabs={tabs} />

			<Outlet />
		</section>
	);
}
