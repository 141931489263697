import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { updatePassword } from "@/api/app/account/update-password";
import InfoIcon from "@/assets/info.svg?react";
import { AlertModal } from "@/components/alert-modal";
import { Button } from "@/components/ui/button";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { useAuthStore } from "@/store/auth";
import { useNavigationStore } from "@/store/navigation-store";

export function AccountPassword() {
	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Home",
				path: "/",
			},
			{
				name: `Minha conta`,
				path: `/account`,
			},
			{
				name: `Senha`,
				path: `/account/password`,
			},
		];

		setPaths(paths);
	}, []);

	const accountPasswordForm = z
		.object({
			old_password: z.string().min(8, {
				message: "A senha precisa ter no mínimo 8 caracteres",
			}),
			password: z.string().min(8, {
				message: "A senha precisa ter no mínimo 8 caracteres",
			}),
			password_confirmation: z.string().min(8, {
				message: "A senha precisa ter no mínimo 8 caracteres",
			}),
		})
		.refine((data) => data.password === data.password_confirmation, {
			message: "As senhas não conferem",
			path: ["confirmPassword"],
		});

	type AccountPasswordForm = z.infer<typeof accountPasswordForm>;

	const {
		register,
		handleSubmit,
		getValues,
		setError,
		formState: { errors },
	} = useForm<AccountPasswordForm>({
		resolver: zodResolver(accountPasswordForm),
	});

	const [confirmationModal, setConfirmationModal] = useState(false);
	const [finalModal, setFinalModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	async function handleUpdatePassword(data: AccountPasswordForm, id: string) {
		setIsLoading(true);

		const response = await updatePassword(data, id);

		if (response.status === "success") {
			setFinalModal(true);
			setConfirmationModal(false);
			setIsLoading(false);
			return;
		}

		if (response.status === "error") {
			const { errors } = response;

			for (const key in errors) {
				setError(key as keyof AccountPasswordForm, {
					type: "manual",
					message: errors[key],
				});
			}

			setConfirmationModal(false);
			setIsLoading(false);
			return;
		}
	}

	const { profile } = useAuthStore();

	const navigate = useNavigate();

	return (
		<>
			<Helmet title="Senha" />

			<form
				className="flex flex-col gap-8"
				onSubmit={handleSubmit(() => {
					setConfirmationModal(true);
				})}
			>
				<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
					Redefinir senha
				</p>

				<div className="flex flex-col gap-6">
					<div className="flex flex-col gap-2">
						<FloatingLabelInput
							id="current_password"
							type="password"
							label="Senha atual"
							bgColor="!bg-neutral-0"
							hasError={!!errors.old_password}
							{...register("old_password")}
						/>

						{errors.old_password && (
							<p className="font-inter text-P6 font-normal leading-160 text-red-600">
								{errors.old_password.message}
							</p>
						)}
					</div>

					<div className="flex flex-col gap-6 md:flex-row">
						<div className="flex w-full flex-col gap-2">
							<FloatingLabelInput
								id="password"
								type="password"
								label="Nova senha"
								bgColor="!bg-neutral-0"
								hasError={!!errors.password}
								{...register("password")}
							/>

							{errors.password && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{errors.password.message}
								</p>
							)}
						</div>
						<div className="flex w-full flex-col gap-2">
							<FloatingLabelInput
								id="password_confirmation"
								type="password"
								label="Repetir nova senha"
								bgColor="!bg-neutral-0"
								hasError={!!errors.password_confirmation}
								{...register("password_confirmation")}
							/>

							{errors.password_confirmation && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{errors.password_confirmation.message}
								</p>
							)}
						</div>
					</div>
				</div>

				<footer className="fixed bottom-0 left-0 right-0 z-[6] flex items-center justify-between bg-neutral-0 px-8 pb-8 pt-4 xl:left-[257px]">
					<button className="hidden h-10 items-center justify-center gap-2 px-6 text-neutral-600 transition-all duration-300 hover:text-neutral-1100 md:flex">
						<InfoIcon width={18} height={18} />
						<p className="font-inter text-P6 font-semibold leading-160">
							Precisa de ajuda?
						</p>
					</button>

					<div className="flex w-full items-center justify-center gap-4 md:w-auto">
						<Button
							className="flex !h-12 w-full items-center justify-center gap-2 rounded-[8px] !bg-neutral-200 font-inter !text-P5 font-semibold leading-160 text-neutral-1100 transition-all duration-500 hover:!bg-opacity-40 md:!w-[140px]
						"
							variant="secondary"
							type="button"
							onClick={() => {
								navigate(-1);
							}}
						>
							Cancelar
						</Button>
						<Button
							className="flex !h-12 w-full items-center justify-center gap-2 rounded-[8px] font-inter !text-P5 font-semibold leading-160 md:!w-[140px]"
							type="submit"
						>
							Salvar
						</Button>
					</div>
				</footer>
			</form>

			{confirmationModal && (
				<AlertModal
					title="Deseja realmente atualizar sua a sua senha?"
					description="Essa ação não poderá ser desfeita!"
					icon="unlock.svg"
					confirmText="Atualizar senha"
					isLoading={isLoading}
					cancelAction={() => setConfirmationModal(false)}
					confirmAction={() => {
						handleUpdatePassword(getValues(), profile?.id ?? "");
					}}
					closeModal={() => setConfirmationModal(false)}
				/>
			)}

			{finalModal && (
				<AlertModal
					title="Senha atualizada com sucesso!"
					icon="done.svg"
					confirmText="Concluir"
					confirmAction={() => {
						setFinalModal(false);
					}}
					hasCancel={false}
					closeModal={() => setFinalModal(false)}
				/>
			)}
		</>
	);
}
