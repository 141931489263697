import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { addAcademyTrainingModule } from "@/api/admin/academy/trainings/module/add-module";
import { updateAcademyTrainingModule } from "@/api/admin/academy/trainings/module/update-module";
import { updateAcademyTrainingModuleAvailable } from "@/api/admin/academy/trainings/module/update-module-available";
import type { Module } from "@/api/admin/academy/trainings/show-training";
import { Modal } from "@/components/modal";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form } from "@/components/ui/form";

interface AdminAcademyShowModalModuleProps {
	title: string;
	trainingId: string;
	module?: Module;
	isEdit?: boolean;
	closeModal: () => void;
	onSuccess: () => void;
}

export function AdminAcademyShowModalModule({
	title,
	closeModal,
	isEdit = false,
	trainingId,
	module,
	onSuccess,
}: AdminAcademyShowModalModuleProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [isAvailable, setIsAvailable] = useState(module?.available);

	const formSchema = z.object({
		name: z.string().min(1, {
			message: "O nome do módulo é obrigatório",
		}),
	});

	type FormSchema = z.infer<typeof formSchema>;

	const form = useForm<FormSchema>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			name: isEdit ? module?.name : "",
		},
	});

	return (
		<Modal
			title={title}
			closeModal={closeModal}
			isLoading={isLoading}
			toggleIsChecked={isAvailable}
			onToggleChange={async () => {
				if (!module) return;

				setIsLoading(true);
				const response = await updateAcademyTrainingModuleAvailable(
					{
						available: !isAvailable,
					},
					module.id,
				);

				if (response.status === "success") {
					setIsAvailable(!isAvailable);
				}

				setIsLoading(false);
			}}
			confirmAction={() => {
				if (isEdit) {
					form.handleSubmit(async (e) => {
						if (!module) return;
						setIsLoading(true);
						const { status } = await updateAcademyTrainingModule(module.id, {
							name: e.name,
							training_id: trainingId,
						});
						if (status === "success") {
							setIsLoading(false);
							closeModal();
							onSuccess();
						}
					})();
				}

				if (!isEdit) {
					form.handleSubmit(async (e) => {
						setIsLoading(true);
						const { status } = await addAcademyTrainingModule({
							name: e.name,
							training_id: trainingId,
						});
						if (status === "success") {
							setIsLoading(false);
							closeModal();
							onSuccess();
						}
					})();
				}
			}}
			className="!h-[300px]"
			cancelAction={closeModal}
			hasToggle={isEdit}
		>
			<Form {...form}>
				<div className="flex flex-col gap-8">
					<div className="flex w-full flex-col items-center gap-6">
						<div className="flex w-full flex-col gap-2">
							<FloatingLabelInput
								id="name"
								type="text"
								label="Nome do módulo"
								hasError={!!form.formState.errors.name}
								{...form.register("name")}
							/>

							{form.formState.errors.name && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{form.formState.errors.name.message}
								</p>
							)}
						</div>
					</div>
				</div>
			</Form>
		</Modal>
	);
}
