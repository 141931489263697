import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import {
	fetchMaterials,
	type Material,
} from "@/api/app/academy/materials/list";
import {
	fetchTrainings,
	type Training,
} from "@/api/app/academy/trainings/list";
import ArrowRightIcon from "@/assets/arrow-right.svg?react";
import PlayButtonIcon from "@/assets/play-button.svg?react";
import { LessonCard } from "@/components/lesson-card";
// import { LessonCard } from "@/components/lesson-card";
import { MaterialCard } from "@/components/material-card";
import { SkeletonMaterialCard } from "@/components/skeletons/material-card";
import { SkeletonPrincipalTrainingCard } from "@/components/skeletons/principal-training-card";
import { SkeletonTrainingCard } from "@/components/skeletons/training-card";
import { Tag } from "@/components/tag";
import { TrainingCard } from "@/components/training-card";
import { Button } from "@/components/ui/button";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "@/components/ui/carousel";
import { useAuthStore } from "@/store/auth";
import { useNavigationStore } from "@/store/navigation-store";

export function AcademyTrainings() {
	useEffect(() => {
		const paths = [
			{
				name: "Academy",
				path: "/academy",
			},
			{
				name: "Treinamentos",
				path: "/academy/trainings",
			},
		];

		setPaths(paths);
	}, []);

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	const [materials, setMaterials] = useState<Material[]>([]);
	const [trainings, setTrainings] = useState<Training[]>([]);

	async function getMaterials() {
		const response = await fetchMaterials({});

		if (response.status === "success") {
			const materials = response.data;

			setMaterials(materials.slice(0, 3));
		}
	}

	async function getTrainings() {
		const response = await fetchTrainings({
			sortBy: "created_at",
		});

		if (response.status === "success") {
			const trainings = response.data;

			setTrainings(trainings);
		}
	}

	const { profile } = useAuthStore();

	useEffect(() => {
		getMaterials();
		getTrainings();
	}, []);

	return (
		<>
			<Helmet title="Treinamentos" />

			<div className="flex flex-col gap-12">
				{trainings.length === 0 && (
					<div className="flex h-full flex-1">
						<SkeletonPrincipalTrainingCard />
					</div>
				)}

				{trainings.length > 0 && (
					<section
						className="flex h-full w-full items-center rounded-xl px-4 pb-8 pt-4 md:pb-[26px] md:pl-6 md:pr-24 md:pt-12"
						style={{
							background: `linear-gradient(180deg, rgba(11, 18, 18, 0.10) 14%, #0B1212 81%), url('${trainings[0].image}')`,
							backgroundSize: "cover",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
						}}
					>
						<div className="flex w-full flex-col gap-12">
							<div className="flex gap-4">
								<Tag
									size="small"
									colors="minimalist-grey"
									className="rounded bg-neutral-1100 bg-opacity-10 px-[6px] py-1"
								>
									{trainings[0].modulesCount > 1
										? `${trainings[0].modulesCount} Módulos`
										: `${trainings[0].modulesCount} Módulo`}
								</Tag>
								<Tag
									size="small"
									colors="minimalist-grey"
									className="rounded bg-neutral-1100 bg-opacity-10 px-[6px] py-1"
								>
									{trainings[0].lessonsCount > 1
										? `${trainings[0].lessonsCount} Aulas`
										: `${trainings[0].lessonsCount} Aula`}
								</Tag>
							</div>

							<div className="flex flex-col gap-4">
								<h6 className="font-poppins text-H6 font-semibold leading-140 text-neutral-1100">
									{trainings[0].name}
								</h6>

								<p
									className="font-inter text-P4 font-normal leading-160 text-neutral-700"
									style={{ maxWidth: 616 }}
								>
									{trainings[0].description}
								</p>
							</div>

							<Button
								className="flex !h-[48px] w-full items-center justify-center gap-2 rounded-[8px] bg-primary-600 !text-P5 md:w-[232px]"
								onClick={() => {
									window.location.href = `/academy/${trainings[0].id}`;
								}}
							>
								<PlayButtonIcon width={20} height={20} />

								<p>Iniciar treinamento</p>
							</Button>
						</div>
					</section>
				)}

				{profile && profile?.lastLessons.length > 0 && (
					<section className="flex flex-col gap-6">
						<h3 className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
							Continuar assistindo
						</h3>

						<div className="hidden gap-8 md:grid lg:grid-cols-3">
							{profile.lastLessons.slice(0, 3).map((lesson) => (
								<LessonCard
									key={lesson.id}
									lessonId={lesson.id}
									trainingId={lesson.training_id}
									duration={lesson.duration}
									lesson={lesson.position + 1}
									title={lesson.name}
									image={lesson.thumbnail}
									size="large"
									timeWatched={lesson.time_watched}
								/>
							))}
						</div>

						<Carousel className="grid md:hidden">
							<CarouselContent className="-ml-8">
								{profile.lastLessons.slice(0, 3).map((lesson, index) => (
									<CarouselItem className="basis-auto pl-8" key={index}>
										<div className="w-full max-w-[320px]">
											<LessonCard
												key={lesson.id}
												lessonId={lesson.id}
												trainingId={lesson.training_id}
												duration={lesson.duration}
												lesson={lesson.position + 1}
												title={lesson.name}
												image={lesson.thumbnail}
												size="large"
												timeWatched={lesson.time_watched}
											/>
										</div>
									</CarouselItem>
								))}
							</CarouselContent>
						</Carousel>
					</section>
				)}

				<section className="mt-12">
					<Carousel>
						<CarouselContent className="-ml-8">
							{trainings.length === 0 &&
								Array.from({ length: 6 }).map((_, index) => (
									<CarouselItem className="basis-auto pl-8" key={index}>
										<SkeletonTrainingCard key={index} />
									</CarouselItem>
								))}

							{trainings.map((training) => (
								<CarouselItem className="basis-auto pl-8" key={training.id}>
									<TrainingCard
										id={training.id}
										author={training?.mentor?.name}
										avatar={training?.mentor?.image}
										image={training.image}
										modules={training.modulesCount}
										lessons={training.lessonsCount}
										title={training.name}
									/>
								</CarouselItem>
							))}
						</CarouselContent>
						<CarouselPrevious className="right-14 top-[-54px] disabled:bg-neutral-100" />
						<CarouselNext className="right-0 top-[-54px]" />

						<p className="absolute left-0 top-[-48px] font-inter text-P3 font-medium leading-160 text-neutral-1100">
							Treinamentos
						</p>
					</Carousel>
				</section>

				<hr className="h-[2px] border-t-neutral-100" />

				<section className="flex flex-col gap-8">
					<div className="flex items-center justify-between">
						<h3 className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
							Materiais gratuitos
						</h3>

						<Link
							to="/academy/free-materials"
							className="flex cursor-pointer items-center justify-center gap-1 font-inter text-P5 font-medium leading-160 text-neutral-600 transition-all duration-300 hover:text-neutral-1100 hover:underline hover:underline-offset-8"
						>
							<p>Ver todos</p>
							<ArrowRightIcon />
						</Link>
					</div>

					<div className="grid w-full grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
						{materials.length === 0 &&
							Array.from({ length: 3 }).map((_, index) => (
								<SkeletonMaterialCard key={index} />
							))}

						{materials.map((material) => (
							<MaterialCard
								key={material.id}
								title={material.title}
								type={material.format}
								link={material.link}
							/>
						))}
					</div>
				</section>
			</div>
		</>
	);
}
