import { useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

import {
	fetchMaterials,
	type Material,
} from "@/api/app/academy/materials/list";
import {
	fetchTrainings,
	type Training,
} from "@/api/app/academy/trainings/list";
import ArrowRightIcon from "@/assets/arrow-right.svg?react";
import CloseIcon from "@/assets/close.svg?react";
import InfoIcon from "@/assets/info.svg?react";
import OptionsIcon from "@/assets/options.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import { MaterialCard } from "@/components/material-card";
import { PaginationList } from "@/components/pagination-list";
import { SkeletonMaterialCard } from "@/components/skeletons/material-card";
import { SkeletonTrainingCard } from "@/components/skeletons/training-card";
import { TrainingCard } from "@/components/training-card";
import { Button } from "@/components/ui/button";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
} from "@/components/ui/carousel";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { useNavigationStore } from "@/store/navigation-store";

export function AcademyFreeMaterials() {
	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Academy",
				path: "/academy",
			},
			{
				name: "Materiais gratuitos",
				path: "/academy/free-materials",
			},
		];

		setPaths(paths);
	}, [setPaths]);

	function reducer(
		state: {
			currentPage: number;
			lastPage: number;
			total: number;
			to: number;
		},
		action: {
			type: string;
			payload?: {
				lastPage?: number;
				total?: number;
				to?: number;
				page?: number;
			};
		},
	) {
		switch (action.type) {
			case "SET_INITIAL_STATE":
				return {
					...state,
					lastPage: action.payload?.lastPage ?? state.lastPage,
					total: action.payload?.total ?? state.total,
					to: action.payload?.to ?? state.to,
					currentPage: action.payload?.page ?? state.currentPage,
				};
			case "NEXT_PAGE":
				return {
					...state,
					currentPage: state.currentPage + 1,
					to: state.to + 9,
				};

			case "PREVIOUS_PAGE":
				return {
					...state,
					currentPage: state.currentPage - 1,
					to: state.to - 9,
				};

			case "PAGE_CHANGE":
				return {
					...state,
					currentPage: action.payload?.page ?? state.currentPage,
					to: (action.payload?.page ?? state.currentPage) * 9,
				};

			default:
				return state;
		}
	}

	const [state, dispatch] = useReducer(reducer, {
		currentPage: 1,
		lastPage: 1,
		total: 9,
		to: 9,
	});

	const [filterIsOpen, setFilterIsOpen] = useState(false);
	const [materials, setMaterials] = useState<Material[]>([]);
	const [trainings, setTrainings] = useState<Training[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	async function getTrainings() {
		const response = await fetchTrainings({
			sortBy: "created_at",
		});

		if (response.status === "success") {
			const trainings = response.data;

			setTrainings(trainings.slice(0, 4));
		}
	}

	async function getMaterials(
		format?: "image" | "document" | "text" | "link",
		title?: string,
	) {
		setMaterials([]);
		setIsLoading(true);

		const response = await fetchMaterials({
			format,
			title,
		});

		if (response.status === "success") {
			const materials = response.data;

			dispatch({
				type: "SET_INITIAL_STATE",
				payload: {
					lastPage: response.lastPage,
					total: response.total,
					to: response.perPage,
					page: response.page,
				},
			});

			setMaterials(materials);
		}

		setIsLoading(false);
	}

	useEffect(() => {
		getMaterials();
		getTrainings();
	}, []);

	const materialsFilter = z.object({
		title: z.string().optional(),
		format: z.enum(["image", "document", "text", "link"]).optional(),
	});

	type MaterialsFilter = z.infer<typeof materialsFilter>;

	const form = useForm<MaterialsFilter>();

	return (
		<>
			<Helmet title="Materiais gratuitos" />

			<div className="flex flex-col gap-12">
				<header className="flex items-center justify-between">
					<h2 className="font-inter text-P2 font-medium leading-140 text-neutral-1100">
						Todos os materiais
					</h2>

					<div className="flex items-center gap-6">
						<button className="hidden h-10 items-center justify-center gap-2 rounded-[6px] bg-transparent px-6 text-neutral-600 transition-all duration-300 hover:bg-neutral-200 hover:text-neutral-1100 md:flex">
							<InfoIcon width={18} height={18} />

							<p className="font-inter text-P6 font-semibold leading-160">
								Saiba mais
							</p>
						</button>

						<button
							className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 px-6 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40"
							onClick={() => setFilterIsOpen(!filterIsOpen)}
						>
							<OptionsIcon />

							<p className="font-inter text-P6 font-semibold leading-160">
								Filtro
							</p>
						</button>
					</div>
				</header>

				<section className="flex flex-col">
					<div
						className={`flex max-h-0 flex-col gap-8 rounded-[10px] bg-neutral-100 bg-opacity-60 opacity-0 transition-all duration-500 ${filterIsOpen && "max-h-fit p-6 opacity-100"}`}
					>
						{filterIsOpen && (
							<Form {...form}>
								<div className="flex w-full flex-col items-center gap-6 md:flex-row">
									<div className="relative w-full text-neutral-200 focus-within:text-neutral-1100">
										<Input
											placeholder="Pesquisar"
											className="h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:text-P5 placeholder:text-neutral-200"
											{...form.register("title")}
										/>
										<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

										{form.watch("title") !== undefined && (
											<button
												className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-200 text-neutral-1100"
												onClick={() => form.setValue("title", undefined)}
											>
												<CloseIcon />
											</button>
										)}
									</div>

									<FormField
										control={form.control}
										name="format"
										render={({ field }) => (
											<FormItem className="w-full md:w-auto">
												<Select
													onValueChange={(e) => {
														field.onChange(e);
													}}
													defaultValue={field.value}
													value={field.value}
												>
													<FormControl>
														<SelectTrigger className="w-full md:w-80">
															<SelectValue placeholder="Classificar por" />
														</SelectTrigger>
													</FormControl>
													<SelectContent>
														<SelectItem value="image">Imagem</SelectItem>
														<SelectItem value="text">
															Arquivo de texto
														</SelectItem>
														<SelectItem value="document">PDF</SelectItem>
													</SelectContent>
												</Select>
											</FormItem>
										)}
									/>
								</div>

								<div className="flex flex-col justify-between gap-6 md:flex-row md:items-center">
									<div>
										<span className="font-inter text-P5 font-normal leading-160 text-neutral-400">
											Nenhum filtro aplicado
										</span>
									</div>

									<div className="flex gap-4">
										<Button
											className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] bg-transparent font-inter !text-P6 font-semibold leading-160 text-neutral-600 transition-all duration-500 hover:bg-neutral-200 disabled:bg-transparent disabled:text-neutral-400"
											variant="secondary"
											onClick={() => {
												form.setValue("title", undefined);
												form.setValue("format", undefined);
												setFilterIsOpen(false);
											}}
										>
											Limpar filtro
										</Button>
										<Button
											className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] font-inter !text-P6 font-semibold leading-160"
											onClick={form.handleSubmit(async (data) => {
												await getMaterials(
													data.format,
													data?.title?.length ? data.title : undefined,
												);

												setFilterIsOpen(false);
											})}
										>
											Aplicar
										</Button>
									</div>
								</div>
							</Form>
						)}
					</div>

					<div
						className={`grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 ${
							filterIsOpen && "mt-10"
						}`}
					>
						{materials.length === 0 &&
							isLoading &&
							Array.from({ length: 6 }).map((_, index) => (
								<SkeletonMaterialCard key={index} />
							))}

						{materials.map((material, index) => (
							<MaterialCard
								key={index}
								title={material.title}
								type={material.format}
								link={material.link}
							/>
						))}
					</div>

					{materials.length === 0 && !isLoading && (
						<div className="flex flex-col items-center justify-center gap-12 md:px-56 md:py-36">
							<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
								<SearchIcon />
							</span>

							<div className="flex flex-col gap-2">
								<h3 className="w-[600px] text-center font-inter text-P2 font-medium leading-160 text-neutral-1100">
									No momento não há nenhum material disponível!
								</h3>

								<p className="text-center font-inter text-P3 font-normal leading-160 text-neutral-500">
									Caso ocorreu um erro de visualização pedimos que entre em
									contato com nosso time.
								</p>
							</div>
						</div>
					)}

					{materials.length > 0 && !isLoading && (
						<footer className="mt-10">
							<PaginationList
								currentPage={state.currentPage}
								lastPage={state.lastPage}
								to={materials.length}
								total={state.total}
								onNextPage={() => dispatch({ type: "NEXT_PAGE" })}
								onPageChange={(page) =>
									dispatch({ type: "PAGE_CHANGE", payload: { page } })
								}
								onPreviousPage={() => dispatch({ type: "PREVIOUS_PAGE" })}
							/>
						</footer>
					)}
				</section>

				<hr className="h-[2px] border-t-neutral-100" />

				<section className="flex flex-col gap-6">
					<div className="flex items-center justify-between">
						<h3 className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
							Veja nossos treinamentos
						</h3>

						<Link
							to="/academy/trainings"
							className="flex cursor-pointer items-center justify-center gap-1 font-inter text-P5 font-medium leading-160 text-neutral-600 transition-all duration-300 hover:text-neutral-1100 hover:underline hover:underline-offset-8"
						>
							<p>Ver todos</p>
							<ArrowRightIcon />
						</Link>
					</div>

					<Carousel>
						<CarouselContent className="-ml-8">
							{trainings.length === 0 &&
								Array.from({ length: 6 }).map((_, index) => (
									<SkeletonTrainingCard key={index} />
								))}

							{trainings.map((training) => (
								<CarouselItem className="basis-auto pl-8" key={training.id}>
									<TrainingCard
										id={training.id}
										author={training?.mentor?.name}
										avatar={training?.mentor?.image}
										image={training.image}
										modules={training.modulesCount}
										lessons={training.lessonsCount}
										title={training.name}
									/>
								</CarouselItem>
							))}
						</CarouselContent>
					</Carousel>
				</section>
			</div>
		</>
	);
}
