import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { addAcademyMentor } from "@/api/admin/academy/trainings/mentor/add-mentor";
import type { Mentor } from "@/api/admin/academy/trainings/mentor/list-mentors";
import type { Training } from "@/api/admin/academy/trainings/show-training";
import { updateAcademyTrainingAvailable } from "@/api/admin/academy/trainings/update-training-available";
import MathPlus from "@/assets/math-plus.svg?react";
import { FileCard } from "@/components/file-card";
import { FileUploader } from "@/components/file-uploader";
import { Modal } from "@/components/modal";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

interface AdminAcademyShowEditProps {
	title: string;
	training: Training;
	mentors: Mentor[];
	refreshMentors: () => void;
	closeModal: () => void;
	onSuccess: (data: {
		name: string;
		mentor: string;
		image: File | string;
	}) => void;
	isEdit?: boolean;
}

export function AdminAcademyShowEdit({
	title,
	training,
	mentors,
	closeModal,
	refreshMentors,
	onSuccess,
	isEdit = false,
}: AdminAcademyShowEditProps) {
	const [showMentorModal, setShowMentorModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const updateTraining = z.object({
		name: z.string().min(3, {
			message: "O nome do treinamento é obrigatório.",
		}),
		mentor: z.string().min(1, {
			message: "O mentor responsável é obrigatório.",
		}),
		image: z.union([z.instanceof(File), z.string()]).refine(
			(image) => {
				if (image instanceof File) {
					return image.size <= 4 * 1024 * 1024;
				}

				return true;
			},
			{
				message: "A imagem deve ter no máximo 4MB.",
			},
		),
	});

	type UpdateTraining = z.infer<typeof updateTraining>;

	const updateTrainingForm = useForm<UpdateTraining>({
		resolver: zodResolver(updateTraining),
		defaultValues: {
			name: training.name,
			mentor: training.mentor.id,
			image: training.image,
		},
	});

	const createMentor = z.object({
		name: z.string().min(1, {
			message: "O nome do mentor é obrigatório.",
		}),
		image: z.union([z.instanceof(File), z.string()]).refine(
			(image) => {
				if (image instanceof File) {
					return image.size <= 4 * 1024 * 1024;
				}

				return true;
			},
			{
				message: "A imagem deve ter no máximo 4MB.",
			},
		),
	});

	type CreateMentor = z.infer<typeof createMentor>;

	const createMentorForm = useForm<CreateMentor>({
		resolver: zodResolver(createMentor),
	});

	const [currentState, setCurrentState] = useState(training.available);

	return (
		<Modal
			title={showMentorModal ? "Adicionar mentor" : title}
			closeModal={closeModal}
			cancelAction={closeModal}
			confirmAction={() => {
				if (showMentorModal) {
					createMentorForm.handleSubmit(async (data) => {
						setIsLoading(true);
						const formData = new FormData();
						formData.append("name", data.name);

						if (data.image instanceof File) {
							formData.append("image", data.image);
						}

						const { status } = await addAcademyMentor(formData);

						if (status === "success") {
							refreshMentors();
							createMentorForm.reset();
							setIsLoading(false);
							setShowMentorModal(false);

							return;
						}
					})();
				}

				if (!showMentorModal) {
					updateTrainingForm.handleSubmit(async (data) => {
						onSuccess(
							data as {
								name: string;
								mentor: string;
								image: File | string;
							},
						);
					})();
				}
			}}
			backAction={() => {
				setShowMentorModal(false);
			}}
			showBackButton={showMentorModal}
			isLoading={isLoading}
			hasToggle={isEdit && !showMentorModal}
			toggleIsChecked={currentState}
			onToggleChange={async () => {
				setIsLoading(true);
				const response = await updateAcademyTrainingAvailable(
					{
						available: !currentState,
					},
					training.id,
				);

				if (response.status === "success") {
					setCurrentState(!currentState);

					setIsLoading(false);
				}

				setIsLoading(false);
			}}
		>
			{!showMentorModal && (
				<Form {...updateTrainingForm}>
					<div className="flex flex-col gap-8">
						<div className="flex w-full flex-col items-center gap-6">
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="name"
									type="text"
									label="Nome do treinamento"
									hasError={!!updateTrainingForm.formState.errors.name}
									{...updateTrainingForm.register("name")}
								/>

								{updateTrainingForm.formState.errors.name && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{updateTrainingForm.formState.errors.name.message}
									</p>
								)}
							</div>

							<div className="flex w-full flex-col gap-6 rounded-[8px] border border-solid border-neutral-200 p-6">
								<div className="flex justify-between">
									<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
										Mentor responsável
									</p>

									<button
										className="flex items-center justify-center gap-1 text-neutral-600 transition-all duration-300 hover:text-neutral-1100"
										onClick={() => setShowMentorModal(true)}
									>
										<MathPlus />

										<span className="font-inter text-P5 font-medium leading-160">
											Adicionar mentor
										</span>
									</button>
								</div>

								<FormField
									control={updateTrainingForm.control}
									name="mentor"
									render={({ field }) => (
										<FormItem className="w-full">
											<Select
												onValueChange={(e) => {
													field.onChange(e);
												}}
												defaultValue={field.value}
												value={field.value}
											>
												<FormControl>
													<SelectTrigger
														className="w-full"
														hasError={
															!!updateTrainingForm.formState.errors.mentor
														}
													>
														<SelectValue placeholder="Mentor responsável" />
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{mentors.map((mentor) => (
														<SelectItem key={mentor.id} value={mentor.id}>
															{mentor.name}
														</SelectItem>
													))}
												</SelectContent>
											</Select>

											{updateTrainingForm.formState.errors.mentor && (
												<p className="font-inter text-P6 font-normal leading-160 text-red-600">
													{updateTrainingForm.formState.errors.mentor.message}
												</p>
											)}
										</FormItem>
									)}
								/>
							</div>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex flex-col gap-2">
								<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Imagem do treinamento
								</p>

								<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Essa será a capa referente ao card do seu treinamento
								</span>
							</div>

							<div className="flex w-full flex-col gap-2">
								{typeof updateTrainingForm.watch("image") === "string" &&
								updateTrainingForm.watch("image") !== "" ? (
									<FileCard
										url={updateTrainingForm.watch("image") as string}
										onRemove={() => updateTrainingForm.setValue("image", "")}
									/>
								) : updateTrainingForm.watch("image") instanceof File ? (
									<FileCard
										file={updateTrainingForm.watch("image") as File}
										onRemove={() => updateTrainingForm.setValue("image", "")}
									/>
								) : (
									<FileUploader
										maxFiles={1}
										maxSize={10 * 1024 * 1024}
										accept={{ "image/*": [".png", ".jpeg", ".jpg"] }}
										onUpload={async (e) => {
											updateTrainingForm.setValue("image", e[0]);
										}}
										disabled={false}
									/>
								)}

								{updateTrainingForm.formState.errors.image && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{updateTrainingForm.formState.errors.image.message}
									</p>
								)}
							</div>
						</div>
					</div>
				</Form>
			)}

			{showMentorModal && (
				<Form {...createMentorForm}>
					<div className="flex flex-col gap-8">
						<div className="flex w-full flex-col items-center gap-6">
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="name"
									type="text"
									label="Nome do produto"
									hasError={!!createMentorForm.formState.errors.name}
									{...createMentorForm.register("name")}
								/>

								{createMentorForm.formState.errors.name && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{createMentorForm.formState.errors.name.message}
									</p>
								)}
							</div>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex flex-col gap-2">
								<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Imagem do mentor
								</p>

								<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Será a imagem referente ao perfil do mentor
								</span>
							</div>

							<div className="flex w-full flex-col gap-2">
								{typeof createMentorForm.watch("image") === "string" &&
								createMentorForm.watch("image") !== "" ? (
									<FileCard
										url={createMentorForm.watch("image") as string}
										onRemove={() => createMentorForm.setValue("image", "")}
									/>
								) : createMentorForm.watch("image") instanceof File ? (
									<FileCard
										file={createMentorForm.watch("image") as File}
										onRemove={() => createMentorForm.setValue("image", "")}
									/>
								) : (
									<FileUploader
										maxFiles={1}
										maxSize={10 * 1024 * 1024}
										accept={{ "image/*": [".png", ".jpeg", ".jpg"] }}
										onUpload={async (e) => {
											createMentorForm.setValue("image", e[0]);
										}}
										disabled={false}
									/>
								)}

								{createMentorForm.formState.errors.image && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{createMentorForm.formState.errors.image.message}
									</p>
								)}
							</div>
						</div>
					</div>
				</Form>
			)}
		</Modal>
	);
}
