import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { getPanelSales, type Sales } from "@/api/admin/panel/sales/list";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";

import { AdminPanelSalesAffiliates } from "./affiliates";
import { AdminPanelSalesPeriod } from "./period";
import { AdminPanelSalesProduct } from "./product";
import { AdminPanelSalesTotalSales } from "./total-sales";

export function AdminPanelSales() {
	const [isLoading, setIsLoading] = useState(false);
	const [sales, setSales] = useState<Sales | null>(null);
	const [periodState, setPeriodState] = useState<string>("last_7_days");

	async function fetchPanelSales() {
		setIsLoading(true);

		const period = {
			today: [dayjs().format("DD/MM/YYYY"), dayjs().format("DD/MM/YYYY")],
			last_7_days: [
				dayjs().subtract(7, "day").format("DD/MM/YYYY"),
				dayjs().format("DD/MM/YYYY"),
			],
			last_30_days: [
				dayjs().subtract(30, "day").format("DD/MM/YYYY"),
				dayjs().format("DD/MM/YYYY"),
			],
			this_month: [
				dayjs().startOf("month").format("DD/MM/YYYY"),
				dayjs().format("DD/MM/YYYY"),
			],
			last_month: [
				dayjs().subtract(1, "month").startOf("month").format("DD/MM/YYYY"),
				dayjs().subtract(1, "month").endOf("month").format("DD/MM/YYYY"),
			],
		};

		const response = await getPanelSales({
			period: period[periodState as keyof typeof period],
		});

		if (response.status === "success") {
			setSales(response.data);
		}

		setIsLoading(false);
	}

	useEffect(() => {
		fetchPanelSales();
	}, [periodState]);

	return (
		<div className="flex flex-col gap-12">
			{isLoading ? (
				<div className="flex flex-col gap-8">
					<div className="mb-4 flex items-center justify-between">
						<Skeleton className="h-12 w-full" />
					</div>

					{/* Skeleton para os gráficos e outros componentes */}
					<Skeleton className="h-96 w-full rounded-[8px]" />

					<section className="flex flex-col gap-8 md:flex-row">
						<Skeleton className="h-96 w-full rounded-[8px]" />
						<Skeleton className="h-96 w-full rounded-[8px]" />
					</section>

					<div className="rounded-[8px] border border-solid border-neutral-100">
						<Skeleton className="h-96 w-full" />
					</div>
				</div>
			) : (
				// Quando os dados estiverem carregados, exibir os componentes reais
				sales && (
					<>
						<Select
							onValueChange={(value) => setPeriodState(value)}
							defaultValue={periodState}
							value={periodState}
						>
							<SelectTrigger className="w-full">
								<SelectValue placeholder="Selecione um período" />
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="today">Hoje</SelectItem>
								<SelectItem value="last_7_days">Últimos 7 dias</SelectItem>
								<SelectItem value="last_30_days">Últimos 30 dias</SelectItem>
								<SelectItem value="this_month">Este mês</SelectItem>
								<SelectItem value="last_month">Mês passado</SelectItem>
							</SelectContent>
						</Select>

						<div className="flex flex-col gap-8">
							<AdminPanelSalesTotalSales
								sales_count_by_platform={
									sales.sales_count_by_platform || [
										{
											platform: "Braip",
											count: 0,
										},
										{
											platform: "Monetizze",
											count: 0,
										},
										{
											platform: "The Mart",
											count: 0,
										},
									]
								}
							/>

							<AdminPanelSalesPeriod
								data_platform_sales={sales.data_platform_sales}
							/>

							<AdminPanelSalesProduct
								data_platform_sales_by_product={
									sales.data_platform_sales_by_product
								}
							/>

							<AdminPanelSalesAffiliates />
						</div>
					</>
				)
			)}
		</div>
	);
}
