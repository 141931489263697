import { Link, useLocation } from "react-router-dom";

type Tab = {
	title: string;
	to: string;
};

interface TabNavigations {
	tabs: Tab[];
}

export function TabNavigation({ tabs }: TabNavigations) {
	return (
		<ul className="flex gap-8 border-b border-b-neutral-100">
			{tabs.map((tab, index) => (
				<Tab key={index} {...tab} />
			))}
		</ul>
	);
}

function Tab({ to, title }: Tab) {
	const location = useLocation();

	return (
		<li
			className={`flex items-center justify-center gap-1 pb-6 pt-4 ${
				to === location.pathname && "border-b-2 border-primary-600"
			}`}
		>
			<Link
				to={to}
				className={`font-inter text-P3 font-normal leading-160 text-neutral-500 transition-all duration-300 ${
					to === location.pathname && "!font-medium !text-neutral-1100"
				}`}
			>
				{title}
			</Link>
		</li>
	);
}
