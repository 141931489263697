import { ReactNode } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import { signOut } from "@/api/auth/sign-out";
import ChevronRightIcon from "@/assets/chevron-right.svg?react";
import ControllerIcon from "@/assets/controller.svg?react";
import LockIcon from "@/assets/lock-2.svg?react";
import LogoutIcon from "@/assets/log-out.svg?react";
import ProfileIcon from "@/assets/profile.svg?react";
import TrophyIcon from "@/assets/trophy.svg?react";
import { Tag } from "@/components/tag";
import { useAuthStore } from "@/store/auth";

export function Account() {
	const navigate = useNavigate();
	const { logout } = useAuthStore();

	async function handleLogout() {
		const response = await signOut();

		if (response.status === "success") {
			logout();
			navigate("/sign-in");
		}
	}
	return (
		<div className="relative flex gap-0 lg:gap-16">
			<section className="sticky top-32 hidden h-fit min-w-72 flex-col gap-8 rounded-xl bg-neutral-100 p-6 lg:flex">
				<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
					Minha conta
				</p>

				<nav className="flex flex-col gap-4">
					<ListItem to="/account" name="Perfil">
						<ProfileIcon />
					</ListItem>

					<ListItem to="/account/password" name="Senha">
						<LockIcon />
					</ListItem>

					<ListItem to="/account/achievements" name="Conquistas">
						<TrophyIcon />
					</ListItem>

					<ListItem to="/account/integrations" name="Integrações">
						<ControllerIcon />
					</ListItem>

					<hr className="h-px border-t-neutral-200" />

					<button
						className="flex h-12 cursor-pointer items-center gap-3 p-3 text-neutral-500 transition-all duration-300 hover:text-neutral-1100"
						onClick={handleLogout}
					>
						<LogoutIcon />

						<p className="font-inter text-P5 font-normal leading-160">Sair</p>
					</button>
				</nav>
			</section>

			<section className="w-full lg:pl-8">
				<Outlet />
			</section>
		</div>
	);
}

function ListItem({
	name,
	to,
	children,
	disabled = false,
}: {
	name: string;
	to: string;
	children: ReactNode;
	disabled?: boolean;
}) {
	const location = useLocation();

	function isActive() {
		return location.pathname === to;
	}

	return (
		<Link
			to={!disabled ? to : "/profile"}
			className={`flex items-center justify-between rounded-[8px] px-2 py-3 text-neutral-400 transition-all duration-300 hover:bg-neutral-200 hover:text-white ${
				isActive() && "bg-neutral-200	!text-white"
			} ${disabled && "cursor-not-allowed !bg-neutral-100 !text-neutral-200"}
			`}
		>
			<div className={`flex flex-1 items-center gap-3`}>
				{children}

				<p className="font-inter text-P5 font-medium leading-160">{name}</p>
			</div>

			{!disabled && <ChevronRightIcon width={20} height={20} />}

			{disabled && (
				<Tag
					size="small"
					colors="minimalist-grey"
					className="px-[6px] py-1 text-[10px]"
				>
					Em breve
				</Tag>
			)}
		</Link>
	);
}
