import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useHookFormMask } from "use-mask-input";
import { z } from "zod";

import { updateAvatar } from "@/api/app/account/update-avatar";
import { updateProfile } from "@/api/app/account/update-profile";
import InfoIcon from "@/assets/info.svg?react";
import PenIcon from "@/assets/pen.svg?react";
import PinIcon from "@/assets/pin.svg?react";
import ProfileIcon from "@/assets/profile.svg?react";
// import UserIcon from "@/assets/user.svg?react";
import ProfileWaterMark from "@/assets/watermark.svg?react";
import { AlertModal } from "@/components/alert-modal";
import { Tag } from "@/components/tag";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { env } from "@/env";
import { useAuthStore } from "@/store/auth";
import { useNavigationStore } from "@/store/navigation-store";
import { formatDateSince } from "@/utils/date-format";
import { authorFallback } from "@/utils/mask-author-fallback";
import { maskCapitalized } from "@/utils/mask-capitalized";
import { uf } from "@/utils/uf";

export function AccountProfile() {
	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Home",
				path: "/",
			},
			{
				name: `Minha conta`,
				path: `/account`,
			},
			{
				name: `Perfil`,
				path: `/account/profile`,
			},
		];

		setPaths(paths);
	}, []);

	const profileForm = z.object({
		name: z
			.string({
				required_error: "Você precisa digitar seu nome completo",
			})
			.min(1, {
				message: "Você precisa digitar seu nome completo",
			}),
		nickname: z
			.string({
				required_error: "Você precisa digitar seu nome de perfil",
			})
			.min(1, {
				message: "Você precisa digitar seu nome de perfil",
			}),
		email: z
			.string({
				required_error: "Você precisa digitar seu e-mail",
			})
			.email({
				message: "Você precisa digitar um e-mail válido",
			}),
		phone: z
			.string({
				required_error: "Você precisa digitar seu WhatsApp",
			})
			.refine(
				(value) => {
					const unmaskedPhone = value.replace(/\D/g, "");
					return unmaskedPhone.length === 11;
				},
				{
					message: "O número de WhatsApp deve estar no formato válido",
				},
			),
		cep: z
			.string({
				required_error: "Você precisa digitar seu CEP",
			})
			.refine(
				(value) => {
					const unmaskedCep = value.replace(/\D/g, "");
					return unmaskedCep.length === 8;
				},
				{
					message: "O CEP deve estar no formato válido",
				},
			),
		address: z
			.string({
				required_error: "Você precisa digitar seu endereço",
			})
			.min(1, {
				message: "Você precisa digitar seu endereço",
			}),
		number: z
			.string({
				required_error: "Você precisa digitar o número da sua casa",
			})
			.min(1, {
				message: "Você precisa digitar o número da sua casa",
			}),
		complement: z.string().optional(),
		district: z.string().nullable(),
		uf: z.string({
			required_error: "Você precisa selecionar o seu estado",
		}),
		city: z.string({
			required_error: "Você precisa selecionar a sua cidade",
		}),
	});

	type ProfileForm = z.infer<typeof profileForm>;

	const form = useForm<ProfileForm>({
		resolver: zodResolver(profileForm),
	});

	const registerWithMask = useHookFormMask(form.register);

	type City = {
		codigo_ibge: string;
		nome: string;
	};

	const [cities, setCities] = useState<City[]>([]);

	async function getMunicipios(uf: string) {
		const response = await axios.get(
			`${env.VITE_BRASIL_API_URL}/ibge/municipios/v1/${uf}?providers=dados-abertos-br,gov,wikipedia`,
		);

		if (response.status === 200) {
			const data = response.data.map((city: City) => {
				return {
					codigo_ibge: city.codigo_ibge,
					nome: maskCapitalized(city.nome),
				};
			});
			setCities(data);

			return data;
		}
	}

	async function getByCep(cep: string) {
		try {
			await axios
				.get(`${env.VITE_BRASIL_API_URL}/cep/v1/${cep}`)
				.then(async (response) => {
					form.setValue("uf", response.data.state);

					await getMunicipios(response.data.state).then((res: City[]) => {
						const city = res.find(
							(city) => city.nome === maskCapitalized(response.data.city),
						);

						if (!city) return;

						form.setValue("city", city.nome);
					});

					form.setValue("district", response.data.neighborhood);

					form.setValue("address", response.data.street);
				});
		} catch (error) {
			console.error(error);
		}
	}

	const { profile } = useAuthStore();

	useEffect(() => {
		if (profile) {
			form.setValue("name", profile.name);
			form.setValue("nickname", profile.nickname);
			form.setValue("email", profile.email);
			form.setValue("phone", profile.phone);
			form.setValue("cep", profile.address?.zipcode);
			form.setValue("address", profile.address?.street);
			form.setValue("number", profile.address?.number);
			form.setValue("complement", profile.address?.complement ?? "");
			form.setValue("uf", profile.address?.state);
			form.setValue("city", profile.address?.city);
			setImagePreview(profile.image ?? "");

			if (profile.address?.zipcode) {
				getByCep(profile.address?.zipcode);
			}
		}
	}, [profile]);

	async function handleUpdateProfile(data: ProfileForm, id: string) {
		setIsLoading(true);
		const response = await updateProfile(
			{
				city: data.city,
				complement: data.complement,
				email: data.email,
				name: data.name,
				nickname: data.nickname,
				number: data.number,
				phone: data.phone,
				state: data.uf,
				street: data.address,
				district: data.address,
				zipcode: data.cep,
			},
			id,
		);

		if (response.status === "success") {
			setFinalModal(true);
			setConfirmationModal(false);
			setIsLoading(false);

			if (fileInputRef.current?.files?.length) {
				const formData = new FormData();
				formData.append("image", fileInputRef.current.files[0]);

				await updateAvatar(formData, id);
			}

			return;
		}

		if (response.status === "error") {
			const { errors } = response;

			for (const key in errors) {
				form.setError(key as keyof ProfileForm, {
					type: "manual",
					message: errors[key],
				});
			}

			setConfirmationModal(false);
			setIsLoading(false);
			return;
		}
	}

	const [confirmationModal, setConfirmationModal] = useState(false);
	const [finalModal, setFinalModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();

	const [imagePreview, setImagePreview] = useState(profile?.image ?? "");
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const handleAvatarClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (event) => {
				if (event.target?.result) {
					setImagePreview(event.target.result.toString());
				}
			};
			reader.readAsDataURL(file);
		}
	};

	return (
		<>
			<Helmet title="Perfil" />
			<Form {...form}>
				<form
					className="flex flex-col gap-10 md:gap-12"
					onSubmit={form.handleSubmit(async () => {
						setConfirmationModal(true);
					})}
				>
					<div className="relative h-full w-full rounded-xl border border-neutral-100">
						<div className="flex h-[98px] items-center justify-end rounded-xl rounded-b-none bg-primary-1000">
							<ProfileWaterMark />
						</div>
						<div className="-mt-[60px] flex flex-col gap-10 px-6 pb-6 md:-mt-5 md:flex-row md:items-center md:px-10 md:pb-10">
							<div className="relative">
								<Avatar className="size-28 border-[6px] border-neutral-0">
									<AvatarImage src={imagePreview} />
									<AvatarFallback>
										{authorFallback(profile?.nickname)}
									</AvatarFallback>
								</Avatar>

								<button
									className="absolute bottom-3 right-0 flex size-10 cursor-pointer items-center justify-center rounded-full bg-neutral-200 p-2 text-neutral-1100 transition-all duration-300 hover:bg-opacity-90"
									type="button"
									onClick={handleAvatarClick}
								>
									<PenIcon width={16} height={16} />
								</button>
								<input
									type="file"
									ref={fileInputRef}
									accept="image/*"
									onChange={handleFileChange}
									className="hidden"
								/>
							</div>

							<div className="flex w-full flex-col gap-4">
								<Tag
									size="small"
									colors="green"
									className="max-w-[134px] rounded-[4px] px-[6px] py-1 font-inter text-P5 font-medium leading-160"
								>
									Membro fundador
								</Tag>

								<div className="flex flex-col justify-between gap-8 md:flex-row md:items-center md:gap-12">
									<div className="flex flex-col gap-1">
										<p className="overflow-hidden text-ellipsis font-inter text-P1 font-semibold leading-140 text-neutral-1100">
											{profile?.name}
										</p>
										<span className="font-inter text-P6 font-normal leading-160 text-neutral-600">
											Membro desde {formatDateSince(profile?.created_at ?? "")}
										</span>
									</div>

									{/* <button className="flex h-12 w-full items-center justify-center gap-2 rounded-[8px] bg-neutral-200 px-6 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40 md:w-auto">
										<UserIcon />

										<p className="font-inter text-P5 font-semibold leading-160">
											ID Card
										</p>
									</button> */}
								</div>
							</div>
						</div>
					</div>

					<div className="flex flex-col gap-8">
						<div className="flex items-center gap-3 text-neutral-1100">
							<ProfileIcon width={20} height={20} />

							<p className="font-inter text-P3 font-medium leading-160">
								Dados pessoais
							</p>
						</div>

						<div className="grid gap-6 md:grid-cols-2">
							<div className="flex flex-col gap-2">
								<FloatingLabelInput
									id="name"
									type="text"
									label="Nome"
									bgColor="!bg-neutral-0"
									hasError={!!form.formState.errors.name}
									{...form.register("name")}
								/>

								{form.formState.errors.name && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.name.message}
									</p>
								)}
							</div>

							<div className="flex flex-col gap-2">
								<FloatingLabelInput
									id="nickname"
									type="text"
									label="Nome do perfil"
									bgColor="!bg-neutral-0"
									hasError={!!form.formState.errors.nickname}
									{...form.register("nickname")}
								/>

								{form.formState.errors.nickname && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.nickname.message}
									</p>
								)}
							</div>

							<div className="flex flex-col gap-2">
								{form.watch("phone") && (
									<FloatingLabelInput
										id="phone"
										type="text"
										bgColor="!bg-neutral-0"
										hasError={!!form.formState.errors.phone}
										label="WhatsApp"
										{...registerWithMask("phone", ["99 99999-9999"], {
											onBlur() {
												const value = form.getValues("phone");
												const unmaskedValue = value.replace(/\D/g, "");

												if (unmaskedValue.length === 11) {
													form.clearErrors("phone");
												}
											},
										})}
									/>
								)}

								{form.formState.errors.phone && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.phone.message}
									</p>
								)}
							</div>

							<div className="flex flex-col gap-2">
								<FloatingLabelInput
									id="email"
									bgColor="!bg-neutral-0"
									hasError={!!form.formState.errors.email}
									type="email"
									label="E-mail"
									{...form.register("email")}
								/>

								{form.formState.errors.email && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.email.message}
									</p>
								)}
							</div>
						</div>
					</div>

					<hr className="h-px border-t-neutral-200" />

					<div className="mb-28 flex flex-col gap-8">
						<div className="flex items-center gap-3 text-neutral-1100">
							<PinIcon width={20} height={20} />

							<p className="font-inter text-P3 font-medium leading-160">
								Endereço
							</p>
						</div>

						<div className="flex flex-col gap-6">
							<div className="flex flex-col gap-6 md:flex-row">
								<div className="flex min-w-48 flex-col gap-2">
									<FloatingLabelInput
										id="cep"
										type="text"
										bgColor="!bg-neutral-0"
										hasError={!!form.formState.errors.cep}
										label="CEP"
										{...registerWithMask("cep", ["99999-999"], {
											onBlur() {
												const value = form.getValues("cep");
												const unmaskedValue = value.replace(/\D/g, "");

												if (unmaskedValue.length === 8) {
													form.clearErrors("cep");

													getByCep(value);
												}
											},
										})}
									/>

									{form.formState.errors.cep && (
										<p className="font-inter text-P6 font-normal leading-160 text-red-600">
											{form.formState.errors.cep.message}
										</p>
									)}
								</div>

								<FormField
									control={form.control}
									name="uf"
									render={({ field }) => (
										<FormItem className="w-full">
											<Select
												onValueChange={(uf) => {
													field.onChange(uf);

													form.setValue("city", "");

													getMunicipios(uf);
												}}
												defaultValue={field.value}
												value={field.value}
											>
												<FormControl>
													<SelectTrigger
														className="w-full"
														hasError={!!form.formState.errors.uf}
													>
														<SelectValue placeholder="Selecione o seu estado" />
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{uf.map((state) => {
														return (
															<SelectItem key={state.sigla} value={state.sigla}>
																{state.nome}
															</SelectItem>
														);
													})}
												</SelectContent>
											</Select>

											{form.formState.errors.uf && (
												<p className="font-inter text-P6 font-normal leading-160 text-red-600">
													{form.formState.errors.uf.message}
												</p>
											)}
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="city"
									render={({ field }) => (
										<FormItem className="w-full">
											<Select
												onValueChange={(e) => {
													field.onChange(e || form.getValues("city"));
												}}
												defaultValue={field.value}
												value={field.value}
											>
												<FormControl>
													<SelectTrigger
														className="w-full"
														hasError={!!form.formState.errors.city}
													>
														<SelectValue placeholder="Selecione sua cidade" />
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{cities.map((state) => {
														return (
															<SelectItem
																key={state.codigo_ibge}
																value={state.nome}
															>
																{state.nome}
															</SelectItem>
														);
													})}
												</SelectContent>
											</Select>

											{form.formState.errors.city && (
												<p className="font-inter text-P6 font-normal leading-160 text-red-600">
													{form.formState.errors.city.message}
												</p>
											)}
										</FormItem>
									)}
								/>
							</div>

							<div className="flex flex-col gap-6 md:flex-row">
								<div className="flex w-full flex-col gap-2">
									<FloatingLabelInput
										id="address"
										type="text"
										label="Rua"
										bgColor="!bg-neutral-0"
										hasError={!!form.formState.errors.address}
										{...form.register("address")}
									/>

									{form.formState.errors.address && (
										<p className="font-inter text-P6 font-normal leading-160 text-red-600">
											{form.formState.errors.address.message}
										</p>
									)}
								</div>

								<div className="flex w-full gap-6">
									<div className="flex w-full flex-col gap-2 md:w-auto md:min-w-28">
										<FloatingLabelInput
											id="number"
											type="text"
											label="Número"
											bgColor="!bg-neutral-0"
											hasError={!!form.formState.errors.number}
											{...form.register("number")}
										/>

										{form.formState.errors.number && (
											<p className="font-inter text-P6 font-normal leading-160 text-red-600">
												{form.formState.errors.number.message}
											</p>
										)}
									</div>

									<div className="flex w-full flex-col gap-2">
										<FloatingLabelInput
											id="complement"
											type="text"
											label="Complemento"
											bgColor="!bg-neutral-0"
											hasError={!!form.formState.errors.complement}
											{...form.register("complement")}
										/>

										{form.formState.errors.complement && (
											<p className="font-inter text-P6 font-normal leading-160 text-red-600">
												{form.formState.errors.complement.message}
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>

					<footer className="fixed bottom-0 left-0 right-0 z-[6] flex items-center justify-between bg-neutral-0 px-8 pb-8 pt-4 xl:left-[257px]">
						<button className="hidden h-10 items-center justify-center gap-2 px-6 text-neutral-600 transition-all duration-300 hover:text-neutral-1100 md:flex">
							<InfoIcon width={18} height={18} />
							<p className="font-inter text-P6 font-semibold leading-160">
								Precisa de ajuda?
							</p>
						</button>

						<div className="flex w-full items-center justify-center gap-4 md:w-auto">
							<Button
								className="flex !h-12 w-full items-center justify-center gap-2 rounded-[8px] !bg-neutral-200 font-inter !text-P5 font-semibold leading-160 text-neutral-1100 transition-all duration-500 hover:!bg-opacity-40 md:!w-[140px]
						"
								variant="secondary"
								type="button"
								onClick={() => {
									navigate(-1);
								}}
							>
								Cancelar
							</Button>
							<Button
								className="flex !h-12 w-full items-center justify-center gap-2 rounded-[8px] font-inter !text-P5 font-semibold leading-160 md:!w-[140px]"
								type="submit"
							>
								Salvar
							</Button>
						</div>
					</footer>
				</form>
			</Form>

			{confirmationModal && (
				<AlertModal
					title="Deseja realmente salvar as alterações feitas em sua conta?"
					description="Seus dados anteriores não serão salvos."
					icon="user.svg"
					confirmText="Salvar"
					isLoading={isLoading}
					cancelAction={() => setConfirmationModal(false)}
					confirmAction={() => {
						handleUpdateProfile(form.getValues(), profile?.id ?? "");
					}}
					closeModal={() => setConfirmationModal(false)}
				/>
			)}

			{finalModal && (
				<AlertModal
					title="As alterações em sua conta foram as com sucesso!"
					icon="done.svg"
					confirmText="Concluir"
					confirmAction={() => {
						setFinalModal(false);
					}}
					hasCancel={false}
					closeModal={() => setFinalModal(false)}
				/>
			)}
		</>
	);
}
