import { Skeleton } from "@/components/ui/skeleton";

export function SkeletonAdminProductsShowDetails() {
	return (
		<div className="mb-28 flex flex-col gap-8">
			<div className="flex w-full flex-col items-center gap-6">
				<div className="flex w-full flex-col gap-2">
					<Skeleton className="h-10 w-full rounded" />
				</div>

				<div className="flex w-full gap-6">
					<Skeleton className="h-10 w-full rounded" />
					<Skeleton className="h-10 w-full rounded" />
				</div>
			</div>

			<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
				<div className="flex flex-col gap-2">
					<Skeleton className="h-6 w-1/3 rounded" />
					<Skeleton className="h-4 w-1/2 rounded" />
				</div>

				<div className="w-full">
					<Skeleton className="h-40 w-full rounded" />
				</div>
			</div>

			<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
				<div className="flex flex-col gap-2">
					<Skeleton className="h-6 w-1/3 rounded" />
					<Skeleton className="h-4 w-1/2 rounded" />
				</div>

				<div className="flex w-full flex-col gap-2">
					<Skeleton className="h-10 w-full rounded" />
					<Skeleton className="h-5 w-1/2 rounded" />
				</div>
			</div>

			<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
				<Skeleton className="h-6 w-1/3 rounded" />

				<div className="w-full">
					{Array.from({ length: 3 }).map((_, index) => (
						<div key={index} className="mb-4 flex flex-col gap-4">
							<Skeleton className="h-20 w-full rounded" />
						</div>
					))}
				</div>
			</div>

			<footer className="fixed bottom-0 left-0 right-0 z-[6] flex items-center justify-end bg-neutral-0 px-8 pb-8 pt-4 xl:left-[257px]">
				<div className="flex w-full items-center justify-center gap-4 md:w-auto">
					<Skeleton className="h-12 w-24 rounded" />
					<Skeleton className="h-12 w-24 rounded" />
				</div>
			</footer>
		</div>
	);
}
