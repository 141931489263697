import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { addAcademyMentor } from "@/api/admin/academy/trainings/mentor/add-mentor";
import type { Mentor } from "@/api/admin/academy/trainings/mentor/list-mentors";
import { updateAcademyMentor } from "@/api/admin/academy/trainings/mentor/update-mentor";
import CloseIcon from "@/assets/close.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import { AdminMentorCard } from "@/components/admin-mentor-card";
import { FileCard } from "@/components/file-card";
import { FileUploader } from "@/components/file-uploader";
import { Modal } from "@/components/modal";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form } from "@/components/ui/form";
import { Input } from "@/components/ui/input";

interface AdminAcademyTrainingsEditMentorsProps {
	title: string;
	mentors: Mentor[];
	selectedMentor: Mentor | null;
	setSelectedMentor: (mentor: Mentor | null) => void;
	closeModal: () => void;
	onSuccess: () => void;
	onDelete: () => void;
}

export function AdminAcademyTrainingsEditMentors({
	title,
	mentors,
	closeModal,
	onSuccess,
	onDelete,
	selectedMentor,
	setSelectedMentor,
}: AdminAcademyTrainingsEditMentorsProps) {
	const [showMentorModal, setShowMentorModal] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [filteredMentors, setFilteredMentors] = useState(mentors);
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	function handleSearch(searchTerm: string) {
		setSearchTerm(searchTerm);

		const filtered = mentors.filter((mentor) =>
			mentor.name.toLowerCase().includes(searchTerm.toLowerCase()),
		);

		setFilteredMentors(filtered);
	}

	const filter = z.object({
		name: z.string().min(3, {
			message: "O nome do mentor é obrigatório.",
		}),
		image: z.union([z.instanceof(File), z.string()]).refine(
			(image) => {
				if (image instanceof File) {
					return image.size <= 4 * 1024 * 1024;
				}

				return true;
			},
			{
				message: "A imagem deve ter no máximo 4MB.",
			},
		),
	});

	type Filter = z.infer<typeof filter>;

	const form = useForm<Filter>({
		resolver: zodResolver(filter),
	});

	return (
		<Modal
			title={showMentorModal ? "Adicionar mentor" : title}
			closeModal={closeModal}
			cancelAction={closeModal}
			confirmText={showMentorModal ? "Salvar" : "Adicionar mentor"}
			isLoading={isLoading}
			confirmAction={() => {
				if (showMentorModal) {
					form.handleSubmit(async (data) => {
						setIsLoading(true);
						const formData = new FormData();
						formData.append("name", data.name);

						if (data.image instanceof File) {
							formData.append("image", data.image);
						}

						if (isEditing && selectedMentor) {
							formData.append("link", data.image);

							const { status } = await updateAcademyMentor(
								selectedMentor.id,
								formData,
							);

							if (status === "success") {
								setIsLoading(false);
								onSuccess();

								return;
							}

							return;
						}

						// Add mentor
						const { status } = await addAcademyMentor(formData);

						if (status === "success") {
							setIsLoading(false);
							onSuccess();

							return;
						}

						setIsLoading(false);
					})();
				} else {
					setShowMentorModal(true);
				}
			}}
			backAction={() => {
				if (showMentorModal) {
					form.clearErrors();
					form.reset();
					setSelectedMentor(null);
					setShowMentorModal(false);
					setIsEditing(false);
				}
			}}
			showBackButton={showMentorModal}
		>
			{!showMentorModal && (
				<div className="flex flex-col gap-8">
					<div
						className={`relative ${
							filteredMentors.length > 0
								? "text-neutral-600"
								: "text-neutral-200"
						} focus-within:text-neutral-1100`}
					>
						<Input
							placeholder="Pesquise pelo nome"
							className={`h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:!text-P5  ${
								filteredMentors.length > 0
									? "placeholder:text-neutral-600"
									: "placeholder:text-neutral-200"
							}`}
							onChange={(e) => handleSearch(e.target.value)}
							value={searchTerm}
						/>
						<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

						{searchTerm && (
							<button
								className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-200 text-neutral-1100"
								onClick={() => handleSearch("")}
							>
								<CloseIcon />
							</button>
						)}
					</div>

					<div className="flex flex-col gap-4">
						{filteredMentors.map((mentor) => (
							<AdminMentorCard
								key={mentor.id}
								avatar={mentor.image}
								name={mentor.name}
								onEdit={() => {
									setIsEditing(true);
									setShowMentorModal(true);

									form.setValue("name", mentor.name);
									form.setValue("image", mentor.image);
								}}
								onRemove={() => {
									setSelectedMentor(mentor);
									onDelete();
								}}
							/>
						))}

						{!filteredMentors.length && (
							<div className="flex flex-col items-center justify-center gap-6 py-14">
								<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
									<SearchIcon />
								</span>
								<h3 className="w-[240px] text-center font-inter font-medium leading-160 text-neutral-1100">
									{searchTerm
										? "Nenhum mentor encontrado"
										: "Nenhum mentor cadastrado"}
								</h3>
							</div>
						)}
					</div>
				</div>
			)}

			{showMentorModal && (
				<Form {...form}>
					<div className="flex flex-col gap-8">
						<div className="flex w-full flex-col items-center gap-6">
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="name"
									type="text"
									label="Nome do produto"
									hasError={!!form.formState.errors.name}
									{...form.register("name")}
								/>

								{form.formState.errors.name && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.name.message}
									</p>
								)}
							</div>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex flex-col gap-2">
								<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Imagem do mentor
								</p>

								<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Será a imagem referente ao perfil do mentor
								</span>
							</div>

							<div className="flex w-full flex-col gap-2">
								{typeof form.watch("image") === "string" &&
								form.watch("image") !== "" ? (
									<FileCard
										url={form.watch("image") as string}
										onRemove={() => form.setValue("image", "")}
									/>
								) : form.watch("image") instanceof File ? (
									<FileCard
										file={form.watch("image") as File}
										onRemove={() => form.setValue("image", "")}
									/>
								) : (
									<FileUploader
										maxFiles={1}
										maxSize={10 * 1024 * 1024}
										accept={{ "image/*": [".png", ".jpeg", ".jpg"] }}
										onUpload={async (e) => {
											form.setValue("image", e[0]);
										}}
										disabled={false}
									/>
								)}

								{form.formState.errors.image && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.image.message}
									</p>
								)}
							</div>
						</div>
					</div>
				</Form>
			)}
		</Modal>
	);
}
