import { Link } from "react-router-dom";

interface ProductCardProps {
	id: string;
	name: string;
	type: string;
	image: string;
}

export function ProductCard({ id, name, type, image }: ProductCardProps) {
	return (
		<Link
			to={`/products/${id}`}
			className="flex w-full max-w-[256px] flex-col rounded-xl bg-neutral-100 bg-opacity-40 transition-all duration-300 hover:bg-opacity-100 sm:w-full"
		>
			<img
				src={image}
				alt={name}
				className="mx-4 mt-4 h-[164px] w-[224px] rounded-xl object-cover"
			/>

			<div className="flex flex-col gap-1 p-6">
				<span className="font-inter text-P5 font-normal leading-160 text-neutral-1100">
					{type}
				</span>

				<p className="line-clamp-1 text-ellipsis font-inter text-P4 font-semibold leading-160 text-neutral-1100">
					{name}
				</p>
			</div>
		</Link>
	);
}
