import { useState } from "react";

import FileIcon from "@/assets/file.svg?react";
import ImageIcon from "@/assets/image.svg?react";
import MailIcon from "@/assets/mail.svg?react";
import PenIcon from "@/assets/pen.svg?react";
import PlayIcon from "@/assets/play.svg?react";
import TrashIcon from "@/assets/trash.svg?react";

interface AdminMaterialCardProps {
	name: string;
	type: "image" | "document" | "text" | "link";
	onEdit: () => void;
	onRemove: () => void;
}

export function AdminMaterialCard({
	name,
	type,
	onEdit,
	onRemove,
}: AdminMaterialCardProps) {
	const [isPenHovered, setIsPenHovered] = useState(false);
	const [isTrashHovered, setIsTrashHovered] = useState(false);

	const types = {
		image: "Arquivo de imagem",
		document: "Arquivo PDF",
		text: "Arquivo de texto",
		link: "Arquivo de vídeo",
	};

	return (
		<div className="flex items-center justify-between rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-4">
			<div className="flex flex-1 space-x-4">
				<div className="flex size-12 items-center justify-center rounded-[10px] border border-primary-1000 bg-primary-600 bg-opacity-[0.04] p-3 text-primary-600">
					{type === "image" && <ImageIcon width={24} height={24} />}

					{type === "document" && <FileIcon width={24} height={24} />}

					{type === "text" && <MailIcon width={24} height={24} />}

					{type === "link" && <PlayIcon width={24} height={24} />}
				</div>

				<div className="flex w-full flex-col gap-2">
					<div className="flex flex-col gap-[2px]">
						<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
							{types[type]}
						</span>
						<p className="line-clamp-1 font-inter text-P5 font-medium leading-160 text-neutral-1100">
							{name}
						</p>
					</div>
				</div>
			</div>

			<div className="flex items-center gap-1">
				<button
					className={`flex items-center justify-center rounded-[6px] p-3
						${isPenHovered ? "bg-neutral-200 text-neutral-1100" : "text-neutral-600"}
						`}
					onClick={onEdit}
					onMouseEnter={() => setIsPenHovered(true)}
					onMouseLeave={() => setIsPenHovered(false)}
				>
					<PenIcon />
				</button>

				<button
					className={`flex items-center justify-center rounded-[6px] p-3
						${isTrashHovered ? "bg-neutral-200 text-neutral-1100" : "text-neutral-600"}
						`}
					onClick={onRemove}
					onMouseEnter={() => setIsTrashHovered(true)}
					onMouseLeave={() => setIsTrashHovered(false)}
				>
					<TrashIcon />
				</button>
			</div>
		</div>
	);
}
