import { useMemo } from "react";
import { Bar, BarChart, XAxis, YAxis } from "recharts";

import { Card, CardContent, CardHeader } from "@/components/ui/card";
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart";

const chartConfig = {
	theMart: { label: "The Mart", color: "hsl(var(--the-mart))" },
	braip: { label: "Braip", color: "hsl(var(--braip))" },
	monetizze: { label: "Monetizze", color: "hsl(var(--monetizze))" },
} satisfies ChartConfig;

type Platform = "Braip" | "Monetizze" | "The Mart";

interface AdminPanelSalesProductProps {
	data_platform_sales_by_product: {
		[product: string]: {
			platforms: Record<Platform, number>[];
			count: number;
		};
	};
}

export function AdminPanelSalesProduct({
	data_platform_sales_by_product,
}: AdminPanelSalesProductProps) {
	const chartData = useMemo(() => {
		// Filter out products with count 0 or no sales across platforms
		return Object.entries(data_platform_sales_by_product).map(
			([product, { platforms }]) => {
				// Sum sales for each platform with logs
				const braip = platforms.reduce((acc, platform) => {
					const sales = platform?.["Braip"] || 0;

					return acc + sales;
				}, 0);

				const monetizze = platforms.reduce((acc, platform) => {
					const sales = platform?.["Monetizze"] || 0;
					return acc + sales;
				}, 0);

				const theMart = platforms.reduce((acc, platform) => {
					const sales = platform?.["The Mart"] || 0;
					return acc + sales;
				}, 0);

				return {
					product,
					braip: braip || null,
					monetizze: monetizze || null,
					theMart: theMart || null,
				};
			},
		);
	}, [data_platform_sales_by_product]);

	return (
		<div className="flex flex-col gap-10 rounded-[8px] border border-solid border-neutral-100 p-6">
			<Card className="flex flex-col gap-10 border-none bg-transparent">
				<CardHeader className="p-0">
					<div className="flex items-center justify-between">
						<h2 className="font-inter text-P3 font-medium leading-140 text-neutral-1100">
							Vendas por produtos
						</h2>
						<ul className="flex gap-6">
							<li className="flex items-center justify-center gap-2">
								<span className="h-2.5 w-2.5 rounded-full bg-pink-800" />
								<p className="font-inter text-P6 font-medium leading-160 text-neutral-500">
									Braip
								</p>
							</li>
							<li className="flex items-center justify-center gap-2">
								<span className="h-2.5 w-2.5 rounded-full bg-blue-800" />
								<p className="font-inter text-P6 font-medium leading-160 text-neutral-500">
									Monetizze
								</p>
							</li>
							<li className="flex items-center justify-center gap-2">
								<span className="h-2.5 w-2.5 rounded-full bg-orange-800" />
								<p className="font-inter text-P6 font-medium leading-160 text-neutral-500">
									The Mart
								</p>
							</li>
						</ul>
					</div>
				</CardHeader>
				<CardContent className="p-0">
					<ChartContainer config={chartConfig}>
						<BarChart layout="vertical" accessibilityLayer data={chartData}>
							<YAxis
								dataKey="product"
								type="category"
								tickLine={false}
								tickMargin={160}
								axisLine={false}
								width={160}
								tick={{
									fontSize: "12px",
									fontFamily: "Inter, sans-serif",
									fontWeight: 500,
									fill: "#6C7373",
									textAnchor: "start",
								}}
							/>
							<XAxis type="number" hide />
							<Bar
								dataKey="braip"
								stackId="a"
								fill="var(--color-braip)"
								barSize={24}
								background={{ fill: "#151C1C" }}
							/>
							<Bar
								dataKey="theMart"
								stackId="a"
								fill="var(--color-theMart)"
								barSize={24}
							/>
							<Bar
								dataKey="monetizze"
								stackId="a"
								fill="var(--color-monetizze)"
								barSize={24}
							/>
							<ChartTooltip
								content={<ChartTooltipContent hideIndicator />}
								cursor={false}
								defaultIndex={1}
							/>
						</BarChart>
					</ChartContainer>
				</CardContent>
			</Card>
		</div>
	);
}
