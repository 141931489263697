import { ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";

import { signOut } from "@/api/auth/sign-out";
import ChevronLeftIcon from "@/assets/chevron-left.svg?react";
import ChevronRightIcon from "@/assets/chevron-right.svg?react";
import ControllerIcon from "@/assets/controller.svg?react";
import DropdownArrow from "@/assets/dropdown-arrow.svg?react";
import LockIcon from "@/assets/lock-2.svg?react";
import LogoutIcon from "@/assets/log-out.svg?react";
import ProfileIcon from "@/assets/profile.svg?react";
import TrophyIcon from "@/assets/trophy.svg?react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useAuthStore } from "@/store/auth";
import { authorFallback } from "@/utils/mask-author-fallback";

import { Tag } from "./tag";

interface ProfileDropdownProps {
	closeDropdown: () => void;
}

export function ProfileDropdown({ closeDropdown }: ProfileDropdownProps) {
	const navigate = useNavigate();
	const { logout, profile } = useAuthStore();

	async function handleLogout() {
		const response = await signOut();

		if (response.status === "success") {
			logout();
			navigate("/sign-in");
		}
	}

	return (
		<div className="gap[-1px] flex flex-col md:w-80">
			<span className="hidden justify-end px-4 md:flex">
				<DropdownArrow />
			</span>

			<div className="flex h-screen flex-col items-center bg-neutral-100 md:h-auto md:rounded-xl">
				<div className="w-full rounded-xl bg-neutral-100 px-5 py-4 md:p-4">
					<button
						className="flex cursor-pointer items-center gap-3 md:cursor-text"
						onClick={closeDropdown}
					>
						<ChevronLeftIcon
							width={32}
							height={32}
							className="flex text-neutral-600 md:hidden"
						/>
						<p className="font-inter text-P2 font-medium leading-140 text-neutral-1100 md:text-P4 md:leading-160">
							Minha conta
						</p>
					</button>
				</div>

				<div className="flex w-full px-5 pb-8 pt-6 md:px-4 md:pb-4">
					<div className="flex w-full flex-col gap-4">
						<div className="flex w-full items-center gap-4 rounded-[10px] bg-neutral-200 bg-opacity-40 p-2">
							<Avatar className="size-10">
								<AvatarImage src={profile?.image ?? ""} />
								<AvatarFallback>
									{authorFallback(profile?.nickname)}
								</AvatarFallback>
							</Avatar>

							<div className="flex flex-col">
								<p className="w-full max-w-[200px] truncate font-inter text-P5 font-medium leading-160 text-neutral-1100">
									{profile?.name}
								</p>
								<span className="w-full max-w-[200px] truncate font-inter text-P5 font-normal leading-160 text-neutral-500">
									{profile?.email}
								</span>
							</div>
						</div>

						<div className="flex flex-col gap-4">
							<ul className="flex flex-col gap-4">
								<ListItem to="/account" name="Perfil" onClick={closeDropdown}>
									<ProfileIcon />
								</ListItem>

								<ListItem
									to="/account/password"
									name="Senha"
									onClick={closeDropdown}
								>
									<LockIcon />
								</ListItem>

								<ListItem
									to="/account/achievements"
									name="Conquistas"
									onClick={closeDropdown}
								>
									<TrophyIcon />
								</ListItem>

								<ListItem
									to="/account/integrations"
									name="Integrações"
									onClick={closeDropdown}
								>
									<ControllerIcon />
								</ListItem>
							</ul>

							<hr className="h-px border-t-neutral-200" />

							<button
								className="flex h-12 cursor-pointer items-center gap-3 p-3 text-neutral-500 transition-all duration-300 hover:text-neutral-1100"
								onClick={handleLogout}
							>
								<LogoutIcon />

								<p className="font-inter text-P5 font-normal leading-160">
									Sair
								</p>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function ListItem({
	name,
	to,
	children,
	disabled = false,
	onClick,
}: {
	name: string;
	to: string;
	children: ReactNode;
	disabled?: boolean;
	onClick?: () => void;
}) {
	return (
		<Link
			to={!disabled ? to : location.pathname}
			className={`flex w-full items-center justify-between rounded-[8px] p-2 text-neutral-500 transition-all duration-300 hover:bg-neutral-200 hover:text-neutral-1100 ${
				disabled && "cursor-not-allowed !text-neutral-200 hover:bg-transparent"
			}`}
			onClick={!disabled ? onClick : undefined}
		>
			<div className="flex items-center gap-3">
				{children}

				<p className="font-inter text-P5 font-normal leading-160">{name}</p>
			</div>

			{!disabled && <ChevronRightIcon width={20} height={20} />}

			{disabled && (
				<Tag
					size="small"
					colors="minimalist-grey"
					className="px-[6px] py-1 text-[10px]"
				>
					Em breve
				</Tag>
			)}
		</Link>
	);
}
