import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";

import { signIn } from "@/api/auth/sign-in";
import { Button } from "@/components/ui/button";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { useAuthStore } from "@/store/auth";

const signInForm = z.object({
	email: z.string().email({
		message: "E-mail inválido",
	}),
	password: z.string().min(6, {
		message: "A senha deve ter no mínimo 6 caracteres",
	}),
});

type SignInForm = z.infer<typeof signInForm>;

export function SignIn() {
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();

	const { setToken, isAuth } = useAuthStore();

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<SignInForm>({
		resolver: zodResolver(signInForm),
	});

	async function handleSignIn({ email, password }: SignInForm) {
		setIsLoading(true);
		const response = await signIn({
			email,
			password,
		});

		if (response.status === "success") {
			const { data: token } = response;

			setToken(`${token}`);

			setIsLoading(false);

			return;
		}

		if (response.status === "error") {
			const { errors } = response;

			for (const key in errors) {
				setError(key as keyof SignInForm, {
					type: "manual",
					message: errors[key],
				});
			}

			setIsLoading(false);

			return;
		}
	}

	useEffect(() => {
		if (isAuth) {
			navigate("/");
		}
	}, [isAuth, navigate]);

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Enter") {
			handleSubmit(handleSignIn)();
		}
	};

	return (
		<>
			<Helmet title="Login" />

			<div className="flex animate-fade flex-col gap-12">
				<div className="flex flex-col gap-2">
					<h1 className="font-poppins text-H7 font-semibold leading-140 text-white">
						Acesse sua conta
					</h1>
					<p className="font-inter text-P5 leading-160 text-neutral-500 md:text-P4">
						Insira seu e-mail e senha para realizar o login.
					</p>
				</div>

				<form
					onSubmit={handleSubmit(handleSignIn)}
					className="flex flex-col gap-10"
				>
					<div className="flex flex-col gap-6">
						<div className="flex flex-col gap-2">
							<FloatingLabelInput
								id="email"
								label="E-mail"
								type="email"
								hasError={!!errors.email}
								onKeyDown={handleKeyDown}
								autoComplete="email"
								{...register("email")}
							/>

							{errors.email && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{errors.email.message}
								</p>
							)}
						</div>

						<div className="flex flex-col gap-2">
							<FloatingLabelInput
								id="password"
								type="password"
								label="Senha"
								hasError={!!errors.password}
								onKeyDown={handleKeyDown}
								autoComplete="current-password"
								{...register("password")}
							/>

							{errors.password && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{errors.password.message}
								</p>
							)}
						</div>

						<Link
							to="/forgot-password"
							className="cursor-pointer font-inter text-P5 font-medium leading-160 text-white transition-colors hover:text-neutral-800 hover:underline hover:underline-offset-8"
						>
							Esqueceu a senha?
						</Link>
					</div>

					<div className="flex flex-col gap-8">
						<Button loading={isLoading} type="submit" disabled={isLoading}>
							Acessar
						</Button>

						<footer className="flex items-center justify-center">
							<p className="font-inter text-P5 leading-160 text-neutral-500">
								Não tem acesso?
								<Link
									to="/sign-up"
									className="cursor-pointer text-white transition-colors hover:text-neutral-800"
								>
									{" "}
									Crie sua conta
								</Link>
							</p>
						</footer>
					</div>
				</form>
			</div>
		</>
	);
}
