import { Skeleton } from "@/components/ui/skeleton";

export function SkeletonProductCard() {
	return (
		<div className="flex w-full min-w-[256px] flex-col rounded-xl bg-neutral-100 bg-opacity-40 transition-all duration-300 hover:bg-opacity-100 sm:w-full">
			<Skeleton className="mx-4 mt-4 h-[164px] w-[224px] rounded-xl" />

			<div className="flex flex-col gap-1 p-6">
				<Skeleton className="h-5 w-full" />
				<Skeleton className="h-6 w-full" />
			</div>
		</div>
	);
}
