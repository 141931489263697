import { useCallback, useEffect } from "react";
import Dropzone, {
	type DropzoneProps,
	type FileRejection,
} from "react-dropzone";
import { toast } from "sonner";

import TrashIcon from "@/assets/trash.svg?react";
import UploadedFileIcon from "@/assets/uploaded-file.svg?react";
import { Progress } from "@/components/ui/progress";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useControllableState } from "@/hooks/use-controllable-state";
import { cn, formatBytes } from "@/lib/utils";

interface FileUploaderProps extends React.HTMLAttributes<HTMLDivElement> {
	/**
	 * Value of the uploader.
	 * @type File[]
	 * @default undefined
	 * @example value={files}
	 */
	value?: File[];

	/**
	 * Function to be called when the value changes.
	 * @type React.Dispatch<React.SetStateAction<File[]>>
	 * @default undefined
	 * @example onValueChange={(files) => setFiles(files)}
	 */
	onValueChange?: React.Dispatch<React.SetStateAction<File[]>>;

	/**
	 * Function to be called when files are uploaded.
	 * @type (files: File[]) => Promise<void>
	 * @default undefined
	 * @example onUpload={(files) => uploadFiles(files)}
	 */
	onUpload?: (files: File[]) => Promise<void>;

	/**
	 * Progress of the uploaded files.
	 * @type Record<string, number> | undefined
	 * @default undefined
	 * @example progresses={{ "file1.png": 50 }}
	 */
	progresses?: Record<string, number>;

	/**
	 * Accepted file types for the uploader.
	 * @type { [key: string]: string[]}
	 * @default
	 * ```ts
	 * { "image/*": [] }
	 * ```
	 * @example accept={["image/png", "image/jpeg"]}
	 */
	accept?: DropzoneProps["accept"];

	/**
	 * Maximum file size for the uploader.
	 * @type number | undefined
	 * @default 1024 * 1024 * 2 // 2MB
	 * @example maxSize={1024 * 1024 * 2} // 2MB
	 */
	maxSize?: DropzoneProps["maxSize"];

	/**
	 * Maximum number of files for the uploader.
	 * @type number | undefined
	 * @default 1
	 * @example maxFiles={5}
	 */
	maxFiles?: DropzoneProps["maxFiles"];

	/**
	 * Whether the uploader should accept multiple files.
	 * @type boolean
	 * @default false
	 * @example multiple
	 */
	multiple?: boolean;

	/**
	 * Whether the uploader is disabled.
	 * @type boolean
	 * @default false
	 * @example disabled
	 */
	disabled?: boolean;
}

export function FileUploader(props: FileUploaderProps) {
	const {
		value: valueProp,
		onValueChange,
		onUpload,
		progresses,
		accept = { "image/*": [] },
		maxSize = 1024 * 1024 * 2,
		maxFiles = 1,
		multiple = false,
		disabled = false,
		className,
		...dropzoneProps
	} = props;

	const [files, setFiles] = useControllableState({
		prop: valueProp,
		onChange: onValueChange,
	});

	const onDrop = useCallback(
		(acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
			if (!multiple && maxFiles === 1 && acceptedFiles.length > 1) {
				toast.error("Cannot upload more than 1 file at a time");
				return;
			}

			if ((files?.length ?? 0) + acceptedFiles.length > maxFiles) {
				toast.error(`Cannot upload more than ${maxFiles} files`);
				return;
			}

			const newFiles = acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				}),
			);

			const updatedFiles = files ? [...files, ...newFiles] : newFiles;

			setFiles(updatedFiles);

			if (rejectedFiles.length > 0) {
				rejectedFiles.forEach(({ file }) => {
					toast.error(`File ${file.name} was rejected`);
				});
			}

			if (
				onUpload &&
				updatedFiles.length > 0 &&
				updatedFiles.length <= maxFiles
			) {
				const target =
					updatedFiles.length > 0 ? `${updatedFiles.length} files` : `file`;

				toast.promise(onUpload(updatedFiles), {
					loading: `Uploading ${target}...`,
					success: () => {
						setFiles([]);
						return `${target} uploaded`;
					},
					error: `Failed to upload ${target}`,
				});
			}
		},

		[files, maxFiles, multiple, onUpload, setFiles],
	);

	function onRemove(index: number) {
		if (!files) return;
		const newFiles = files.filter((_, i) => i !== index);
		setFiles(newFiles);
		onValueChange?.(newFiles);
	}

	useEffect(() => {
		return () => {
			if (!files) return;
			files.forEach((file) => {
				if (isFileWithPreview(file)) {
					URL.revokeObjectURL(file.preview);
				}
			});
		};
	}, []);

	const isDisabled = disabled || (files?.length ?? 0) >= maxFiles;

	return (
		<div className="relative flex flex-col gap-6 overflow-hidden">
			<Dropzone
				onDrop={onDrop}
				accept={accept}
				maxSize={maxSize}
				maxFiles={maxFiles}
				multiple={maxFiles > 1 || multiple}
				disabled={isDisabled}
			>
				{({ getRootProps, getInputProps, isDragActive }) => (
					<div
						{...getRootProps()}
						className={cn(
							"group relative grid h-60 w-full cursor-pointer place-items-center rounded-lg border-2 border-dashed border-muted-foreground/25  bg-neutral-1100 bg-opacity-[0.03] p-8 text-center transition",
							"ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
							isDragActive && "border-muted-foreground/50",
							isDisabled && "pointer-events-none opacity-60",
							className,
						)}
						{...dropzoneProps}
					>
						<input {...getInputProps()} />
						{isDragActive ? (
							<div className="flex flex-col items-center justify-center gap-4 sm:px-5">
								<div className="rounded-full border border-dashed p-3">
									<UploadedFileIcon />
								</div>
								<p className="font-medium text-muted-foreground">
									Solte para adicionar
								</p>
							</div>
						) : (
							<div className="flex flex-col items-center justify-center gap-4 sm:px-5">
								<div className="flex items-center justify-center rounded-[10px] border border-solid border-primary-1000 bg-primary-600 bg-opacity-[0.04] p-4">
									<UploadedFileIcon />
								</div>

								<div className="space-y-px">
									<p className="text-center font-inter text-P4 font-medium leading-160 text-neutral-1100">
										Arraste ou clique aqui para <br /> adicionar uma imagem
									</p>
									<p className="text-sm text-muted-foreground/70">
										Formatos aceitos:{" "}
										{Object.entries(accept)
											.map(([mimeType, extensions]) => {
												const formattedExtensions = extensions.length
													? extensions.join(", ")
													: mimeType;
												return formattedExtensions;
											})
											.join(", ")}{" "}
										de até {formatBytes(maxSize)} cada.
									</p>
								</div>
							</div>
						)}
					</div>
				)}
			</Dropzone>

			{files?.length ? (
				<ScrollArea className="h-fit w-full">
					<div className="max-h-48 space-y-4">
						{files?.map((file, index) => (
							<FileCard
								key={index}
								file={file}
								onRemove={() => onRemove(index)}
								progress={progresses?.[file.name]}
							/>
						))}
					</div>
				</ScrollArea>
			) : null}
		</div>
	);
}

interface FileCardProps {
	file: File;
	onRemove: () => void;
	progress?: number;
}

function FileCard({ file, progress, onRemove }: FileCardProps) {
	return (
		<div className="flex items-center justify-between rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-4">
			<div className="flex flex-1 space-x-4">
				{isFileWithPreview(file) ? (
					<img
						src={file.preview}
						alt={file.name}
						width={48}
						height={48}
						loading="lazy"
						className="aspect-square shrink-0 rounded-md object-cover"
					/>
				) : null}
				<div className="flex w-full flex-col gap-2">
					<div className="flex flex-col gap-[2px]">
						<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
							{file.type.startsWith("image/") ? "Imagem" : "Arquivo"}
						</span>
						<p className="line-clamp-1 font-inter text-P5 font-medium leading-160 text-neutral-1100">
							{file.name}
						</p>
					</div>
					{progress ? <Progress value={progress} /> : null}
				</div>
			</div>

			<button
				className="flex items-center justify-center p-3"
				onClick={onRemove}
			>
				<TrashIcon />
			</button>
		</div>
	);
}

function isFileWithPreview(file: File): file is File & { preview: string } {
	return "preview" in file && typeof file.preview === "string";
}
