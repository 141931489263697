import { useState } from "react";

import type { User } from "@/api/admin/users/show";
import CopyIcon from "@/assets/copy.svg?react";
import MailIcon from "@/assets/mail-2.svg?react";
import PinIcon from "@/assets/pin.svg?react";
import ProfileWaterMark from "@/assets/watermark.svg?react";
import { Modal } from "@/components/modal";
import { Tag } from "@/components/tag";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { formatDateSince } from "@/utils/date-format";
import { authorFallback } from "@/utils/mask-author-fallback";

interface AdminUsersModalProfileProps {
	closeModal: () => void;
	user: User | null;
}

export function AdminUsersModalProfile({
	closeModal,
	user,
}: AdminUsersModalProfileProps) {
	const tabs = [
		{
			title: "Dados",
			disabled: false,
		},
		{
			title: "Questionário",
			disabled: false,
		},
	];

	const [activeTab, setActiveTab] = useState(0);
	const [showPhoneTooltip, setShowPhoneTooltip] = useState(false);
	const [showEmailTooltip, setShowEmailTooltip] = useState(false);

	const handleCopy = (
		text: string,
		setShowTooltip: (value: boolean) => void,
	) => {
		navigator.clipboard.writeText(text);
		setShowTooltip(true);
		setTimeout(() => setShowTooltip(false), 2000);
	};

	return (
		<Modal
			title="Dados do usuário"
			closeModal={closeModal}
			confirmAction={() => {}}
			hasCancel={false}
			hasConfirm={false}
		>
			{user && (
				<div className="flex flex-col gap-12">
					<div className="relative h-full w-full rounded-xl border border-solid border-neutral-200">
						<div className="flex h-[98px] items-center justify-end rounded-xl rounded-b-none bg-primary-1000">
							<ProfileWaterMark />
						</div>
						<div className="-mt-[60px] flex flex-col gap-10 px-6 pb-6 md:-mt-5 md:flex-row md:items-center md:px-10 md:pb-10">
							<Avatar className="size-28 border-[6px] border-neutral-100">
								<AvatarImage src={user?.image ?? ""} />
								<AvatarFallback>{authorFallback(user.name)}</AvatarFallback>
							</Avatar>

							<div className="flex w-full flex-col gap-4">
								<Tag
									size="small"
									colors="green"
									className="max-w-[134px] rounded-[4px] px-[6px] py-1 font-inter text-P5 font-medium leading-160"
								>
									Membro fundador
								</Tag>

								<div className="flex flex-col justify-between gap-8 md:flex-row md:items-center md:gap-12">
									<div className="flex flex-col gap-1">
										<p className="overflow-hidden text-ellipsis font-inter text-P1 font-semibold leading-140 text-neutral-1100">
											{user?.name}
										</p>
										<span className="font-inter text-P6 font-normal leading-160 text-neutral-600">
											Membro desde {formatDateSince(user.created_at)}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="flex flex-col gap-8">
						<ul className="flex gap-8 border-b border-b-neutral-200">
							{tabs.map((tab, index) => (
								<li
									className={`flex cursor-pointer items-center justify-center gap-1 pb-6 pt-4 font-inter text-P3 font-normal leading-160 text-neutral-500 transition-all duration-300 ${
										index === activeTab &&
										"border-b-2 border-primary-600 !font-medium !text-neutral-1100"
									} ${tab.disabled && "!cursor-not-allowed !text-neutral-200"}`}
									key={index}
									onClick={() => {
										if (tab.disabled) return;
										setActiveTab(index);
									}}
								>
									{tab.title}
								</li>
							))}
						</ul>

						<div>
							{activeTab === 0 && (
								<div className="flex flex-col gap-6">
									<div className="flex flex-col gap-6 rounded-[8px] border border-solid border-neutral-200 p-6">
										<div className="flex items-center gap-3 text-neutral-1100">
											<MailIcon />

											<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
												Contato
											</p>
										</div>

										<hr className="h-px border-t-neutral-200" />

										<div className="flex gap-4">
											<div className="flex w-full flex-col gap-1">
												<span className="font-inter text-P6 font-medium leading-160 text-neutral-600">
													WhatsApp
												</span>

												<div className="flex items-center gap-3 text-neutral-1100">
													<p className="font-inter text-P5 font-medium leading-160 ">
														{user.phone}
													</p>

													<span
														className="relative cursor-pointer"
														onClick={() =>
															handleCopy(user.phone, setShowPhoneTooltip)
														}
													>
														<CopyIcon />
														{showPhoneTooltip && (
															<div className="relative">
																<span className="absolute -top-16 left-1/2 flex w-[144px] -translate-x-1/2 flex-col items-center rounded bg-white p-1 px-3 py-2 text-center text-xs text-black shadow-lg">
																	WhatsApp copiado
																</span>
																<span className="absolute bottom-[20px] left-1/2 -translate-x-1/2 border-[6px] border-x-transparent border-b-transparent border-t-white"></span>
															</div>
														)}
													</span>
												</div>
											</div>

											<div className="flex w-full flex-col gap-1">
												<span className="font-inter text-P6 font-medium leading-160 text-neutral-600">
													E-mail
												</span>

												<div className="flex items-center gap-3 text-neutral-1100">
													<p className="font-inter text-P5 font-medium leading-160">
														{user.email}
													</p>

													<span
														className="relative cursor-pointer"
														onClick={() =>
															handleCopy(user.email, setShowEmailTooltip)
														}
													>
														<CopyIcon />
														{showEmailTooltip && (
															<div className="relative">
																<span className="absolute -top-16 left-1/2 flex w-[124px] -translate-x-1/2 flex-col items-center rounded bg-white p-1 px-3 py-2 text-center text-xs text-black shadow-lg">
																	E-mail copiado
																</span>
																<span className="absolute bottom-[20px] left-1/2 -translate-x-1/2 border-[6px] border-x-transparent border-b-transparent border-t-white"></span>
															</div>
														)}
													</span>
												</div>
											</div>
										</div>
									</div>

									{user.address && (
										<div className="flex flex-col gap-6 rounded-[8px] border border-solid border-neutral-200 p-6">
											<div className="flex items-center gap-3 text-neutral-1100">
												<PinIcon width={20} height={20} />

												<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
													Endereço
												</p>
											</div>

											<hr className="h-px border-t-neutral-200" />

											<div className="flex flex-col gap-6">
												<div className="flex gap-4">
													<div className="flex w-full flex-col gap-1">
														<span className="font-inter text-P6 font-medium leading-160 text-neutral-600">
															CEP
														</span>

														<p className="font-inter text-P5 font-medium leading-160 text-neutral-1100">
															{user.address.zipcode}
														</p>
													</div>

													<div className="flex w-full flex-col gap-1">
														<span className="font-inter text-P6 font-medium leading-160 text-neutral-600">
															Estado
														</span>

														<p className="font-inter text-P5 font-medium leading-160 text-neutral-1100">
															{user.address.state}
														</p>
													</div>

													<div className="flex w-full flex-col gap-1">
														<span className="font-inter text-P6 font-medium leading-160 text-neutral-600">
															Cidade
														</span>

														<p className="font-inter text-P5 font-medium leading-160 text-neutral-1100">
															{user.address.city}
														</p>
													</div>
												</div>

												<div className="flex gap-4">
													<div className="flex w-full flex-col gap-1">
														<span className="font-inter text-P6 font-medium leading-160 text-neutral-600">
															Rua
														</span>

														<p className="font-inter text-P5 font-medium leading-160 text-neutral-1100">
															{user.address.street}
														</p>
													</div>

													<div className="flex w-full flex-col gap-1">
														<span className="font-inter text-P6 font-medium leading-160 text-neutral-600">
															Número
														</span>

														<p className="font-inter text-P5 font-medium leading-160 text-neutral-1100">
															{user.address.number}
														</p>
													</div>

													<div className="flex w-full flex-col gap-1">
														<span className="font-inter text-P6 font-medium leading-160 text-neutral-600">
															Complemento
														</span>

														<p className="font-inter text-P5 font-medium leading-160 text-neutral-1100">
															{user.address.complement}
														</p>
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
							)}

							{activeTab === 1 && user.questionsUser.length > 0 && (
								<div className="flex flex-col gap-6">
									{user.questionsUser.map((question, index) => (
										<div
											key={index}
											className="flex flex-col gap-6 rounded-[8px] border border-solid border-neutral-200 p-6"
										>
											<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
												{question.question}
											</p>

											<div className="flex flex-wrap gap-4">
												{question.answers.map((answer, index) => (
													<div
														key={index}
														className={`
															flex h-10 items-center rounded-[4px] px-2 py-1.5

															${answer.checked ? "bg-neutral-1100" : "bg-neutral-200"}
														`}
													>
														<p
															className={`font-inter text-P6 font-medium leading-160 ${
																answer.checked
																	? "text-neutral-0"
																	: "text-neutral-1100"
															}`}
														>
															{answer.description}
														</p>
													</div>
												))}
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</Modal>
	);
}
