interface AdminPanelUsersMetrics {
	new_users: number;
	new_integration_users: number;
}

export function AdminPanelUsersMetrics({
	new_users,
	new_integration_users,
}: AdminPanelUsersMetrics) {
	return (
		<div className="flex items-center gap-8">
			<div className="flex w-full flex-col gap-4 rounded-[8px] border border-solid border-neutral-100 p-6">
				<p className="truncate font-inter text-P4 font-normal leading-160 text-neutral-800">
					Novos usuários
				</p>

				<div className="flex items-center justify-between">
					<p className="truncate font-inter text-H7 font-medium leading-140 text-neutral-1100">
						{new_users}
					</p>
				</div>
			</div>

			<div className="flex w-full flex-col gap-4 rounded-[8px] border border-solid border-neutral-100 p-6">
				<p className="truncate font-inter text-P4 font-normal leading-160 text-neutral-800">
					Novos usuários com integração
				</p>

				<div className="flex items-center justify-between">
					<p className="truncate font-inter text-H7 font-medium leading-140 text-neutral-1100">
						{new_integration_users}
					</p>
				</div>
			</div>
		</div>
	);
}
