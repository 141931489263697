import { ReactNode } from "react";

import HeartIcon from "@/assets/heart.svg?react";
import { formatCommentDate } from "@/utils/date-format";
import { authorFallback } from "@/utils/mask-author-fallback";

import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";

interface CommentProps {
	id: string;
	content: string;
	likes: number;
	user_name: string;
	user_image: string;
	created_at: string;
	logged_user_liked: boolean;
	isOwnerOrAdmin: boolean;
	children?: ReactNode;
	handleAnswer?: () => void;
	handleDelete?: () => void;
	handleLike?: () => void;
}

export function Comment({
	content,
	likes,
	user_name,
	user_image,
	created_at,
	children,
	isOwnerOrAdmin,
	logged_user_liked,
	handleAnswer,
	handleDelete,
	handleLike,
}: CommentProps) {
	return (
		<div className="flex w-full gap-6">
			<Avatar className="size-10 border-[6px] border-neutral-0">
				<AvatarImage src={user_image} />
				<AvatarFallback>{authorFallback(user_name)}</AvatarFallback>
			</Avatar>

			<div className="flex w-full flex-col gap-6">
				<div className="flex flex-col gap-3">
					<div className="flex items-center justify-between gap-12">
						<p className="line-clamp-1 text-ellipsis text-left font-inter text-P5 font-medium leading-160 text-neutral-1100">
							{user_name}
						</p>

						<p className="font-inter text-P6 font-normal leading-160 text-neutral-500">
							{formatCommentDate(created_at)}
						</p>
					</div>

					<p className="font-inter text-P5 font-normal leading-160 text-neutral-1100">
						{content}
					</p>
				</div>

				<div className="flex items-center gap-6">
					<button
						className={`flex items-center justify-center gap-1 font-inter text-P7 font-medium leading-160 transition-all duration-300 ${
							logged_user_liked ? "text-neutral-1100" : "text-neutral-600"
						} hover:text-neutral-1100`}
						onClick={handleLike}
					>
						<HeartIcon />

						<p>{likes > 1 ? `${likes} curtidas` : `${likes} curtida`}</p>
					</button>
					<button
						className="font-inter text-P7 font-medium leading-160 text-neutral-600 transition-all duration-300 hover:text-neutral-1100"
						onClick={handleAnswer}
					>
						Responder
					</button>

					{isOwnerOrAdmin && (
						<>
							<span className="block h-[18px] w-[1px] rounded-[1px] bg-neutral-200" />

							<button
								className="font-inter text-P7 font-medium leading-160 text-neutral-600 transition-all duration-300 hover:text-neutral-1100"
								onClick={handleDelete}
							>
								Excluir
							</button>
						</>
					)}
				</div>

				{children}
			</div>
		</div>
	);
}
