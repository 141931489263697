import { Skeleton } from "@/components/ui/skeleton";

export function SkeletonProduct() {
	const skeletonTabs = [
		{ title: "Sobre", to: "#" },
		{ title: "Materiais", to: "#" },
		{ title: "Dúvidas", to: "#" },
	];

	return (
		<div className="relative flex flex-col gap-8 lg:flex-row">
			<section className="flex h-fit w-full flex-col gap-8 rounded-[12px] bg-neutral-100 bg-opacity-40 lg:sticky lg:top-32 lg:max-w-[352px]">
				<div className="mx-4 mt-4">
					<Skeleton className="h-[180px] w-full rounded-xl lg:w-[320px]" />
				</div>

				<div className="flex flex-col gap-10 px-6 pb-6">
					<div className="flex flex-col gap-2">
						<Skeleton className="h-6 w-1/4" />
						<Skeleton className="h-8 w-full" />
					</div>
					<hr className="h-[2px] border-t-neutral-100" />

					<div className="flex flex-col gap-4">
						<Skeleton className="h-4 w-3/4" />
						<div className="flex flex-wrap gap-3">
							<Skeleton className="h-12 w-[92px] rounded-[8px]" />
							<Skeleton className="h-12 w-[92px] rounded-[8px]" />
							<Skeleton className="h-12 w-[92px] rounded-[8px]" />
						</div>
					</div>
					<div className="flex flex-col gap-6 rounded-[10px] border border-neutral-100 bg-neutral-100 bg-opacity-80 p-4">
						<Skeleton className="h-4 w-1/2" />
						<Skeleton className="h-12 w-full rounded-[8px]" />
					</div>
				</div>
			</section>

			<section className="flex w-full flex-col gap-12">
				<div className="flex flex-col">
					<div className="flex gap-4">
						{skeletonTabs.map((_, index) => (
							<Skeleton key={index} className="h-10 w-[100px] rounded-lg" />
						))}
					</div>
				</div>
				<div className="flex flex-col gap-4">
					<Skeleton className="h-6 w-3/4" />
					<Skeleton className="h-6 w-1/2" />
					<Skeleton className="h-6 w-full" />
					<Skeleton className="h-6 w-2/3" />
				</div>
			</section>
		</div>
	);
}
