import { useNavigate } from "react-router-dom";

import ArrowLeftIcon from "@/assets/arrow-left.svg?react";
import ArrowRightIcon from "@/assets/arrow-right.svg?react";
import MoveLeftIcon from "@/assets/move-left.svg?react";
import { Button } from "@/components/ui/button";
import { useLessonStore } from "@/store/lesson";
import { useTrainingStore } from "@/store/training";

interface AcademyTrainingHeaderProps {
	setShowMenu: (value: boolean) => void;
	showMenu: boolean;
}

export function AcademyTrainingHeader({
	setShowMenu,
	showMenu,
}: AcademyTrainingHeaderProps) {
	const { lesson } = useLessonStore();
	const { training } = useTrainingStore();

	const navigate = useNavigate();

	function handleNextLesson() {
		if (lesson && training) {
			// Encontra o módulo atual baseado no módulo_id da aula
			const currentModule = training.modules.find(
				(module) => module.id === lesson.module_id,
			);

			if (currentModule) {
				// Encontra o índice da aula atual dentro do módulo
				const currentLessonIndex = currentModule.lessons.findIndex(
					(l) => l.id === lesson.id,
				);

				// Verifica se há uma próxima aula
				if (currentLessonIndex < currentModule.lessons.length - 1) {
					const nextLesson = currentModule.lessons[currentLessonIndex + 1];

					navigate(`/academy/${training.id}/${nextLesson.id}`);
				}
			}
		}
	}

	function handlePreviousLesson() {
		if (lesson && training) {
			// Encontra o módulo atual baseado no módulo_id da aula
			const currentModule = training.modules.find(
				(module) => module.id === lesson.module_id,
			);

			if (currentModule) {
				// Encontra o índice da aula atual dentro do módulo
				const currentLessonIndex = currentModule.lessons.findIndex(
					(l) => l.id === lesson.id,
				);

				// Verifica se há uma aula anterior
				if (currentLessonIndex > 0) {
					const previousLesson = currentModule.lessons[currentLessonIndex - 1];

					navigate(`/academy/${training.id}/${previousLesson.id}`);
				}
			}
		}
	}

	function canNavigateToPreviousLesson() {
		if (lesson && training) {
			const currentModule = training.modules.find(
				(module) => module.id === lesson.module_id,
			);

			if (currentModule) {
				const currentLessonIndex = currentModule.lessons.findIndex(
					(l) => l.id === lesson.id,
				);

				if (currentLessonIndex > 0) {
					return true;
				}
			}
		}

		return false;
	}

	function canNavigateToNextLesson() {
		if (lesson && training) {
			const currentModule = training.modules.find(
				(module) => module.id === lesson.module_id,
			);

			if (currentModule) {
				const currentLessonIndex = currentModule.lessons.findIndex(
					(l) => l.id === lesson.id,
				);

				if (currentLessonIndex < currentModule.lessons.length - 1) {
					return true;
				}
			}
		}

		return false;
	}

	return (
		<header className="flex w-full items-center justify-between rounded-xl bg-neutral-100 bg-opacity-40 p-6">
			<div className="flex w-full items-center gap-8">
				<button
					className="flex size-12 min-w-12 items-center justify-center rounded-[8px] bg-neutral-200 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40"
					onClick={() => setShowMenu(!showMenu)}
				>
					<MoveLeftIcon />
				</button>

				<div className="flex w-full flex-col gap-1.5 md:max-w-[335px]">
					<p className="font-inter text-P5 font-medium leading-160 text-neutral-1100">
						Progresso do treinamento
					</p>

					{training?.percentage ? (
						<div className="flex items-center gap-4">
							<div
								className={`flex h-1 w-full items-center rounded-full bg-neutral-1100 bg-opacity-20`}
							>
								<div
									className={`h-1 rounded-full bg-neutral-1100`}
									style={{ width: `${training?.percentage / 100}%` }}
								/>
							</div>

							<p className="font-inter text-P6 font-medium leading-160 text-neutral-1100">
								{training?.percentage / 100}%
							</p>
						</div>
					) : (
						<div className="flex items-center gap-4">
							<div
								className={`flex h-1 w-full items-center rounded-full bg-neutral-1100 bg-opacity-20`}
							>
								<div
									className={`h-1 rounded-full bg-neutral-1100`}
									style={{ width: `0%` }}
								/>
							</div>

							<p className="font-inter text-P6 font-medium leading-160 text-neutral-1100">
								0%
							</p>
						</div>
					)}
				</div>
			</div>

			<div className="hidden items-center gap-4 md:flex">
				<Button
					className="flex !h-10 flex-1 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 font-inter !text-P6 font-semibold leading-160 text-neutral-1100 transition-all duration-500 hover:bg-neutral-200 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:bg-transparent disabled:text-neutral-600"
					variant="secondary"
					onClick={handlePreviousLesson}
					disabled={!canNavigateToPreviousLesson()}
				>
					<ArrowLeftIcon width={18} height={18} />
					Aula anterior
				</Button>
				<Button
					className="flex !h-10 flex-1 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 font-inter !text-P6 font-semibold leading-160 text-neutral-1100 transition-all duration-500 hover:bg-neutral-200 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:bg-transparent disabled:text-neutral-600"
					onClick={handleNextLesson}
					disabled={!canNavigateToNextLesson()}
				>
					Próxima aula
					<ArrowRightIcon width={18} height={18} />
				</Button>
			</div>
		</header>
	);
}
