import { AxiosError } from "axios";

import { api } from "@/lib/axios";

interface FetchDashboardParams {
	period: string[];
	product_id: string;
}

type PlatformSalesTotal = {
	platform: string;
	total_value: string;
	total_commission_value: string;
};

type CountSalesByPaymentMethod = {
	payment_method: string;
	count: number;
};

type CountSalesByState = {
	state: string;
	count: number;
};

type PlatformSalesTotalByDay = {
	date: string;
	platform: string;
	total_value: string;
	total_commission_value: string;
};

export type Dashboard = {
	sales_total_value: string;
	sales_total_commission_value: string;
	data_platform_sales_total: PlatformSalesTotal[];
	data_platform_sales_total_by_day: {
		[date: string]: PlatformSalesTotalByDay[];
	};
	count_sales_by_payment_method: CountSalesByPaymentMethod[];
	count_sales_by_state: CountSalesByState[];
};

export async function getDashboard(
	params: Partial<FetchDashboardParams>,
): Promise<
	| {
			status: "success";
			data: Dashboard;
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get("/dashboard/info-sales-dashboard", {
			params,
		});

		return {
			status: "success",
			data: data.data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
