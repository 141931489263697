import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { addAcademyTrainingLesson } from "@/api/admin/academy/trainings/lesson/add-lesson";
import {
	type Lesson,
	type Material,
	showAcademyTrainingLesson,
} from "@/api/admin/academy/trainings/lesson/show-lesson";
import { updateAcademyTrainingLessonAvailable } from "@/api/admin/academy/trainings/lesson/update-lesson-available";
import { addAcademyMentor } from "@/api/admin/academy/trainings/mentor/add-mentor";
import {
	fetchAdminMentors,
	type Mentor,
} from "@/api/admin/academy/trainings/mentor/list-mentors";
import CloseIcon from "@/assets/close.svg?react";
import MathPlus from "@/assets/math-plus.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import { FileCard } from "@/components/file-card";
import { FileUploader } from "@/components/file-uploader";
import { Modal } from "@/components/modal";
import { TextareaInput } from "@/components/textarea-input";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

import { AdminAcademyShowModalLessonMaterial } from "./modal-material";

interface AdminAcademyShowModalLessonProps {
	lessonId: string;
	moduleId: string;
	closeModal: () => void;
	isEdit?: boolean;
	onSuccess: (data: {
		id: string;
		name: string;
		description: string;
		mentor_id: string;
		link: string;
		module_id: string;
		duration: number;
		image: File | string;
		materials: {
			id?: string;
			material_id?: string;
			title: string;
			format: string;
			created_at?: string;
			file: File | string;
		}[];
	}) => void;
	onSuccessAdd: () => void;
}

export function AdminAcademyShowModalLesson({
	lessonId,
	moduleId,
	closeModal,
	isEdit = false,
	onSuccess,
	onSuccessAdd,
}: AdminAcademyShowModalLessonProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [lesson, setLesson] = useState<Lesson | null>(null);
	const [mentors, setMentors] = useState<Mentor[]>([]);
	const [showMentorModal, setShowMentorModal] = useState(false);
	const [currentState, setCurrentState] = useState(false);
	const [showMaterialModal, setShowMaterialModal] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [editIndex, setEditIndex] = useState<number | null>(null);
	const [materials, setMaterials] = useState<Material[]>([]);

	function handleSearch(value: string) {
		setSearchTerm(value);

		if (value === "") {
			form.setValue(
				"materials",
				materials.map((material: Material) => ({
					id: material.id.toString(),
					format: material.format,
					file: material.link,
					title: material.title,
					material_id: material.material_id,
				})),
			);
			return;
		}

		const filteredMaterials = materials.filter((material) => {
			return (
				material.link.toLowerCase().includes(value.toLowerCase()) ||
				material.title.toLowerCase().includes(value.toLowerCase())
			);
		});

		form.setValue(
			"materials",
			filteredMaterials.map((material: Material) => ({
				id: material.id.toString(),
				format: material.format,
				file: material.link,
				title: material.title,
				material_id: material.material_id,
			})),
		);
	}

	const filter = z.object({
		name: z.string().min(1, {
			message: "O nome do módulo é obrigatório",
		}),
		description: z.string({
			required_error: "A descrição da aula é obrigatória.",
		}),
		mentor: z.string({
			required_error: "O mentor responsável é obrigatório.",
		}),
		video_link: z.string({
			required_error: "O link do vídeo é obrigatório.",
		}),
		image: z
			.union([
				z.instanceof(File, {
					message: "A imagem deve ser um arquivo.",
				}),
				z.string(),
			])
			.refine(
				(image) => {
					if (image instanceof File) {
						return image.size <= 4 * 1024 * 1024;
					}

					return true;
				},
				{
					message: "A imagem deve ter no máximo 4MB.",
				},
			),
		materials: z
			.array(
				z.object({
					id: z.string().optional(),
					title: z.string().optional(),
					format: z.string(),
					file: z.union([z.instanceof(File), z.string()]).refine(
						(file) => {
							if (file instanceof File) {
								return file.size <= 4 * 1024 * 1024;
							}
							return true;
						},
						{
							message: "O arquivo deve ter no máximo 4MB.",
						},
					),
					material_id: z.string().optional(),
					created_at: z.string().optional(),
				}),
			)
			.optional()
			.default([]),
	});

	type Filter = z.infer<typeof filter>;

	const form = useForm<Filter>({
		resolver: zodResolver(filter),
	});

	const title = isEdit ? "Editar aula" : "Adicionar aula";

	const createMentor = z.object({
		name: z.string().min(1, {
			message: "O nome do mentor é obrigatório.",
		}),
		image: z.union([z.instanceof(File), z.string()]).refine(
			(image) => {
				if (image instanceof File) {
					return image.size <= 4 * 1024 * 1024;
				}

				return true;
			},
			{
				message: "A imagem deve ter no máximo 4MB.",
			},
		),
	});

	type CreateMentor = z.infer<typeof createMentor>;

	const createMentorForm = useForm<CreateMentor>({
		resolver: zodResolver(createMentor),
	});

	async function getLessonById() {
		if (!lessonId) {
			return;
		}

		setIsLoading(true);

		const response = await showAcademyTrainingLesson(lessonId);

		if (response.status === "success") {
			setLesson(response.data);
			setCurrentState(response.data.available);

			form.setValue("name", response.data.name);
			form.setValue("description", response.data.description);
			form.setValue("mentor", response.data.mentor.id);
			form.setValue("video_link", response.data.link);
			form.setValue("image", response.data.thumbnail);
			form.setValue(
				"materials",
				response.data.materials.map((material: Material) => ({
					id: material.id.toString(),
					format: material.format,
					file: material.link,
					title: material.title,
					material_id: material.material_id,
				})),
			);

			setMaterials(response.data.materials);
		}

		setIsLoading(false);
	}

	async function fetchMentors() {
		setIsLoading(true);
		const response = await fetchAdminMentors();

		if (response.status === "success") {
			setMentors(response.data);
		}

		setIsLoading(false);
	}

	useEffect(() => {
		getLessonById();
		fetchMentors();
	}, [lessonId]);

	// Função que lida com o sucesso de adicionar/editar material
	const handleAddOrEditMaterial = (material: {
		id?: string;
		title: string;
		format: string;
		file: File | string;
		material_id?: string;
	}) => {
		// Garante que materials seja um array
		const materials = form.getValues("materials") || [];

		// Verifica se estamos editando um material
		if (editIndex !== null) {
			// Atualiza o material existente
			materials[editIndex] = {
				id: material.id,
				material_id: material.material_id,
				title: material.title,
				format: material.format,
				file: material.file,
			};
			setEditIndex(null); // Reseta o índice de edição
		} else {
			// Adiciona um novo material
			materials.push({
				file: material.file,
				title: material.title,
				format: material.format,
			});
		}

		// Atualiza o campo de materials no formulário
		form.setValue("materials", materials);
		setShowMaterialModal(false); // Fecha o modal de material
		setEditIndex(null); // Reseta o índice de edição
	};

	// Função para remover material do array
	const handleRemoveMaterial = (index: number) => {
		const materials = form.getValues("materials");
		materials.splice(index, 1);
		form.setValue("materials", materials);
	};

	return (
		<Modal
			title={showMentorModal ? "Adicionar mentor" : title}
			closeModal={closeModal}
			showBackButton={showMentorModal}
			cancelAction={closeModal}
			backAction={() => {
				if (showMentorModal) {
					setShowMentorModal(false);
				}
			}}
			confirmAction={() => {
				if (showMaterialModal) {
					setShowMaterialModal(false);
					setShowMentorModal(false);

					return;
				}

				if (!showMentorModal && !isEdit) {
					form.handleSubmit(async (data) => {
						const formData = new FormData();

						formData.append("name", data.name);
						formData.append("description", data.description);
						formData.append("mentor_id", data.mentor);
						formData.append("link", data.video_link);
						formData.append("thumbnail", data.image);
						formData.append("module_id", moduleId);
						formData.append("duration", "0");

						if (data.materials && data.materials.length > 0) {
							data.materials.forEach((material, index) => {
								formData.append(
									`materials[${index}][title]`,
									material.title as string,
								);
								formData.append(`materials[${index}][file]`, material.file);
							});
						}

						setIsLoading(true);

						const { status } = await addAcademyTrainingLesson(formData);

						if (status === "success") {
							onSuccessAdd();

							form.reset();
							setIsLoading(false);
							closeModal();

							return;
						}
					})();
				}

				if (!showMentorModal && isEdit) {
					form.handleSubmit(async (data) => {
						if (!lesson) return;

						onSuccess({
							id: lesson.id,
							name: data.name,
							description: data.description,
							mentor_id: data.mentor,
							link: data.video_link,
							module_id: lesson.module_id,
							duration: lesson.duration,
							image: data.image,
							materials: data.materials.map((material) => ({
								title: material.title as string,
								format: material.format,
								material_id: material.material_id as string,
								created_at: material.created_at as string,
								id: material.id as string,
								file: material.file,
							})),
						});

						form.reset();

						return;
					})();
				}

				if (showMentorModal) {
					createMentorForm.handleSubmit(async (data) => {
						setIsLoading(true);
						const formData = new FormData();
						formData.append("name", data.name);

						if (data.image instanceof File) {
							formData.append("image", data.image);
						}

						const { status } = await addAcademyMentor(formData);

						if (status === "success") {
							await fetchMentors();
							createMentorForm.reset();
							setIsLoading(false);
							setShowMentorModal(false);

							return;
						}
					})();
				}
			}}
			hasToggle={isEdit && !showMentorModal}
			toggleIsChecked={currentState}
			onToggleChange={async () => {
				if (!lesson) return;

				setIsLoading(true);
				const response = await updateAcademyTrainingLessonAvailable(
					{
						available: !currentState,
					},
					lesson.id,
				);

				if (response.status === "success") {
					setCurrentState(!currentState);
					setIsLoading(false);
				}

				setIsLoading(false);
			}}
			isLoading={isLoading}
			confirmText={isEdit ? "Salvar" : "Adicionar"}
		>
			{!showMentorModal && (
				<Form {...form}>
					<div className="flex flex-col gap-8">
						<div className="flex w-full flex-col items-center gap-6">
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="name"
									type="text"
									label="Nome da aula"
									hasError={!!form.formState.errors.name}
									{...form.register("name")}
								/>

								{form.formState.errors.name && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.name.message}
									</p>
								)}
							</div>

							<div className="flex w-full flex-col gap-2">
								<TextareaInput
									setValue={(e) => form.setValue("description", e)}
									value={form.watch("description") || ""}
									placeholder="Sobre a aula"
									showButtons={false}
									height="150px"
									maxLength={500}
									heightIsFixed
								/>

								{form.formState.errors.description && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.description.message}
									</p>
								)}
							</div>
						</div>

						<div className="flex w-full flex-col gap-6 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex justify-between">
								<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
									Mentor responsável
								</p>

								<button
									className="flex items-center justify-center gap-1 text-neutral-600 transition-all duration-300 hover:text-neutral-1100"
									onClick={() => setShowMentorModal(true)}
								>
									<MathPlus />

									<span className="font-inter text-P5 font-medium leading-160">
										Adicionar mentor
									</span>
								</button>
							</div>

							<FormField
								control={form.control}
								name="mentor"
								render={({ field }) => (
									<FormItem className="w-full">
										<Select
											onValueChange={(e) => {
												field.onChange(e);
											}}
											defaultValue={field.value}
											value={field.value}
										>
											<FormControl>
												<SelectTrigger
													className="w-full"
													hasError={!!form.formState.errors.mentor}
												>
													<SelectValue placeholder="Mentor responsável" />
												</SelectTrigger>
											</FormControl>
											<SelectContent>
												{mentors.map((mentor) => (
													<SelectItem key={mentor.id} value={mentor.id}>
														{mentor.name}
													</SelectItem>
												))}
											</SelectContent>
										</Select>

										{form.formState.errors.mentor && (
											<p className="font-inter text-P6 font-normal leading-160 text-red-600">
												{form.formState.errors.mentor.message}
											</p>
										)}
									</FormItem>
								)}
							/>
						</div>

						<div className="flex w-full flex-col gap-6 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex flex-col gap-2">
								<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Vídeo da aula
								</p>

								<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Adicione o link do vídeo para a aula
								</span>
							</div>

							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="name"
									type="text"
									label="Link do vídeo"
									hasError={!!form.formState.errors.video_link}
									{...form.register("video_link")}
								/>

								{form.formState.errors.video_link && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.video_link.message}
									</p>
								)}
							</div>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex flex-col gap-2">
								<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Imagem da aula
								</p>

								<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Essa será a capa do referente ao card da sua aula
								</span>
							</div>

							<div className="flex w-full flex-col gap-2">
								{typeof form.watch("image") === "string" &&
								form.watch("image") !== "" ? (
									<FileCard
										url={form.watch("image") as string}
										onRemove={() => form.setValue("image", "")}
									/>
								) : form.watch("image") instanceof File ? (
									<FileCard
										file={form.watch("image") as File}
										onRemove={() => form.setValue("image", "")}
									/>
								) : (
									<FileUploader
										maxFiles={1}
										maxSize={10 * 1024 * 1024}
										accept={{ "image/*": [".png", ".jpeg", ".jpg"] }}
										onUpload={async (e) => {
											form.setValue("image", e[0]);
										}}
										disabled={false}
									/>
								)}

								{form.formState.errors.image && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.image.message}
									</p>
								)}
							</div>
						</div>

						<div className="flex w-full flex-col gap-6 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex flex-col gap-2">
								<div className="flex justify-between">
									<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
										Material adicionado
									</p>

									<button
										className="flex items-center justify-center gap-1 text-neutral-600 transition-all duration-300 hover:text-neutral-1100"
										onClick={() => {
											setShowMaterialModal(true);
											setShowMentorModal(false);
										}}
									>
										<MathPlus />

										<span className="font-inter text-P5 font-medium leading-160">
											Adicionar material
										</span>
									</button>
								</div>
								<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Adicione materiais complementares em sua aula
								</span>
							</div>

							<div>
								<div className="relative text-neutral-200 focus-within:text-neutral-1100">
									<Input
										placeholder="Pesquisar"
										className="h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:text-P5 placeholder:text-neutral-200"
										onChange={(e) => handleSearch(e.target.value)}
										value={searchTerm}
									/>
									<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

									{searchTerm && (
										<button
											className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-200 text-neutral-1100"
											onClick={() => handleSearch("")}
										>
											<CloseIcon />
										</button>
									)}
								</div>

								<div className="mt-6 flex flex-col gap-6">
									{form.watch("materials")?.length > 0 &&
										form.watch("materials").map((material, index) => {
											const isFile = material.file instanceof File;

											return (
												<div key={index}>
													{isFile ? (
														<FileCard
															file={material.file as File}
															hasOnEdit
															onRemove={() => {
																handleRemoveMaterial(index);
															}}
															onEdit={() => {
																setEditIndex(index); // Define o índice de edição
																setShowMaterialModal(true); // Abre o modal
															}}
														/>
													) : (
														<FileCard
															url={material.file as string}
															hasOnEdit
															onRemove={() => {
																handleRemoveMaterial(index);
															}}
															onEdit={() => {
																setEditIndex(index); // Define o índice de edição
																setShowMaterialModal(true); // Abre o modal
															}}
														/>
													)}
												</div>
											);
										})}
								</div>

								{!form.watch("materials")?.length && (
									<div className="flex flex-col items-center justify-center gap-6 px-10 py-14">
										<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
											<SearchIcon />
										</span>

										<div className="flex flex-col gap-2">
											<h3 className="text-center font-inter font-medium leading-160 text-neutral-1100">
												{searchTerm
													? "Não foi possível encontrar nenhum material"
													: "No momento você não possui nenhum material complementar adicionado!"}
											</h3>

											<p className="text-center font-inter font-normal leading-160 text-neutral-500">
												{searchTerm
													? "Tente novamente com outra palavra chave"
													: "Adicione um material e ele poderá ser visualizado e gerenciado por aqui."}
											</p>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</Form>
			)}

			{showMaterialModal && (
				<AdminAcademyShowModalLessonMaterial
					closeModal={() => {
						setShowMaterialModal(false);
						setEditIndex(null);
					}}
					currentMaterial={
						editIndex !== null
							? {
									id: form.watch("materials")[editIndex].id,
									title: form.watch("materials")[editIndex].title || "",
									format: form.watch("materials")[editIndex].format,
									file: form.watch("materials")[editIndex].file as string,
									material_id:
										form.watch("materials")[editIndex].material_id || "",
									link: form.watch("materials")[editIndex].file as string,
								}
							: null
					}
					isEditing={editIndex !== null} // Indica se estamos editando
					onSuccess={handleAddOrEditMaterial}
				/>
			)}

			{showMentorModal && (
				<Form {...createMentorForm}>
					<div className="flex flex-col gap-8">
						<div className="flex w-full flex-col items-center gap-6">
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="name"
									type="text"
									label="Nome do produto"
									hasError={!!createMentorForm.formState.errors.name}
									{...createMentorForm.register("name")}
								/>

								{createMentorForm.formState.errors.name && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{createMentorForm.formState.errors.name.message}
									</p>
								)}
							</div>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex flex-col gap-2">
								<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Imagem do mentor
								</p>

								<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Será a imagem referente ao perfil do mentor
								</span>
							</div>

							<div className="flex w-full flex-col gap-2">
								{typeof createMentorForm.watch("image") === "string" &&
								createMentorForm.watch("image") !== "" ? (
									<FileCard
										url={createMentorForm.watch("image") as string}
										onRemove={() => createMentorForm.setValue("image", "")}
									/>
								) : createMentorForm.watch("image") instanceof File ? (
									<FileCard
										file={createMentorForm.watch("image") as File}
										onRemove={() => createMentorForm.setValue("image", "")}
									/>
								) : (
									<FileUploader
										maxFiles={1}
										maxSize={10 * 1024 * 1024}
										accept={{ "image/*": [".png", ".jpeg", ".jpg"] }}
										onUpload={async (e) => {
											createMentorForm.setValue("image", e[0]);
										}}
										disabled={false}
									/>
								)}

								{createMentorForm.formState.errors.image && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{createMentorForm.formState.errors.image.message}
									</p>
								)}
							</div>
						</div>
					</div>
				</Form>
			)}
		</Modal>
	);
}
