import {
	DragDropContext,
	Draggable,
	type DraggableStyle,
	Droppable,
	type DropResult,
} from "@hello-pangea/dnd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { deleteAcademyTraining } from "@/api/admin/academy/trainings/delete-training";
import { deleteAcademyTrainingLesson } from "@/api/admin/academy/trainings/lesson/delete-lesson";
import { moveAcademyTrainingLesson } from "@/api/admin/academy/trainings/lesson/move-lesson";
import { updateAcademyTrainingLesson } from "@/api/admin/academy/trainings/lesson/update-lesson";
import {
	fetchAdminMentors,
	type Mentor,
} from "@/api/admin/academy/trainings/mentor/list-mentors";
import { deleteAcademyTrainingModule } from "@/api/admin/academy/trainings/module/delete-module";
import { moveAcademyTrainingModule } from "@/api/admin/academy/trainings/module/move-module";
import {
	type Module,
	showAcademyTraining,
	type Training,
} from "@/api/admin/academy/trainings/show-training";
import { updateAcademyTraining } from "@/api/admin/academy/trainings/update-training";
import MathPlus from "@/assets/math-plus.svg?react";
import PenIcon from "@/assets/pen.svg?react";
import TrashIcon from "@/assets/trash.svg?react";
import WarningsIcon from "@/assets/warnings.svg?react";
import { AdminLessonCard } from "@/components/admin-lesson-card";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/admin-training-accordion";
import { AlertModal } from "@/components/alert-modal";
import { DeleteModal } from "@/components/delete-modal";
import { SkeletonAdminTrainingAccordion } from "@/components/skeletons/admin-training-accordion";
import { Skeleton } from "@/components/ui/skeleton";
import { WarningModal } from "@/components/warning-modal";
import { useNavigationStore } from "@/store/navigation-store";

import { AdminAcademyShowEdit } from "./edite";
import { AdminAcademyShowModalLesson } from "./modal-lesson";
import { AdminAcademyShowModalModule } from "./modal-module";

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
}

const grid = 8;

function getItemStyle(
	isDragging: boolean,
	draggableStyle: DraggableStyle | undefined,
) {
	return {
		userSelect: "none" as const,
		margin: `0 0 ${grid}px 0`,
		background: isDragging ? "rgba(255, 255, 255, 0.05)" : "transparent",
		borderRadius: 6,
		...draggableStyle,
	};
}

export function AdminAcademyShow() {
	const { id } = useParams<{
		id: string;
	}>();

	// Estados para gerenciar os dados principais
	const [modules, setModules] = useState<Module[]>([]);
	// Armazena a lista de módulos de um treinamento

	const [training, setTraining] = useState<Training | null>(null);
	// Armazena os dados do treinamento atual, como nome, mentor, etc.

	const [mentors, setMentors] = useState<Mentor[]>([]);
	// Armazena a lista de mentores disponíveis

	const [currentModule, setCurrentModule] = useState<Module | null>(null);
	// Armazena o módulo atual que está sendo editado ou visualizado

	// Estados para controle dos modais
	const [showModalCreateModule, setShowModalModule] = useState(false);
	// Controla a visibilidade do modal de criação/edição de módulo

	const [showModalTraining, setShowModalTraining] = useState(false);
	// Controla a visibilidade do modal de edição do treinamento

	const [showModalLesson, setShowModalLesson] = useState(false);
	// Controla a visibilidade do modal de adição/edição de aula

	const [deleteModal, setDeleteModal] = useState(false);
	// Controla a visibilidade do modal de confirmação de exclusão do treinamento

	const [successDeleteModal, setSuccessDeleteModal] = useState(false);
	// Controla a visibilidade do modal de sucesso após exclusão do treinamento

	const [successModal, setSuccessModal] = useState(false);
	// Controla a visibilidade do modal de sucesso ao salvar as edições do treinamento

	const [warningModal, setWarningModal] = useState(false);
	// Controla a visibilidade do modal de aviso quando alterações estão sendo salvas

	const [successAddModule, setSuccessAddModule] = useState(false);
	// Controla a visibilidade do modal de sucesso ao adicionar um novo módulo

	// Estados para gerenciar modos de edição
	const [isEditingModule, setIsEditingModule] = useState(false);
	// Controla se um módulo está em modo de edição

	const [isEditingTraining, setIsEditingTraining] = useState(false);
	// Controla se o treinamento está em modo de edição

	const [isEditingLesson, setIsEditingLesson] = useState(false);
	// Controla se uma aula está em modo de edição

	// Estado de carregamento (loading) geral
	const [isLoading, setIsLoading] = useState(false);
	// Indica se alguma ação está em andamento, como exclusão ou carregamento de dados

	// Estado para armazenar os dados atualizados de um treinamento
	const [updatedTraining, setUpdatedTraining] = useState<{
		name: string;
		mentor: string;
		image: string | File;
	} | null>(null);
	// Armazena os dados atualizados temporariamente antes de confirmar a atualização de um treinamento

	// Estado para exibir o modal de confirmação de exclusão do módulo
	const [showDeleteModuleModal, setShowDeleteModuleModal] = useState(false);

	// Estado para exibir o modal de sucesso após a exclusão do módulo
	const [showDeleteModuleSuccessModal, setShowDeleteModuleSuccessModal] =
		useState(false);

	// Estado para selecionar a lesson atual
	const [currentLesson, setCurrentLesson] = useState<string | null>(null);

	// Estado para exibir o modal de aviso ao salvar as alterações de uma aula
	const [warningUpdateLessonModal, setWarningUpdateLessonModal] =
		useState(false);

	// Estado para exibir o modal de sucesso ao salvar as alterações de uma aula
	const [successUpdateLessonModal, setSuccessUpdateLessonModal] =
		useState(false);

	// Estado para armazenar os dados atualizados de uma aula
	const [updatedLesson, setUpdatedLesson] = useState<{
		id: string;
		name: string;
		description: string;
		mentor_id: string;
		link: string;
		duration: number;
		image: string | File;
		module_id: string;
		materials: {
			id?: string;
			material_id?: string;
			title: string;
			format: string;
			created_at?: string;
			file: File | string;
		}[];
	} | null>(null);

	// Estado para exibir o modal de sucesso criar uma aula
	const [successAddLesson, setSuccessAddLesson] = useState(false);

	// Estado para exibir o modal de confirmação de exclusão de uma aula
	const [showDeleteLessonModal, setShowDeleteLessonModal] = useState(false);

	// Estado para exibir o modal de sucesso após a exclusão de uma aula
	const [showDeleteLessonSuccessModal, setShowDeleteLessonSuccessModal] =
		useState(false);

	const navigate = useNavigate();

	async function getTrainingById() {
		if (!id) return navigate("/admin/academy");

		const response = await showAcademyTraining(id);

		if (response.status === "success") {
			setTraining(response.data);
			setModules(response.data.modules);
		}

		if (response.status === "error") {
			navigate("/admin/academy");
		}
	}

	async function fetchMentors() {
		setIsLoading(true);
		const response = await fetchAdminMentors();

		if (response.status === "success") {
			setMentors(response.data);
		}

		setIsLoading(false);
	}

	async function handleDeleteTraining() {
		if (!training) return;

		setIsLoading(true);

		const response = await deleteAcademyTraining(training.id);

		if (response.status === "success") {
			setDeleteModal(false);
			setSuccessDeleteModal(true);
			setIsLoading(false);
		}
	}

	async function handleDeleteModule() {
		if (!currentModule) return;

		setIsLoading(true);
		const response = await deleteAcademyTrainingModule(currentModule.id);

		if (response.status === "success") {
			setShowDeleteModuleModal(false);
			setShowDeleteModuleSuccessModal(true);

			await getTrainingById().then(() => {
				setIsLoading(false);
			});
		}

		setIsLoading(false);
	}

	async function handleDeleteLesson() {
		if (!currentLesson) return;

		setIsLoading(true);
		const response = await deleteAcademyTrainingLesson(currentLesson);

		if (response.status === "success") {
			setShowDeleteLessonModal(false);
			setShowDeleteLessonSuccessModal(true);

			await getTrainingById().then(() => {
				setIsLoading(false);
			});
		}

		setIsLoading(false);
	}

	async function handleMoveModule(id: string, position: number) {
		if (!id) return;

		setIsLoading(true);

		await moveAcademyTrainingModule(id, position);

		setIsLoading(false);
	}

	async function handleMoveLesson(id: string, position: number) {
		if (!id) return;

		setIsLoading(true);

		await moveAcademyTrainingLesson(id, position);

		setIsLoading(false);
	}

	async function onDragEnd(result: DropResult) {
		const { source, destination } = result;

		// Se não houver destino, significa que o item foi solto fora da área permitida
		if (!destination) {
			return;
		}

		// Movimentação de módulos
		if (result.type === "MODULES") {
			const findDragModule = modules.find((_, index) => index === source.index);

			if (!findDragModule) return;

			await handleMoveModule(findDragModule.id, destination.index);

			const reorderedModules = reorder(
				modules,
				source.index,
				destination.index,
			);
			setModules(reorderedModules);
		}

		// Movimentação de lições dentro de um módulo
		if (result.type === "LESSONS") {
			const moduleIndex = parseInt(result.source.droppableId.split("-")[1]);

			const findDragLesson = modules[moduleIndex].lessons.find(
				(_, index) => index === source.index,
			);

			if (!findDragLesson) return;

			await handleMoveLesson(findDragLesson.id, destination.index);

			const lessons = reorder(
				modules[moduleIndex].lessons,
				result.source.index,
				destination.index,
			);
			const newModules = [...modules];
			newModules[moduleIndex].lessons = lessons;
			setModules(newModules);
		}
	}

	useEffect(() => {
		getTrainingById();
		fetchMentors();
	}, []);

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Configurações",
				path: "/admin",
			},
			{
				name: "Academy",
				path: "/admin/academy",
			},
			{
				name: "Treinamentos",
				path: "/admin/academy",
			},
			{
				name: training?.name ?? "Carregando...",
				path: `/admin/academy/${id}`,
			},
		];

		setPaths(paths);
	}, [training]);

	return (
		<section className="flex w-full flex-col gap-12">
			<div className="flex items-center justify-between gap-6">
				{!isLoading ? (
					<h3 className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
						{training?.name}
					</h3>
				) : (
					<Skeleton className="h-8 w-72" />
				)}

				<div className="flex items-center gap-6">
					<button
						className="flex items-center justify-center gap-1 text-neutral-600 transition-all duration-300 hover:text-neutral-1100"
						onClick={() => setDeleteModal(true)}
					>
						<TrashIcon />
						<span className="font-inter text-P5 font-medium leading-160">
							Excluir
						</span>
					</button>

					<div className="h-6 w-[1px] bg-neutral-100" />

					<button
						className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 px-6 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400"
						disabled={isLoading}
						onClick={() => {
							setShowModalTraining(true);
							setIsEditingTraining(true);
						}}
					>
						<PenIcon />
						<p className="font-inter text-P6 font-semibold leading-160">
							Editar detalhes
						</p>
					</button>

					<button
						className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-primary-600 px-6 text-neutral-1100 transition-all duration-300 hover:bg-primary-700"
						onClick={() => setShowModalModule(!showModalCreateModule)}
					>
						<MathPlus />
						<p className="font-inter text-P6 font-semibold leading-160">
							Novo módulo
						</p>
					</button>
				</div>
			</div>

			<div>
				{isLoading &&
					Array.from({
						length: 6,
					}).map((_, index) => <SkeletonAdminTrainingAccordion key={index} />)}
			</div>

			{!isLoading && (
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable-modules" type="MODULES">
						{(provided) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								style={{
									background: "transparent",
									width: "100%",
								}}
							>
								{modules.map((module, moduleIndex) => (
									<Draggable
										key={moduleIndex}
										draggableId={`module-${moduleIndex}`}
										index={moduleIndex}
									>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												style={getItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style,
												)}
											>
												<div {...provided.dragHandleProps}>
													<Accordion
														key={moduleIndex}
														type="single"
														collapsible
													>
														<AccordionItem value={`item-${moduleIndex}`}>
															<AccordionTrigger
																module={moduleIndex + 1}
																title={module.name}
																addedLessons={module.lessons.length}
																addNewLesson={() => {
																	setCurrentModule(module);
																	setShowModalLesson(true);
																	setIsEditingLesson(false);
																}}
																editModule={() => {
																	setIsEditingModule(true);
																	setShowModalModule(true);
																	setCurrentModule(module);
																}}
																deleteModule={() => {
																	setCurrentModule(module);
																	setShowDeleteModuleModal(true);
																}}
															/>
															<AccordionContent>
																<Droppable
																	droppableId={`droppable-${moduleIndex}`}
																	type="LESSONS"
																>
																	{(provided) => (
																		<div
																			{...provided.droppableProps}
																			ref={provided.innerRef}
																			style={{
																				background: "transparent",
																				width: "100%",
																			}}
																		>
																			{module.lessons.map((lesson, index) => (
																				<Draggable
																					key={lesson.id}
																					draggableId={lesson.id}
																					index={index}
																				>
																					{(provided, snapshot) => (
																						<div
																							ref={provided.innerRef}
																							{...provided.draggableProps}
																							{...provided.dragHandleProps}
																							style={getItemStyle(
																								snapshot.isDragging,
																								provided.draggableProps.style,
																							)}
																						>
																							<AdminLessonCard
																								id={lesson.id}
																								image={lesson.thumbnail}
																								lesson={index + 1}
																								title={lesson.name}
																								onEdit={() => {
																									setCurrentLesson(lesson.id);
																									setIsEditingLesson(true);
																									setShowModalLesson(true);
																								}}
																								onDelete={() => {
																									setCurrentLesson(lesson.id);
																									setShowDeleteLessonModal(
																										true,
																									);
																								}}
																							/>
																						</div>
																					)}
																				</Draggable>
																			))}
																			{provided.placeholder}
																		</div>
																	)}
																</Droppable>
															</AccordionContent>
														</AccordionItem>
													</Accordion>
												</div>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			)}

			{!isLoading && modules.length === 0 && (
				<div className="flex flex-col items-center justify-center gap-6 px-10 py-36">
					<span className="flex items-center justify-center rounded-[14px] border border-red-1000 bg-red-600 bg-opacity-[0.04] p-5 text-red-600">
						<WarningsIcon />
					</span>

					<div className="flex flex-col gap-2">
						<h3 className="text-center font-inter text-P2 font-medium leading-160 text-neutral-1100">
							Seu treinamento está desativado por <br />
							não ter nenhum módulo e aula adicionada!
						</h3>

						<p className="text-center font-inter font-normal leading-160 text-neutral-500">
							Adicione pelo menos um módulo para ativar seu treinamento.
						</p>
					</div>
				</div>
			)}

			{training && showModalTraining && (
				<AdminAcademyShowEdit
					title="Editar treinamento"
					training={training}
					mentors={mentors}
					closeModal={() => {
						setShowModalTraining(false);
						setIsEditingTraining(false);
					}}
					refreshMentors={fetchMentors}
					isEdit={isEditingTraining}
					onSuccess={async (e) => {
						setUpdatedTraining(e);
						setWarningModal(true);
						setShowModalTraining(false);
					}}
				/>
			)}

			{warningModal && (
				<WarningModal
					title="Deseja realmente salvar as novas alterações?"
					description="Os dados anteriores não serão salvos."
					icon="warning-2.svg"
					isLoading={isLoading}
					cancelAction={() => {
						setWarningModal(false);
						setShowModalTraining(true);
					}}
					confirmAction={async () => {
						if (!training) return;

						if (!updatedTraining) return;

						const formData = new FormData();

						formData.append("name", updatedTraining.name);
						formData.append("mentor_id", updatedTraining.mentor);
						formData.append("description", updatedTraining.name);

						if (updatedTraining.image instanceof File) {
							formData.append("image", updatedTraining.image);
						}

						setIsLoading(true);
						const { status } = await updateAcademyTraining(
							training.id,
							formData,
						);

						if (status === "success") {
							await getTrainingById().then(() => {
								setIsLoading(false);
								setSuccessModal(true);
								setWarningModal(false);
							});
						}
					}}
					closeModal={() => {
						setWarningModal(false);
						setShowModalTraining(true);
					}}
				/>
			)}

			{successModal && (
				<AlertModal
					title="Alterações salvas com sucesso!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessModal(false);
						setShowModalTraining(true);
					}}
					closeModal={() => {
						setSuccessModal(false);
						setShowModalTraining(true);
					}}
				/>
			)}

			{warningUpdateLessonModal && (
				<WarningModal
					title="Deseja realmente salvar as novas alterações?"
					description="Os dados anteriores não serão salvos."
					icon="warning-2.svg"
					isLoading={isLoading}
					cancelAction={() => {
						setWarningUpdateLessonModal(false);
					}}
					confirmAction={async () => {
						if (!updatedLesson) return;

						const formData = new FormData();

						formData.append("name", updatedLesson.name);
						formData.append("description", updatedLesson.description);
						formData.append("mentor_id", updatedLesson.mentor_id);
						formData.append("link", updatedLesson.link);
						formData.append("module_id", updatedLesson.module_id);
						formData.append("duration", updatedLesson.duration.toString());

						if (updatedLesson.image instanceof File) {
							formData.append("image", updatedLesson.image);
						}

						updatedLesson.materials.forEach((material, index) => {
							formData.append(
								`materials[${index}][title]`,
								material.title as string,
							);

							if (material.file instanceof File) {
								formData.append(
									`materials[${index}][file]`,
									material.file as File,
								);
							}

							if (!(material.file instanceof File)) {
								formData.append(
									`materials[${index}][id]`,
									material.id as string,
								);

								formData.append(
									`materials[${index}][link]`,
									material.file as string,
								);
							}
						});

						setIsLoading(true);

						const { status } = await updateAcademyTrainingLesson(
							updatedLesson.id,
							formData,
						);

						if (status === "success") {
							setIsLoading(false);
							setSuccessUpdateLessonModal(true);
							setWarningUpdateLessonModal(false);
						}
					}}
					closeModal={() => {
						setWarningModal(false);
						setShowModalTraining(true);
					}}
				/>
			)}

			{successUpdateLessonModal && (
				<AlertModal
					title="Alterações salvas com sucesso!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessUpdateLessonModal(false);
					}}
					closeModal={() => {
						setSuccessUpdateLessonModal(false);
					}}
				/>
			)}

			{training && showModalCreateModule && (
				<AdminAcademyShowModalModule
					title={isEditingModule ? "Editar módulo" : "Novo módulo"}
					closeModal={() => {
						setShowModalModule(false);
						setIsEditingModule(false);
					}}
					trainingId={training.id}
					isEdit={isEditingModule}
					module={currentModule ?? undefined}
					onSuccess={() => {
						setSuccessAddModule(true);
						getTrainingById();
					}}
				/>
			)}

			{successAddModule && (
				<AlertModal
					title={
						!isEditingModule
							? "Alterações salvas com sucesso!"
							: "Módulo adicionado com sucesso!"
					}
					description={
						!isEditingModule
							? ""
							: "Agora você já pode adicionar aulas em seu módulo."
					}
					icon="done.svg"
					confirmText={!isEditingModule ? "Concluir" : "Adicionar aulas"}
					cancelText="Adicionar mais tarde"
					hasCancel={isEditingLesson}
					isLoading={isLoading}
					confirmAction={() => {
						setSuccessAddModule(false);
						setCurrentModule(null);
					}}
					cancelAction={() => {
						setSuccessAddModule(false);
						setCurrentModule(null);
					}}
					closeModal={() => {
						setSuccessAddModule(false);
						setCurrentModule(null);
					}}
				/>
			)}

			{showModalLesson && (
				<AdminAcademyShowModalLesson
					lessonId={currentLesson ?? ""}
					moduleId={currentModule?.id ?? ""}
					closeModal={() => {
						setShowModalLesson(false);
						setIsEditingLesson(false);
						setCurrentLesson(null);
					}}
					isEdit={isEditingLesson}
					onSuccess={async (e) => {
						setUpdatedLesson({
							description: e.description,
							duration: e.duration,
							id: e.id,
							image: e.image,
							link: e.link,
							mentor_id: e.mentor_id,
							materials: e.materials,
							module_id: e.module_id,
							name: e.name,
						});
						setWarningUpdateLessonModal(true);
						setShowModalLesson(false);
					}}
					onSuccessAdd={() => {
						setSuccessAddLesson(true);
						getTrainingById();
					}}
				/>
			)}

			{deleteModal && (
				<DeleteModal
					title="Deseja realmente excluir esse treinamento?"
					description="Essa ação não poderá ser desfeita."
					icon="warning.svg"
					confirmText="Excluir treinamento"
					isLoading={isLoading}
					cancelAction={() => {
						setDeleteModal(false);
					}}
					confirmAction={handleDeleteTraining}
					closeModal={() => {
						setDeleteModal(false);
					}}
				/>
			)}

			{successDeleteModal && (
				<AlertModal
					title="Treinamento excluído!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessDeleteModal(false);

						navigate("/admin/academy");
					}}
					closeModal={() => {
						setSuccessDeleteModal(false);
						navigate("/admin/academy");
					}}
				/>
			)}

			{showDeleteModuleModal && (
				<DeleteModal
					title="Deseja realmente excluir este módulo?"
					description="Essa ação não poderá ser desfeita."
					icon="warning.svg"
					confirmText="Excluir módulo"
					isLoading={isLoading}
					cancelAction={() => {
						setShowDeleteModuleModal(false);
						setCurrentModule(null);
					}}
					confirmAction={handleDeleteModule}
					closeModal={() => {
						setShowDeleteModuleModal(false);
						setCurrentModule(null);
					}}
				/>
			)}

			{showDeleteModuleSuccessModal && (
				<AlertModal
					title="Módulo excluído com sucesso!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setShowDeleteModuleSuccessModal(false);
					}}
					closeModal={() => {
						setShowDeleteModuleSuccessModal(false);
					}}
				/>
			)}

			{showDeleteLessonModal && (
				<DeleteModal
					title="Deseja realmente excluir essa aula?"
					description="Essa ação não poderá ser desfeita."
					icon="warning.svg"
					confirmText="Excluir aula"
					isLoading={isLoading}
					cancelAction={() => {
						setShowDeleteLessonModal(false);
						setCurrentLesson(null);
					}}
					confirmAction={handleDeleteLesson}
					closeModal={() => {
						setShowDeleteLessonModal(false);
						setCurrentLesson(null);
					}}
				/>
			)}

			{showDeleteLessonSuccessModal && (
				<AlertModal
					title="Módulo excluído com sucesso!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setShowDeleteLessonSuccessModal(false);
					}}
					closeModal={() => {
						setShowDeleteLessonSuccessModal(false);
					}}
				/>
			)}

			{successAddLesson && (
				<AlertModal
					title="Aula adicionada com sucesso!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessAddLesson(false);
					}}
					closeModal={() => {
						setSuccessAddLesson(false);
					}}
				/>
			)}
		</section>
	);
}
