import { useEffect, useReducer, useState } from "react";

import {
	getDashboardMoviments,
	type Moviment,
} from "@/api/app/dashboard/moviments";
import CloseIcon from "@/assets/close.svg?react";
import CopyIcon from "@/assets/copy.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import { PaginationList } from "@/components/pagination-list";
import { Tag } from "@/components/tag";
import { Input } from "@/components/ui/input";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";

export function DashboardSalesMovements() {
	const [isLoading, setIsLoading] = useState(false);
	const [dashboardMoviments, setDashboardMoviments] = useState<Moviment[]>([]);
	const [paginationData, setPaginationData] = useState({
		lastPage: 1,
		page: 1,
		perPage: 10,
		total: 0,
	});
	const [searchTerm, setSearchTerm] = useState("");

	type Platforms = "Braip" | "Monetizze" | "The Mart";

	function reducer(
		state: {
			currentPage: number;
			lastPage: number;
			total: number;
			to: number;
		},
		action: {
			type: string;
			payload?: {
				lastPage?: number;
				total?: number;
				to?: number;
				page?: number;
			};
		},
	) {
		switch (action.type) {
			case "SET_INITIAL_STATE":
				return {
					...state,
					lastPage: action.payload?.lastPage ?? state.lastPage,
					total: action.payload?.total ?? state.total,
					to: action.payload?.to ?? state.to,
					currentPage: action.payload?.page ?? state.currentPage,
				};
			case "NEXT_PAGE":
				return {
					...state,
					currentPage: state.currentPage + 1,
					to: state.to + state.to,
				};
			case "PREVIOUS_PAGE":
				return {
					...state,
					currentPage: state.currentPage - 1,
					to: state.to - state.to,
				};
			case "PAGE_CHANGE":
				return {
					...state,
					currentPage: action.payload?.page ?? state.currentPage,
					to: (action.payload?.page ?? state.currentPage) * state.to,
				};
			default:
				return state;
		}
	}

	const [state, dispatch] = useReducer(reducer, {
		currentPage: paginationData.page,
		lastPage: paginationData.lastPage,
		total: paginationData.total,
		to: paginationData.perPage,
	});

	useEffect(() => {
		dispatch({
			type: "SET_INITIAL_STATE",
			payload: {
				lastPage: paginationData.lastPage,
				total: paginationData.total,
				page: paginationData.page,
				to: paginationData.perPage,
			},
		});
	}, [paginationData]);

	const platformColors: {
		[key in Platforms]:
			| "minimalist-pink"
			| "minimalist-blue"
			| "minimalist-orange";
	} = {
		Braip: "minimalist-pink",
		Monetizze: "minimalist-blue",
		"The Mart": "minimalist-orange",
	};

	async function handleDashboardMoviments() {
		setIsLoading(true);
		const response = await getDashboardMoviments({
			externalKey: searchTerm || undefined,
			page: state.currentPage,
		});

		if (response.status === "success") {
			const { data, lastPage, page, perPage, total } = response;
			setDashboardMoviments(data);
			setPaginationData({ lastPage, page, perPage, total });
		}

		setIsLoading(false);
	}

	useEffect(() => {
		handleDashboardMoviments();
	}, [state.currentPage, searchTerm]);

	return (
		<div className="flex flex-col gap-10 p-6">
			<h2 className="font-inter text-P3 font-medium leading-140 text-neutral-1100">
				Movimentações por vendas
			</h2>

			<div className="flex flex-col gap-8">
				<div
					className={`relative ${dashboardMoviments.length > 0 ? "text-neutral-600" : "text-neutral-200"} focus-within:text-neutral-1100`}
				>
					<Input
						placeholder="Pesquise pelo código da venda"
						className={`h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:!text-P5 ${
							dashboardMoviments.length > 0
								? "placeholder:text-neutral-600"
								: "border-neutral-100 placeholder:text-neutral-200"
						}`}
						onChange={(e) => setSearchTerm(e.target.value)}
						value={searchTerm}
					/>
					<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

					{searchTerm !== "" && (
						<button
							className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-200 text-neutral-1100"
							onClick={() => setSearchTerm("")}
						>
							<CloseIcon />
						</button>
					)}
				</div>

				{dashboardMoviments.length === 0 && (
					<div className="flex h-full w-full flex-1 flex-col items-center justify-center gap-6 py-32">
						<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
							<Search2Icon />
						</span>
						<div className="flex flex-col items-center gap-2">
							<h3 className="text-center font-inter text-P2 font-medium leading-140 text-neutral-1100">
								No momento você não possui nenhuma venda realizada
							</h3>
							<p className="text-center font-inter text-P3 font-normal leading-160 text-neutral-500">
								Verifique se os filtros selecionados estão corretos
							</p>
						</div>
					</div>
				)}

				{!isLoading && dashboardMoviments.length > 0 && (
					<>
						<Table>
							<TableHeader>
								<TableRow>
									<TableHead className="w-[20%]">Produto</TableHead>
									<TableHead className="w-[15%]">Valor</TableHead>
									<TableHead className="w-[15%]">Comissão</TableHead>
									<TableHead className="w-[20%]">Data</TableHead>
									<TableHead className="w-[10%]">Plataforma</TableHead>
									<TableHead className="w-[20%]">Código de rastreio</TableHead>
									<TableHead className="w-[20%]">Código da venda</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{dashboardMoviments.map((sale, index) => (
									<TableRow key={index}>
										<TableCell>
											<p className="line-clamp-1 text-ellipsis font-inter text-P5 font-normal leading-160 text-neutral-1100">
												{sale.productName}
											</p>
										</TableCell>
										<TableCell>
											<p className="line-clamp-1 text-ellipsis font-inter text-P5 font-normal leading-160 text-neutral-1100">
												{new Intl.NumberFormat("pt-BR", {
													style: "currency",
													currency: "BRL",
												}).format(Number(sale.value))}
											</p>
										</TableCell>
										<TableCell>
											<p className="line-clamp-1 text-ellipsis font-inter text-P5 font-normal leading-160 text-neutral-1100">
												{new Intl.NumberFormat("pt-BR", {
													style: "currency",
													currency: "BRL",
												}).format(Number(sale.commission_value))}
											</p>
										</TableCell>
										<TableCell>
											<p className="line-clamp-1 text-ellipsis font-inter text-P5 font-normal leading-160 text-neutral-600">
												{sale.created_at}
											</p>
										</TableCell>
										<TableCell>
											<div>
												<Tag
													size="small"
													colors={platformColors[sale.platform as Platforms]}
													className="w-fit rounded px-[8px] py-1 text-P7"
												>
													{sale.platform}
												</Tag>
											</div>
										</TableCell>
										<TableCell>
											<div className="flex cursor-pointer items-center gap-2 text-neutral-1100">
												<p className="font-inter text-P5 font-medium leading-160 underline underline-offset-4">
													{sale.trackingCode}
												</p>

												<span className="relative cursor-pointer">
													<CopyIcon width={16} height={16} />
												</span>
											</div>
										</TableCell>
										<TableCell>
											<div className="flex cursor-pointer items-center gap-2 text-neutral-1100">
												<p className="font-inter text-P5 font-medium leading-160 underline underline-offset-4">
													{sale.externalKey}
												</p>

												<span className="relative cursor-pointer">
													<CopyIcon width={16} height={16} />
												</span>
											</div>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						<PaginationList
							currentPage={state.currentPage}
							lastPage={state.lastPage}
							to={state.to > state.total ? state.total : state.to}
							total={state.total}
							onNextPage={() => dispatch({ type: "NEXT_PAGE" })}
							onPageChange={(page) =>
								dispatch({ type: "PAGE_CHANGE", payload: { page } })
							}
							onPreviousPage={() => dispatch({ type: "PREVIOUS_PAGE" })}
						/>
					</>
				)}
			</div>
		</div>
	);
}
