import { Skeleton } from "@/components/ui/skeleton";

export function SkeletonAdminTrainingAccordion() {
	return (
		<div className="mb-6 flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-100 p-6 transition-all duration-300">
			<div className="flex items-center justify-between">
				<div className="flex flex-col gap-0.5">
					<Skeleton className="h-4 w-16" />
					<Skeleton className="h-6 w-40" />
				</div>

				<div className="relative flex cursor-pointer items-center justify-center rounded-[8px] bg-transparent p-2 text-neutral-1100 transition-all duration-300">
					<Skeleton className="h-6 w-6" />
				</div>
			</div>

			<hr className="h-px border-t-neutral-100" />

			<div className="flex items-center justify-between">
				<Skeleton className="h-8 w-40 rounded-[6px]" />

				<div className="flex items-center gap-4">
					<Skeleton className="h-10 w-32 rounded-[6px]" />
					<Skeleton className="h-10 w-10 rounded-[6px]" />
				</div>
			</div>
		</div>
	);
}
