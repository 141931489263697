import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import { resetForgotPassword } from "@/api/auth/forgot-password/reset";
import { PasswordStrength } from "@/components/password-strength";
import { Button } from "@/components/ui/button";
import { FloatingLabelInput } from "@/components/ui/float-input";

const forgotPasswordChagePasswordForm = z
	.object({
		password: z.string().min(8, {
			message: "A senha precisa ter no mínimo 8 caracteres",
		}),
		password_confirmation: z.string().min(8, {
			message: "A senha precisa ter no mínimo 8 caracteres",
		}),
	})
	.refine((data) => data.password === data.password_confirmation, {
		message: "As senhas não conferem",
		path: ["confirmPassword"],
	});

type ForgotPasswordChagePasswordForm = z.infer<
	typeof forgotPasswordChagePasswordForm
>;

export function ForgotPasswordChagePassword() {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const [params] = useSearchParams();

	const email = params.get("email");
	const token = params.get("token");

	console.log(email, token);

	useEffect(() => {
		if (!email || !token) {
			navigate("/forgot-password");
		}
	}, []);

	const {
		register,
		handleSubmit,
		setError,
		watch,
		formState: { errors },
	} = useForm<ForgotPasswordChagePasswordForm>({
		resolver: zodResolver(forgotPasswordChagePasswordForm),
	});

	async function handleForgotPasswordChagePassword({
		password,
		password_confirmation,
	}: ForgotPasswordChagePasswordForm) {
		setIsLoading(true);
		const response = await resetForgotPassword({
			email: email as string,
			token: token as string,
			password,
			password_confirmation,
		});

		if (response.status === "success") {
			navigate("/forgot-password-confirm");
			setIsLoading(false);
			return;
		}

		if (response.status === "error") {
			const { errors } = response;

			for (const key in errors) {
				setError(key as keyof ForgotPasswordChagePasswordForm, {
					message: errors[key as keyof ForgotPasswordChagePasswordForm],
				});
			}

			setIsLoading(false);
			return;
		}
	}

	return (
		<>
			<Helmet title="Esqueci a senha" />

			<div className="flex animate-fade flex-col gap-12">
				<div className="flex flex-col gap-2">
					<h1 className="font-poppins text-H7 font-semibold leading-140 text-white">
						Alterar senha
					</h1>
					<p className="font-inter text-P5 leading-160 text-neutral-500 md:text-P4">
						Digite sua nova senha abaixo. Ela precisa ser segura.
					</p>
				</div>

				<form
					onSubmit={handleSubmit(handleForgotPasswordChagePassword)}
					className="flex flex-col gap-10"
				>
					<div className="flex flex-col gap-6">
						<div className="flex flex-col gap-2">
							<FloatingLabelInput
								id="password"
								type="password"
								label="Nova senha"
								hasError={!!errors.password}
								{...register("password")}
							/>

							{errors.password && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{errors.password.message}
								</p>
							)}
						</div>

						<div className="flex flex-col gap-2">
							<FloatingLabelInput
								id="confirmPassword"
								type="password"
								label="Confirme a nova senha"
								hasError={!!errors.password_confirmation}
								{...register("password_confirmation")}
							/>

							{errors.password_confirmation && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{errors.password_confirmation.message}
								</p>
							)}
						</div>

						{watch("password") && (
							<PasswordStrength password={watch("password")} />
						)}
					</div>

					<div className="flex flex-col gap-8">
						<Button type="submit" loading={isLoading}>
							Alterar senha
						</Button>
					</div>
				</form>
			</div>
		</>
	);
}
