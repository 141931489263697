import { AxiosError } from "axios";

import { api } from "@/lib/axios";

interface verifyPasswordIntegration {
	password: string;
}

export async function verifyPasswordIntegration(
	id: string,
	data: Partial<verifyPasswordIntegration>,
): Promise<
	| {
			status: "success";
			data: string;
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const response = await api.post(
			`/integrations-user/verify-password/${id}`,
			data,
		);

		return {
			status: "success",
			data: response.data.data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
