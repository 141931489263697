import { create } from "zustand";
import { persist } from "zustand/middleware";

type Notification = {
	id: string;
	read_at: string | null;
	created_at: string;
	user_id: string;
	data: {
		message: string;
		data: {
			id: string;
			name: string;
		};
	};
};

type LastLessons = {
	available: boolean;
	duration: number;
	id: string;
	module_id: string;
	name: string;
	position: number;
	thumbnail: string;
	time_watched: number;
	training_id: string;
	watched: boolean;
};

type Question = {
	id: string;
	question: string;
	answer: string;
};

type Address = {
	id: string;
	zipcode: string;
	street: string;
	number: string;
	district: string;
	complement: string | null;
	city: string;
	state: string;
};

type User = {
	id: string;
	name: string;
	nickname: string;
	email: string;
	phone: string;
	status: string;
	isAdmin: boolean;
	isSudo: boolean;
	created_at: string;
	image: string | null;
	address: Address;
	questionsUser: Question[];
	notifications: {
		data: Notification[];
		meta: {
			count_read: number;
			total: number;
		};
	};
	lastLessons: LastLessons[];
};

type State = {
	token: string | null;
	profile: User | null;
	isAuth: boolean;
	onRegister: {
		email: string;
	} | null;
};

type Actions = {
	setToken: (token: string) => void;
	setProfile: (profile: User) => void;
	logout: () => void;
	setOnRegister: (email: string) => void;
	resetOnRegister: () => void;
};

export const useAuthStore = create(
	persist<State & Actions>(
		(set) => ({
			token: null,
			profile: null,
			isAuth: false,
			onRegister: null,
			setToken: (token: string) =>
				set((_) => ({
					token,
					isAuth: true,
				})),

			setProfile: (profile: User) =>
				set((_) => ({
					profile,
				})),

			logout: () =>
				set((_) => ({
					token: null,
					isAuth: false,
					profile: null,
				})),

			setOnRegister: (email: string) =>
				set((_) => ({
					onRegister: {
						email,
					},
				})),

			resetOnRegister: () =>
				set((_) => ({
					onRegister: null,
				})),
		}),
		{
			name: "@fm-suplementos/auth",
		},
	),
);
