import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
	createAdminProducts,
	type createAdminProductsParams,
} from "@/api/admin/products/create";
import {
	fetchAdminProductsNiches,
	type Niche,
} from "@/api/admin/products/niches";
import { fetchAdminProductsPlatforms } from "@/api/admin/products/platforms";
import { fetchAdminProductsTypes, type Type } from "@/api/admin/products/type";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/accordion-platform";
import { FileCard } from "@/components/file-card";
import { FileUploader } from "@/components/file-uploader";
import { Modal } from "@/components/modal";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

interface AdminProductsCreateProps {
	closeModal: () => void;
	closeAfterCreate: (id: string) => void;
}

interface PlatformForm {
	external_id: string;
	id: string;
	link: string;
	platform: string;
	platform_id: string;
	url_postback: string;
	isActive: boolean;
}

export function AdminProductsCreate({
	closeModal,
	closeAfterCreate,
}: AdminProductsCreateProps) {
	const params = z.object({
		name: z.string(),
		image: z.union([z.instanceof(File), z.string()]).refine(
			(image) => {
				if (image instanceof File) {
					return image.size <= 4 * 1024 * 1024;
				}

				return true;
			},
			{
				message: "A imagem deve ter no máximo 4MB.",
			},
		),
		type_id: z.string(),
		niche_id: z.string(),
		telegram_link: z.string().url(),
		platforms: z
			.array(
				z.object({
					platform_id: z.string(),
					link: z.string().optional(),
					external_id: z.string().optional(),
					isActive: z.boolean().default(false),
				}),
			)
			.refine(
				(platforms) =>
					platforms.every((platform) => {
						if (platform.isActive) {
							return (
								platform.link?.trim() !== "" &&
								platform.external_id?.trim() !== ""
							);
						}

						return true;
					}),
				{
					message:
						"Se a plataforma estiver ativa, link e código do produto são obrigatórios.",
					path: ["platforms"],
				},
			),
	});

	type Params = z.infer<typeof params>;

	const form = useForm<Params>({
		resolver: zodResolver(params),
	});

	const [isLoading, setIsLoading] = useState(true);
	const [types, setTypes] = useState<Type[]>([]);
	const [niches, setNiches] = useState<Niche[]>([]);
	const [platforms, setPlatforms] = useState<PlatformForm[]>([]);

	async function fetchTypes() {
		setIsLoading(true);
		const response = await fetchAdminProductsTypes();

		if (response.status === "success") {
			setTypes(response.data);
		}

		setIsLoading(false);
	}

	async function fetchNiches() {
		setIsLoading(true);

		const response = await fetchAdminProductsNiches();

		if (response.status === "success") {
			setNiches(response.data);
		}

		setIsLoading(false);
	}

	async function fetchPlatforms() {
		setIsLoading(true);

		const response = await fetchAdminProductsPlatforms();

		if (response.status === "success") {
			const platforms = response.data.map((platform) => ({
				id: "",
				platform_id: platform.id,
				link: "",
				external_id: "",
				platform: platform.name,
				url_postback: "",
				isActive: false,
			}));

			setPlatforms(platforms);
		}

		setIsLoading(false);
	}

	async function handleCreateProduct(data: createAdminProductsParams) {
		try {
			setIsLoading(true);
			const formData = new FormData();

			formData.append("name", data.name);
			formData.append("type_id", data.type_id);
			formData.append("niche_id", data.niche_id);
			formData.append("telegram_link", data.telegram_link);

			if (data.image instanceof File) {
				formData.append("image", data.image);
			} else {
				console.error("A imagem não é um arquivo válido.");
				return;
			}

			data.platforms.forEach((platform, index) => {
				formData.append(
					`platforms[${index}][platform_id]`,
					platform.platform_id,
				);
				formData.append(`platforms[${index}][link]`, platform.link);
				formData.append(
					`platforms[${index}][external_id]`,
					platform.external_id,
				);
			});

			const response = await createAdminProducts(formData);

			if (response.status === "success") {
				closeAfterCreate(response.id);
			}

			if (response.status === "error") {
				const { errors } = response;

				for (const key in errors) {
					form.setError(key as keyof Params, {
						type: "manual",
						message: errors[key],
					});
				}

				setIsLoading(false);
			}
		} catch (error) {
			console.error("Erro ao enviar formulário:", error);
		} finally {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		fetchTypes();
		fetchNiches();
		fetchPlatforms();
	}, []);

	useEffect(() => {
		const newPlatforms = platforms.map((platform, index) => {
			return {
				platform_id: platform.platform_id,
				link: form.watch(`platforms.${index}.link`),
				external_id: form.watch(`platforms.${index}.external_id`),
				isActive: false,
			};
		});

		form.setValue("platforms", newPlatforms);
	}, [platforms]);

	return (
		<Modal
			title="Adicionar produto"
			closeModal={closeModal}
			cancelAction={closeModal}
			confirmAction={() => {
				form.handleSubmit(async (data) => {
					const format = {
						...data,
						platforms: data.platforms.filter((platform) => platform.isActive),
					};

					console.log(form.formState.errors);
					console.log(data, "data");
					console.log(format, "formated");

					handleCreateProduct({
						...format,
						platforms: format.platforms.map((platform) => ({
							...platform,
							link: platform.link || "",
							external_id: platform.external_id || "",
						})),
					});
				})();
			}}
			isLoading={isLoading}
		>
			{!isLoading && (
				<Form {...form}>
					<div className="flex flex-col gap-8">
						<div className="flex w-full flex-col items-center gap-6">
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="name"
									type="text"
									label="Nome do produto"
									hasError={!!form.formState.errors.name}
									{...form.register("name")}
								/>

								{form.formState.errors.name && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.name.message}
									</p>
								)}
							</div>

							<FormField
								control={form.control}
								name="type_id"
								render={({ field }) => (
									<FormItem className="w-full">
										<div className="flex w-full flex-col gap-2">
											<Select
												onValueChange={(e) => {
													field.onChange(e);
												}}
												defaultValue={field.value}
												value={field.value}
											>
												<FormControl>
													<SelectTrigger
														className="w-full"
														hasError={!!form.formState.errors.type_id}
													>
														<SelectValue placeholder="Tipo de produto" />
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{types.map((type) => (
														<SelectItem key={type.id} value={type.id}>
															{type.name}
														</SelectItem>
													))}
												</SelectContent>
											</Select>

											{form.formState.errors.type_id && (
												<p className="font-inter text-P6 font-normal leading-160 text-red-600">
													{form.formState.errors.type_id.message}
												</p>
											)}
										</div>
									</FormItem>
								)}
							/>

							<FormField
								control={form.control}
								name="niche_id"
								render={({ field }) => (
									<FormItem className="w-full">
										<div className="flex w-full flex-col gap-2">
											<Select
												onValueChange={(e) => {
													field.onChange(e);
												}}
												defaultValue={field.value}
												value={field.value}
											>
												<FormControl>
													<SelectTrigger
														className="w-full"
														hasError={!!form.formState.errors.niche_id}
													>
														<SelectValue placeholder="Nicho" />
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{niches.map((type) => (
														<SelectItem key={type.id} value={type.id}>
															{type.name}
														</SelectItem>
													))}
												</SelectContent>
											</Select>
											{form.formState.errors.niche_id && (
												<p className="font-inter text-P6 font-normal leading-160 text-red-600">
													{form.formState.errors.niche_id.message}
												</p>
											)}
										</div>
									</FormItem>
								)}
							/>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex flex-col gap-2">
								<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Imagem do produto
								</p>
								<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Essa será a imagem de divulgação do seu produto.
								</span>
							</div>
							<div className="flex w-full flex-col gap-2">
								{form.watch("image") instanceof File ? (
									<FileCard
										file={form.watch("image") as File}
										onRemove={() => {
											form.setValue("image", "");
										}}
									/>
								) : (
									<FileUploader
										maxFiles={1}
										maxSize={10 * 1024 * 1024}
										accept={{ "image/*": [".png", ".jpeg", ".jpg"] }}
										onUpload={async (e) => {
											form.setValue("image", e[0]);
										}}
										disabled={false}
									/>
								)}
								{form.formState.errors.image && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.image.message}
									</p>
								)}
							</div>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex flex-col gap-2">
								<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Link do telegram
								</p>
								<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Adicione o link do Telegram para o seu produto.
								</span>
							</div>
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="telegram_link"
									type="text"
									label="Link do Telegram"
									hasError={!!form.formState.errors.telegram_link}
									{...form.register("telegram_link")}
								/>
								{form.formState.errors.telegram_link && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.telegram_link.message}
									</p>
								)}
							</div>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6 pb-0">
							<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
								Links de afiliados
							</p>
							<div className="w-full">
								<Accordion type="single" collapsible>
									{platforms.map((platform, index) => (
										<Accordion type="single" collapsible key={index}>
											<AccordionItem
												value={`item-${index}`}
												className="!bg-neutral-1100 !bg-opacity-[0.03]"
												onBlur={() => {
													form.setValue(
														`platforms.${index}.isActive`,
														!form.watch(`platforms.${index}.isActive`),
													);
												}}
											>
												<AccordionTrigger>{platform.platform}</AccordionTrigger>
												<AccordionContent>
													<div className="flex gap-6">
														<div className="flex w-full flex-col gap-2">
															<FloatingLabelInput
																id={`platforms.${index}.link`}
																type="text"
																label="Link de afiliado"
																bgColor="bg-transparent"
																hasError={
																	!!form.formState.errors.platforms?.[index]
																		?.link
																}
																{...form.register(`platforms.${index}.link`)}
																onBlur={() => {
																	form.clearErrors();
																}}
															/>
															{form.formState.errors.platforms?.[index]
																?.link && (
																<p className="font-inter text-P6 font-normal leading-160 text-red-600">
																	{
																		form.formState.errors.platforms?.[index]
																			?.link?.message
																	}
																</p>
															)}
														</div>
														<div className="flex w-[70%] flex-col gap-2">
															<FloatingLabelInput
																id={`platforms.${index}.external_id`}
																type="text"
																label="Código do produto"
																bgColor="bg-transparent"
																hasError={
																	!!form.formState.errors.platforms?.[index]
																		?.external_id
																}
																{...form.register(
																	`platforms.${index}.external_id`,
																)}
																onBlur={() => {
																	form.clearErrors();
																}}
															/>
															{form.formState.errors.platforms?.[index]
																?.external_id && (
																<p className="font-inter text-P6 font-normal leading-160 text-red-600">
																	{
																		form.formState.errors.platforms?.[index]
																			?.external_id?.message
																	}
																</p>
															)}
														</div>
													</div>
												</AccordionContent>
											</AccordionItem>
										</Accordion>
									))}
								</Accordion>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Modal>
	);
}
