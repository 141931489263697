import { AxiosError } from "axios";

import { api } from "@/lib/axios";

type Answer = {
	checked: boolean;
	description: string;
	id: string;
};

type Question = {
	id: string;
	question: "Em qual momento da jornada digital você se encontra?";
	answers: Answer[];
};

export type User = {
	address: {
		city: string;
		complement: string;
		district: string;
		id: string;
		number: string;
		state: string;
		street: string;
		zipcode: string;
	} | null;
	created_at: string;
	email: string;
	id: string;
	image: string | null;
	isAdmin: boolean;
	name: string;
	nickname: string;
	phone: string;
	questionsUser: Question[];
	status: string;
};

export async function showUser(id: string): Promise<
	| {
			status: "success";
			data: User;
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get(`/user/show/${id}`);

		return {
			status: "success",
			data: data.data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
