import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import { addDoubt } from "@/api/admin/products/add-doubt";
import { deleteDoubt } from "@/api/admin/products/delete-doubt";
import { showAdminProduct } from "@/api/admin/products/show";
import { updateDoubt } from "@/api/admin/products/update-doubt";
import CloseIcon from "@/assets/close.svg?react";
import MathPlus from "@/assets/math-plus.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import { AdminQuestionCard } from "@/components/admin-question-card";
import { AlertModal } from "@/components/alert-modal";
import { DeleteModal } from "@/components/delete-modal";
import { Modal } from "@/components/modal";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useAdminProductStore } from "@/store/admin-product";

import { SkeletonAdminProductsShowQuestions } from "./skeletons/questions";

export function AdminProductsShowQuestions() {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [successModal, setSuccessModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [successDeleteModal, setSuccessDeleteModal] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedDoubt, setSelectedDoubt] = useState<string | null>(null);

	const [searchTerm, setSearchTerm] = useState("");

	function closeModal() {
		setModalIsOpen(false);
		setIsEditing(false);

		form.reset();
	}

	const filter = z.object({
		doubt_id: z.string().optional(),
		question: z.string().min(1, { message: "Campo obrigatório" }),
		answer: z.string().min(1, { message: "Campo obrigatório" }),
	});

	type Filter = z.infer<typeof filter>;

	const form = useForm<Filter>({
		resolver: zodResolver(filter),
	});

	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();

	const { product, setProduct } = useAdminProductStore();

	const [filteredQuestions, setFilteredQuestions] = useState(
		product?.doubts || [],
	);

	function handleSearch(searchTerm: string) {
		setSearchTerm(searchTerm);

		const filtered = product?.doubts.filter((doubt) =>
			doubt.question.toLowerCase().includes(searchTerm.toLowerCase()),
		);

		setFilteredQuestions(filtered || []);
	}

	async function getProductById() {
		if (!id) return navigate("/products");

		const response = await showAdminProduct(id);

		if (response.status === "success") {
			setProduct(response.data);

			setFilteredQuestions(response.data.doubts);
		}

		if (response.status === "error") {
			navigate("/products");
		}
	}

	async function handleAddQuestion(
		data: Filter,
		doubt_id: string | null = null,
	) {
		if (!id) return;

		setIsLoading(true);

		if (isEditing) {
			const response = await updateDoubt({
				doubt_id: doubt_id as string,
				question: data.question,
				answer: data.answer,
			});

			if (response.status === "success") {
				setIsLoading(false);
				closeModal();
				setSuccessModal(true);
				setProduct(null);
				getProductById();

				return;
			}

			if (response.status === "error") {
				const { errors } = response;

				for (const key in errors) {
					form.setError(key as keyof Filter, {
						type: "manual",
						message: errors[key],
					});
				}

				setIsLoading(false);
			}

			return;
		}

		// Create new question
		if (!isEditing) {
			const response = await addDoubt({
				product_id: id,
				question: data.question,
				answer: data.answer,
			});

			if (response.status === "success") {
				setIsLoading(false);
				closeModal();
				setSuccessModal(true);
				setProduct(null);
				getProductById();

				return;
			}

			if (response.status === "error") {
				const { errors } = response;

				for (const key in errors) {
					form.setError(key as keyof Filter, {
						type: "manual",
						message: errors[key],
					});
				}

				setIsLoading(false);
			}
		}
	}

	async function handleRemoveQuestion() {
		if (!selectedDoubt) return;

		setIsLoading(true);

		const response = await deleteDoubt(selectedDoubt);

		if (response.status === "success") {
			setDeleteModal(false);
			setSuccessDeleteModal(true);
			setIsLoading(false);
		}
	}

	useEffect(() => {
		setProduct(null);
		getProductById();
	}, [id]);

	if (!product) return <SkeletonAdminProductsShowQuestions />;

	return (
		<>
			<Helmet title="Dúvidas" />
			<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-100 p-6">
				<div className="flex items-center justify-between">
					<h4 className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
						Dúvidas adicionadas
					</h4>

					<button
						className="flex items-center justify-center gap-1 text-neutral-600 transition-all duration-300 hover:text-neutral-1100"
						onClick={() => setModalIsOpen(true)}
					>
						<MathPlus />

						<span className="font-inter text-P5 font-medium leading-160">
							Adicionar dúvida
						</span>
					</button>
				</div>

				<div
					className={`relative ${
						filteredQuestions.length > 0
							? "text-neutral-600"
							: "text-neutral-200"
					} focus-within:text-neutral-1100`}
				>
					<Input
						placeholder="Pesquise pelo nome"
						className={`h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:!text-P5  ${
							filteredQuestions.length > 0
								? "placeholder:text-neutral-600"
								: "placeholder:text-neutral-200"
						}`}
						onChange={(e) => handleSearch(e.target.value)}
						value={searchTerm}
					/>
					<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

					{searchTerm && (
						<button
							className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-200 text-neutral-1100"
							onClick={() => handleSearch("")}
						>
							<CloseIcon />
						</button>
					)}
				</div>

				<div>
					{!!filteredQuestions.length && (
						<div className="mt-6 grid grid-cols-1 gap-6">
							{filteredQuestions.map((question) => (
								<AdminQuestionCard
									key={question.id}
									name={question.question}
									content={question.answer}
									onEdit={() => {
										setIsEditing(true);
										setModalIsOpen(true);
										form.setValue("doubt_id", question.id);
										form.setValue("question", question.question);
										form.setValue("answer", question.answer);
									}}
									onRemove={() => {
										setSelectedDoubt(question.id);
										setDeleteModal(true);
									}}
								/>
							))}
						</div>
					)}

					{!filteredQuestions.length && (
						<div className="flex flex-col items-center justify-center gap-6 px-10 py-36">
							<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
								<SearchIcon />
							</span>

							<div className="flex flex-col gap-2">
								<h3 className="text-center font-inter font-medium leading-160 text-neutral-1100">
									{searchTerm
										? "Não foi possível encontrar nenhuma dúvida"
										: "No momento você não possui nenhuma dúvida adicionada"}
								</h3>

								<p className="text-center font-inter font-normal leading-160 text-neutral-500">
									{searchTerm
										? "Tente novamente com outra palavra chave"
										: "Adicione uma dúvida e ela poderá ser visualizada e gerenciada por aqui."}
								</p>
							</div>
						</div>
					)}
				</div>

				{modalIsOpen && (
					<Modal
						title={isEditing ? "Editar dúvida" : "Nova dúvida"}
						closeModal={closeModal}
						confirmAction={() => {
							form.handleSubmit(async (data) => {
								await handleAddQuestion(data, form.getValues("doubt_id"));
							})();
						}}
						confirmText="Adicionar"
						isLoading={isLoading}
						cancelAction={closeModal}
					>
						<Form {...form}>
							<div className="flex flex-col gap-8">
								<div className="flex w-full flex-col items-center gap-6">
									<div className="flex w-full flex-col gap-2">
										<FloatingLabelInput
											id="name"
											type="text"
											label="Título da sua dúvida"
											bgColor="bg-neutral-100"
											hasError={!!form.formState.errors.question}
											{...form.register("question")}
										/>
										{form.formState.errors.question && (
											<p className="font-inter text-P6 font-normal leading-160 text-red-600">
												{form.formState.errors.question.message}
											</p>
										)}
									</div>
								</div>

								<div className="flex w-full flex-col gap-2">
									<Textarea
										className="h-[270px] w-full resize-none rounded-[8px] border border-neutral-200 bg-transparent p-4 font-inter !text-P5 font-normal leading-160 text-neutral-1100"
										placeholder="Insira a resposta para sua dúvida"
										hasError={!!form.formState.errors.answer}
										{...form.register("answer")}
									/>

									{form.formState.errors.answer && (
										<p className="font-inter text-P6 font-normal leading-160 text-red-600">
											{form.formState.errors.answer.message}
										</p>
									)}
								</div>
							</div>
						</Form>
					</Modal>
				)}

				{successModal && (
					<AlertModal
						title={
							isEditing
								? "Alterações salvas com sucesso!"
								: "Dúvida adicionada com sucesso!"
						}
						icon="done.svg"
						confirmText="Concluir"
						confirmAction={() => {
							setSuccessModal(false);
						}}
						hasCancel={isEditing ? false : true}
						cancelText="Adicionar mais dúvidas"
						cancelAction={() => {
							setSuccessModal(false);
							setModalIsOpen(true);
						}}
						closeModal={() => setSuccessModal(false)}
					/>
				)}

				{deleteModal && (
					<DeleteModal
						title="Deseja realmente excluir esta dúvida?"
						description="Essa ação não poderá ser desfeita."
						icon="warning.svg"
						confirmText="Excluir dúvida"
						isLoading={isLoading}
						cancelAction={() => setDeleteModal(false)}
						confirmAction={handleRemoveQuestion}
						closeModal={() => setDeleteModal(false)}
					/>
				)}

				{successDeleteModal && (
					<AlertModal
						title="Dúvida excluída com sucesso!"
						icon="done.svg"
						confirmText="Concluir"
						hasCancel={false}
						confirmAction={() => {
							setSuccessDeleteModal(false);
							setProduct(null);
							getProductById();
						}}
						closeModal={() => {
							setSuccessDeleteModal(false);
							setProduct(null);
							getProductById();
						}}
					/>
				)}
			</div>
		</>
	);
}
