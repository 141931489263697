import { Skeleton } from "@/components/ui/skeleton";

export function SkeletonPrincipalTrainingCard() {
	return (
		<div className="flex h-full w-full items-center rounded-xl bg-neutral-100 bg-opacity-40 p-6">
			<div className="flex w-full flex-1 flex-col gap-12">
				<div className="flex gap-4">
					<Skeleton className="h-6 w-[60px] rounded px-[6px] py-1" />
					<Skeleton className="h-6 w-[60px] rounded px-[6px] py-1" />
				</div>

				<div className="flex flex-col gap-4">
					<Skeleton className="h-8 w-[300px] rounded" />
					<Skeleton className="h-5 w-full rounded" style={{ maxWidth: 616 }} />
					<Skeleton className="h-5 w-full rounded" style={{ maxWidth: 616 }} />
				</div>

				<Skeleton className="h-[48px] w-[232px] rounded-[8px]" />
			</div>
		</div>
	);
}
