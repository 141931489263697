import { AxiosError } from "axios";

import { api } from "@/lib/axios";

interface FetchAwardsMonthParams {
	month: string;
}

type Ranking = {
	id: string;
	user_id: string;
	user_name: string;
	image: string;
	strategy: null | string;
	total_sales: number;
};

export type Awards = {
	ranking: Ranking[];
	currentUser: {
		position: number;
		total_sales: number;
	};
};

export async function fetchAwardsMonthlyRanking(
	params: Partial<FetchAwardsMonthParams>,
): Promise<
	| {
			status: "success";
			data: Awards;
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get("/monthly-ranking", {
			params,
		});

		return {
			status: "success",
			data: data.data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
