import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useHookFormMask } from "use-mask-input";
import { z } from "zod";

import { initialSignUp } from "@/api/auth/sign-up/initial";
import { Button } from "@/components/ui/button";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { useAuthStore } from "@/store/auth";

const signUpForm = z.object({
	name: z.string().min(1, {
		message: "Você precisa digitar seu nome completo",
	}),
	email: z.string().email({
		message: "Você precisa digitar um e-mail válido",
	}),
	phone: z.string().min(13, {
		message: "Você precisa digitar seu WhatsApp",
	}),
});

type SignUpForm = z.infer<typeof signUpForm>;

export function SignUp() {
	const navigate = useNavigate();
	const { setOnRegister, onRegister } = useAuthStore();

	const [isLoading, setIsLoading] = useState(false);

	const {
		register,
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm<SignUpForm>({
		resolver: zodResolver(signUpForm),
	});
	const registerWithMask = useHookFormMask(register);

	async function handleSignUp(data: SignUpForm) {
		setIsLoading(true);
		const response = await initialSignUp(data);

		if (response.status === "success") {
			setOnRegister(data.email);

			setIsLoading(false);

			navigate("/sign-up-confirm");
			return;
		}

		if (response.status === "error") {
			const { errors } = response;

			for (const key in errors) {
				setError(key as keyof SignUpForm, {
					type: "manual",
					message: errors[key],
				});
			}

			setIsLoading(false);

			return;
		}
	}

	useEffect(() => {
		if (onRegister) {
			navigate("/sign-up-confirm");
		}
	}, []);

	return (
		<>
			<Helmet title="Cadastre-se" />

			<div className="flex animate-fade flex-col gap-12">
				<div className="flex flex-col gap-2">
					<h1 className="font-poppins text-H7 font-semibold leading-140 text-white">
						Crie sua conta
					</h1>
					<p className="font-inter text-P5 leading-160 text-neutral-500 md:text-P4">
						Digite seus dados abaixo para continuar.
					</p>
				</div>

				<form
					onSubmit={handleSubmit(handleSignUp)}
					className="flex flex-col gap-10"
				>
					<div className="flex flex-col gap-6">
						<div className="flex flex-col gap-2">
							<FloatingLabelInput
								id="name"
								type="text"
								label="Nome"
								hasError={!!errors.name}
								{...register("name")}
							/>

							{errors.name && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{errors.name.message}
								</p>
							)}
						</div>

						<div className="flex flex-col gap-2">
							<FloatingLabelInput
								id="email"
								hasError={!!errors.email}
								type="email"
								label="E-mail"
								{...register("email")}
							/>

							{errors.email && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{errors.email.message}
								</p>
							)}
						</div>

						<div className="flex flex-col gap-2">
							<FloatingLabelInput
								id="phone"
								type="text"
								hasError={!!errors.phone}
								label="WhatsApp"
								{...registerWithMask("phone", ["99 99999-9999"], {
									required: true,
									inputType: "number",
									onChange(event) {
										const { value } = event.target;

										const phoneRegex = /(\d{2})\s(\d{5})-(\d{4})/;

										if (!phoneRegex.test(value)) {
											setError("phone", {
												type: "manual",
												message: "Você precisa digitar seu WhatsApp",
											});
											return;
										}

										clearErrors("phone");
									},
								})}
							/>

							{errors.phone && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{errors.phone.message}
								</p>
							)}
						</div>
					</div>

					<div className="flex flex-col gap-8">
						<Button type="submit" loading={isLoading}>
							Continuar
						</Button>

						<footer className="flex items-center justify-center">
							<p className="font-inter text-P5 leading-160 text-neutral-500">
								Já tem acesso?
								<Link
									to="/sign-in"
									className="cursor-pointer text-white transition-colors hover:text-primary-600"
								>
									{" "}
									Acesse sua conta
								</Link>
							</p>
						</footer>
					</div>
				</form>
			</div>
		</>
	);
}
