import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { updateAdminProductsAbout } from "@/api/admin/products/update-about";
import { AlertModal } from "@/components/alert-modal";
import { TextareaInput } from "@/components/textarea-input";
import { useAdminProductStore } from "@/store/admin-product";

export function AdminProductsShowAbout() {
	const [successModdal, setSuccessModal] = useState(false);
	const [modalMessage, setModalMessage] = useState("");

	const filter = z.object({
		description: z.string().optional(),
		rules: z.string().optional(),
		awards: z.string().optional(),
		earnings_projection: z.string().optional(),
	});

	type Filter = z.infer<typeof filter>;

	const form = useForm<Filter>({
		resolver: zodResolver(filter),
	});

	const { id } = useParams<{ id: string }>();

	const { product } = useAdminProductStore();

	useEffect(() => {
		if (product) {
			form.setValue("description", product.description || "");
			form.setValue("rules", product.rules || "");
			form.setValue("awards", product.awards || "");
			form.setValue("earnings_projection", product.earnings_projection || "");
		}
	}, [product]);

	async function handleComplete(fieldName: keyof Filter) {
		const data = {
			description: form.watch("description"),
			rules: form.watch("rules"),
			awards: form.watch("awards"),
			earnings_projection: form.watch("earnings_projection"),
		};

		const response = await updateAdminProductsAbout(data, id || "");

		if (response.status === "success") {
			const fieldMap: Record<keyof Filter, string> = {
				description: "Descrição do produto",
				rules: "Regras de afiliação",
				awards: "Premiações para afiliados",
				earnings_projection: "Projeção de ganhos",
			};

			const fieldUpdated = fieldMap[fieldName];

			setModalMessage(`${fieldUpdated} foi atualizada com sucesso!`);
			setSuccessModal(true);
		}
	}

	return (
		<div className="flex flex-col gap-6">
			<div className="flex flex-col gap-8 rounded-[8px] bg-neutral-100 p-6">
				<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
					Descrição do produto
				</p>
				<TextareaInput
					setValue={(e) => form.setValue("description", e)}
					value={form.watch("description") || ""}
					showCounter={false}
					heightIsFixed
					height="140px"
					onComplete={() => handleComplete("description")}
					maxLength={2000}
				/>
			</div>

			<div className="flex flex-col gap-8 rounded-[8px] bg-neutral-100 p-6">
				<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
					Regras de afiliação
				</p>
				<TextareaInput
					setValue={(e) => form.setValue("rules", e)}
					value={form.watch("rules") || ""}
					showCounter={false}
					heightIsFixed
					height="140px"
					onComplete={() => handleComplete("rules")}
					maxLength={2000}
				/>
			</div>

			<div className="flex flex-col gap-8 rounded-[8px] bg-neutral-100 p-6">
				<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
					Premiações para afiliados
				</p>
				<TextareaInput
					setValue={(e) => form.setValue("awards", e)}
					value={form.watch("awards") || ""}
					showCounter={false}
					heightIsFixed
					height="140px"
					onComplete={() => handleComplete("awards")}
					maxLength={2000}
				/>
			</div>

			<div className="flex flex-col gap-8 rounded-[8px] bg-neutral-100 p-6">
				<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
					Projeção de ganhos para afiliados
				</p>
				<TextareaInput
					setValue={(e) => form.setValue("earnings_projection", e)}
					value={form.watch("earnings_projection") || ""}
					showCounter={false}
					heightIsFixed
					height="140px"
					onComplete={() => handleComplete("earnings_projection")}
					maxLength={2000}
				/>
			</div>

			{successModdal && (
				<AlertModal
					title={modalMessage}
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessModal(false);
						window.location.reload();
					}}
					closeModal={() => {
						setSuccessModal(false);
						window.location.reload();
					}}
				/>
			)}
		</div>
	);
}
