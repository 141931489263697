import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import {
	fetchMaterials,
	type Material,
} from "@/api/app/academy/materials/list";
import {
	fetchTrainings,
	type Training,
} from "@/api/app/academy/trainings/list";
import ArrowRightIcon from "@/assets/arrow-right.svg?react";
import PlayButtonIcon from "@/assets/play-button.svg?react";
import { MaterialCard } from "@/components/material-card";
import { SkeletonFreeMaterialsLink } from "@/components/skeletons/free-materials-link";
import { SkeletonMaterialCard } from "@/components/skeletons/material-card";
import { SkeletonPrincipalTrainingCard } from "@/components/skeletons/principal-training-card";
import { SkeletonTrainingCard } from "@/components/skeletons/training-card";
import { Tag } from "@/components/tag";
import { TrainingCard } from "@/components/training-card";
import { Button } from "@/components/ui/button";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
} from "@/components/ui/carousel";
import { useNavigationStore } from "@/store/navigation-store";

export function Academy() {
	useEffect(() => {
		const paths = [
			{
				name: "Academy",
				path: "/academy",
			},
		];

		setPaths(paths);
	}, []);

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	const [trainings, setTrainings] = useState<Training[]>([]);
	const [materials, setMaterials] = useState<Material[]>([]);

	async function getTrainings() {
		const response = await fetchTrainings({
			sortBy: "created_at",
		});

		if (response.status === "success") {
			const trainings = response.data;

			setTrainings(trainings.slice(0, 4));
		}
	}

	async function getMaterials() {
		const response = await fetchMaterials({});

		if (response.status === "success") {
			const materials = response.data;

			setMaterials(materials.slice(0, 3));
		}
	}

	useEffect(() => {
		getTrainings();
		getMaterials();
	}, []);

	return (
		<div className="flex h-screen flex-col gap-12">
			<Helmet title="Academy" />

			<section className="grid gap-8 lg:grid-cols-[2fr_1fr]">
				{trainings.length === 0 && (
					<div className="flex h-full flex-1">
						<SkeletonPrincipalTrainingCard />
					</div>
				)}

				{trainings.length > 0 && (
					<div
						className="flex h-full w-full items-center rounded-xl px-4 pb-8 pt-4 md:pb-[26px] md:pl-6 md:pr-24 md:pt-12"
						style={{
							background: `
								linear-gradient(180deg, rgba(11, 18, 18, 0.10) 23.13%, rgba(11, 18, 18, 0.80) 72.41%),
								linear-gradient(0deg, rgba(16, 22, 22, 0.30) 0%, rgba(16, 22, 22, 0.30) 100%),
								url('${trainings[0].image}')`,
							backgroundSize: "cover",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
						}}
					>
						<div className="flex w-full flex-col gap-12">
							<div className="flex gap-4">
								<Tag
									size="small"
									colors="minimalist-grey"
									className="rounded bg-neutral-1100 bg-opacity-10 px-[6px] py-1"
								>
									{trainings[0].modulesCount > 1
										? `${trainings[0].modulesCount} Módulos`
										: `${trainings[0].modulesCount} Módulo`}
								</Tag>
								<Tag
									size="small"
									colors="minimalist-grey"
									className="rounded bg-neutral-1100 bg-opacity-10 px-[6px] py-1"
								>
									{trainings[0].lessonsCount > 1
										? `${trainings[0].lessonsCount} Aulas`
										: `${trainings[0].lessonsCount} Aula`}
								</Tag>
							</div>

							<div className="flex flex-col gap-4">
								<h6 className="font-poppins text-H6 font-semibold leading-140 text-neutral-1100">
									{trainings[0].name}
								</h6>

								<p
									className="font-inter text-P4 font-normal leading-160 text-neutral-700"
									style={{ maxWidth: 616 }}
								>
									{trainings[0].description}
								</p>
							</div>

							<Button
								className="flex !h-[48px] w-full items-center justify-center gap-2 rounded-[8px] bg-primary-600 md:w-[232px]"
								onClick={() => {
									window.location.href = `/academy/${trainings[0].id}`;
								}}
							>
								<PlayButtonIcon width={20} height={20} />

								<p>Iniciar treinamento</p>
							</Button>
						</div>
					</div>
				)}

				{materials.length === 0 || trainings.length === 0 ? (
					<SkeletonFreeMaterialsLink />
				) : (
					<Link
						to="/academy/free-materials"
						className="relative flex h-full items-end rounded-xl p-10 transition-all duration-300 hover:opacity-90 md:min-w-[352px]"
						style={{
							background:
								"linear-gradient(209deg, #282E2E 18.23%, rgba(40, 46, 46, 0.40) 70.62%)",
						}}
					>
						<img
							src="/materials.png"
							className="absolute right-0 top-0 h-full lg:h-auto"
						/>

						<h6 className="font-poppins text-H7 font-semibold leading-140 text-neutral-1100">
							Materiais <br />
							gratuitos
						</h6>
					</Link>
				)}
			</section>

			<section className="flex flex-col gap-8">
				<div className="flex items-center justify-between">
					<h3 className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
						Treinamentos
					</h3>

					<Link
						to="/academy/trainings"
						className="flex cursor-pointer items-center justify-center gap-1 font-inter text-P5 font-medium leading-160 text-neutral-600 transition-all duration-300 hover:text-neutral-1100 hover:underline hover:underline-offset-8"
					>
						<p>Ver todos</p>
						<ArrowRightIcon />
					</Link>
				</div>

				<div className="block lg:hidden">
					<Carousel>
						<CarouselContent className="-ml-8">
							{trainings.length === 0 &&
								Array.from({ length: 4 }).map((_, index) => (
									<CarouselItem className="basis-auto pl-8" key={index}>
										<SkeletonTrainingCard key={index} />
									</CarouselItem>
								))}

							{trainings.map((training) => (
								<CarouselItem className="basis-auto pl-8" key={training.id}>
									<TrainingCard
										id={training.id}
										author={training?.mentor?.name}
										avatar={training?.mentor?.image}
										image={training.image}
										modules={training.modulesCount}
										lessons={training.lessonsCount}
										title={training.name}
									/>
								</CarouselItem>
							))}
						</CarouselContent>
					</Carousel>
				</div>

				<div className="hidden grid-cols-1 gap-8 md:grid-cols-2 lg:grid lg:grid-cols-4">
					{trainings.length === 0 &&
						Array.from({ length: 4 }).map((_, index) => (
							<SkeletonTrainingCard key={index} />
						))}

					{trainings.map((training) => (
						<TrainingCard
							key={training.id}
							id={training.id}
							author={training?.mentor?.name}
							avatar={training?.mentor?.image}
							image={training.image}
							modules={training.modulesCount}
							lessons={training.lessonsCount}
							title={training.name}
						/>
					))}
				</div>
			</section>

			<section className="flex flex-col gap-8">
				<div className="flex items-center justify-between">
					<h3 className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
						Materiais gratuitos
					</h3>

					<Link
						to="/academy/free-materials"
						className="flex cursor-pointer items-center justify-center gap-1 font-inter text-P5 font-medium leading-160 text-neutral-600 transition-all duration-300 hover:text-neutral-1100 hover:underline hover:underline-offset-8"
					>
						<p>Ver todos</p>
						<ArrowRightIcon />
					</Link>
				</div>

				<div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
					{materials.length === 0 &&
						Array.from({ length: 3 }).map((_, index) => (
							<SkeletonMaterialCard key={index} />
						))}

					{materials.map((material) => (
						<MaterialCard
							key={material.id}
							title={material.title}
							type={material.format}
							link={material.link}
						/>
					))}
				</div>
			</section>
		</div>
	);
}
