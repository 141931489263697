import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { getUser } from "@/api/app/account/me";
import CloseIcon from "@/assets/close-2.svg?react";
import { Breadcrumb } from "@/components/breadcrumb";
import { Header } from "@/components/header";
import { NavBar } from "@/components/nav-bar";
import { AuthProvider } from "@/hooks/auth";
import { useAuthStore } from "@/store/auth";
import { useNavigationStore } from "@/store/navigation-store";

const menuVariants = {
	open: { x: 0, transition: { duration: 0.3 } },
	closed: { x: "-100%", transition: { duration: 0.3 } },
};

export function AppLayout() {
	const { setMenuIsOpen, menuIsOpen } = useNavigationStore();
	const { setProfile } = useAuthStore();

	const toggleMenu = () => {
		setMenuIsOpen(!menuIsOpen);
	};

	const [paths] = useNavigationStore((state) => [state.paths]);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 1280 && menuIsOpen) {
				setMenuIsOpen(false);
			}
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [menuIsOpen, setMenuIsOpen]);

	useEffect(() => {
		getUser().then((response) => {
			if (response.status === "success") {
				const { data } = response;
				setProfile(data);
			}
		});
	}, [setProfile]);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [paths]);

	return (
		<AuthProvider>
			<div className="flex">
				<aside className="relative">
					<AnimatePresence>
						{menuIsOpen && (
							<>
								<div
									className="fixed bottom-0 left-0 right-0 top-0 z-[5] bg-neutral-0 bg-opacity-40"
									onClick={toggleMenu}
								/>
								<motion.div
									initial="closed"
									animate="open"
									exit="closed"
									variants={menuVariants}
									className="fixed inset-y-0 left-0 z-20"
								>
									<header className="flex items-center justify-between bg-neutral-0 px-5 pb-4 pt-[50px] xl:hidden">
										<p className="font-inter text-P2 font-semibold leading-140 text-neutral-1100">
											Menu
										</p>
										<button className="text-neutral-400" onClick={toggleMenu}>
											<CloseIcon width={32} height={32} />
										</button>
									</header>
									<NavBar />
								</motion.div>
							</>
						)}
					</AnimatePresence>
					<div
						className={`sticky top-0 hidden h-screen overflow-y-auto border-neutral-100 transition-all ${
							!menuIsOpen ? "xl:flex" : "md:hidden"
						}`}
					>
						<NavBar />
					</div>
				</aside>

				<main className="w-full overflow-x-hidden">
					<Header />
					<div className="mt-[98px] flex flex-col gap-10 px-5 py-8 md:p-8 xl:gap-0">
						<div className="flex xl:hidden">
							<Breadcrumb paths={paths} />
						</div>
						<Outlet />
					</div>
				</main>
			</div>
		</AuthProvider>
	);
}
