import { Label, PolarRadiusAxis, RadialBar, RadialBarChart } from "recharts";

import { Card, CardContent, CardHeader } from "@/components/ui/card";
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart";

interface DashboardPaymentsProps {
	count_sales_by_payment_method: {
		payment_method: string;
		count: number;
	}[];
}

const chartConfig = {
	pix: {
		label: "PIX",
		color: "hsl(var(--pix))",
	},
	card: {
		label: "Cartão",
		color: "hsl(var(--card))",
	},
	boleto: {
		label: "Boleto",
		color: "hsl(var(--billet))",
	},
} satisfies ChartConfig;

export function DashboardPayments({
	count_sales_by_payment_method,
}: DashboardPaymentsProps) {
	const generateChartData = (
		data: DashboardPaymentsProps["count_sales_by_payment_method"],
	) => {
		const chartEntry: Record<string, number> = {
			pix: 0,
			card: 0,
			boleto: 0,
		};

		data.forEach((entry) => {
			const key = entry.payment_method.toLowerCase();
			if (key in chartEntry) {
				chartEntry[key] = entry.count;
			}
		});

		return [chartEntry];
	};

	const chartData =
		count_sales_by_payment_method.length > 0
			? generateChartData(count_sales_by_payment_method)
			: [
					{
						pix: 0,
						card: 0,
						boleto: 0,
					},
				];

	const totalVisitors =
		chartData[0].pix + chartData[0].card + chartData[0].boleto;

	return (
		<Card className="flex flex-col border-none bg-transparent">
			<CardHeader>
				<div className="flex items-center justify-between">
					<h2 className="font-inter text-P3 font-medium leading-140 text-neutral-1100">
						Formas de pagamento
					</h2>

					<ul className="flex gap-6">
						<li className="flex items-center justify-center gap-2">
							<span className="h-2.5 w-2.5 rounded-full bg-[#7736B8]" />

							<p className="font-inter text-P6 font-medium leading-160 text-neutral-500">
								Pix
							</p>
						</li>

						<li className="flex items-center justify-center gap-2">
							<span className="h-2.5 w-2.5 rounded-full bg-[#53D484]" />

							<p className="font-inter text-P6 font-medium leading-160 text-neutral-500">
								Cartão
							</p>
						</li>

						<li className="flex items-center justify-center gap-2">
							<span className="h-2.5 w-2.5 rounded-full bg-[#23459E]" />

							<p className="font-inter text-P6 font-medium leading-160 text-neutral-500">
								Boleto
							</p>
						</li>
					</ul>
				</div>
			</CardHeader>
			<CardContent className="flex flex-1 items-center p-0">
				<ChartContainer config={chartConfig} className="w-full">
					<RadialBarChart
						data={chartData}
						endAngle={180}
						innerRadius={240}
						outerRadius={window.innerWidth < 768 ? 120 : 350}
						cy={window.innerWidth < 768 ? 250 : 288}
					>
						<ChartTooltip
							cursor={false}
							content={<ChartTooltipContent hideLabel />}
						/>
						<PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
							<Label
								content={({ viewBox }) => {
									if (viewBox && "cx" in viewBox && "cy" in viewBox) {
										return (
											<text x={viewBox.cx} y={viewBox.cy} textAnchor="middle">
												<tspan
													x={viewBox.cx}
													y={(viewBox.cy || 0) - 56}
													className="fill-neutral-1100 font-poppins text-H6 font-medium leading-140"
												>
													{totalVisitors.toLocaleString()}
												</tspan>
												<tspan
													x={viewBox.cx}
													y={(viewBox.cy || 0) - 34}
													className="fill-neutral-500 font-inter text-P6 font-normal leading-160"
												>
													Vendas
												</tspan>
											</text>
										);
									}
								}}
							/>
						</PolarRadiusAxis>
						<RadialBar
							dataKey="pix"
							stackId="a"
							fill="var(--color-pix)"
							className="stroke-transparent stroke-2"
							background={{
								fill: "#262C2C",
							}}
						/>
						<RadialBar
							dataKey="card"
							fill="var(--color-card)"
							stackId="a"
							className="stroke-transparent stroke-2"
						/>
						<RadialBar
							dataKey="boleto"
							fill="var(--color-boleto)"
							stackId="a"
							className="stroke-transparent stroke-2"
						/>
					</RadialBarChart>
				</ChartContainer>
			</CardContent>
		</Card>
	);
}
