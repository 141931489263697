import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import { addMaterial } from "@/api/admin/products/add-material";
import { deleteMaterial } from "@/api/admin/products/delete-material";
import { showAdminProduct } from "@/api/admin/products/show";
import { updateMaterial } from "@/api/admin/products/update-meterial";
import type { Material } from "@/api/app/products/show";
import CloseIcon from "@/assets/close.svg?react";
import FileIcon from "@/assets/file-2.svg?react";
import FormatUppercaseIcon from "@/assets/format-uppercase.svg?react";
import ImageIcon from "@/assets/image-2.svg?react";
import MathPlus from "@/assets/math-plus.svg?react";
import PlayButtonOIcon from "@/assets/play-button-o.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import { AdminMaterialCard } from "@/components/admin-material-card";
import { AlertModal } from "@/components/alert-modal";
import { DeleteModal } from "@/components/delete-modal";
import { FileCard } from "@/components/file-card";
import { FileUploader } from "@/components/file-uploader";
import { Modal } from "@/components/modal";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { useAdminProductStore } from "@/store/admin-product";

export function AdminProductsShowMaterials() {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [currentMaterial, setCurrentMaterial] = useState<Material | null>(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [successDeleteModal, setSuccessDeleteModal] = useState(false);
	const [selectedMaterial, setSelectedMaterial] = useState<string | null>(null);

	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { product, setProduct } = useAdminProductStore();

	const materials = useMemo(() => product?.materials || [], [product]);

	const filteredMaterials = useMemo(() => {
		return materials.filter((material) =>
			material.title.toLowerCase().includes(searchTerm.toLowerCase()),
		);
	}, [materials, searchTerm]);

	function handleSearch(searchTerm: string) {
		setSearchTerm(searchTerm);
	}

	function closeModal() {
		setModalIsOpen(false);
		setIsEditing(false);
		setCurrentMaterial(null);
	}

	function openEditModal(material: Material) {
		setCurrentMaterial(material);
		const materialType = material.format as
			| "image"
			| "text"
			| "link"
			| "document";

		const type =
			materialType === "image"
				? "Imagem"
				: materialType === "text"
					? "Texto"
					: materialType === "link"
						? "Link"
						: "Documento";

		form.setValue("name", material.title);
		form.setValue("type", type);
		form.setValue("file", material.link);
		setIsEditing(true);
		setModalIsOpen(true);
	}

	const filterSchema = z.object({
		name: z
			.string()
			.min(3, "O nome do material deve conter no mínimo 3 caracteres"),
		type: z.string(),
		file: z.union([z.instanceof(File), z.string()]),
		material_id: z.string().optional(),
		link: z.string().optional(),
	});

	type Filter = z.infer<typeof filterSchema>;

	const form = useForm<Filter>({
		resolver: zodResolver(filterSchema),
	});

	useEffect(() => {
		async function fetchProduct() {
			if (!id) return navigate("/products");

			setIsLoading(true);
			const response = await showAdminProduct(id);

			if (response.status === "success") {
				setProduct(response.data);
				setIsLoading(false);
			} else {
				navigate("/products");
			}
		}

		fetchProduct();
	}, [id, navigate, setProduct]);

	const confirmAction = form.handleSubmit(async (data) => {
		if (!id) return;

		const formData = new FormData();
		formData.append("title", data.name);

		if (!isEditing) {
			formData.append("product_id", id);
		}

		if (data.file instanceof File) {
			formData.append("material", data.file instanceof File ? data.file : "");
		}

		setIsLoading(true);

		if (isEditing && currentMaterial) {
			formData.append("support_material_id", currentMaterial.id);
			formData.append("link", currentMaterial.link);

			if (data.file instanceof File) {
				formData.delete("link");
			}

			const response = await updateMaterial(formData);

			if (response.status === "success") {
				setModalIsOpen(false);
				setIsEditing(false);
				form.reset();

				const updatedProduct = await showAdminProduct(id);
				if (updatedProduct.status === "success") {
					setProduct(updatedProduct.data);
				}

				setIsLoading(false);
				return;
			}

			if (response.status === "error") {
				const { errors } = response;
				for (const key in errors) {
					form.setError(key as keyof Filter, {
						type: "manual",
						message: errors[key],
					});
				}
			}
		} else {
			const response = await addMaterial(formData);

			if (response.status === "success") {
				setModalIsOpen(false);
				setIsEditing(false);
				form.reset();

				const updatedProduct = await showAdminProduct(id);
				if (updatedProduct.status === "success") {
					setProduct(updatedProduct.data);
				}

				setIsLoading(false);
				return;
			}

			if (response.status === "error") {
				const { errors } = response;
				for (const key in errors) {
					form.setError(key as keyof Filter, {
						type: "manual",
						message: errors[key],
					});
				}
			}
		}

		setIsLoading(false);
	});

	async function handleDeleteMaterial() {
		if (!selectedMaterial) return;

		setIsLoading(true);

		const response = await deleteMaterial(selectedMaterial);

		if (response.status === "success") {
			setDeleteModal(false);
			setSuccessDeleteModal(true);
			setIsLoading(false);

			if (!id) return;

			const updatedProduct = await showAdminProduct(id);
			if (updatedProduct.status === "success") {
				setProduct(updatedProduct.data);
			}
		}
	}

	return (
		<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-100 p-6">
			<div className="flex items-center justify-between">
				<h4 className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
					Materiais adicionados
				</h4>

				<button
					className="flex items-center justify-center gap-1 text-neutral-600 transition-all duration-300 hover:text-neutral-1100"
					onClick={() => {
						form.reset();

						setModalIsOpen(true);
					}}
				>
					<MathPlus />
					<span className="font-inter text-P5 font-medium leading-160">
						Adicionar material
					</span>
				</button>
			</div>

			<div
				className={`relative ${filteredMaterials.length > 0 ? "text-neutral-600" : "text-neutral-200"} focus-within:text-neutral-1100`}
			>
				<Input
					placeholder="Pesquise pelo nome"
					className={`h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:!text-P5 ${
						filteredMaterials.length > 0
							? "placeholder:text-neutral-600"
							: "placeholder:text-neutral-200"
					}`}
					onChange={(e) => handleSearch(e.target.value)}
					value={searchTerm}
				/>
				<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

				{searchTerm && (
					<button
						className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-200 text-neutral-1100"
						onClick={() => handleSearch("")}
					>
						<CloseIcon />
					</button>
				)}
			</div>

			<div>
				{filteredMaterials.length ? (
					<div className="mt-6 grid grid-cols-1 gap-6">
						{filteredMaterials.map((material) => (
							<AdminMaterialCard
								key={material.id}
								name={material.title}
								type={material.format as "image" | "text" | "link" | "document"}
								onEdit={() => openEditModal(material)}
								onRemove={() => {
									setSelectedMaterial(material.id);
									setDeleteModal(true);
								}}
							/>
						))}
					</div>
				) : (
					<div className="flex flex-col items-center justify-center gap-6 px-10 py-36">
						<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
							<SearchIcon />
						</span>

						<div className="flex flex-col gap-2">
							<h3 className="text-center font-inter font-medium leading-160 text-neutral-1100">
								{searchTerm
									? "Não foi possível encontrar nenhum material"
									: "No momento você não possui nenhum material adicionado"}
							</h3>
							<p className="text-center font-inter font-normal leading-160 text-neutral-500">
								{searchTerm
									? "Tente novamente com outra palavra chave"
									: "Adicione um material e ele poderá ser visualizado e gerenciado por aqui."}
							</p>
						</div>
					</div>
				)}
			</div>

			{modalIsOpen && (
				<Modal
					title={isEditing ? "Editar material" : "Novo material"}
					closeModal={closeModal}
					confirmAction={confirmAction}
					isLoading={isLoading}
				>
					<Form {...form}>
						<div className="flex flex-col gap-8">
							<div className="flex w-full flex-col items-center gap-6">
								<div className="flex w-full flex-col gap-2">
									<FloatingLabelInput
										id="name"
										type="text"
										label="Nome do material"
										hasError={!!form.formState.errors.name}
										{...form.register("name")}
									/>

									{form.formState.errors.name && (
										<p className="font-inter text-P6 font-normal leading-160 text-red-600">
											{form.formState.errors.name.message}
										</p>
									)}
								</div>

								<FormField
									control={form.control}
									name="type"
									render={({ field }) => (
										<FormItem className="w-full">
											<Select
												onValueChange={(e) => {
													field.onChange(e);
												}}
												defaultValue={field.value}
												value={field.value}
											>
												<FormControl>
													<SelectTrigger
														className="w-full"
														hasError={!!form.formState.errors.type}
													>
														<SelectValue placeholder="Tipo de material" />
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													<SelectItem value="Imagem">
														<div className="flex items-center gap-3">
															<ImageIcon width={20} height={20} />
															<p>Imagem</p>
														</div>
													</SelectItem>
													<SelectItem value="Documento">
														<div className="flex items-center gap-3">
															<FileIcon width={20} height={20} />
															<p>Documento</p>
														</div>
													</SelectItem>
													<SelectItem value="Link">
														<div className="flex items-center gap-3">
															<PlayButtonOIcon width={20} height={20} />
															<p>Video</p>
														</div>
													</SelectItem>
													<SelectItem value="Texto">
														<div className="flex items-center gap-3">
															<FormatUppercaseIcon width={20} height={20} />
															<p>Texto</p>
														</div>
													</SelectItem>
												</SelectContent>
											</Select>

											{form.formState.errors.type && (
												<p className="font-inter text-P6 font-normal leading-160 text-red-600">
													{form.formState.errors.type.message}
												</p>
											)}
										</FormItem>
									)}
								/>
							</div>

							<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
								<div className="flex flex-col gap-2">
									<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
										{form.watch("type") === "Link" ? "Link" : "Arquivo"}
									</p>

									<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
										{form.watch("type") === "Link"
											? "Adicione o link do seu vídeo"
											: "Adicione o arquivo do seu material"}
									</span>
								</div>

								{form.watch("type") === "Link" && (
									<div className="flex w-full flex-col gap-2">
										<FloatingLabelInput
											id="link"
											type="text"
											label="Link"
											hasError={!!form.formState.errors.link}
											{...form.register("file")}
										/>

										{form.formState.errors.link && (
											<p className="font-inter text-P6 font-normal leading-160 text-red-600">
												{form.formState.errors.link.message}
											</p>
										)}
									</div>
								)}

								{form.watch("type") !== "Link" && (
									<div className="flex w-full flex-col gap-2">
										{typeof form.watch("file") === "string" &&
										form.watch("file") !== "" ? (
											<FileCard
												url={form.watch("file") as string}
												onRemove={() => form.setValue("file", "")}
											/>
										) : form.watch("file") instanceof File ? (
											<FileCard
												file={form.watch("file") as File}
												onRemove={() => form.setValue("file", "")}
											/>
										) : (
											<FileUploader
												maxFiles={1}
												maxSize={20 * 1024 * 1024}
												accept={
													form.watch("type") === "Imagem"
														? {
																"image/*": [".jpg", ".jpeg", ".png", ".gif"],
															}
														: form.watch("type") === "Link"
															? {
																	"video/mp4": [".mp4"],
																	"video/quicktime": [".mov"],
																}
															: form.watch("type") === "Documento"
																? {
																		"application/pdf": [".pdf"],
																		"application/msword": [".doc"],
																		"application/vnd.openxmlformats-officedocument.wordprocessingml.document":
																			[".docx"],
																		"application/vnd.ms-powerpoint": [".ppt"],
																		"application/vnd.openxmlformats-officedocument.presentationml.presentation":
																			[".pptx"],
																		"application/vnd.ms-excel": [".xls"],
																		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
																			[".xlsx"],
																		"text/csv": [".csv"],
																		"application/zip": [".zip"],
																	}
																: form.watch("type") === "Texto"
																	? {
																			"text/plain": [".txt"],
																		}
																	: undefined
												}
												onUpload={async (e) => {
													form.setValue("file", e[0]);
												}}
												disabled={false}
											/>
										)}

										{form.formState.errors.file && (
											<p className="font-inter text-P6 font-normal leading-160 text-red-600">
												{form.formState.errors.file.message}
											</p>
										)}
									</div>
								)}
							</div>
						</div>
					</Form>
				</Modal>
			)}

			{deleteModal && (
				<DeleteModal
					title="Deseja realmente excluir esse material?"
					description="Essa ação não poderá ser desfeita."
					icon="warning.svg"
					confirmText="Excluir material"
					isLoading={isLoading}
					cancelAction={() => setDeleteModal(false)}
					confirmAction={handleDeleteMaterial}
					closeModal={() => setDeleteModal(false)}
				/>
			)}

			{successDeleteModal && (
				<AlertModal
					title="Material excluído com sucesso!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessDeleteModal(false);
						setProduct(null);
					}}
					closeModal={() => {
						setSuccessDeleteModal(false);
						setProduct(null);
					}}
				/>
			)}
		</div>
	);
}
