import { Skeleton } from "@/components/ui/skeleton";

export function SkeletonMaterialCard() {
	return (
		<div className="relative flex h-[164px] w-full flex-col gap-6 rounded-xl bg-neutral-100 bg-opacity-40 p-6 transition-colors duration-300 ease-in-out">
			<div className="flex size-12 items-center justify-center rounded-[10px] border border-neutral-100 bg-neutral-600 bg-opacity-[0.04] p-3">
				<Skeleton className="h-[24px] w-[24px] rounded" />
			</div>
			<div className="flex flex-col gap-1">
				<Skeleton className="h-5 w-full rounded" />
				<Skeleton className="h-4 w-3/4 rounded" />
			</div>
			<div className="absolute bottom-6 right-6 flex size-8 items-center justify-center rounded-[4px] bg-neutral-600 p-2 text-neutral-1100 opacity-100 transition-opacity duration-300 md:opacity-0">
				<Skeleton className="h-[16px] w-[16px] rounded" />
			</div>
		</div>
	);
}
