import { AxiosError } from "axios";

import { api } from "@/lib/axios";

interface GetPanelSalesParams {
	period: string[];
	product_id: string;
}

type sales_count_by_platform = {
	platform: "The Mart" | "Monetizze" | "Braip";
	count: number;
};

type data_platform_sales = {
	[date: string]: {
		platform: "The Mart" | "Monetizze" | "Braip";
		date: string;
		count: number;
	}[];
};

type data_platform_sales_by_product = {
	[product: string]: {
		platforms: Record<string, number>[];
		count: number;
	};
};

export type Sales = {
	sales_count: number;
	sales_count_by_platform: sales_count_by_platform[];
	data_platform_sales: data_platform_sales;
	data_platform_sales_by_product: data_platform_sales_by_product;
};

export async function getPanelSales(
	params: Partial<GetPanelSalesParams>,
): Promise<
	| {
			status: "success";
			data: Sales;
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get("/panel/info-sales-panel", {
			params,
		});

		return {
			status: "success",
			data: data.data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
