import { useEffect, useRef, useState } from "react";

import { useAuthStore } from "@/store/auth";
import { authorFallback } from "@/utils/mask-author-fallback";

import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Button } from "./ui/button";
import { Textarea } from "./ui/textarea";

interface InputCommentProps {
	onSubmit: (comment: string) => void;
	isLoading?: boolean;
}

export function InputComment({ onSubmit, isLoading }: InputCommentProps) {
	const [value, setValue] = useState("");
	const [isFocused, setIsFocused] = useState(false);
	const textAreaRef = useRef<HTMLDivElement>(null);

	function handleFocus() {
		setIsFocused(true);
	}

	useEffect(() => {
		function handleClickOutsideProfile(event: MouseEvent) {
			if (
				textAreaRef.current &&
				!textAreaRef.current.contains(event.target as Node)
			) {
				setIsFocused(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutsideProfile);

		return () => {
			document.removeEventListener("mousedown", handleClickOutsideProfile);
		};
	}, []);

	const { profile } = useAuthStore();

	return (
		<div className="flex w-full gap-6">
			<Avatar className="size-10 border-[6px] border-neutral-0">
				<AvatarImage src={profile?.image ?? ""} />
				<AvatarFallback>
					{authorFallback(profile?.nickname || profile?.name)}
				</AvatarFallback>
			</Avatar>

			<div className="relative w-full" ref={textAreaRef}>
				<Textarea
					className="w-full resize-none rounded-[8px] border border-neutral-100 bg-transparent p-4 font-inter !text-P5 font-normal leading-160 text-neutral-1100"
					style={{
						height: isFocused || value ? "100px" : "auto",
					}}
					placeholder="Adicione um comentário"
					value={value}
					onChange={(e) => setValue(e.target.value)}
					onFocus={handleFocus}
				/>

				{(isFocused || value) && (
					<div className="absolute bottom-4 right-4 flex gap-4">
						<Button
							className="flex !h-10 flex-1 items-center justify-center gap-2 rounded-[4px] bg-transparent px-4 font-inter !text-P7 font-semibold leading-160 text-neutral-600 transition-all duration-500 hover:bg-neutral-200 disabled:bg-transparent disabled:text-neutral-400"
							variant="secondary"
							type="button"
							onClick={() => {
								setValue("");
								setIsFocused(false);
							}}
						>
							Cancelar
						</Button>
						<Button
							className={`flex !h-10 flex-1 items-center justify-center gap-2 rounded-[4px] bg-neutral-100 px-4 font-inter !text-P7 font-semibold leading-160 text-neutral-400 hover:bg-neutral-200 hover:bg-opacity-40
								${value ? "!bg-primary-600 text-neutral-1100" : ""}
							`}
							type="button"
							loading={isLoading}
							onClick={() => {
								if (value) {
									onSubmit(value);
									setValue("");
								}

								setTimeout(() => {
									setIsFocused(false);
								}, 2000);
							}}
						>
							Adicionar comentário
						</Button>
					</div>
				)}
			</div>
		</div>
	);
}
