import { AxiosError } from "axios";

import { api } from "@/lib/axios";

interface FetchTrainingParams {
	name: string;
	sortBy: "created_at" | "name";
	perPage: string;
}

export type Training = {
	id: string;
	name: string;
	description: string;
	mentor: {
		id: string;
		name: string;
		image: string;
	};
	available: boolean;
	image: string;
	modulesCount: number;
	lessonsCount: number;
};

export async function fetchTrainings(
	params: Partial<FetchTrainingParams>,
): Promise<
	| {
			status: "success";
			data: Training[];
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get("/training", {
			params,
		});

		return {
			status: "success",
			data: data.data.data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
