import { AxiosError } from "axios";

import { api } from "@/lib/axios";

interface getDashboardMovimentsParams {
	externalKey?: string;
	page?: number;
}

export type Moviment = {
	id: string;
	productName: string;
	value: string;
	commission_value: string;
	created_at: string;
	platform: "Braip" | "The Mart" | "Monetizze";
	externalKey: string;
	trackingCode: string;
};

export async function getDashboardMoviments(
	params: Partial<getDashboardMovimentsParams>,
): Promise<
	| {
			status: "success";
			data: Moviment[];
			lastPage: number;
			page: number;
			perPage: number;
			total: number;
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get("/dashboard/sales-dashboard", {
			params,
		});

		return {
			status: "success",
			data: data.data.data,
			lastPage: data.data.meta.lastPage,
			page: data.data.meta.page,
			perPage: data.data.meta.perPage,
			total: data.data.meta.total,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
