import { useState } from "react";

import ArrowLeftIcon from "@/assets/arrow-left.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import { ContentCardNavigation } from "@/components/content-card-navigation";
import { useProductStore } from "@/store/product";

export function ProductQuestions() {
	const { product } = useProductStore();

	const [currentQuestion, setCurrentQuestion] = useState<{
		title: string;
		content: string;
	} | null>(null);

	return (
		<>
			{!currentQuestion && (product?.doubts.length ?? 0) > 0 && (
				<div className="flex flex-col gap-6">
					{product?.doubts.map((question, index) => (
						<ContentCardNavigation
							key={index}
							title={question.question}
							onNavigate={() =>
								setCurrentQuestion({
									title: question.question,
									content: question.answer,
								})
							}
						/>
					))}
				</div>
			)}

			{!currentQuestion && (product?.doubts.length ?? 0) === 0 && (
				<div className="flex flex-col items-center justify-center gap-6 px-56 py-36">
					<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
						<SearchIcon />
					</span>
					<h3 className="w-[240px] text-center font-inter font-medium leading-160 text-neutral-1100">
						Esse produto não possui nenhuma dúvida cadastrada
					</h3>
				</div>
			)}

			{currentQuestion && (
				<div className="flex flex-col gap-8">
					<button
						onClick={() => setCurrentQuestion(null)}
						className="flex cursor-pointer gap-1 font-inter text-P5 font-medium leading-160 text-neutral-600 transition-all duration-300 hover:text-neutral-1100 hover:underline hover:underline-offset-8"
					>
						<ArrowLeftIcon width={20} height={20} />
						<p>Voltar</p>
					</button>

					<div className="flex flex-col gap-6">
						<h3 className="font-inter text-P1 font-medium leading-140 text-neutral-1100">
							{currentQuestion.title}
						</h3>

						{currentQuestion.content.split("\n").map((paragraph, index) => (
							<p
								key={index}
								className="font-inter text-P5 font-normal leading-160 text-neutral-500"
							>
								{paragraph}
							</p>
						))}
					</div>
				</div>
			)}
		</>
	);
}
