import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";

import { passwordSignUp } from "@/api/auth/sign-up/password";
import { PasswordStrength } from "@/components/password-strength";
import { Button } from "@/components/ui/button";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { useAuthStore } from "@/store/auth";

const signUpPasswordForm = z
	.object({
		password: z
			.string({
				required_error: "A senha é obrigatória",
			})
			.min(8, {
				message: "A senha deve ter no mínimo 8 caracteres",
			}),
		password_confirmation: z
			.string({
				required_error: "A confirmação de senha é obrigatória",
			})
			.min(8, {
				message: "A senha deve ter no mínimo 8 caracteres",
			}),
	})
	.refine((data) => data.password === data.password_confirmation, {
		message: "As senhas não conferem",
		path: ["password_confirmation"],
	});

type SignUpPasswordForm = z.infer<typeof signUpPasswordForm>;

export function SignUpPassword() {
	const navigate = useNavigate();
	const { isAuth } = useAuthStore();

	const [isLoading, setIsLoading] = useState(false);

	const {
		register,
		handleSubmit,
		watch,
		setError,
		formState: { errors },
	} = useForm<SignUpPasswordForm>({
		resolver: zodResolver(signUpPasswordForm),
	});

	async function handleSignUpPassword({
		password,
		password_confirmation,
	}: SignUpPasswordForm) {
		setIsLoading(true);
		const response = await passwordSignUp({
			password,
			password_confirmation,
		});

		if (response.status === "success") {
			navigate("/sign-up-welcome");

			setIsLoading(false);

			return;
		}

		if (response.status === "error") {
			const { errors } = response;

			for (const key in errors) {
				setError(key as keyof SignUpPasswordForm, {
					type: "manual",
					message: errors[key],
				});
			}

			setIsLoading(false);

			return;
		}
	}

	useEffect(() => {
		if (!isAuth) {
			navigate("/sign-in");
		}
	}, [isAuth]);

	return (
		<>
			<Helmet title="Cadastre-se" />

			<div className="flex animate-fade flex-col gap-12">
				<div className="flex flex-col gap-2">
					<h1 className="font-poppins text-H7 font-semibold leading-140 text-white">
						Falta pouco! Crie sua senha
					</h1>
					<p className="font-inter text-P5 leading-160 text-neutral-500 md:text-P4">
						Crie uma sua senha abaixo. Ela precisa ser segura.{" "}
					</p>
				</div>

				<form
					onSubmit={handleSubmit(handleSignUpPassword)}
					className="flex flex-col gap-10"
				>
					<div className="flex flex-col gap-6">
						<div className="flex flex-col gap-2">
							<FloatingLabelInput
								id="password"
								type="password"
								label="Senha"
								hasError={!!errors.password}
								{...register("password")}
							/>

							{errors.password && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{errors.password.message}
								</p>
							)}
						</div>

						<div className="flex flex-col gap-2">
							<FloatingLabelInput
								id="password_confirmation"
								type="password"
								label="Confirme sua senha"
								hasError={!!errors.password_confirmation}
								{...register("password_confirmation")}
							/>

							{errors.password_confirmation && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{errors.password_confirmation.message}
								</p>
							)}
						</div>

						{watch("password") && (
							<PasswordStrength password={watch("password")} />
						)}
					</div>

					<div className="flex flex-col gap-8">
						<Button type="submit" loading={isLoading}>
							Finalizar cadastro
						</Button>

						<footer className="flex items-center justify-center">
							<p className="font-inter text-P5 leading-160 text-neutral-500">
								Já tem acesso?
								<Link
									to="/sign-in"
									className="cursor-pointer text-white transition-colors hover:text-primary-600"
								>
									{" "}
									Acesse sua conta
								</Link>
							</p>
						</footer>
					</div>
				</form>
			</div>
		</>
	);
}
