export function maskHideEmail(email: string) {
	if (!email) return "";

	const [username, domain] = email.split("@");
	const maskedUsername = username
		.split("")
		.map((char, index) =>
			index < 1 || index > username.length - 3 ? char : "*",
		)
		.join("");

	return `${maskedUsername}@${domain}`;
}
