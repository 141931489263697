import { Outlet } from "react-router-dom";

import { TabNavigation } from "@/components/tab-navigation";

export function AdminAcademy() {
	const tabs = [
		{
			title: "Treinamentos",
			to: `/admin/academy`,
		},
		{
			title: "Materiais",
			to: `/admin/academy/materials`,
		},
	];

	return (
		<section className="flex w-full flex-col gap-12">
			<TabNavigation tabs={tabs} />

			<Outlet />
		</section>
	);
}
