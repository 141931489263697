import { useState } from "react";

import { addLocalStorageItem } from "@/utils/local-storage";

import { Button } from "./ui/button";

interface OnboardingModalProps {
	setFinishedOnboarding: () => void;
}

export function OnboardingModal({
	setFinishedOnboarding,
}: OnboardingModalProps) {
	const onboarding = [
		{
			id: 1,
			title: "Um só lugar para você vender mais",
			description:
				"Afilie-se a vários produtos de diferentes plataformas em um só espaço, facilitando a gestão e aumentando suas oportunidades de vendas.",
			image: "/onboarding1.png",
		},
		{
			id: 2,
			title: "Conheça nossos produtos",
			description:
				"Veja nossa lista de produtos disponíveis para afiliação. Escolha o que mais combina com você e comece a vender de forma fácil e rápida!",
			image: "/onboarding2.png",
		},
		{
			id: 3,
			title: "Aprenda e treine novas habilidades",
			description:
				"Acesse nossa área de treinamentos e aprenda tudo o que precisa para vender mais como afiliado. Melhore suas habilidades e aumente seus resultados!",
			image: "/onboarding3.png",
		},
	];

	const [currentIndex, setCurrentIndex] = useState(0);

	const goToPrevious = () => {
		if (currentIndex === 0) return;
		setTimeout(() => setCurrentIndex((prev) => prev - 1), 150);
	};

	const goToNext = () => {
		if (currentIndex === onboarding.length - 1) {
			addLocalStorageItem("@fm-suplementos/onboarding", true);
			setFinishedOnboarding();
			return;
		}

		setTimeout(() => setCurrentIndex((prev) => prev + 1), 150);
	};

	return (
		<div className="flex h-full w-full max-w-[704px] flex-col items-center rounded-xl bg-neutral-100 md:h-auto">
			<div className="flex h-full flex-col items-center gap-2.5 px-5 pt-6 md:h-auto md:px-4 md:pt-4">
				<div className="md;pt-10 flex items-center justify-center rounded-[10px] bg-gradient-to-b from-primary-600 via-[#058D4C33] px-4 pt-24 transition-all duration-500 md:px-[90px]">
					<img src={onboarding[currentIndex].image} alt="Macbook Pro" />
				</div>

				<div className="flex flex-1 flex-col items-center justify-between md:justify-normal">
					<div className="flex flex-col gap-3 px-8 pb-8 pt-10 md:p-8">
						<div className="flex gap-4 pb-5 md:hidden md:pb-0">
							{onboarding.map((_, index) => {
								return (
									<span
										key={index}
										className={`inline-block h-[2px] w-8 rounded-[10px] bg-neutral-200 transition-all duration-500 ${
											currentIndex === index && "!bg-neutral-1100"
										}`}
									></span>
								);
							})}
						</div>

						<h6 className="font-poppins text-H7 font-semibold leading-140 text-neutral-1100 transition-all duration-500">
							{onboarding[currentIndex].title}
						</h6>

						<p className="font-inter text-P4 font-normal leading-160 text-neutral-500 transition-all duration-500">
							{onboarding[currentIndex].description}
						</p>
					</div>

					<nav className="flex w-full items-center justify-center p-8 md:justify-between">
						<div className="hidden gap-4 md:flex">
							{onboarding.map((_, index) => {
								return (
									<span
										key={index}
										className={`inline-block h-[2px] w-5 rounded-[10px] bg-neutral-200 transition-all duration-500 ${
											currentIndex === index && "!w-10 !bg-neutral-1100"
										}`}
									></span>
								);
							})}
						</div>
						<div className="flex gap-4">
							<Button
								className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] bg-transparent font-inter !text-P6 font-semibold leading-160 text-neutral-600 transition-all duration-500 hover:bg-neutral-200 disabled:bg-transparent disabled:text-neutral-400"
								variant="secondary"
								onClick={goToPrevious}
								disabled={currentIndex === 0}
							>
								Voltar
							</Button>
							<Button
								className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] font-inter !text-P6 font-semibold leading-160"
								onClick={goToNext}
							>
								{currentIndex === onboarding.length - 1 ? "Começar" : "Avançar"}
							</Button>
						</div>
					</nav>
				</div>
			</div>
		</div>
	);
}
