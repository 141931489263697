import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";

import { showComments } from "@/api/app/academy/trainings/comments";
import { showLesson } from "@/api/app/academy/trainings/lesson";
import { showTraining } from "@/api/app/academy/trainings/show";
import { useCommentStore } from "@/store/comment";
import { useLessonStore } from "@/store/lesson";
import { useNavigationStore } from "@/store/navigation-store";
import { useTrainingStore } from "@/store/training";

import { AcademyTrainingComments } from "./comments";
import { AcademyTrainingDetails } from "./details";
import { AcademyTrainingHeader } from "./header";
import { AcademyTrainingLesson } from "./lesson";
import { LessonMaterials } from "./materials";
import { AcademyTrainingMenu } from "./menu";

export function AcademyTraining() {
	const { trainingId, lessonId } = useParams<{
		trainingId: string;
		lessonId: string;
	}>();

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	const menuRef = useRef<HTMLDivElement>(null);

	const [showMenu, setShowMenu] = useState(false);
	const [showBackground, setShowBackground] = useState(false);

	useEffect(() => {
		function handleClickOutsideMenu(event: MouseEvent) {
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				setShowMenu(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutsideMenu);

		return () => {
			document.removeEventListener("mousedown", handleClickOutsideMenu);
		};
	}, []);

	useEffect(() => {
		if (showMenu) {
			const timer = setTimeout(() => setShowBackground(true), 1000);
			return () => clearTimeout(timer);
		} else {
			setShowBackground(false);
		}
	}, [showMenu]);

	const navigate = useNavigate();
	const { setTraining, training } = useTrainingStore();
	const { setLesson } = useLessonStore();
	const { setComment } = useCommentStore();

	async function getTrainingById() {
		if (!trainingId) return navigate("/academy/trainings");

		const response = await showTraining(trainingId);

		if (response.status === "success") {
			setTraining(response.data);
			return response.data;
		}

		if (response.status === "error") {
			navigate("/products");
		}
	}

	async function getLessonById(currentLessonId: string) {
		const response = await showLesson(currentLessonId);

		if (response.status === "success") {
			setLesson(response.data);
		}

		if (response.status === "error") {
			navigate(`/academy/trainings/${trainingId}`);
		}
	}

	async function fetchCommentsByLessonId(currentLessonId: string) {
		const response = await showComments({
			lessonId: currentLessonId,
			sortBy: "likes-DESC",
			perPage: 20,
		});

		if (response.status === "success") {
			setComment(response.data);
		}
	}

	useEffect(() => {
		async function fetchData() {
			const trainingData = await getTrainingById();

			if (!lessonId && trainingData) {
				const currentLessonId = trainingData.modules[0].lessons[0].id;
				navigate(`/academy/${trainingId}/${currentLessonId}`);
			}

			if (trainingData) {
				const currentLessonId =
					lessonId ?? trainingData.modules[0].lessons[0].id;
				await getLessonById(currentLessonId);
				await fetchCommentsByLessonId(currentLessonId);
			}
		}

		fetchData();
	}, [trainingId, lessonId]);

	useEffect(() => {
		const paths = [
			{
				name: "Academy",
				path: "/academy",
			},
			{
				name: "Treinamentos",
				path: "/academy/trainings",
			},
			{
				name: `${training ? training?.name : "..."}`,
				path: `/academy/trainings/${trainingId}`,
			},
		];

		setPaths(paths);
	}, [training]);

	return (
		<>
			{training && <Helmet title={training.name} />}

			<div className="flex flex-col gap-14">
				<AnimatePresence>
					{showMenu && (
						<motion.div
							className={`fixed left-0 top-0 z-40 flex h-full w-full transition-all duration-500 ${
								showBackground ? "bg-neutral-0 bg-opacity-60" : "bg-transparent"
							}`}
							initial={{ opacity: 0, x: "-100%", scale: 0.8 }}
							animate={{ opacity: 1, x: "0%", scale: 1 }}
							exit={{ opacity: 0, x: "-100%", scale: 0.8 }}
							transition={{
								type: "spring",
								stiffness: 300,
								damping: 30,
								duration: 0.5,
							}}
						>
							<div ref={menuRef} className="flex h-full w-full max-w-[352px]">
								<AcademyTrainingMenu closeMenu={() => setShowMenu(false)} />
							</div>
						</motion.div>
					)}
				</AnimatePresence>

				<section className="flex flex-col gap-8">
					<AcademyTrainingHeader
						setShowMenu={setShowMenu}
						showMenu={showMenu}
					/>

					<AcademyTrainingLesson />
				</section>

				<section className="flex flex-col md:flex-row md:gap-24">
					<div className="flex w-full flex-col gap-10">
						<AcademyTrainingDetails />

						<hr className="h-px border-t-neutral-100" />

						<AcademyTrainingComments />
					</div>

					<aside className="sticky top-36 flex h-full w-full flex-col gap-6 rounded-xl border border-neutral-100 p-6 md:max-w-[352px] ">
						<LessonMaterials />
					</aside>
				</section>
			</div>
		</>
	);
}
