import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { userUpdatePassword } from "@/api/admin/users/update-password";
import { Modal } from "@/components/modal";
import { PasswordStrength } from "@/components/password-strength";
import { FloatingLabelInput } from "@/components/ui/float-input";

interface AdminUsersModalPasswordProps {
	closeModal: () => void;
	currentUserId: string;
}

export function AdminUsersModalPassword({
	closeModal,
	currentUserId,
}: AdminUsersModalPasswordProps) {
	const [isLoading, setIsLoading] = useState(false);

	const forgotPasswordChagePasswordForm = z
		.object({
			password: z.string().min(8, {
				message: "A senha precisa ter no mínimo 8 caracteres",
			}),
			password_confirmation: z.string().min(8, {
				message: "A senha precisa ter no mínimo 8 caracteres",
			}),
		})
		.refine((data) => data.password === data.password_confirmation, {
			message: "As senhas não conferem",
			path: ["confirmPassword"],
		});

	type ForgotPasswordChagePasswordForm = z.infer<
		typeof forgotPasswordChagePasswordForm
	>;

	const {
		register,
		setError,
		watch,
		formState: { errors },
	} = useForm<ForgotPasswordChagePasswordForm>({
		resolver: zodResolver(forgotPasswordChagePasswordForm),
	});

	async function handleFormSubmit(data: ForgotPasswordChagePasswordForm) {
		setIsLoading(true);

		const response = await userUpdatePassword(data, currentUserId);

		if (response.status === "success") {
			setIsLoading(false);
			closeModal();
		}

		if (response.status === "error") {
			const { errors } = response;

			for (const key in errors) {
				setError(key as keyof ForgotPasswordChagePasswordForm, {
					type: "manual",
					message: errors[key],
				});
			}

			setIsLoading(false);
			return;
		}
	}

	return (
		<Modal
			title="Alterar senha"
			closeModal={closeModal}
			confirmAction={() => {
				handleFormSubmit({
					password: watch("password"),
					password_confirmation: watch("password_confirmation"),
				});
			}}
			cancelAction={closeModal}
			isLoading={isLoading}
			className="h-auto"
		>
			<form className="flex flex-col gap-10">
				<div className="flex flex-col gap-6">
					<div className="flex flex-col gap-2">
						<FloatingLabelInput
							id="password"
							type="password"
							label="Nova senha"
							hasError={!!errors.password}
							{...register("password")}
						/>

						{errors.password && (
							<p className="font-inter text-P6 font-normal leading-160 text-red-600">
								{errors.password.message}
							</p>
						)}
					</div>

					<div className="flex flex-col gap-2">
						<FloatingLabelInput
							id="confirmPassword"
							type="password"
							label="Confirme a nova senha"
							hasError={!!errors.password_confirmation}
							{...register("password_confirmation")}
						/>

						{errors.password_confirmation && (
							<p className="font-inter text-P6 font-normal leading-160 text-red-600">
								{errors.password_confirmation.message}
							</p>
						)}
					</div>

					{watch("password") && (
						<PasswordStrength password={watch("password")} />
					)}
				</div>
			</form>
		</Modal>
	);
}
