import { AxiosError } from "axios";

import { api } from "@/lib/axios";

export interface PasswordSignUpBody {
	password: string;
	password_confirmation: string;
}

export async function passwordSignUp({
	password,
	password_confirmation,
}: PasswordSignUpBody): Promise<
	{ status: "success" } | { status: "error"; errors: { [key: string]: string } }
> {
	try {
		await api.post("/auth/register-password", {
			password,
			password_confirmation,
		});

		return {
			status: "success",
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
			message: string;
		}>;

		if (axiosError.response?.status === 401) {
			return {
				status: "error",
				errors: {
					code: axiosError.response.data.message,
				},
			};
		}

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
