import * as AccordionPrimitive from "@radix-ui/react-accordion";
import {
	ComponentPropsWithoutRef,
	ElementRef,
	forwardRef,
	useState,
} from "react";

import { cn } from "@/lib/utils";

import { Switch } from "./ui/switch";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = forwardRef<
	ElementRef<typeof AccordionPrimitive.Item>,
	ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
	<AccordionPrimitive.Item
		ref={ref}
		className={cn(
			"mb-6 flex flex-col gap-6 rounded-[8px] border border-neutral-100 p-6 transition-all duration-300 hover:bg-neutral-100 [&[data-state=open]]:bg-neutral-100 [&[data-state=open]]:bg-opacity-60",
			className,
		)}
		{...props}
	/>
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = forwardRef<
	ElementRef<typeof AccordionPrimitive.Trigger>,
	ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = () => {
		setIsOpen((prevIsOpen) => !prevIsOpen);
	};

	return (
		<AccordionPrimitive.Header className="flex">
			<AccordionPrimitive.Trigger
				ref={ref}
				className={cn(
					"flex flex-1 items-center justify-between transition-all duration-300 [&[data-state=open]>span>svg]:rotate-180",
					className,
				)}
				{...props}
				onClick={handleToggle}
			>
				<h4 className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
					{children}
				</h4>

				<Switch checked={isOpen} />
			</AccordionPrimitive.Trigger>
		</AccordionPrimitive.Header>
	);
});
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = forwardRef<
	ElementRef<typeof AccordionPrimitive.Content>,
	ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
	<AccordionPrimitive.Content
		ref={ref}
		className="overflow-hidden text-P5 transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
		{...props}
	>
		<div
			className={cn(
				"flex flex-col gap-6 font-inter text-P5 font-normal leading-160 text-neutral-500",
				className,
			)}
		>
			<hr className="h-px border-t-neutral-200" />
			{children}
		</div>
	</AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
