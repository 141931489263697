import { create } from "zustand";

import type { Training } from "@/api/app/academy/trainings/show";

type State = {
	training: Training | null;
};

type Actions = {
	setTraining: (training: Training | null) => void;
};

export const useTrainingStore = create<State & Actions>((set) => ({
	training: null,
	setTraining: (training) => set({ training }),
}));
