interface OptionItemProps extends React.HTMLAttributes<HTMLButtonElement> {
	text: string;
	isSelected: boolean;
}

export function OptionItem({ text, isSelected, ...rest }: OptionItemProps) {
	return (
		<span
			className={`flex cursor-pointer items-center rounded-[2px] bg-neutral-200 p-4 transition-colors hover:bg-neutral-100 ${isSelected && "bg-white hover:bg-white"}`}
			{...rest}
		>
			<p
				className={`font-inter text-P5 font-medium leading-160 ${isSelected ? "text-neutral-0" : "text-white"}`}
			>
				{text}
			</p>
		</span>
	);
}
