import { createContext, ReactNode, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuthStore } from "@/store/auth";

interface AuthProviderProps {
	children: ReactNode;
}

export interface LoginProps {
	email: string;
	password: string;
}

interface IAuthContext {}

const AuthContext = createContext({} as IAuthContext);

function AuthProvider({ children }: AuthProviderProps) {
	const { isAuth, profile } = useAuthStore();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (!isAuth && location.pathname === "/forgot-password-change") {
			return;
		}

		if (
			isAuth &&
			profile?.questionsUser.length === 0 &&
			location.pathname !== "/onboarding"
		) {
			navigate("/onboarding");
			return;
		}
	}, [isAuth]);

	return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>;
}

function useAuth() {
	const context = useContext(AuthContext);

	return context;
}

export { AuthProvider, useAuth };
