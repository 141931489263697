import { Link } from "react-router-dom";

import ChevronRightIcon from "@/assets/chevron-right.svg?react";

type Path = {
	name: string;
	path: string;
};

interface BreadcrumbProps {
	paths: Path[];
}

export function Breadcrumb({ paths }: BreadcrumbProps) {
	return (
		<nav className="overflow-x-auto overflow-y-hidden hide-scrollbar">
			<ol className="flex items-center justify-center gap-2">
				{paths.map((path, index) => (
					<li key={`breadcrumb-${path.name}-${index}`}>
						{index < paths.length - 1 ? (
							<span className="flex items-center gap-2 text-neutral-400">
								<Link
									to={path.path}
									className="font-inter text-P4 font-medium leading-140 md:text-P2"
								>
									{path.name}
								</Link>
								<ChevronRightIcon />
							</span>
						) : (
							<span className="font-inter text-P4 font-medium leading-140 text-white md:text-P2">
								{path.name}
							</span>
						)}
					</li>
				))}
			</ol>
		</nav>
	);
}
