import CloseIcon from "@/assets/close-2.svg?react";

import { Button } from "./ui/button";

interface ModalProps {
	title: string;
	description?: string;
	icon: string;
	hasCancel?: boolean;
	hasConfirm?: boolean;
	confirmText?: string;
	cancelText?: string;
	isLoading?: boolean;
	confirmAction: () => void;
	cancelAction?: () => void;
	closeModal: () => void;
}

export function AlertModal({
	title,
	description,
	icon,
	hasCancel = true,
	hasConfirm = true,
	confirmText,
	cancelText,
	isLoading = false,
	confirmAction,
	cancelAction,
	closeModal,
}: ModalProps) {
	return (
		<div className="fixed bottom-0 left-0 right-0 top-0 z-20 flex flex-col items-center justify-center gap-4 bg-[#000] bg-opacity-60">
			<div className="relative flex w-[480px] flex-col items-center rounded-xl bg-neutral-100 p-10">
				<button
					className="absolute -top-14 right-0 flex items-center justify-center gap-2.5 rounded-[8px] bg-neutral-100 p-2"
					onClick={closeModal}
				>
					<CloseIcon width={24} height={24} />
				</button>

				<div className="flex flex-col items-center gap-8">
					<div className="flex items-center justify-center rounded-[14px] border border-primary-1000 bg-primary-600 bg-opacity-[0.04] p-5">
						<img src={`/svg/${icon}`} alt="icon" />
					</div>

					<div className="flex flex-col items-center gap-4">
						<h2 className="text-center font-inter !text-P2 font-medium leading-140 text-neutral-1100">
							{title}
						</h2>

						{description && (
							<p className="text-center font-inter text-P4 font-normal leading-160 text-neutral-600">
								{description}
							</p>
						)}
					</div>
				</div>

				<div className="mt-10 flex w-full flex-col gap-4 px-12">
					{hasConfirm && (
						<Button
							className="flex !h-12 w-full items-center justify-center gap-2 rounded-[8px] font-inter !text-P5 font-semibold leading-160"
							onClick={confirmAction}
							loading={isLoading}
						>
							{confirmText || "Confirmar"}
						</Button>
					)}

					{hasCancel && (
						<Button
							className="flex !h-12 w-full items-center justify-center gap-2 rounded-[8px] bg-transparent font-inter !text-P5 font-semibold leading-160 text-neutral-600 transition-all duration-500 hover:bg-neutral-200 hover:text-neutral-1100 disabled:bg-transparent disabled:text-neutral-400"
							variant="secondary"
							onClick={cancelAction}
						>
							{cancelText || "Cancelar"}
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}
