import "dayjs/locale/pt-br";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

export function formatDateSince(date: string) {
	const dateFormatted = dayjs(date, "DD-MM-YYYY HH:mm")
		.locale("pt-br")
		.format("MMMM [de] YYYY");

	return dateFormatted.charAt(0).toUpperCase() + dateFormatted.slice(1);
}

export function formatDateNotification(date: string) {
	const parsedDate = dayjs(date, "DD-MM-YYYY HH:mm");
	return parsedDate.locale("pt-br").fromNow();
}

export function formatDate(date: string) {
	const dateFormatted = date.split(" ")[0];

	const parsedDate = dayjs(dateFormatted, "DD-MM-YYYY", true);

	if (!parsedDate.isValid()) {
		console.log("Invalid date", dateFormatted);
		return null;
	}

	return parsedDate.locale("pt-br").format("DD [de] MMMM [de] YYYY");
}

export function formatCommentDate(date: string) {
	const formattedDate = dayjs(date, "DD-MM-YYYY HH:mm")
		.locale("pt-br")
		.format("DD.MM.YYYY [às] HH[h]mm");

	return formattedDate;
}
