import MoreAltIcon from "@/assets/more-alt.svg?react";
import PenIcon from "@/assets/pen.svg?react";
import TrashIcon from "@/assets/trash.svg?react";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface AdminLessonCardProps {
	id: string;
	image: string;
	lesson: number;
	title: string;
	onEdit: () => void;
	onDelete: () => void;
}

export function AdminLessonCard({
	id,
	image,
	lesson,
	title,
	onEdit,
	onDelete,
}: AdminLessonCardProps) {
	const optionsIcons = {
		trash: <TrashIcon />,
		pen: <PenIcon />,
	};

	const options:
		| {
				title: string;
				icon: keyof typeof optionsIcons;
		  }[]
		| undefined = [
		{ title: "Editar", icon: "pen" },
		{ title: "Excluir", icon: "trash" },
	];

	return (
		<div className="flex w-full items-center justify-between gap-6 rounded-[6px] bg-neutral-1100 bg-opacity-5 p-4">
			<div className="flex items-center gap-6">
				<img
					src={image || "https://via.placeholder.com/150"}
					className="h-[64px] w-[85px] rounded-[6px] object-cover"
				/>

				<div className="flex w-full flex-1 flex-col gap-2">
					<span className="font-inter text-P6 font-normal leading-160 text-neutral-600">
						Aula {lesson}
					</span>

					<p className="line-clamp-1 text-ellipsis font-inter text-P5 font-medium leading-160 text-neutral-1100">
						{title}
					</p>
				</div>
			</div>

			<span className="relative flex size-8 cursor-pointer items-center justify-center rounded-[8px] bg-transparent p-2  text-neutral-1100 transition-all duration-300 hover:rounded-[8px] hover:bg-neutral-200">
				<DropdownMenu>
					<DropdownMenuTrigger className="focus-visible:outline-none">
						<MoreAltIcon />
					</DropdownMenuTrigger>
					<DropdownMenuContent>
						{options.map((option, index) => (
							<DropdownMenuItem
								key={id + index + "option"}
								onClick={option.title === "Editar" ? onEdit : onDelete}
							>
								{optionsIcons[option.icon]}
								<p>{option.title}</p>
							</DropdownMenuItem>
						))}
					</DropdownMenuContent>
				</DropdownMenu>
			</span>
		</div>
	);
}
