import { create } from "zustand";

import type { Comment } from "@/api/app/academy/trainings/comments";

type State = {
	comment: Comment[] | null;
};

type Actions = {
	setComment: (comment: Comment[] | null) => void;
};

export const useCommentStore = create<State & Actions>((set) => ({
	comment: null,
	setComment: (comment) => set({ comment }),
}));
