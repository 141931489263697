import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import LockIcon from "@/assets/lock.svg?react";
import { Button } from "@/components/ui/button";

export function ForgotPasswordConfirm() {
	const navigate = useNavigate();

	return (
		<>
			<Helmet title="Esqueci a senha" />

			<div className="flex animate-fade flex-col gap-12">
				<span className="flex size-20 items-center justify-center rounded-s border border-solid border-neutral-200 bg-[#ffffff0a] p-4 text-primary-600">
					<LockIcon />
				</span>
				<div className="flex flex-col gap-2">
					<h1 className="font-poppins text-H7 font-semibold leading-140 text-white">
						Senha alterada com sucesso!
					</h1>
					<p className="font-inter text-P4 leading-160 text-neutral-500 md:text-P4">
						Você já pode acessar sua conta.
					</p>
				</div>
				<section className="flex flex-col gap-10">
					<div className="flex flex-col gap-8">
						<Button onClick={() => navigate("/sign-in")}>
							Acessar minha conta
						</Button>
					</div>
				</section>
			</div>
		</>
	);
}
