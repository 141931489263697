import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import {
	type Achievement,
	fetchAchievements,
} from "@/api/app/account/achievements";
import { Button } from "@/components/ui/button";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
} from "@/components/ui/carousel";
import { Skeleton } from "@/components/ui/skeleton";
import { useAuthStore } from "@/store/auth";
import { useNavigationStore } from "@/store/navigation-store";

const achievementLink: Record<string, string> = {
	"Membro fundador": "foundation-member.png",
	"Conta criada": "account-created.png",
	"Primeiros passos": "first-steps.png",
	"Integração Braip": "braip-integration.png",
	"Integração Monetizze": "monetizze-integration.png",
	"Integração The Mart": "the-mart-integration.png",
	"15 mil": "15-thousand.png",
	"30 mil": "30-thousand.png",
	"60 mil": "60-thousand.png",
	"100 mil": "100-thousand.png",
	"200 mil": "200-thousand.png",
	"500 mil": "500-thousand.png",
	"1 Milhão": "1-million.png",
	"Top 1": "top-1.png",
	"Top 2": "top-2.png",
	"Top 3": "top-3.png",
	"Top 4": "top-4.png",
	"Top 5": "top-5.png",
};

export function AccountAchievements() {
	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Home",
				path: "/",
			},
			{
				name: `Minha conta`,
				path: `/account`,
			},
			{
				name: `Conquistas`,
				path: `/account/achievements`,
			},
		];

		setPaths(paths);
	}, []);

	const [isLoading, setIsLoading] = useState(false);
	const [achievementsAccount, setAchievementsAccount] = useState<Achievement[]>(
		[],
	);
	const [achievementsIntegrations, setAchievementsIntegrations] = useState<
		Achievement[]
	>([]);
	const [achievementsAwards, setAchievementsAwards] = useState<Achievement[]>(
		[],
	);
	const [achievementsRanking, setAchievementsRanking] = useState<Achievement[]>(
		[],
	);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedAchievement, setSelectedAchievement] = useState<
		| (Achievement & {
				gradient?: string;
		  })
		| null
	>(null);

	const openModal = (
		achievement: Achievement & {
			gradient?: string;
		},
	) => {
		setSelectedAchievement({
			...achievement,
			gradient: achievement.gradient,
		});
		setIsModalOpen(true);
	};

	useEffect(() => {
		setIsLoading(true);

		fetchAchievements()
			.then((response) => {
				if (response.status === "success") {
					const achievementsAccount = response.data.filter(
						(achievement) => achievement.type === "account",
					);

					const achievementsIntegrations = response.data.filter(
						(achievement) => achievement.type === "integration",
					);

					const achievementsAwards = response.data.filter(
						(achievement) => achievement.type === "award",
					);

					const achievementsRanking = response.data.filter(
						(achievement) => achievement.type === "achievement",
					);

					setAchievementsAccount(achievementsAccount);
					setAchievementsIntegrations(achievementsIntegrations);
					setAchievementsAwards(achievementsAwards);
					setAchievementsRanking(achievementsRanking);
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return (
		<>
			<Helmet title="Conquistas" />

			<div className="flex flex-col gap-12">
				<AchievementSection
					title="Conquistas sobre a conta"
					count={`${
						achievementsAccount.filter((achievement) => achievement.checked)
							.length
					} de ${achievementsAccount.length - 1}`}
					achievements={achievementsAccount}
					isLoading={isLoading}
					openModal={openModal}
				/>

				<AchievementSection
					title="Conquistas de integrações"
					count={`
						${
							achievementsIntegrations.filter(
								(achievement) => achievement.checked,
							).length
						} de ${achievementsIntegrations.length - 1}
						`}
					achievements={achievementsIntegrations}
					isLoading={isLoading}
					openModal={openModal}
				/>

				<AchievementSection
					title="Conquistas das placas de premiações"
					count={`
						${
							achievementsAwards.filter((achievement) => achievement.checked)
								.length
						} de ${achievementsAwards.length - 1}
						`}
					achievements={achievementsAwards}
					isLoading={isLoading}
					openModal={openModal}
				/>

				<AchievementSection
					title="Conquistas do ranking"
					count={`
						${
							achievementsRanking.filter((achievement) => achievement.checked)
								.length
						} de ${achievementsRanking.length - 1}
						`}
					achievements={achievementsRanking}
					isLoading={isLoading}
					openModal={openModal}
				/>

				{isModalOpen && selectedAchievement && (
					<AchievementModal
						onClose={() => setIsModalOpen(false)}
						name={selectedAchievement.name}
						description={selectedAchievement.congratulations_description}
						gradient={
							selectedAchievement.gradient ??
							"linear-gradient(0deg, #03502B 0%, #023D20 85.42%)"
						}
					/>
				)}
			</div>
		</>
	);
}

type AchievementSectionProps = {
	isLoading: boolean;
	title: string;
	count: string;
	achievements: Achievement[];
	openModal: (
		achievement: Achievement & {
			gradient?: string;
		},
	) => void;
};

function AchievementSection({
	isLoading,
	title,
	count,
	achievements,
	openModal,
}: AchievementSectionProps) {
	return (
		<section className="flex flex-col gap-20">
			<div className="flex items-center justify-between gap-4">
				<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
					{title}
				</p>
				<span className="font-inter text-P5 font-normal leading-160 text-neutral-600">
					{count}
				</span>
			</div>

			<div className="block lg:hidden">
				<Carousel className="-mt-10">
					<CarouselContent className="-ml-8 mt-10">
						{achievements.length === 0 &&
							isLoading &&
							Array.from({ length: 4 }).map((_, index) => (
								<CarouselItem className="basis-auto pl-8" key={index}>
									<SkeletonAchievementCard key={index} />
								</CarouselItem>
							))}

						{achievements.map((achievement) => (
							<CarouselItem className="basis-auto pl-8" key={achievement.id}>
								<AchievementCard
									key={achievement.id}
									isChecked={achievement.checked}
									description={achievement.description}
									name={achievement.name}
									onClick={() => {
										let gradient;

										switch (achievement.type) {
											case "account":
												gradient =
													"linear-gradient(0deg, #03502B 0%, #023D20 85.42%)";
												break;
											case "integration": {
												const gradientMap: Record<string, string> = {
													"Integração Braip":
														"linear-gradient(0deg, #980BD9 0%, #46077A 85.42%)",
													"Integração Monetizze":
														"linear-gradient(0deg, #0A5FB4 0%, #094179 85.42%)",
													"Integração The Mart":
														"linear-gradient(0deg, #F90 0%, #774700 85.42%)",
												};

												gradient =
													gradientMap[achievement.name] ??
													"linear-gradient(0deg, #A61381 0%, #79005B 85.42%)";

												break;
											}
											case "award": {
												const gradientMap: Record<string, string> = {
													"15 mil":
														"linear-gradient(0deg, #980BD9 0%, #45077A 85.42%)",
													"30 mil":
														"linear-gradient(180deg, #070969 15.09%, #0D51D9 100%)",
													"60 mil":
														"linear-gradient(0deg, #05E9EC 0%, #066369 85.42%)",
													"100 mil":
														"linear-gradient(0deg, #11D977 0%, #07673C 85.42%)",
													"200 mil":
														"linear-gradient(180deg, #784E27 14.62%, #EBB684 100%)",
													"500 mil":
														"linear-gradient(0deg, #D5AB26 0%, #765109 85.42%)",
													"1 Milhão":
														"linear-gradient(180deg, #383838 5.19%, #808080 100%)",
												};

												gradient =
													gradientMap[achievement.name] ??
													"linear-gradient(0deg, #03502B 0%, #023D20 85.42%)";

												break;
											}
											case "achievement": {
												const gradientMap: Record<string, string> = {
													"Top 1":
														"linear-gradient(180deg, #916814 5.19%, #D29A26 100%)",
													"Top 2":
														"linear-gradient(180deg, #868A8E 5.19%, #F1F3F5 100%)",
												};

												gradient =
													gradientMap[achievement.name] ??
													"linear-gradient(180deg, #8C5C30 5.19%, #CD9867 100%)";

												break;
											}
										}

										openModal({
											...achievement,
											gradient: gradient,
										});
									}}
								/>
							</CarouselItem>
						))}
					</CarouselContent>
				</Carousel>
			</div>

			<div className="hidden auto-cols-[230px] grid-cols-1 gap-x-6 gap-y-16 md:grid-cols-3 lg:grid lg:grid-cols-3 xl:grid-cols-4">
				{isLoading
					? Array.from({ length: 6 }).map((_, index) => (
							<SkeletonAchievementCard key={index} />
						))
					: achievements.map((achievement) => (
							<AchievementCard
								key={achievement.id}
								isChecked={achievement.checked}
								description={achievement.description}
								name={achievement.name}
								onClick={() => {
									let gradient;

									switch (achievement.type) {
										case "account":
											gradient =
												"linear-gradient(0deg, #03502B 0%, #023D20 85.42%)";
											break;
										case "integration": {
											const gradientMap: Record<string, string> = {
												"Integração Braip":
													"linear-gradient(0deg, #980BD9 0%, #46077A 85.42%)",
												"Integração Monetizze":
													"linear-gradient(0deg, #0A5FB4 0%, #094179 85.42%)",
												"Integração The Mart":
													"linear-gradient(0deg, #F90 0%, #774700 85.42%)",
											};

											gradient =
												gradientMap[achievement.name] ??
												"linear-gradient(0deg, #A61381 0%, #79005B 85.42%)";

											break;
										}
										case "award": {
											const gradientMap: Record<string, string> = {
												"15 mil":
													"linear-gradient(0deg, #980BD9 0%, #45077A 85.42%)",
												"30 mil":
													"linear-gradient(180deg, #070969 15.09%, #0D51D9 100%)",
												"60 mil":
													"linear-gradient(0deg, #05E9EC 0%, #066369 85.42%)",
												"100 mil":
													"linear-gradient(0deg, #11D977 0%, #07673C 85.42%)",
												"200 mil":
													"linear-gradient(180deg, #784E27 14.62%, #EBB684 100%)",
												"500 mil":
													"linear-gradient(0deg, #D5AB26 0%, #765109 85.42%)",
												"1 Milhão":
													"linear-gradient(180deg, #383838 5.19%, #808080 100%)",
											};

											gradient =
												gradientMap[achievement.name] ??
												"linear-gradient(0deg, #03502B 0%, #023D20 85.42%)";

											break;
										}
										case "achievement": {
											const gradientMap: Record<string, string> = {
												"Top 1":
													"linear-gradient(180deg, #916814 5.19%, #D29A26 100%)",
												"Top 2":
													"linear-gradient(180deg, #868A8E 5.19%, #F1F3F5 100%)",
											};

											gradient =
												gradientMap[achievement.name] ??
												"linear-gradient(180deg, #8C5C30 5.19%, #CD9867 100%)";

											break;
										}
									}

									openModal({
										...achievement,
										gradient: gradient,
									});
								}}
							/>
						))}
			</div>
		</section>
	);
}

type AchievementCardProps = {
	name: string;
	description: string;
	isChecked: boolean;
	onClick: () => void;
};

function AchievementCard({
	isChecked,
	description,
	name,
	onClick,
}: AchievementCardProps) {
	return (
		<div
			className={`relative flex h-full w-full min-w-[230px] max-w-[230px] cursor-pointer flex-col rounded-[12px] bg-neutral-1100 bg-opacity-[0.03] px-6 pb-6 pt-20 md:w-full
					${!isChecked && "!cursor-not-allowed"}
				`}
			onClick={isChecked ? onClick : () => {}}
		>
			<div className="absolute -top-10 left-1/2 h-24 w-24 -translate-x-1/2 transform">
				<img
					src={`
						/achievements/
						${achievementLink[name] ?? ""}
					`}
					alt={name}
					className="h-full w-full object-contain"
					style={{
						filter: isChecked ? "none" : "grayscale(100%)",
					}}
				/>
			</div>

			<div className="flex flex-col items-center gap-8">
				<div className="flex flex-col items-center gap-2">
					<p className="text-center font-inter text-P4 font-medium leading-160 text-neutral-1100">
						{name}
					</p>

					<span className="h-8 text-center font-inter text-P6 font-normal leading-160 text-neutral-600">
						{description}
					</span>
				</div>

				<div className="flex w-full flex-col items-center gap-2">
					{isChecked ? (
						<div className="flex w-full items-center gap-4">
							<div
								className={`flex h-1 w-full items-center rounded-full bg-neutral-1100 bg-opacity-20`}
							>
								<div
									className={`h-1 rounded-full bg-neutral-1100`}
									style={{ width: `${100}%` }}
								/>
							</div>
						</div>
					) : (
						<div className="flex w-full items-center gap-4">
							<div
								className={`flex h-1 w-full items-center rounded-full bg-neutral-1100 bg-opacity-20`}
							>
								<div
									className={`h-1 rounded-full bg-neutral-1100`}
									style={{ width: `0%` }}
								/>
							</div>
						</div>
					)}

					<p className="text-center text-P7 font-medium leading-160 text-neutral-1100">
						{isChecked ? "Conquistado" : "Não conquistado"}
					</p>
				</div>
			</div>
		</div>
	);
}

type AchievementModalProps = {
	onClose: () => void;
	name: string;
	description: string;
	gradient: string;
};

export function AchievementModal({
	onClose,
	description,
	name,
	gradient,
}: AchievementModalProps) {
	useEffect(() => {
		// Bloquear o scroll
		document.body.style.overflow = "hidden";

		// Restaurar o scroll ao desmontar o modal
		return () => {
			document.body.style.overflow = "";
		};
	}, []);

	const { profile } = useAuthStore();

	return (
		<div
			className="fixed bottom-0 left-0 right-0 top-0 z-20 flex flex-col items-center justify-center gap-4 bg-[#000] bg-opacity-60"
			onClick={onClose}
		>
			<div
				className="relative flex w-11/12 flex-col items-center gap-10 rounded-xl bg-neutral-100 md:w-[480px]"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="flex w-full flex-col items-center p-4 pb-0">
					<div className="flex w-full flex-col items-center">
						<div
							className="relative min-h-[106px] !w-full rounded-[8px]"
							style={{
								background: gradient,
							}}
						/>

						<img
							src={`/achievements/
								${achievementLink[name] ?? ""}`}
							alt="achievement"
							className="absolute top-[75px] h-24 w-24"
						/>
					</div>
				</div>

				<div className="flex flex-col items-center gap-12 p-8 pt-0">
					<div className="mt-[35px] flex flex-col items-center justify-center gap-10">
						<h3 className="text-clip font-inter text-P1 font-semibold leading-140 text-neutral-1100">
							{name.toUpperCase()}
						</h3>

						<div className="flex flex-col items-center justify-center gap-2">
							<p className="text-center font-inter text-P3 font-medium leading-160 text-neutral-1100">
								Parabéns, {profile?.nickname ?? profile?.name}!
							</p>

							<span className="text-center font-inter text-P4 font-normal leading-160 text-neutral-600">
								{description}
							</span>
						</div>
					</div>

					<Button
						type="button"
						variant="secondary"
						className="!h-10 !w-[240px] !font-inter !text-P5 !font-normal !leading-160"
						onClick={onClose}
					>
						Concluir
					</Button>
				</div>
			</div>
		</div>
	);
}

function SkeletonAchievementCard() {
	return (
		<div className="relative flex h-full w-full min-w-[230px] flex-col rounded-[12px] bg-neutral-1100 bg-opacity-[0.03] px-6 pb-6 pt-20">
			<div className="absolute -top-10 left-1/2 h-24 w-24 -translate-x-1/2 transform">
				<Skeleton className="h-full w-full rounded-full" />
			</div>

			<div className="flex flex-col items-center gap-8">
				<div className="flex flex-col items-center gap-2">
					<Skeleton className="h-6 w-36" />
					<Skeleton className="h-4 w-48" />
				</div>

				<div className="flex w-full flex-col items-center gap-2">
					<Skeleton className="h-1 w-full rounded-full" />
					<Skeleton className="h-4 w-24" />
				</div>
			</div>
		</div>
	);
}
