import { AxiosError } from "axios";

import { api } from "@/lib/axios";

interface GetPanelUsersParams {
	period: string[];
}

type data_new_users_and_integrations = {
	[date: string]: {
		type: "new_users" | "new_integration_users";
		date: string;
		count: number;
	}[];
};

type onboarding_questions = {
	[question: string]: {
		onboarding_answers: {
			[answer: string]: {
				count: number;
			}[];
		};
		count: number;
	};
};

export type Users = {
	new_users: number;
	new_integration_users: number;
	data_new_users_and_integrations: data_new_users_and_integrations;
	onboarding_questions: onboarding_questions;
};

export async function getPanelUsers(
	params: Partial<GetPanelUsersParams>,
): Promise<
	| {
			status: "success";
			data: Users;
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get("/panel/info-users-panel", {
			params,
		});

		return {
			status: "success",
			data: data.data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
