import { useState } from "react";

import type { User } from "@/api/admin/users/list";
import { showUser, User as ShowUser } from "@/api/admin/users/show";
import { userUpdateStatus } from "@/api/admin/users/update-status";
import { userUpdateType } from "@/api/admin/users/update-type";
import AdminMark from "@/assets/admin-mark.svg?react";
import Lock2Icon from "@/assets/lock-2.svg?react";
import MoreAltIcon from "@/assets/more-alt.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import SmileMouthOpenIcon from "@/assets/smile-mouth-open.svg?react";
import StarIcon from "@/assets/star.svg?react";
import UnavailableIcon from "@/assets/unavailable.svg?react";
import UnblockIcon from "@/assets/unblock.svg?react";
import { AlertModal } from "@/components/alert-modal";
import { DeleteModal } from "@/components/delete-modal";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import { useAuthStore } from "@/store/auth";
import { authorFallback } from "@/utils/mask-author-fallback";

import { AdminUsersModalPassword } from "./modal-password";
import { AdminUsersModalProfile } from "./modal-profile";

interface AdminUsersListProps {
	users: User[];
	setUsers: (value: User[]) => void;
	filterIsOpen: boolean;
}

export function AdminUsersList({
	users,
	filterIsOpen,
	setUsers,
}: AdminUsersListProps) {
	const [desactivateUserModal, setDesactivateUserModal] = useState(false);
	const [successChangeStatusModal, setSuccessChangeStatusModal] =
		useState(false);
	const [currentUserId, setCurrentUserId] = useState("");
	const [currentStatus, setCurrentStatus] = useState<"1" | "2">("1");
	const [isLoading, setIsLoading] = useState(false);

	const [isModalPasswordOpen, setIsModalPasswordOpen] = useState(false);
	const [isModalProfileOpen, setIsModalProfileOpen] = useState(false);

	const [currentUserData, setCurrentUserData] = useState<ShowUser | null>(null);

	const { profile } = useAuthStore();

	const optionsIcons: { [key: string]: JSX.Element } = {
		smileMouthOpen: <SmileMouthOpenIcon />,
		lock2: <Lock2Icon />,
		star: <StarIcon />,
		unvailable: <UnavailableIcon />,
	};

	const getOptions = (user: User) => {
		const options = [{ title: "Dados do usuário", icon: "smileMouthOpen" }];

		if (!user.isAdmin || profile?.isSudo) {
			options.push({ title: "Alterar senha", icon: "lock2" });
		}

		if (profile?.isSudo && user.id !== profile.id) {
			options.push({ title: "Remover admin", icon: "star" });
		}

		if (profile?.isAdmin && !user.isAdmin) {
			options.push({ title: "Desativar usuário", icon: "unvailable" });
		}

		return options;
	};

	async function handleChangeStatus(id: string, status: "1" | "2") {
		try {
			setIsLoading(true);
			const result = await userUpdateStatus({ status }, id);

			if (result.status === "success") {
				const updatedUsers = users.map((user) => {
					if (user.id === id) {
						return {
							...user,
							status: status === "1" ? "Ativo" : "Desativado",
						} as User;
					}
					return user;
				});

				setUsers(updatedUsers);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	async function handleChangeType(id: string, type: boolean) {
		try {
			setIsLoading(true);
			const result = await userUpdateType({ type }, id);
			if (result.status === "success") {
				const updatedUsers = users.map((user) => {
					if (user.id === id) {
						return {
							...user,
							isAdmin: type,
						} as User;
					}
					return user;
				});

				setUsers(updatedUsers);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	async function handleShowUser(id: string) {
		try {
			const result = await showUser(id);

			if (result.status === "success") {
				setCurrentUserData(result.data);
			}
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<div className={!filterIsOpen ? "-mt-12 mb-32" : ""}>
			{users.length > 0 && (
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead className="w-[35%]">Nome</TableHead>
							<TableHead className="w-[40%]">E-mail</TableHead>
							<TableHead className="w-[25%]">Data de entrada</TableHead>
							<TableHead className="w-[72px]"></TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{users.map((user, index) => (
							<TableRow
								key={index}
								className={`${
									user.status === "Desativado" ? "opacity-30" : ""
								}`}
							>
								<TableCell>
									<div className="flex items-center gap-4">
										<div className="relative">
											{user.isAdmin && (
												<AdminMark className="absolute top-0 z-[6] size-3.5" />
											)}
											<Avatar className="size-9 border-[6px] border-neutral-0">
												<AvatarImage src={user.image} />
												<AvatarFallback className="!text-P7 text-neutral-1100">
													{authorFallback(user.name)}
												</AvatarFallback>
											</Avatar>
										</div>
										<p className="line-clamp-1 text-ellipsis font-inter text-P5 font-normal leading-160 text-neutral-1100">
											{user.name}
										</p>
									</div>
								</TableCell>
								<TableCell>
									<p className="line-clamp-1 text-ellipsis font-inter text-P5 font-normal leading-160 text-neutral-1100">
										{user.email}
									</p>
								</TableCell>
								<TableCell>
									<p className="line-clamp-1 text-ellipsis font-inter text-P5 font-normal leading-160 text-neutral-600">
										{user.created_at}
									</p>
								</TableCell>
								<TableCell className="flex items-center justify-end">
									<span className="relative flex size-8 cursor-pointer items-center justify-center p-1 !text-neutral-600 hover:rounded-[8px] hover:bg-neutral-200">
										<DropdownMenu>
											<DropdownMenuTrigger className="relative focus-visible:outline-none">
												<MoreAltIcon />
											</DropdownMenuTrigger>
											<DropdownMenuContent className="absolute -right-4 !w-[240px]">
												{getOptions(user).map((option, index) => (
													<DropdownMenuItem
														key={index + "option"}
														onClick={() => {
															setCurrentUserId(user.id);

															if (option.title === "Dados do usuário") {
																handleShowUser(user.id);
																setIsModalProfileOpen(true);
															}

															if (option.title === "Alterar senha") {
																setIsModalPasswordOpen(true);
															}

															if (
																option.title === "Desativar usuário" &&
																user.status === "Desativado"
															) {
																handleChangeStatus(user.id, "1");
																setSuccessChangeStatusModal(true);
															}

															if (
																option.title === "Desativar usuário" &&
																user.status === "Ativo"
															) {
																setDesactivateUserModal(true);
																setCurrentStatus("2");
															}

															if (
																option.title === "Remover admin" &&
																user.isAdmin
															) {
																handleChangeType(user.id, false);
															}

															if (
																option.title === "Remover admin" &&
																!user.isAdmin
															) {
																handleChangeType(user.id, true);
															}
														}}
													>
														{option.icon === "unvailable" &&
														user.status === "Desativado" ? (
															<UnblockIcon />
														) : (
															optionsIcons[option.icon]
														)}

														{option.title === "Remover admin" && (
															<p>
																{user.isAdmin
																	? "Remover admin"
																	: "Promover a admin"}
															</p>
														)}

														{option.title === "Desativar usuário" && (
															<p>
																{user.status === "Desativado"
																	? "Ativar usuário"
																	: "Desativar usuário"}
															</p>
														)}

														{option.title !== "Remover admin" &&
															option.title !== "Desativar usuário" && (
																<p>{option.title}</p>
															)}
													</DropdownMenuItem>
												))}
											</DropdownMenuContent>
										</DropdownMenu>
									</span>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			)}

			{!users.length && (
				<div className="flex h-full w-full flex-1 flex-col items-center justify-center gap-6 px-16 py-52">
					<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
						<SearchIcon />
					</span>

					<div className="flex flex-col items-center gap-2">
						<h3 className="text-center font-inter text-P2 font-medium leading-140 text-neutral-1100">
							No momento você não possui nenhum usuário cadastrado.
						</h3>

						<p className="text-center font-inter text-P3 font-normal leading-160 text-neutral-500">
							Após o usuário realizar o cadastro, você poderá gerencia-los por
							aqui.
						</p>
					</div>
				</div>
			)}

			{isModalPasswordOpen && (
				<AdminUsersModalPassword
					closeModal={() => {
						setIsModalPasswordOpen(false);
						setCurrentUserId("");
					}}
					currentUserId={currentUserId}
				/>
			)}

			{isModalProfileOpen && (
				<AdminUsersModalProfile
					closeModal={() => setIsModalProfileOpen(false)}
					user={currentUserData}
				/>
			)}

			{desactivateUserModal && (
				<DeleteModal
					title="Deseja realmente desativar este usuário?"
					description="O usuário não terá mais acesso ao conteúdo até ser reativado."
					icon="delete-user.svg"
					confirmText="Desativar usuário"
					isLoading={isLoading}
					cancelAction={() => setDesactivateUserModal(false)}
					confirmAction={() => {
						handleChangeStatus(currentUserId, currentStatus);
						setDesactivateUserModal(false);
						setSuccessChangeStatusModal(true);
					}}
					closeModal={() => setDesactivateUserModal(false)}
				/>
			)}

			{successChangeStatusModal && (
				<AlertModal
					title={
						currentStatus === "1"
							? "Usuário ativado com sucesso!"
							: "Usuário desativado com sucesso!"
					}
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessChangeStatusModal(false);
						setCurrentUserId("");
					}}
					closeModal={() => {
						setSuccessChangeStatusModal(false);
						setCurrentUserId("");
					}}
				/>
			)}
		</div>
	);
}
