import dayjs from "dayjs";
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";

import { Card, CardContent, CardHeader } from "@/components/ui/card";
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart";

const generateMockData = () => {
	const mockData = [];
	const today = dayjs();
	for (let i = 0; i < 7; i++) {
		const date = today.subtract(i, "day").format("YYYY-MM-DD");
		mockData.push({
			date,
			braip: 0,
			monetizze: 0,
			theMart: 0,
		});
	}
	return mockData.reverse();
};

interface AdminPanelSalesPeriodProps {
	data_platform_sales: {
		[date: string]: {
			platform: "The Mart" | "Monetizze" | "Braip";
			date: string;
			count: number;
		}[];
	};
}

const chartConfig = {
	theMart: {
		label: "The Mart",
		color: "hsl(var(--the-mart))",
	},
	braip: {
		label: "Braip",
		color: "hsl(var(--braip))",
	},
	monetizze: {
		label: "Monetizze",
		color: "hsl(var(--monetizze))",
	},
} satisfies ChartConfig;

export function AdminPanelSalesPeriod({
	data_platform_sales,
}: AdminPanelSalesPeriodProps) {
	// Verificar se há dados de vendas, se não, usar dados mock
	const chartData = Object.keys(data_platform_sales).length
		? Object.keys(data_platform_sales).map((date) => {
				const dataForDate = data_platform_sales[date];
				const formattedData: Record<string, number | string> = { date };

				dataForDate.forEach((entry) => {
					formattedData[entry.platform.toLowerCase()] = entry.count;
				});

				return formattedData;
			})
		: generateMockData(); // Se não houver dados, gera dados mock

	return (
		<div className="flex flex-col gap-10 rounded-[8px] border border-solid border-neutral-100 p-6">
			<Card className="flex flex-col gap-10 border-none bg-transparent">
				<CardHeader className="p-0">
					<div className="flex items-center justify-between">
						<h2 className="font-inter text-P3 font-medium leading-140 text-neutral-1100">
							Vendas por período
						</h2>
						<ul className="flex gap-6">
							<li className="flex items-center justify-center gap-2">
								<span className="h-2.5 w-2.5 rounded-full bg-pink-800" />
								<p className="font-inter text-P6 font-medium leading-160 text-neutral-500">
									Braip
								</p>
							</li>
							<li className="flex items-center justify-center gap-2">
								<span className="h-2.5 w-2.5 rounded-full bg-blue-800" />
								<p className="font-inter text-P6 font-medium leading-160 text-neutral-500">
									Monetizze
								</p>
							</li>
							<li className="flex items-center justify-center gap-2">
								<span className="h-2.5 w-2.5 rounded-full bg-orange-800" />
								<p className="font-inter text-P6 font-medium leading-160 text-neutral-500">
									The Mart
								</p>
							</li>
						</ul>
					</div>
				</CardHeader>
				<CardContent className="p-0">
					<ChartContainer config={chartConfig} className="h-72 w-full">
						<AreaChart data={chartData} margin={{ left: -20, right: 12 }}>
							<CartesianGrid vertical={false} />
							<XAxis
								dataKey="date"
								tickLine={false}
								axisLine={false}
								tickMargin={8}
								tickFormatter={(value) => {
									return value;
								}}
							/>
							<YAxis
								tickLine={false}
								axisLine={false}
								tickMargin={8}
								tickCount={5}
							/>
							<ChartTooltip cursor={false} content={<ChartTooltipContent />} />
							<defs>
								<linearGradient id="fillBraip" x1="0" y1="0" x2="0" y2="1">
									<stop
										offset="0%"
										stopColor="var(--color-braip)"
										stopOpacity={0.5}
									/>
									<stop
										offset="100%"
										stopColor="var(--color-braip)"
										stopOpacity={0}
									/>
								</linearGradient>
								<linearGradient id="fillMonetizze" x1="0" y1="0" x2="0" y2="1">
									<stop
										offset="0%"
										stopColor="var(--color-monetizze)"
										stopOpacity={0.5}
									/>
									<stop
										offset="100%"
										stopColor="var(--color-monetizze)"
										stopOpacity={0}
									/>
								</linearGradient>
								<linearGradient id="fillTheMart" x1="0" y1="0" x2="0" y2="1">
									<stop
										offset="0%"
										stopColor="var(--color-theMart)"
										stopOpacity={0.5}
									/>
									<stop
										offset="100%"
										stopColor="var(--color-theMart)"
										stopOpacity={0}
									/>
								</linearGradient>
							</defs>
							<Area
								dataKey="braip"
								type="natural"
								fill="url(#fillBraip)"
								stroke="var(--color-braip)"
							/>
							<Area
								dataKey="monetizze"
								type="natural"
								fill="url(#fillMonetizze)"
								stroke="var(--color-monetizze)"
							/>
							<Area
								dataKey="theMart"
								type="natural"
								fill="url(#fillTheMart)"
								stroke="var(--color-theMart)"
							/>
						</AreaChart>
					</ChartContainer>
				</CardContent>
			</Card>
		</div>
	);
}
