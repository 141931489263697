function addLocalStorageItem(key: string, value: unknown) {
	localStorage.setItem(key, JSON.stringify(value));
}

function getLocalStorageItem(key: string) {
	const item = localStorage.getItem(key);
	return item ? JSON.parse(item) : null;
}

function removeLocalStorageItem(key: string) {
	localStorage.removeItem(key);
}

export { addLocalStorageItem, getLocalStorageItem, removeLocalStorageItem };
