import { Link } from "react-router-dom";

import ChevronRightIcon from "@/assets/chevron-right.svg?react";
import { authorFallback } from "@/utils/mask-author-fallback";

import { Tag } from "./tag";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";

export interface AdminTrainingCardProps {
	id: string;
	name: string;
	training_image: string;
	mentor: string;
	mentor_avatar: string;
	modules: number;
	lessons: number;
}

export function AdminTrainingCard({
	id,
	name,
	training_image,
	mentor,
	mentor_avatar,
	modules,
	lessons,
}: AdminTrainingCardProps) {
	return (
		<Link
			to={`/admin/academy/${id}`}
			className="flex cursor-pointer items-center gap-6 rounded-[8px] border border-solid border-neutral-100 p-4 text-neutral-600 transition-all hover:bg-neutral-100"
		>
			<img
				src={training_image}
				className="h-[64px] w-[86px] rounded-[6px] object-cover"
			/>

			<div className="flex flex-1 items-center gap-6">
				<div className="flex flex-1 flex-col justify-center gap-2">
					<span className="line-clamp-1 text-ellipsis font-inter text-P6 font-normal leading-160 text-neutral-600">
						Nome
					</span>

					<p className="line-clamp-1 text-ellipsis text-left font-inter text-P5 font-medium leading-160 text-neutral-1100">
						{name}
					</p>
				</div>

				<div className="flex flex-1 flex-col justify-center gap-2">
					<span className="line-clamp-1 text-ellipsis font-inter text-P6 font-normal leading-160 text-neutral-600">
						Mentor
					</span>

					<div className="flex items-center gap-3">
						<Avatar className="size-6">
							<AvatarImage src={mentor_avatar ?? ""} />
							<AvatarFallback className="!text-P7">
								{authorFallback(mentor ?? "Desconhecido")}
							</AvatarFallback>
						</Avatar>

						<p className="font-inter text-P5 font-normal leading-160 text-neutral-1100">
							{mentor ?? "Desconhecido"}
						</p>
					</div>
				</div>

				<div className="flex flex-1 flex-col justify-center gap-2">
					<span className="line-clamp-1 text-ellipsis font-inter text-P6 font-normal leading-160 text-neutral-600">
						Conteúdo
					</span>

					<div className="flex items-center gap-2">
						<Tag
							size="small"
							colors="minimalist-grey"
							className="rounded px-[8px] py-1 text-P7"
						>
							{modules > 1 ? `${modules} módulos` : `${modules} módulo`}
						</Tag>

						<Tag
							size="small"
							colors="minimalist-grey"
							className="rounded px-[8px] py-1 text-P7"
						>
							{lessons > 1 ? `${lessons} aulas` : `${lessons} aula`}
						</Tag>
					</div>
				</div>
			</div>

			<ChevronRightIcon width={24} height={24} />
		</Link>
	);
}
