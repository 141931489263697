import { TextareaHTMLAttributes, forwardRef } from "react";

import { cn } from "@/lib/utils";

export interface TextareaProps
	extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	hasError?: boolean;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
	({ className, hasError, ...props }, ref) => {
		return (
			<textarea
				className={cn(
					`flex min-h-[80px] w-full rounded-md border bg-background px-3 py-2 text-neutral-1100 ring-offset-background placeholder:text-muted-foreground focus:border-primary-600 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 ${
						hasError ? "!border-red-600 focus:border-red-600" : ""
					}`,
					className,
				)}
				ref={ref}
				{...props}
			/>
		);
	},
);
Textarea.displayName = "Textarea";

export { Textarea };
