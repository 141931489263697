import { CheckPasswordStrength } from "@/utils/check-password-strength";

interface PasswordStrengthProps {
	password: string;
}

export function PasswordStrength({ password }: PasswordStrengthProps) {
	const strength = {
		1: "Fraca",
		2: "Média",
		3: "Forte",
		4: "Segura",
	};

	const PASSWORD_STRENGTH = CheckPasswordStrength(password);

	const color: { [key: number]: string } = {
		1: "bg-red-600 transition-all duration-300 ease-in-out",
		2: "bg-orange-600 transition-all duration-300 ease-in-out",
		3: "!bg-blue-500 transition-all duration-300 ease-in-out",
		4: "!bg-primary-600 transition-all duration-300 ease-in-out important",
	};

	return (
		<div className="flex flex-col gap-3 pt-5">
			<div className="flex flex-1 items-center gap-4">
				{[1, 2, 3, 4].map((_, index) => (
					<span
						key={index}
						className={
							"h-[3px] flex-1 rounded-[100px] bg-neutral-200 " +
							`	${PASSWORD_STRENGTH > index && color[PASSWORD_STRENGTH]}`
						}
					/>
				))}
			</div>

			<div className="flex items-center justify-between">
				<p className="font-inter text-P6 font-normal leading-160 text-neutral-500">
					Força da sua senha
				</p>

				<p className="font-inter text-P6 font-medium leading-160 text-white">
					{strength[PASSWORD_STRENGTH as keyof typeof strength]}
				</p>
			</div>
		</div>
	);
}
